import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from 'axios';

const PedidosDoctores = (props) => {
    const {order,setOrder} = props;
    const [doctores,setDoctores] = useState([]);
    const [doctorId,setDoctorId] = useState(null);
    const [selectedDoctor,setSelectedDoctor] = useState({});
    const [consultoriosDoc,setConsultoriosDoc] = useState([]);
    const [consultorio,setConsultorio] = useState({});
  

    useEffect(()=>{
        axios.get('api/doctors').then((resp)=>{
            setDoctores(resp.data);
            var doctoresArray = resp.data;
            var dataDoctores = {};
            for (var i = 0; i < doctoresArray.length; i++) {
              dataDoctores[doctoresArray[i].id +".- " + doctoresArray[i].nombre+" "+doctoresArray[i].apellidos] = null; 
            }
            M.Autocomplete.init(document.querySelectorAll('#autocomplete-doctors'), {
                data: dataDoctores,
                minLength:0, 
                limit:20,
                onAutocomplete:(value)=>handleDoctor(value)
            })
        }).catch(e=>console.error(e))
    },[]);

    useEffect(()=>{
        if(doctorId > 0){
            let tempDoc = doctores.find((item)=>item.id === doctorId);
            if(tempDoc && tempDoc.id){
                console.log(tempDoc.doctor_offices)
                setConsultoriosDoc(tempDoc.doctor_offices);
                setSelectedDoctor(tempDoc);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[doctorId]);

    useEffect(()=>{
        if(selectedDoctor && selectedDoctor.id){
            console.log("selectedDoctor",selectedDoctor)
            setOrder({...order,doctor_id:selectedDoctor.id});
            M.FormSelect.init(document.querySelector('#consultorio-select'));
        }
    },[selectedDoctor])

    const handleDoctor = (value) =>{
        let tempArray = value.split(".- ");
        if(tempArray.length > 0){
            let intId =  parseInt(tempArray[0]);
            setDoctorId(intId);
        }else{
            setDoctorId(null)
        }
    }

    const handleConsultorio = (id) =>{
        let tempConsultorio =consultoriosDoc.find((item)=>item.id == id);
        setOrder({...order,direccion_envio:`${tempConsultorio.direccion}, Col.${tempConsultorio.colonia}, ${tempConsultorio.municipio}`})
        setConsultorio(tempConsultorio);
    }

    return (
        <div className='row m-0 p-10-h-20'>
            <div className='col s12 titles-badge centrado'>Datos del Doctor:</div>
            <div className='m-t-25'>&nbsp;</div>
            <div className='col s12 m-p-0 row'>
                <div className='col s2 m-p-0 centrar' style={{height:40,fontSize:16}}>
                    Enviar a:
                </div>
                <div className='col s10 m-p-0'>
                    <div className='input-field m-0 p-5'>
                        <input type="text" id="autocomplete-doctors" autocomplete="off"className="autocomplete" 
                        style={{border:'solid 1px #dee2e6',borderRadius:'4px', height:24, padding:'0 0 0 6px'}}/>
                    </div>
                </div>
            </div>
            {selectedDoctor && selectedDoctor.id ?
                <div className='row m-p-0'>
                <div className='col s12 row m-p-0'>
                    <div className='col s2 m-p-0 centrar' style={{height:40,fontSize:14}}>
                        Consultorio:
                    </div>
                    <div className="col s10 m-p-0">
                        <div className='input-field m-10'>
                            <select  id='consultorio-select' defaultValue={'0'} onChange={(e)=>handleConsultorio(e.target.value)}>
                                <option value="0" className='m-0' disabled selected>Seleccione el consultorio</option>
                                {consultoriosDoc.map((item)=>
                                    <option key={item.id} value={item.id} className='m-0'>{item.nombre}</option>
                                )}
                            </select>
                        </div>   
                    </div> 
                </div>
                <div className='col s12 row m-p-0'>
                    <div className='col s2 centrar m-p-0' style={{height:60,fontSize:14}}>
                        Direccion:
                    </div>
                    <div className="col s10 m-p-0">
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:30,padding:5,width:"100%"}}>
                            <span>{consultorio?.direccion}</span>  
                        </div>
                    </div>
                    <div className="col s4 m-p-0">
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:30,padding:5,width:"100%"}}>
                            <span>{consultorio?.municipio}</span>  
                        </div>
                    </div>
                    <div className="col s4 m-p-0">
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:30,padding:5,width:"100%"}}>
                            <span>{consultorio?.estado}</span>  
                        </div>
                    </div>
                    <div className="col s2 m-p-0">
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:30,padding:5,width:"100%"}}>
                            <span>{consultorio?.cp}</span>  
                        </div>
                    </div>

                    <div className="col s12 m-t-10 p-0">
                        <div className='p-0-10'>Observaciones del Doctor</div>
                        <div className="input-text p-0-10">
                            <textarea id="motivo" className="materialize-textarea validate"
                            style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:150,padding:5,background:'#EEE'}}
                            value={selectedDoctor.observaciones_generales}
                            onChange={(e)=>console.log("mocos")}
                            ></textarea>
                        </div>
                    </div>

                    <div className="col s12  m-p-0">
                        <div className='p-0-10'>Observaciones Importantes</div>
                        <div className="input-text p-0-10">
                            <textarea id="motivo2" className="materialize-textarea validate"
                            style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:100,padding:5,background:'#f75676',color:'#FFF'}}
                            value={selectedDoctor.observaciones_importantes}
                            onChange={(e)=>console.log("mocos")}
                            ></textarea>
                        </div>
                    </div>
                </div>
                </div>
                :
                <div className='col s12 p-20 centrar'>
                    <div className='notification-warning p-2'> Por favor selecciona un doctor </div>                   
                </div>   
                }
        </div>
    );
};

export default PedidosDoctores;