import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Loader from '../../Layout/Loaders/Loader';

const today = new Date();

const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

const VisitasTable = () => {
    const {user} = useContext(UserContext);
    const [rango,setRango] = useState({start:firstDayMonth,end:lastDayMonth});
    const [visitas,setVisitas] = useState([]);
    const [loaded,setLoaded] = useState(false);
    const [empleados,setEmpleados] = useState([]);
    const [filtros,setFiltros ] = useState({start:null,end:null,representante:'',doctor:'',filtrarXRango:false});
    const [sortBy, setSortBy] = useState({
        field:"",
        sortBy:"ASC"
    });
    const [permisos,setPermisos] = useState({
        visits_read:null,
        visits_update:null,
        visits_delete:null
    })

    useEffect(()=>{
        _getEmpleados();
        getDoctoresAutocomplete();
    },[]);

    useEffect(()=>{
       // setLoaded(false);
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                visits_read:checkPermiso("visits_read",user.permissions),
                visits_update:checkPermiso("visits_update",user.permissions),
                visits_delete:checkPermiso("visits_delete",user.permissions)
            }));
            getVisitasRango(rango);
        }
    },[user, rango]);

    useEffect(()=>{
        (permisos.visits_read !== null) && setLoaded(true);
    },[permisos]);
    
    const getVisitasRango = (range) =>{
        axios.post('api/visits_range',range).then(resp=>{
            console.log("Visitasw", resp)
            setVisitas(resp.data);
        }).catch(e=>console.log(e));
        M.Modal.init(document.querySelector('#filtrosvisitas'));
    }

    const _handleSort = (field) =>{
        if(field !== sortBy.field){
            setSortBy({...sortBy,field:field,sortBy:"DESC"});
        }else{
            setSortBy({...sortBy,sortBy:sortBy.sortBy === "DESC"?"ASC":"DESC"})
        }
    }
    useEffect(()=>{
        _ordenarTabla();
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[sortBy])
 
     const _ordenarTabla = () => {
         //se crea una copia de visitas para que la funcion de sort no modifique el array original
         let ordenados = visitas.slice(0);
       
         if(!sortBy.field.includes(".")){
            if(sortBy.sortBy === "ASC"){
                ordenados.sort((a,b)=>{
                    if (a[sortBy.field] > b[sortBy.field]) {
                        return 1;
                      }
                      if (a[sortBy.field] < b[sortBy.field]) {
                        return -1;
                      }
                      return 0;
                })
            }else{
                ordenados.sort((a,b)=>{
                    if (a[sortBy.field] < b[sortBy.field]) {
                        return 1;
                      }
                      if (a[sortBy.field] > b[sortBy.field]) {
                        return -1;
                      }
                      return 0;
                })
            }
         }else{
         //Caso con objetos anidados
           let arrayField = sortBy.field.split(".");
                if(sortBy.sortBy === "ASC"){
                    ordenados.sort((a,b)=>{
                        if (a[arrayField[0]][arrayField[1]] > b[arrayField[0]][arrayField[1]]) {
                            return 1;
                        }
                        if (a[arrayField[0]][arrayField[1]] < b[arrayField[0]][arrayField[1]]) {
                            return -1;
                        }
                        return 0;
                    })
                }else{
                    ordenados.sort((a,b)=>{
                        if (a[arrayField[0]][arrayField[1]] < b[arrayField[0]][arrayField[1]]) {
                            return 1;
                        }
                        if (a[arrayField[0]][arrayField[1]] > b[arrayField[0]][arrayField[1]]) {
                            return -1;
                        }
                        return 0;
                    })
                }
         }
      
         setVisitas(ordenados)
    }
    
    const _getEmpleados = () =>{
        axios.get('api/employees').then((resp)=>{
            setEmpleados(resp.data);
        }).catch(e=>console.log("Error:",e))
    }

    const getDoctoresAutocomplete = () => {
        axios.get('/api/doctors').then(res=>{
            var doctorsArray = res.data;
            var dataDoctor = {};
            for (var i = 0; i < doctorsArray.length; i++) {
                dataDoctor[doctorsArray[i].nombre+" "+doctorsArray[i].apellidos] = null; 
            }
            console.log("data doctor",dataDoctor)
            M.Autocomplete.init(document.querySelectorAll('#autocomplete-input'), {
                data: dataDoctor,
                minLength:0, 
                limit:10,
                onAutocomplete:_handleChange
            });
        }).catch(e=>console.log("Error",e));
    }

    const _handleChange = (value) =>{
        console.log('el valor',value);
       // setEmpleadoID(idEmp);
    }

    const _aplicarFiltros = () =>{
        console.log("Filtros",filtros);
        if(filtros.filtrarXRango){
            
        }
    }

    const _limpiarFiltros = () =>{

    }
    return (
        <div>
            {/**FILTOS MODAL **/}
            <div id="filtrosvisitas" className="modal">
                <div className="modal-content m-p-0 p-10">
                    <div className='close-btn modal-close'>
                        <i className="material-icons">close</i>
                    </div>
                    <div className='row modal-content m-p-0'>
                        <div className="row col s3 m12 p-5">
                            <div className="col s3 m1 m-p-0">
                                <img className='dentometric' src='/images/filter1.png' height={40} alt='filtros empleados'/>
                            </div>
                            <div className="col s3 m10 m-p-0 t-left"><h5 className='t-left'>Filtros</h5></div>
                        </div>
                        <div className='row'>
                            <div className="col s12">       
                                <div className="switch input-text">
                                    <label>
                                     {filtros.filtrarXRango ? 'Filtrar por Medico ó Representante':'Filtrar por rango de fecha'}
                                    <input id="visitar" type="checkbox" onChange={(e)=>setFiltros({...filtros,filtrarXRango:e.target.checked})}/>
                                    <span className="lever"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {!filtros.filtrarXRango ? 
                            <div className='row col s12 height-200'>
                                <div className='col s12 m12 m-t-10'>
                                    <label>Filtrar por representante</label>
                                    <select className='browser-default' value={filtros.representante} onChange={(e)=>setFiltros({...filtros,representante:e.target.value})}>
                                        <option value="" disabled>Filtrar por Representante</option>
                                        {empleados.map((item) =>
                                            <option value={item.id}  key={item.id}>{item.nombre} {item.apellidos}</option>
                                        )}
                                    </select>
                                </div>
                                <div className='col s12 m12  m-t-10'>
                                    <label>Filtrar por doctor</label>
                                    <div className='input-field m-p-0'>
                                        <input type="text" id="autocomplete-input" class="autocomplete" autoComplete="off"/>
                                    </div> 
                                </div> 
                            </div>  
                            : 
                            <div className='row col 6'>
                                <div className="col s12 m-t-10 input-text">
                                    <label htmlFor="inicio">Desde</label>
                                    <input id="inicio" type="date" value={filtros.start} onChange={(e)=>setFiltros({...filtros,start:e.target.value})}/>
                                </div>

                                <div className="col s12 m-t-10 input-text">
                                    <label htmlFor="fin">Hasta</label>
                                    <input id="fin" type="date"  value={filtros.end}  onChange={(e)=>setFiltros({...filtros,end:e.target.value})}/>
                                </div>
                            </div>   
                        }
                    </div>
               
                    <div className="modal-footer row m-p-0">
                        <div className="col s6 m8"></div>
                        <div className="col s3 m2">
                            <button className="btn-blanco waves-effect waves-light row"
                            style={{padding:'4px 8px!important',}}
                            onClick={(e)=>console.log()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/clear-filters.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0  modal-close' style={{fontSize: '14px', cursor:'pointer' }} onClick={(e)=>_limpiarFiltros()}> &nbsp;Cancelar</div>
                            </button>
                        </div>
                        <div className="col s3 m2">
                            <button className="btn-blanco waves-effect waves-light row modal-close"
                            style={{padding:'4px 8px!important',}}
                            onClick={(e)=>_aplicarFiltros()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter1.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Filtrar</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/** FIN FILTOS MODAL **/}
            <div className='container-admin-menu '> <Breadcrumb hasButton={true} hasFilters={false} /> </div>
            <div className='container-admin-container'> 
                <div className='container-admin z-depth-1 row'>
                    {loaded ?
                        <>
                            <div className='row col s12 info-badge border-r-10 m-p-0 centrar-h'>
                                <div className='col s12 m9'>
                                    <h6><span>Visitas del Mes</span> | <span>Fecha - {today.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</span> </h6>
                                </div>
                                <div className="col s12 m3 t-right">
                                    <button className="btn-blanco row modal-trigger" data-target="filtrosvisitas" type="submit" name="action">
                                        <div className='col s2 m2 m-p-0 centrar'>
                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter.png' alt='doctor dento metric' style={{width:'20px',}} />
                                        </div>    
                                        <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> + Filtros</div>
                                    </button>
                                </div>
                            </div>
                            {permisos.visits_read ?
                                <div className='m-p-0'>
                                    <div>
                                        <div className='col table table-doc m-p-0' style={{width:'100%'}}>
                                            <div className='table-header row m-t-20 movil-oculto'>
                                                <div className='col s10 m2 centrar'> Doctor &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("doctor.apellidos")}}>unfold_more</i></div>
                                                <div className='col s10 m2 centrar'> Representante &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("representante")}}>unfold_more</i></div>
                                                <div className='col s10 m2 centrar'> Lugar / Horario</div>
                                                <div className='col s12 m1 centrar'> Fecha Visita <i className="material-icons" onClick={(e)=>{_handleSort("fecha_visita")}}>unfold_more</i></div>
                                                <div className='col s12 m1 centrar'> Hora <i className="material-icons" onClick={(e)=>{_handleSort("hora")}}>unfold_more</i></div>
                                                <div className='col s12 m1 centrar'> Causa</div>
                                                <div className='col s12 m2 centrar'> Observaciones</div>
                                                <div className='col s12 m1'> Acciones</div>
                                            </div>
                                            
                                            <div className='table-container'>
                                            {visitas.map((visita)=>
                                                <div className='row' key={visita.id}>
                                                    <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                        <div className='table-header-movil'> Nombre </div>
                                                    </div>
                                                    <div className='col s9 m2 t-centrado'> 
                                                        <div>{visita?.doctor.apellidos} {visita?.doctor.nombre}</div>
                                                    </div>
                                                    <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                        <div className='table-header-movil'> Representante </div>
                                                    </div>
                                                    <div className='col s9 m2 t-centrado'> 
                                                        <div className='centrado'>{visita.representante}</div>
                                                    </div>
                                                    <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                        <div className='table-header-movil'> direccion </div>
                                                    </div>
                                                    <div className='col s9 m2 t-centrado'> 
                                                        <div className='centrado'>{visita.direccion} </div>
                                                    </div>
                                                    <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                        <div className='table-header-movil'> Fecha </div>
                                                    </div>
                                                    <div className='col s9 m1 centrar row '>
                                                        <div className='centrado'>{visita.fecha_visita}</div>
                                                    </div>
                                                    <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                        <div className='table-header-movil'> Hora </div>
                                                    </div>
                                                    <div className='col s9 m1 t-centrado'> 
                                                        <div className='centrado'>{visita.hora}</div>
                                                    </div>
                                                    <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                        <div className='table-header-movil'> Causa </div>
                                                    </div>
                                                    <div className='col s9 m1 t-centrado'> 
                                                        <div className='centrado'>
                                                            {visita.causa_visita}
                                                        </div>
                                                    </div>
                                                    <div className='col s12 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                        <div className='table-header-movil'> observaciones </div>
                                                    </div>
                                                    <div className='col s12 m2 t-centrado'> 
                                                    <div className='centrado'>{visita.observaciones.slice(0,25)} ...</div>
                                                    </div>
                                                    <div className='col s12 m1 movil-oculto'>
                                                        <div className='row centrado cont-textos'>
                                                        {permisos.visits_update &&
                                                            <Link to={`${visita.id}`} style={{color:'green'}}
                                                            data-position="top"
                                                            data-tooltip="Editar"
                                                            className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                                <i className="material-icons m-p-0" style={{fontSize:'20px',}}>create</i>
                                                            </Link>
                                                        }
                                                            <Link to={`detail/${visita.id}`} 
                                                            data-position="top"
                                                            data-tooltip="Ver Detalle"
                                                            className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                                <i className="material-icons m-p-0" 
                                                                style={{fontSize:'20px',}}>content_paste</i>
                                                            </Link>
                                                            {permisos.visits_delete &&
                                                            <div className='col m6 m-p-0 centrar movil-100'>
                                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} >delete_forever</i>
                                                            </div>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            </div>    
                                            
                                        </div>
                                    </div>
                                </div>
                                :
                                <NoPermissions />
                            } 
                        </>
                        :
                        <Loader />
                    }
                </div>
            </div>
        </div>
    );
};

export default VisitasTable;