import React, { useEffect, useState }  from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import M from 'materialize-css'
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../axios';
import PermisosModal from './PermisosModal';


const Roles = () => {
    const [roles,setRoles] = useState([]);
    const [permisosCurrent,setPermisosCurrent] = useState([]);
    const [usuariosCurrent,setUsuariosCurrent] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        var elems = document.querySelector('#modal-users');
        M.Modal.init(elems);
        M.Modal.init(document.querySelector('#modal-permisos'));
        getAllRoles();
    },[])

    const getAllRoles = () =>{
        axios.get('api/roles').then((res)=>{
            setRoles(res.data)
        }).catch(e=>console.log("ERROR",e))
    };

    const getRoleUsers = (rolName) =>{
        let data = {rol_name: rolName}
        axios.post('api/get_rol_users',data).then((res)=>{
            console.log("Usuarios",res.data.users)
            setUsuariosCurrent(res.data.users);
        }).catch(e=>console.log("Error",e))
    }

    const handleLink = (userID) =>{
        _closeModal();
        navigate(`/admin/usuarios/detail/${userID}`)
    }

    const _openModal = (rolName) => {
        let instance = M.Modal.getInstance(document.querySelector('#modal-users'));
        instance.open();
        getRoleUsers(rolName);
    }
    const _closeModal = () => {
        let instance = M.Modal.getInstance(document.querySelector('#modal-users'));
        instance.close();
    }
    const _openPermisosModal = (permisos = null) => {
        setPermisosCurrent(permisos)
        let instance = M.Modal.getInstance(document.querySelector('#modal-permisos'));
        instance.open();
    }
    const _closePermisosModal = () => {
        let instance = M.Modal.getInstance(document.querySelector('#modal-permisos'));
        instance.close();
    }

    return (
        <>
            <div id="modal-users" className="modal border-r-6">
                <div className="modal-content border-r-6">
                    <div className='close-btn' onClick={_closeModal}>
                        <i className="material-icons">close</i>
                    </div>
                    <div className="col s12 m12 row m-p-0 " style={{padding:'0px!important', margin:'0px!important',}}>
                        <div className='col s4 m2 row m-p-0'>
                            <div className='m-p-0' style={{height:'4%',}}>
                                <img className='dentometric m-p-0' src='/images/rol6.png' alt='dento metric' 
                                style={{height:'100%', width:'100%'}} />
                            </div>
                        </div>
                        <div className='col s8 m10 row'>
                            <div className='p-5' style={{height:'18%',}}> &nbsp;</div>
                            <div className='p-5'>Nombre del rol</div> 
                            <h5 className=' m-p-0'>el nombre de el rol a mostrar </h5>
                        </div>
                    </div>
                    <h6>Listado de  usuarios con acceso a este Rol </h6>
                    <div className='table col s12 m-p-0'>
                        <div className='table-header row m-p-0 movil-oculto'>
                            <div className='col s10 m1 m-p-0' style={{width:'34px'}}/>
                            <div className='col s12 m5 p-5'> Nombre</div>
                            <div className='col s12 m4 p-0-10'> Email</div>    
                            <div className='col s12 m2 p-5 centrar'> Ver Detalle</div>       
                        </div> 
                    </div>
                    
                    {usuariosCurrent.map((usuario)=>
                        <div className='col s12 m12 row m-0 p-2'>
                            <div className='col s1 m1' style={{width:'34px',}}>
                                <span className='p-5'>
                                    <img className='dentometric m-p-0' src='/images/default-user.png' alt='dento metric' 
                                    style={{height:'20px', width:'25px',}} />
                                </span>
                            </div>
                            <div className='col s9 m5'>
                                <span className='p-5'>{usuario.name}</span>
                            </div>
                            <div className='col s8 m4'> {usuario.email} </div>
                            <div className='col s2 m2 m-p-0 centrar'> 
                                <span className='col s12 m4 movil-visible'> Ver </span>
                                <div className='col m6 m-p-0 centrar movil-100' onClick={(e)=>handleLink(usuario.id)}>
                                    <i className="material-icons m-p-0" style={{fontSize:'20px',color:'blue',cursor:'pointer'}}>content_paste</i>
                                </div>
                            </div>
                            <div className='col s12 m4 movil-visible'> <hr></hr> </div>
                        </div>
                    )}
                    
                    
                    <div className='p-0-24' >&nbsp;</div>
                </div>
            </div>
            {/*INICIO PERMISOS MODAL */}
            <PermisosModal permisosCurrent={permisosCurrent} _closePermisosModal={_closePermisosModal}/>

            {/*FIN DEL MODALES */}
            <div className='container-admin-menu '> <Breadcrumb hasButton={true} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <div className='container-admin-container m-p-0'>
                        <div className='row s12 info-badge border-r-10 m-p-0' style={{bordeRadius:'10px!important', padding:'0px!important', margin:'0px!important'}}>
                            <div className="col s12 m12 row m-p-0 " style={{padding:'0px!important', margin:'0px!important',}}>
                                <div className='col s12 m2 m-p-0' 
                                style={{height:'10%',}}>
                                    <img className='dentometric m-p-0 border-r-10' src='/images/rol.jpg' alt='dento metric' 
                                    style={{height:'100%', width:'100%'}} />
                                </div>
                                <div className="col s12 m10 m-6  centar"> <br></br> Roles de usuarios</div> 
                            </div>
                        </div>
                    </div>
                    
                    <div className='table col s12 p-5'>
                        <div className='table-header row m-p-0 movil-oculto'>
                            <div className='col s10 m1' style={{width:'40px',}}/>
                            <div className='col s12 m5'> Rol de acceso</div>
                            <div className='col s12 m2'> Usuarios</div>
                            <div className='col s12 m2'> Permisos</div>
                            <div className='col s10 m1'> Acciones </div>                 
                        </div> 
                        {roles.map((rol)=>
                            <div className='table-container row m-0 p-5' key={rol.id}>
                                <div className='col s12 m1 movil-oculto row' style={{width:'34px',}}>
                                    <span className='p-5'>
                                        <img className='dentometric m-p-0' src='/images/rol5.png' alt='dento metric' 
                                        style={{height:'25px', width:'25px',}} />
                                    </span>
                                </div>
                                <div className='col s3 m4 movil-visible'>
                                    <div className='table-header-movil'> Rol </div>
                                </div>
                                <div className='col s9 m5 row m-0 p-5'>
                                    <div className='col s2 m12 m-p-0 movil-visible'>
                                        <img className='dentometric m-p-0' src='/images/rol5.png' alt='dento metric' 
                                        style={{height:'25px', width:'25px',}} />
                                    </div>
                                    <div className='col s10 m12 m-0 p-t-15'>{rol.name}</div>
                                </div>
                                <div className='col s6 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                    <div className='table-header-movil'> Usuarios </div>
                                </div>
                                <div className='col s6 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                    <div className='table-header-movil'> Permisos </div>
                                </div>
                                <div className='col s6 m2 m-0'> 
                                    <button className="btn-blanco row m-0 p-1-5 centrar-l" id="#modal-users"
                                    onClick={()=>_openModal(rol.name)} style={{width:'auto'}}>
                                        <div className='col s10 m10 m-p-0 centrar-l' style={{fontSize: '12px', cursor:'pointer'}}>Ver Usuarios &nbsp; &nbsp; </div>
                                        <div className='col s2 m2 m-p-0 centrar'>
                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/rol2.png' alt='doctor dento metric' style={{width:'20px',height:'20px',}} />
                                        </div> 
                                    </button>
                                </div>
                                <div className='col s6 m2 m-0'> 
                                    <button className="btn-blanco row m-0 p-1-5 centrar-l" id="#modal-users" onClick={()=>_openPermisosModal(rol.permissions)} style={{width:'auto'}}>
                                        <div className='col s10 m10 m-p-0 centrar-l' style={{fontSize: '12px', cursor:'pointer'}}>Ver Permisos &nbsp; &nbsp; </div>
                                        <div className='col s2 m2 m-p-0 centrar'>
                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/rol3.png' alt='doctor dento metric' style={{width:'20px',height:'20px',}} />
                                        </div>    
                                    </button>
                                </div>
                                <div className='col s12 m1 row'>
                                    <div className='col s4 m4 movil-visible'>
                                        <div className='table-header-movil'> Accionss </div>
                                    </div>
                                    <div className="col s4 m6 m-0 p-0-2 centrar tooltipped" style={{color:'green',border:'solid green'}}
                                    data-tooltip="Editar" > 
                                        <Link to={`${rol.id}`} >
                                            <span className='movil-visible'> Editar </span>
                                            <i className="material-icons m-p-0 t-verde" 
                                            style={{color:'green!important', cursor:'pointer',fontSize:'20px',}}>create</i>
                                        </Link>
                                    </div>
                                    <div className="col s4 m6 m-0 p-0-2 centrar tooltipped" data-tooltip="Borrar">
                                    <span className='movil-visible'> Borrar </span>
                                        <i className="material-icons m-p-0 t-rojo" 
                                        style={{color:'red!important',cursor:'pointer', fontSize:'20px',}}>delete_forever</i>
                                    </div>
                                </div>
                                <div className='col s12 m12 movil-visible'> <hr></hr> </div>
                            </div>
                        )}                    
                    </div>
                </div>
            </div>
        </>
    );
};

export default Roles;