import React,{useEffect, useRef, useState} from 'react';
import Spinner from '../../Layout/Loaders/Spinner';
import axios from '../../axios';
import M from 'materialize-css';

const layoutPaciente = {
    nombre:'',
    fecha_nacimiento:'',
    telefono:'',
    celular:'',
    direccion:'',
    ciudad:'',
    cp:'',
    rfc:''
}
//ID PARA EL ESTADO DE JALISCO
const estadoId = 14;

const PedidosPaciente = (props) => {
    const {order,setOrder,patient_create,patient_update} = props;
    const [paciente,setPaciente] = useState(layoutPaciente);
    const [pacientes,setPacientes] = useState([]);
    const [ciudades,setCiudades] = useState([]);
    const [loading,setLoading] = useState(false);
    const [buttonLabel,setButtonLabel] = useState('Guardar Paciente');
    const [envios,setEnvios] = useState([]);
    //retoma el valor del state Order despues de que se renderiza
    const stateRef = useRef();
    stateRef.current = order;

    useEffect(()=>{
       
        axios.get(`api/municipalities-by-state/${estadoId}`).then((resp)=>{
            console.log("municipios",resp);
            setCiudades(resp.data);
        }).catch((e)=>console.log("Error: ",e));

        axios.get('api/deliveries').then(resp=>{
            console.log("envio",resp.data)
            setEnvios(resp.data)
        }).catch(e=>console.log("Error: ",e))
    },[]);

    useEffect(()=>{
       _getPacientes();
    },[]);

    const _getPacientes = ()=>{
        axios.get('api/patients').then((resp)=>{
            setPacientes(resp.data);
            var pacientesArray = resp.data;
            var dataPacientes = {};
            for (var i = 0; i < pacientesArray.length; i++) {
              dataPacientes[pacientesArray[i].nombre] = null; 
            }
            initAutocomplete(pacientesArray,dataPacientes);
        }).catch(e=>console.error(e))
    }

    const initAutocomplete = (pacientesArray,dataPacientes)=>{
        const elem = document.querySelectorAll('#paciente-autocomplete');
        const options = {
            data:dataPacientes,
            minLength:5, 
            limit:20,
            onAutocomplete:(value)=>{
               // handlePacienteName(value);
                _setPacienteSelected(pacientesArray,value);
            }
        };
        M.Autocomplete.init(elem,options,{isOpen:true});
    }

    const cleanData = () =>{
        setPaciente(layoutPaciente);
        let elem = document.querySelectorAll('#paciente-autocomplete');
        elem[0].value="";
        setButtonLabel('Guardar Paciente');
        _getPacientes();
    }

    const handlePacienteName = (value)=>{
        if(value.length >= 5 ){ 
            console.log("value",value)
            setPaciente({...paciente,nombre:value})
        }
    }
    
    const _setPacienteSelected = (listado,value) =>{
        console.log("set paciente selected",stateRef.current)
        let tempPactiente = listado.find(({nombre})=>String(value).trim() === nombre);
        if(tempPactiente){
            setPaciente(tempPactiente);
            setOrder({...stateRef.current,patient_id:tempPactiente.id}); 
          //  setButtonLabel('Editar Paciente');
        }
    }

    const savePaciente = () =>{
        console.log("save...",paciente);
        axios.post('api/patients',paciente).then((res)=>{
            console.log(res.data);
            setPaciente(res.data);
            setOrder({...order,patient_id:res.data.id}); 
            setLoading(false);
           // setButtonLabel('Editar Paciente');
        }).catch((e)=>{
             console.log("Error: ",e)
             setLoading(false);
        });
    }

   /* const updatePaciente = () =>{
        axios.put(`api/patients/${paciente.id}`,paciente).then((res)=>{
            console.log(res.data);
            setPaciente(res.data);
            setOrder({...order,patient_id:res.data.id}); 
            setLoading(false);
           // setButtonLabel('Editar Paciente');
        }).catch((e)=>{
             console.log("Error: ",e)
             setLoading(false);
        });
    }*/

    const _handlePaciente = () =>{
       /* if(paciente.id){
            updatePaciente();
        }else{*/
            setLoading(true);
            savePaciente();
        //}
    } 

    const _handleChecked = (e) =>{
        let value = e.target.checked?1:0;
        setOrder({...order,con_cita:value});
    }

    return (
        <div className='container ancho100 m-0 p-t-15'>
            <div className="row ancho100" id='general-form'>
                <div className='col s12 m6 row'>
                    <div className='col s12 titles-badge centrado'>Datos del Paciente</div>
                    
                            <div className="col s12 m-t-10 input-text">
                                <label className='m-p-0'>Nombre</label>
                                <div className="input-field m-p-0">
                                    <input type="text" 
                                        id="paciente-autocomplete"
                                        autoComplete="off" 
                                        className="autocomplete" 
                                        onChange={(e)=>handlePacienteName(e.target.value)}
                                        style={{backgroundColor:"#ffffff",fontSize:"14px",height:24,border:"solid 1px #dee2e6",borderRadius:4}}   
                                        />
                                </div>
                            </div>
                            <div className="col s12 m9">
                                <div className="input-text">
                                    <label htmlFor="name">Direccion</label>
                                    <input className="validate" id="direccion" type="text" value={paciente.direccion} onChange={(e)=>setPaciente({...paciente,direccion:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m3">
                                <div className="input-text">
                                    <label htmlFor="cp">C.P.</label>
                                    <input id="cp" type="number"  value={paciente.cp} onChange={(e)=>setPaciente({...paciente,cp:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m4">
                                <div className="input-text">
                                    <label htmlFor="name">F. Nacimiento</label>
                                    <input id="fecha" type="date" value={paciente.fecha_nacimiento} onChange={(e)=>setPaciente({...paciente,fecha_nacimiento:e.target.value})} disabled={paciente.id?true:false}/>
                                </div>
                            </div>
                            <div className="col s12 m4">
                                <div className="input-text">
                                    <label htmlFor="tel">Teléfono</label>
                                    <input id="tel" type="text" value={paciente.telefono} onChange={(e)=>setPaciente({...paciente,telefono:e.target.value})}/>
                                </div>
                            </div>
                             <div className="col s12 m4">
                                <div className="input-text">
                                    <label htmlFor="cel">Celular</label>
                                    <input id="cel" type="text" value={paciente.celular} onChange={(e)=>setPaciente({...paciente,celular:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m8">
                                <div className="input-text">
                                    <label htmlFor="ciudad">Ciudad</label>
                                    <select className='browser-default' value={paciente.ciudad} onChange={(e)=>setPaciente({...paciente,ciudad:e.target.value})}>
                                        <option value="">Seleccione la ciudad</option>
                                        {ciudades.map(ciudad=>
                                            <option key={ciudad.id} value={ciudad.description}>{ciudad.description}</option>
                                        )}  
                                    </select>
                                </div>
                            </div>
                            <div className="col s12 m4">
                                <div className="input-text p-2">
                                    <label htmlFor="cita" className='centrar'>Cita <br></br></label>
                                    <div className='centrado'>
                                        <div className="switch">
                                            <br></br>
                                            <label>
                                                Con
                                                <input type="checkbox"  checked={order.con_cita} onChange={(e)=>_handleChecked(e)}/>
                                                <span className="lever"></span>
                                                Sin
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s6 p-20 m-0 p-2">
                                <button className='btn-delete width-full' onClick={cleanData}>Limpiar Datos</button>
                            </div>
                            {!paciente.id&&
                                <div className="col s6 p-20 m-0 p-2">
                                {(patient_create || patient_update) &&
                                    <button className='btn-info width-full' onClick={(e)=>_handlePaciente()}>{buttonLabel}</button>
                                }
                                </div>  
                                
                            }
                       
                        {!loading ?
                        <></>:
                        <div className='col s12 height-200 centrar'>
                            <Spinner size={"big"} />
                        </div>
                    }
                </div>
                <div className='col s12 m6 row'>
                    <div className='col s12 titles-badge centrado'>Datos del Envio</div>
                    <div className="col s12 m12 m-t-10">
                        <div className="input-text">
                            <label>Forma de envío</label>
                            <select className='browser-default' defaultValue={""} 
                                onChange={(e)=>setOrder({...order,delivery_id:e.target.value})}
                                style={{backgroundColor:"#ffffff",fontSize:"14px",height:30,border:"solid 1px #dee2e6"}}>
                                <option value="">Selecciona una opcion</option>
                                {envios.map(envio=>
                                    <option key={envio.id} value="1" >{envio.descripcion}</option>
                                )} 
                            </select>
                        </div>
                    </div>
                    <div className="col s12">
                        <div className="input-text">
                            <label htmlFor="name">Enviar a</label>
                            <input id="name" type="text" value={order.direccion_envio} onChange={(e)=>setOrder({...order,direccion_envio:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 p-0-10 input-text">
                        <label htmlFor="comentarios"> Comentarios del paciente para el envio</label>
                        <textarea id="comentarios" type="text" style={{background:'#ffffff', minHeight:'80px'}} value={order.comentarios_envio  } onChange={(e)=>setOrder({...order,comentarios_envio:e.target.value})}></textarea>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default PedidosPaciente;