import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import axios from '../../../axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

//ID PARA EL ESTADO DE JALISCO
const estadoId = 14;

const New = () => {
    const [cliente,setCliente] = useState({
        nombre:'',
        direccion:'',
        cp:'',
        ciudad:'',
        fecha_nacimiento:'',
        telefono:'',
        celular:'',
        rfc:'',
    });
    const navigate = useNavigate();
    const [ciudades,setCiudades] = useState([]);

    useEffect(()=>{
        axios.get(`api/municipalities-by-state/${estadoId}`).then((resp)=>{
            console.log("municipios",resp);
            setCiudades(resp.data);
        }).catch((e)=>console.log("Error: ",e));
    },[])

    const _saveCliente = (e) => {
        e.preventDefault();
        console.log("cliente..",cliente)
        axios.post('api/patients',cliente).then(resp=>{
            console.log('guardo paciente', resp.data)
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>clientes creado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/clientes")
            }, 3000);
        }).catch(e=>console.log(e))
    }

    return (
        <>
        <div className='container-admin-menu '> <Breadcrumb/> </div>
        <div className='container-admin-container'>
            <div className='container-admin  z-depth-1'>
            
                <div className='row col s12 m-p-0 info-badge border-r-10'>
                    <div className="col s12 m1 m-p-0 centrar">
                        <div className='sombra-azul-chica col s12 m2 m-p-0' 
                        style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'36px', width:'36px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                            <img className='dentometric m-p-0' src='/images/paciente.png' alt='dento metric' 
                            style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                        </div>
                    </div>
                    <div className="col s12 m9 m-0 p-8">
                        NUEVO - Alta de Paciente
                    </div>  
                    <div className="col s12 m2 m-0 p-8 t-right">
                        <Link to="../clientes">Regresar</Link>
                    </div>  
                </div>

                <form  onSubmit={_saveCliente} className="row ancho100" id='general-form'>
                    <div className="col s12 m12 m-t-25">
                        <div className="input-text">
                            <label htmlFor="name"> Nombres completo </label>
                            <input id="name" type="text"  
                            onChange={(e)=>setCliente({...cliente,nombre:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 m10">
                        <div className="input-text">
                            <label htmlFor="direccion"> Direccion </label>
                            <input id="direccion" type="text" 
                            onChange={(e)=>setCliente({...cliente,direccion:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 m2">
                        <div className="input-text">
                            <label htmlFor="cp">C.P.</label>
                            <input id="cp" type="text" 
                            onChange={(e)=>setCliente({...cliente,cp:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 m3">
                        <div className="input-text">
                            <label htmlFor="ciudad">Ciudad</label>
                            <select className='browser-default' value={cliente.ciudad} onChange={(e)=>setCliente({...cliente,ciudad:e.target.value})}>
                                <option value="">Seleccione la ciudad</option>
                                {ciudades.map(ciudad=>
                                    <option key={ciudad.id} value={ciudad.description}>{ciudad.description}</option>
                                )}  
                            </select>
                        </div>
                    </div>
                    <div className="col s12 m3">
                        <div className="input-text">
                            <label htmlFor="fNacimiento">Fecha Nacimiento</label>
                            <input id="fNacimiento" type="date" 
                            onChange={(e)=>setCliente({...cliente,fecha_nacimiento:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 m3">
                        <div className="input-text">
                            <label htmlFor="telefono">Teléfono</label>
                            <input id="telefono" type="text" 
                            onChange={(e)=>setCliente({...cliente,telefono:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 m3">
                        <div className="input-text">
                            <label htmlFor="rfc"> RFC </label>
                            <input id="rfc" type="text" 
                            onChange={(e)=>setCliente({...cliente,rfc:e.target.value})}/>
                        </div>
                    </div>

                    <div className="col s12 m12 centrar-r"> 
                        <button className="btn waves-effect waves-light" type="submit" name="action"> Guardar datos
                            <i className="material-icons right">save</i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>
    );
};

export default New;