import React, {useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import M from 'materialize-css';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import Spinner from '../../../Layout/Loaders/Spinner';

const Edit = () => {
    let {tipoId} = useParams();
    const user = useContext(UserContext);
    const [tipo,setTipo] = useState({descripcion:""});
    const [permisos,setPermisos] = useState({
        employee_types_read:null,
        employee_types_update:null
    });
    const [validate, setValidate] = useState(true);
    const [loaded,setLoaded] = useState(false);
    const navigate = useNavigate()

    useEffect(()=>{
        axios.get(`api/employeetipes/${tipoId}`)
        .then((res)=>{
            setTipo(res.data)
        }).catch(e=>console.log("error",e))
    },[tipoId])

    useEffect(()=>{
        if(user.user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                employee_types_update:checkPermiso("employee_types_update",user.user.permissions),
                employee_types_read:checkPermiso("employee_types_read",user.user.permissions)
            }));

            axios.get(`api/employeetipes/${tipoId}`)
            .then((res)=>{
                setTipo(res.data);
            }).catch(e=>console.log(e))
        }
    },[tipoId,user])

    useEffect(()=>{
        (permisos.employee_types_read !==null) && setLoaded(true)
    },[permisos]);


    const _update = (e) =>{
        e.preventDefault()
        axios.put(`api/employeetipes/${tipoId}`,tipo)
        .then((res)=>{
            setTipo(res.data)
            setValidate(true);
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Tipo actualizado exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/tipoempleados")
            }, 3000);
        }).catch(e=>{
            console.log(e)
            e.response.data.errors.descripcion.length > 0 && setValidate(false);   
        })
    }

    return (
        <>
        <div className='container-admin-menu'><Breadcrumb hasButton={false} hasFilters={false} actual={tipo.descripcion}/></div>
        <div className='container-admin-container'>
            <div className='container-admin'>
            {loaded ?
                <>
                {permisos.employee_types_read ?
                    <>
                    <h5>Editar Tipo de empleado</h5>
                    <form className="row ancho100" id='general-form'  onSubmit={_update}>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="descripcion">Descripcion</label>
                                <input id="descripcion" type="text" className="validate" value={tipo.descripcion} 
                                onChange={(e)=>setTipo({...tipo,descripcion:e.target.value})}/>
                                {!validate &&  <span class="helper-text t-rojo">El campo de descripcion es obligatorio</span>}
                            </div>
                        </div>
                        <div className='col s12 p-t-35'> 
                            {permisos.employee_types_update &&
                            <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                                <i className="material-icons right">send</i>
                            </button>
                            }
                        </div>
                    </form>
                    </>
                        :
                        <NoPermissions />
                        }
                    </>  
                    :
                    <div className='height-200 centrar'>
                        <Spinner size={"medium"} />
                    </div>
                }
            </div>
        </div>
    </>
    );
};

export default Edit;