import React from 'react';

const Spinner = ({size}) => {
    // Size - tamanio del spinner big,medium,small// Size - tamanio del spinner big,medium,small
    return (
        <div className={`preloader-wrapper ${size} active`}>
            <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div><div className="gap-patch">
                    <div className="circle"></div>
                </div><div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    );
};

export default Spinner;