import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import '../Admin/admin.scss';
import MenuLeft from '../MenuLeft/MenuLeft';
import TopMenu from '../TopMenu/TopMenu';

const Admin = () => {
    //console.log("ADMIN")
    const [wMenuLateral, setWMenuLateral] = useState('15%');
    const [wContainerAdmin, setWContainerAdmin] = useState('100%');
    const [colapsed, setColapsed] = useState(false)
    return (
        <div className='bg-container-admin'>
            <div className='row m-p-0'>
                <div className='col cont-menu-lateral' style={{width:wMenuLateral, overflowY:'hidden',overflowX:'hidden', zIndex:99}}>
                    <MenuLeft colapsed={colapsed} 
                    setColapsed={setColapsed} 
                    setWContainerAdmin={setWContainerAdmin} 
                    setWMenuLateral={setWMenuLateral} />
                </div>
                <div className='col con-container-gral m-0' style={{width:wContainerAdmin}}>
                    <div className='movil-visible m-p-0'>
                        <TopMenu/>
                    </div>
                    <div style={{paddingLeft:wMenuLateral,}}>
                        <div className='movil-oculto'>
                         <TopMenu/>
                        </div>                      
                        <div className='row m-p-0'>
                            <Outlet /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Admin;