
import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import '../Doctores/doctores.scss';
import M from 'materialize-css';
import DoctorTable from './DoctorTable';
import DoctorCard from './DoctorCard';
import ImportadorModal from './ImportadorModal';
import DoctoresReport from './DoctoresReport';
import { BlobProvider } from '@react-pdf/renderer';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import Loader from '../../Layout/Loaders/Loader';
import NoPermissions from '../NoPermissions/NoPermissions';

const today = new Date().toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' });

const Doctores = () => {
    const {user} = useContext(UserContext);
    const [doctores, setDoctores] = useState([]);
    const [bulkDoctores,setBulkDoctores] = useState([]);
    const [listView, setListView] = useState(false);
    const [reportView,setReportView] = useState(false)
    const [instituciones,setInstituciones] = useState([]);
    const [especialidades,setEspecialidades] = useState([]);
    const [searchKey,setSearhKey] = useState('');
    const [filtros , setFiltros] = useState({
        especialidad:null,
        institucion:null,
        visitados:null,
        enviados:null,
        representante:null,
        country_id:42,
        state_id:null,
        municipalty_id:null,
        suburb_id:null
    });
    const [searching,setSearching] = useState(false);
    const [estados,setEstados] = useState([]);
    const [municipios,setMunicipios] = useState([]);
    const [suburbs,setSuburbs] = useState([]);
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        doctors_create:null,    
        doctors_read:null,
        doctors_update:null,
        doctors_delete:null,
        doctors_btn_upload:null,
        doctors_btn_report:null
    })

    useEffect(()=>{
        _getDoctores();
        //por default el id 42 es el de mexico
        getEstados(42);
       // handleState(14);
        setLoaded(false);
       //validar permisos
       if(user.id){
        setPermisos((prevPremisos) => ({
            ...prevPremisos,
            doctors_create:checkPermiso("doctors_create",user.permissions),
            doctors_read:checkPermiso("doctors_read",user.permissions),
            doctors_update:checkPermiso("doctors_update",user.permissions),
            doctors_delete:checkPermiso("doctors_delete",user.permissions),
            doctors_btn_upload:checkPermiso("doctors_btn_upload",user.permissions),
            doctors_btn_report:checkPermiso("doctors_btn_report",user.permissions),
        }));
       }
    },[user]);

    useEffect(()=>{
        (permisos.doctors_read !== null) && setLoaded(true);
    },[permisos])

    const _getDoctores = () => {
        axios.get(`api/doctors`).then((res)=>{
            console.log("doctores",res.data);
            setDoctores(res.data);
            setBulkDoctores(res.data);
            M.Dropdown.init(document.querySelectorAll('dropdown-trigger'));
            var elems = document.querySelector('#doctorMovil-collapsible');
            M.Collapsible.init(elems,{});
        }).catch(e=>console.log("error",e))
    }

    useEffect(()=>{
        axios.get('api/institutions').then((resp)=>{
            setInstituciones(resp.data)
        }).catch(e=>alert(e))
        //Inicializacion de Modal de friltros
        M.Modal.init(document.querySelector('#modalFecha'));
        M.Modal.init(document.querySelector('#filtrosmodal'));   
        M.Datepicker.init(document.querySelectorAll('.datepicker'),{container:"body",showClearBtn:true});
    },[]);

    useEffect(()=>{
        axios.get('api/specialties').then((resp)=>{
            setEspecialidades(resp.data)
            console.log("especialidades", resp.data);
        }).catch(e=>alert(e))
    },[]);

    const _viewCard = () => {
        setListView(!listView);
    }
    const handleKeyPress = (event) =>{
        if(event.key === 'Enter'){
            console.log('enter press here! ',searchKey);
            searchKey ? _searchDoctorsField() : setDoctores(bulkDoctores);
        }
    } 

    const _searchDoctorsField = () =>{
        console.log("search by word",searchKey);
        axios.post('api/searchdoctorfield',{searchword:searchKey}).then(resp=>{
            console.log(resp);
            setDoctores(resp.data);
            setSearching(true);
        }).catch((e)=>console.log(e))
    }

    const searchByFilters = () =>{
        console.log(filtros)
     
        axios.post('api/search_doctor_by_filters',filtros).then(resp=>{
            console.log("resultado",resp)
            if(resp.status === 200){
                setDoctores(resp.data);
            }
        }).catch(e=>console.log(e))
    }

    const _searchByDate = () => {
        let instanceI = M.Datepicker.getInstance(document.querySelector('#fInicio'));
        let fInicio = new Date(instanceI.el.value);
        let instanceF = M.Datepicker.getInstance(document.querySelector('#fFin'));
        let fFin = new Date(instanceF.el.value);
        
        let tempDoctors = doctores.filter((doc)=>{
            let created_at = new Date(doc.created_at);
            if(created_at >= fInicio && created_at <= fFin ){
                return doc;
            }else return null;
        });
        setDoctores(tempDoctors);
        instanceI.destroy();
        instanceF.destroy();
        closeFechaModal();
    }

    const closeFiltrosModal = () =>{
        let instance = M.Modal.getInstance(document.querySelector('#filtrosmodal'));
        instance.close();
        //_limpiarFiltros();
    }

    const closeFechaModal = () =>{
        let instance = M.Modal.getInstance(document.querySelector('#modalFecha'));
        M.Datepicker.init(document.querySelectorAll('.datepicker'),{container:"body",showClearBtn:true});
        instance.close();
    }

    const _limpiarFiltros = () =>{
        setFiltros({
            especialidad:"",
            institucion:"",
            visitados:"",
            enviados:"",
            representante:"",
            country_id:42,
            state_id:"",
            municipalty_id:"",
            suburb_id:""
        })
        setMunicipios([]);
        setSuburbs([]);
        _getDoctores();
    }

    const getEstados = (paisId) =>{
        axios.get(`api/states-by-country/${paisId}`)
        .then(res=>{
            console.log("estados",res.data)
            setEstados(res.data)
        }).catch(e=>console.log(e))
    }

    const handleState = (estadoId) =>{
        if(estadoId > 0){
            setFiltros({...filtros,state_id:estadoId})
            axios.get(`api/municipalities-by-state/${estadoId}`)
            .then(res=>{
                console.log("muni",res)
                setMunicipios(res.data)
            }).catch(e=>console.log(e))
        }else{
            setMunicipios([])
        }
    }

    const handleMunipio = (municipioId) =>{
        if(municipioId > 0){
            setFiltros({...filtros,municipalty_id:municipioId})
            axios.get(`api/suburbs-by-munipality/${municipioId}`)
            .then(res=>{
                console.log("colonias",res.data)
                setSuburbs(res.data)
            }).catch(e=>console.log(e))
        }else{
            setSuburbs([])
        }
    }

    const _changeReportView = () =>{
        let value = !reportView;
        setReportView(value)
    }


    return (
        <div>
            <div id="modalFecha" className="modal">
                <div className="modal-content row m-p-0 p-10">
                    <div className='close-btn modal-close'>
                        <i className="material-icons">close</i>
                    </div>
                    <div className="mt-3 mt-md-0 text-md-right col-lg-6 m-p-0 row" style={{textAlign:'right',}}>
                        <div className='col s12 m4 m-p-0 row'>
                            <div className="col s2 m4 m-0 p-5">
                                <img className='dentometric' src='/images/calendar.png' style={{width:'100%'}}
                                alt='doctor dento metric' />
                            </div>
                            <div className="col s10 m8 m-0 p-5 actual centrar" style={{color:'#000000'}}><br></br>Rango de Fechas</div>
                        </div>
                        <div className='col s12 m8 m-p-0 centrar'></div>
                    </div>

                    <div className="col s12 m6 m-0 p-10">
                        <div className="col s12 m12 m-0 p-5 actual centrar" style={{color:'#000000'}}>Fecha de Inicio</div>
                        <input type="text" className="m-p-0 datepicker centrar form-input" id='fInicio'/>
                    </div>
                    <div className="col s12 m6 m-0 p-10">
                        <div className="col s12 m12 m-0 p-5 actual centrar" style={{color:'#000000'}}>Fecha de Final</div>
                        <input type="text" className="m-p-0 datepicker centrar form-input" id='fFin'/>
                    </div>
                </div>  
                <div className="modal-footer">
                    <div className="modal-footer row m-0 p-12">
                        <div className="col s12 m8"></div>
                        <div className="col s6 m2">
                            <button className="btn-blanco waves-effect waves-light row"
                            style={{padding:'4px 8px!important'}}
                            onClick={(e)=>closeFechaModal()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/clear-filters.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Cancelar</div>
                            </button>
                        </div>
                        <div className="col s6 m2">
                            <button className="btn-blanco waves-effect waves-light row"
                            style={{padding:'4px 8px!important',}}
                            onClick={()=>_searchByDate()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter1.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Filtrar</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/**FILTOS MODAL **/}
            <div id="filtrosmodal" className="modal">
                <div className="modal-content m-p-0 p-10">
                    <div className='close-btn modal-close'>
                        <i className="material-icons">close</i>
                    </div>
                    <div className='row modal-content m-p-0' style={{margin:'0!important'}}>
                        <div className="col s12 m12 m-0 p-5 row">
                            <div className="col s2 m1 m-p-0">
                                <img className='dentometric' src='/images/filter1.png' style={{width:'100%'}}
                                alt='doctor dento metric' />
                            </div>
                            <div className="col s10 m10 m-p-0 t-left"><h5 className='t-left'>Filtros</h5></div>
                        </div>
                        <div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                               <div> <img className='dentometric' src='/images/especialidad-1.png'
                                alt='doctor dento metric' /></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select className='browser-default' onChange={(e)=>setFiltros({...filtros,especialidad:e.target.value})} value={filtros.especialidad}>
                                    <option value="" disabled selected>Filtrar por Especialidad</option>
                                    {especialidades.map((esp) =>
                                        <option value={esp.id}  key={esp.id}>{esp.especialidad}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                                <div><img className='dentometric' src='/images/especialidad-2.png'
                                alt='doctor dento metric' /></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select className='browser-default' onChange={(e)=>setFiltros({...filtros,institucion:e.target.value})} value={filtros.institucion}>
                                    <option value="" disabled selected>Filtrar por Institucion</option>
                                    {instituciones.map((inst) =>
                                        <option value={inst.id}  key={inst.id}>{inst.institucion}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        {/*<div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                                <div><img className='dentometric' src='/images/especialidad-4.png'
                                alt='doctor dento metric'/></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select className='browser-default' onChange={(e)=>setFiltros({...filtros,representante:e.target.value})} value={filtros.representante}>
                                    <option value="" disabled selected>Filtrar por Representante</option>
                                    {instituciones.map((inst) => 
                                        <option value={inst.id}  key={inst.id}>{inst.institucion}</option>
                                    )}
                                </select>
                            </div>
                        </div>*/}
                        
                        <div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                                <div><img className='dentometric' src='/images/especialidad-3.png'
                                alt='doctor dento metric' /></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select className='browser-default' onChange={(e)=>setFiltros({...filtros,enviados:e.target.value})} value={filtros.enviados}>
                                    <option value="" disabled selected>Filtrar por Envian</option>
                                    <option>Todos</option>
                                    <option>Han Enviado</option>
                                    <option>No han Enviado</option>
                                </select>
                            </div>
                        </div>
                        <div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                                <div><img className='dentometric' src='/images/especialidad-5.png'
                                alt='doctor dento metric' /></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select className='browser-default' onChange={(e)=>setFiltros({...filtros,visitados:e.target.value})} value={filtros.visitados}>
                                    <option value="" disabled selected>Filtrar por Visitas</option>
                                    <option value={null}>Todos</option>
                                    <option value={0}>Han Visitado</option>
                                    <option value={1}>No han Visitado</option>
                                </select>
                            </div>
                        </div>
                     
                        <div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                                <div><img className='dentometric' src='/images/especialidad-6.png'
                                alt='doctor dento metric' /></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select value={filtros.state_id} className='browser-default'  onChange={(e)=>handleState(e.target.value)}>
                                    <option  value="" disabled selected>Filtrar por Estado</option>
                                    {estados.map((est) =>
                                        <option value={est.id}  key={est.id}>{est.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        {municipios.length > 0 &&
                        <div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                                <div><img className='dentometric' src='/images/especialidad-6.png'
                                alt='doctor dento metric' /></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select className='browser-default' onChange={(e)=>handleMunipio(e.target.value)} value={filtros.municipalty_id}>
                                    <option value="" disabled selected>Filtrar por Municipio</option>
                                    {municipios.map((muni) =>
                                        <option value={muni.id}  key={muni.id}>{muni.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        }
                        {suburbs.length > 0 &&
                        <div className="col s12 m6 filtros row">
                            <div className="col s2 m2">
                                <div><img className='dentometric' src='/images/especialidad-6.png'
                                alt='doctor dento metric' /></div>
                            </div>
                            <div className="col s10 m10 m-0">
                                <select className='browser-default'  onChange={(e)=>setFiltros({...filtros,suburb_id:e.target.value})} value={filtros.suburb_id}>
                                    <option value="" disabled selected>Filtrar por Colonia</option>
                                    {suburbs.map((sub) =>
                                        <option value={sub.id}  key={sub.id}>{sub.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        }
                        
                    </div>
               
                    <div className="modal-footer row m-p-0">
                        <div className="col s12 m8"></div>
                        <div className="col s6 m2">
                            <button className="btn-blanco waves-effect waves-light row"
                            style={{padding:'4px 8px!important',}}
                            onClick={(e)=>closeFiltrosModal()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/clear-filters.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Cancelar</div>
                            </button>
                        </div>
                        <div className="col s6 m2">
                            <button className="btn-blanco waves-effect waves-light row modal-close"
                            style={{padding:'4px 8px!important',}}
                            onClick={(e)=>searchByFilters()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter1.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Filtrar</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*END FILTROS MODAL */}
             <ImportadorModal></ImportadorModal>
            
            <div className='container-admin-menu '> <Breadcrumb hasButton={permisos.doctors_create} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1 row'>
                {!reportView ?
                    loaded ?
                    <>
                        {/* DOCTORES HEADER Y FILTROS */}

                        <ul class="collapsible movil-visible sombra-azul-chica border-r-6" id='doctorMovil-collapsible'>
                            <li className='border-r-6'>
                                <div class="collapsible-header m-0 p-10">
                                    <i class="material-icons font38" style={{color:'blue'}}><b>low_priority</b></i>
                                    <span className='m-p-0 actual' style={{color:'#000000'}}> Filtrar por: </span>
                                </div>
                                <div class="collapsible-body m-p-0">
                                    <div className='col s12 info-badge border-r-10 row m-p-0 movil-visible' 
                                    style={{bordeRadius:'10px!important', padding:'0px!important'}}>          
                                        <div className="col s12 m4 m-p-0">
                                        {permisos.doctors_btn_upload &&
                                            <div className="col s6 m4 m-0 p-0-10 centrar">
                                                <button className="btn-blanco row modal-trigger"
                                                style={{padding:'4px 8px!important'}}
                                                type="submit" name="action">
                                                    <div className='col s2 m2 m-p-0 centrar'>
                                                        <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/subir.png' alt='doctor dento metric' style={{width:'20px',}} />
                                                    </div>    
                                                    <div className='col s10 m10 m-p-0' style={{cursor:'pointer' }}>Subir</div>
                                                </button>
                                            </div>
                                        }
                                        {permisos.doctors_btn_report&&
                                            <div className="col s6 m4 m-0 p-0-10 centrar">
                                                <button className="btn-blanco row modal-trigger"
                                                style={{padding:'4px 8px!important'}}
                                                onClick={()=>_changeReportView()}
                                                type="submit" name="action">
                                                    <div className='col s2 m2 m-p-0 centrar'>
                                                        <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/pdf-reporte.png' alt='doctor dento metric' style={{width:'20px',}} />
                                                    </div>    
                                                    <div className='col s10 m10 m-p-0' style={{cursor:'pointer' }}>Reporte</div>
                                                </button>
                                            </div>
                                        }
                                        </div>
                                        <div className="col s12 m4 m-0 p-6-0 row">
                                            <div className="col s12 m12 m-p-0 centrar" id='serchDoctor'>
                                                <div className="btn-blanco waves-effect waves-light row m-0" style={{padding:'0px 8px!important'}}
                                                type="submit" name="action" >
                                                    <input className="col s10 m10 m-p-0" type="text" 
                                                    placeholder='Filtar por: Id/Apellido/Nombre/Correo/Compañía'
                                                    style={{fontSize:'12px'}}
                                                    onChange={(e)=>setSearhKey(e.target.value)}
                                                    onKeyPress={handleKeyPress} />
                                                    <i className="col s1 m1 material-icons" onClick={_searchDoctorsField}>search</i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s12 m4 m-p-0">
                                            <div className='col s12 border-r-10 m-p-0 row'>
                                                <div className="col s4 m4 m-0 p-0-10 centrar">
                                                    <button className="btn-blanco row modal-trigger" 
                                                    style={{padding:'4px 8px!important',cursor:'pointer'}}
                                                    data-target='modalFecha' 
                                                    name="action">
                                                        <div className='col s2 m2 m-p-0 centrar'>
                                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/calendar.png' alt='doctor dento metric' style={{width:'20px',}} />
                                                        </div>    
                                                        <div className='col s10 m10 m-p-0 centrar ' style={{}}>Fecha </div>
                                                    </button>
                                                </div>
                                                <div className="col s4 m4 m-0 p-0-10 centrar">
                                                    <button className="btn-blanco row modal-trigger"
                                                    style={{padding:'4px 8px!important'}}
                                                    data-target="filtrosmodal" type="submit" name="action">
                                                        <div className='col s2 m2 m-p-0 centrar'>
                                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter.png' alt='doctor dento metric' style={{width:'20px',}} />
                                                        </div>    
                                                        <div className='col s10 m10 m-p-0' style={{cursor:'pointer'}}>Filtros</div>
                                                    </button>
                                                </div>
                                                <div className="col s4 m4 m-0 p-0-10 centrar">
                                                    <button className="btn-blanco row modal-trigger"
                                                    style={{padding:'4px 8px!important'}}
                                                    onClick={(e)=>_limpiarFiltros()}
                                                    type="submit" name="action">
                                                        <div className='col s2 m2 m-p-0 centrar'>
                                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/clear-filters.png' alt='doctor dento metric' style={{width:'20px',}} />
                                                        </div>    
                                                        <div className='col s10 m10 m-p-0' style={{cursor:'pointer' }}>Limpiar</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <div className='col s12 info-badge border-r-10 row m-p-0 movil-oculto' 
                        style={{bordeRadius:'10px!important', padding:'0px!important'}}>          
                            <div className="col s12 m4 m-p-0">
                            {permisos.doctors_btn_upload &&
                                <div className="col s6 m4 m-0 p-0-10 centrar">
                                    <button className="btn-blanco row modal-trigger"
                                    style={{padding:'4px 8px!important'}}
                                    type="submit" name="action">
                                        <div className='col s2 m2 m-p-0 centrar'>
                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/subir.png' alt='doctor dento metric' style={{width:'20px',}} />
                                        </div>    
                                        <div className='col s10 m10 m-p-0' style={{cursor:'pointer' }}>Subir</div>
                                    </button>
                                </div>
                            }
                            {permisos.doctors_btn_report&&
                                <div className="col s6 m4 m-0 p-0-10 centrar">
                                    <button className="btn-blanco row modal-trigger"
                                    style={{padding:'4px 8px!important'}}
                                    onClick={()=>_changeReportView()}
                                    type="submit" name="action">
                                        <div className='col s2 m2 m-p-0 centrar'>
                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/pdf-reporte.png' alt='doctor dento metric' style={{width:'20px',}} />
                                        </div>    
                                        <div className='col s10 m10 m-p-0' style={{cursor:'pointer' }}>Reporte</div>
                                    </button>
                                </div>
                            }
                            </div>
                            <div className="col s12 m4 m-p-0 row">
                                <div className="col s12 m12 m-p-0 centrar" id='serchDoctor'>
                                    <div className="btn-blanco waves-effect waves-light row m-0" style={{padding:'0px 8px!important'}}
                                    type="submit" name="action" >
                                        <input className="col s10 m10 m-p-0" type="text" 
                                        placeholder='Filtar por: Id/Apellido/Nombre/Correo/Compañía'
                                        style={{fontSize:'12px'}}
                                        onChange={(e)=>setSearhKey(e.target.value)}
                                        onKeyPress={handleKeyPress} />
                                        <i className="col s1 m1 material-icons" onClick={_searchDoctorsField}>search</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m4 m-p-0">
                                <div className='col s12 border-r-10 m-p-0 row'>
                                    <div className="col s4 m4 m-0 p-0-10 centrar">
                                        <button className="btn-blanco row modal-trigger" 
                                        style={{padding:'4px 8px!important',cursor:'pointer'}}
                                        data-target='modalFecha' 
                                        name="action">
                                            <div className='col s2 m2 m-p-0 centrar'>
                                                <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/calendar.png' alt='doctor dento metric' style={{width:'20px',}} />
                                            </div>    
                                            <div className='col s10 m10 m-p-0 centrar ' style={{}}>Fecha </div>
                                        </button>
                                    </div>
                                    <div className="col s4 m4 m-0 p-0-10 centrar">
                                        <button className="btn-blanco row modal-trigger"
                                        style={{padding:'4px 8px!important'}}
                                        data-target="filtrosmodal" type="submit" name="action">
                                            <div className='col s2 m2 m-p-0 centrar'>
                                                <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter.png' alt='doctor dento metric' style={{width:'20px',}} />
                                            </div>    
                                            <div className='col s10 m10 m-p-0' style={{cursor:'pointer'}}>Filtros</div>
                                        </button>
                                    </div>
                                    <div className="col s4 m4 m-0 p-0-10 centrar">
                                        <button className="btn-blanco row modal-trigger"
                                        style={{padding:'4px 8px!important'}}
                                        onClick={(e)=>_limpiarFiltros()}
                                        type="submit" name="action">
                                            <div className='col s2 m2 m-p-0 centrar'>
                                                <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/clear-filters.png' alt='doctor dento metric' style={{width:'20px',}} />
                                            </div>    
                                            <div className='col s10 m10 m-p-0' style={{cursor:'pointer' }}>Limpiar</div>
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className='m-0 p-10'>
                            <div className="col s12 m11 m-p-0 centrar-l">
                                {permisos.doctors_read&&
                                <div className='p-10 actual' style={{color:'#000000'}}>
                                Doctores: {doctores.length}
                                </div>}
                            </div>
                            {permisos.doctors_read && 
                                <div className='col s1 m1 m-0 p-2 centrar movil-oculto' style={{textAlign:'right',}}>
                                {
                                    listView
                                    ?
                                    <button className="btn-blanco waves-effect waves-light row modal-trigger m-0 centrar"
                                    style={{color:'blue!important', padding:'0px 2px!important'}}
                                    onClick={(e)=>_viewCard()}>
                                        <i className="material-icons m-p-0" 
                                        style={{fontSize:'24px', cursor:'pointer', color:'blue!important',}}>grid_on</i>
                                    </button>
                                    :
                                    <button className="btn-blanco waves-effect waves-light row modal-trigger m-0 centar"
                                    style={{color:'blue!important', padding:'0px 2px!important'}}
                                    onClick={(e)=>_viewCard()}>
                                        <i className="material-icons m-p-0" 
                                        style={{fontSize:'24px', cursor:'pointer', color:'blue!important'}}>format_list_numbered</i>
                                    </button>
                                }
                                </div> 
                            }
                        </div>
                        
                        {permisos.doctors_read &&  
                            <div className='movil-visible'><DoctorTable doctores={doctores} permisos={permisos}/> </div>
                        }

                        <div className='row m-p-0'>&nbsp;</div>
                        {(searching && doctores.length === 0) &&
                            <div className='row centrar'>      
                                <div className='col notification-warning'>
                                    <p>Lo sentimos no se encontraron resultados, realiza una nueva busqueda.</p>
                                </div>
                            </div>
                        }
                        {listView
                            ?
                            (permisos.doctors_read) ?
                                <DoctorTable doctores={doctores} permisos={permisos}/>
                                :
                                <NoPermissions/>
                            :
                            (permisos.doctors_read) ?
                                <DoctorCard doctores={doctores} permisos={permisos}/> 
                                :
                                <NoPermissions/>
                        }
                    </>
                    :
                    <Loader></Loader>
                :
                <>
                    <div className='col s12 info-badge border-r-10 m-p-0' style={{bordeRadius:'10px!important', padding:'0px!important',}}>
                        REPORTE - {today}
                        <BlobProvider document={<DoctoresReport doctores={doctores} />}>
                            {({url}) => (<a href={url} target="_blank" rel="noreferrer">&nbsp;&nbsp;PDF</a>)}
                        </BlobProvider>
                        <button className='btn-blanco centrar right puntero' onClick={(e)=>_changeReportView()}>Regresar <i class="material-icons">reply</i></button> 
                    </div>
                    {doctores.map((doctor)=>
                        <div className='col s12 row m-t-10 z-depth-1 p-10 border-r-6'>
                            <div className='col s12'><label>ID: {doctor.id}</label></div>
                            <div className='col s12'><label>Fecha de creacion: {new Date(doctor.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</label></div>
                            <div className='col s12 m3'><label>Nombre:</label><div>{doctor.nombre} {doctor.apellidos}</div></div>
                            <div className='col s12 m3'><label>Compañía:</label><div>{doctor.compania}</div></div>
                            <div className='col s6 m3'><label>Telefono Particular:</label><div>{doctor.telefono}</div></div>
                            <div className='col s6 m3'><label>Celular:</label><div>{doctor.celular}</div></div>
                        
                            <div className='col s12 m3'><label>Email:</label><div>{doctor.email}</div></div>
                            <div className='col s12 m3'><label>Pagina Web:</label><div>{doctor.pagina}</div></div>
                            <div className='col s9 m3'><label>RFC:</label><div>{doctor.rfc}</div></div>
                            <div className='col s3 m3'><label>Visitado:</label><div>{doctor.visitar===1?"Si":"No"}</div></div>

                            <div className='col s12 m3'>
                                <label>Especialidades</label>
                                {doctor.specialties&&
                                    doctor.specialties.map(item=>
                                        <div>{item.especialidad}</div>
                                )}  
                            </div>

                             <div className='col m3'>
                                <label>Instituciones</label>
                                {doctor.institutions&&
                                    doctor.institutions.map(item=>
                                        <div>{item.institucion}</div>
                                )}  
                            </div>

                            <div className='col m3'>
                                <label>Consultorios</label>
                                {doctor.doctor_offices&&
                                    doctor.doctor_offices.map(item=>
                                        <div>{item.nombre}</div>
                                )}  
                            </div>

                            <div className='col s12 '><hr className='divider'></hr></div>
                            <div className='col m4'><label>Observaciones</label><div>{doctor.observaciones_dental}</div></div>
                            <div className='col m4'><label>Observaciones generales:</label><div>{doctor.observaciones_generales}</div></div>
                            <div className='col m4'><label>observaciones importantes:</label><div>{doctor.observaciones_importantes}</div></div>
                            
                        </div>
                    )}
                </>
                }
                </div>
            </div>
        </div>
    );
};

export default Doctores;