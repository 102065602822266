import React from 'react';
import { BrowserRouter,Routes,Route,Navigate} from 'react-router-dom';

import PrivateRoutes from './PrivateRoutes';
import Login from "./Components/Login/Login";
import Admin from "./Components/Admin/Admin";
import Usuarios from "./Components/Usuarios/Usuarios";
import PerfilUsuario from "./Components/Usuarios/Perfil";
import NewUsuario from './Components/Usuarios/New';
import EditUsuario from "./Components/Usuarios/Edit";
import Sucursales from './Components/Sucursales/Sucursales';
import NewSucursal from './Components/Sucursales/New';
import EditSucursal from "./Components/Sucursales/Edit";
import DetailUsuario from './Components/Usuarios/Detail';
import Especialidades from './Components/Especialidades/Especialidades';
import EditEspecialidades from './Components/Especialidades/Edit';
import NewEspecialidad from './Components/Especialidades/New';
import Instituciones from './Components/Catalogos/Instituciones/Instituciones';
import NewInstitucion from './Components/Catalogos/Instituciones/New';
import EditInstitucion from './Components/Catalogos/Instituciones/Edit';
import Paqueteria from './Components/Catalogos/Paqueteria/Paqueteria';
import NewPaqueteria from './Components/Catalogos/Paqueteria/New';
import EditPaqueteria from './Components/Catalogos/Paqueteria/Edit';
import Proveedores from './Components/Catalogos/Proveedores/Proveedores';
import NewProveedores from './Components/Catalogos/Proveedores/New';
import EditProveedores from './Components/Catalogos/Proveedores/Edit';
import DetalleProveedores from './Components/Catalogos/Proveedores/Detalle';
import TipoEmpleados from './Components/Catalogos/TipoEmpleados/TipoEmpleados';
import NewTipoEmpleados from './Components/Catalogos/TipoEmpleados/New';
import EditTipoEmpleados from './Components/Catalogos/TipoEmpleados/Edit';
import Paises from './Components/Configuraciones/Paises/Paises';
import EditPais from './Components/Configuraciones/Paises/Edit';
import Estados from './Components/Configuraciones/Estados/Estados';
import NewEstado from './Components/Configuraciones/Estados/New';
import EditEstado from './Components/Configuraciones/Estados/Edit';
import Municipios from './Components/Configuraciones/Municipios/Municipios';
import Colonias from './Components/Configuraciones/Colonias/Colonias';
import Doctores from './Components/Doctores/Doctores';
import NewDoctor from './Components/Doctores/New';
import EditDoctor from './Components/Doctores/Edit';
import DoctorDetails from './Components/Doctores/Detail';
import VisitasDoctores from './Components/Visitas/VisitasDoctores';
import Empleados from './Components/Empleados/Empleados';
import DoctoresReport from './Components/Doctores/DoctoresReport';
import NewEmpeados from './Components/Empleados/New';
import EditEmpleado from './Components/Empleados/Edit';
import DetailEmpleado from './Components/Empleados/Detail';
import Vacaciones from './Components/Empleados/Vacaciones/Vacaciones';
import NewVacation from './Components/Empleados/Vacaciones/NewVacation';
import ModeloCitas from './Components/Agenda/ModeloCitas/ModeloCitas';
import VisitasTable from './Components/Visitas/VisitasTable';
import Agenda from './Components/Agenda/Agenda';
import VerAgenda from './Components/Agenda/VerAgenda';
import Servicios from './Components/Servicios/Servicios';
import NewService from './Components/Servicios/New';
import EditService from './Components/Servicios/Edit'
import Categorias from './Components/Servicios/Categorias/Categorias';
import NewPedido from './Components/Pedidos/New';
import Pedidos from './Components/Pedidos/Pedidos';
import EditPedidos from './Components/Pedidos/Edit';
import Clientes from './Components/Pedidos/Clientes/Clientes';
import EditClientes from './Components/Pedidos/Clientes/EditClientes';
import NuevoClientes from './Components/Pedidos/Clientes/New';
import Cupones from './Components/Pedidos/Cupones/Cupones';
import Nuevo from './Components/Pedidos/Cupones/Nuevo';
import Roles from './Components/Roles/Roles';
import NewRol from './Components/Roles/NewRol';
import EditarRol from './Components/Roles/EditarRol';
import EditCupones from './Components/Pedidos/Cupones/EditCupones';
import Perfil from './Components/Usuarios/Perfil';
import EditVisita from './Components/Visitas/Edit';
import DetailVisita from './Components/Visitas/Detail';
import IngresosReporte from './Components/Reportes/Ingesos/Ingresos';
import IngresosReporteGlobalSucursal from './Components/Reportes/Ingesos/GlobalSucursal';
import ReporteGlobal from './Components/Reportes/Ingesos/ReporteGlobal';

const MainRoutes = () => {
    return ( 
        <BrowserRouter>
            <Routes>
                {/*Rutas publicas*/}
                <Route path="/" element={<Navigate replace to="login" />} />
                <Route path="/login" element={<Login/>}> </Route>

                {/*Rutas protegidas*/}
                <Route path="/admin" element={<PrivateRoutes />}>
                    {/*Aqui definimos la ruta por default en el Navigate(Redireccion)*/}
                    <Route path="/admin" element={<Navigate replace to="usuarios" />} />
                    <Route path="/admin" element={<Admin />}>
                        {/*Rutas para usuarios administradores*/}
                        {/*ROLES*/}
                        <Route path='/admin/roles' element={<Roles />} />
                        <Route path='/admin/roles/new' element={<NewRol/>} />
                        <Route path='/admin/roles/:rolId' element={<EditarRol/>}/>
                        {/*USUARIOS*/}
                        <Route path="/admin/usuarios" element={<Usuarios />} />
                        <Route path='/admin/usuarios/new' element={<NewUsuario />} />
                        <Route path='/admin/usuarios/:userId' element={<EditUsuario/>}/>
                        <Route path='/admin/usuarios/detail/:userId' element={<DetailUsuario />}/>
                        <Route path='/admin/usuarios/perfil/:userId' element={<PerfilUsuario/>}/>
                        {/*SUCURSALES*/}
                        <Route path='/admin/sucursales' element={<Sucursales/>} />
                        <Route path='/admin/sucursales/new' element={<NewSucursal />} />
                        <Route path='/admin/sucursales/:sucursalId' element={<EditSucursal/>}/>
                        {/*ESPECIALIDADES*/}
                        <Route path='/admin/especialidades' element={<Especialidades />}></Route>
                        <Route path='/admin/especialidades/new' element={<NewEspecialidad />}></Route>
                        <Route path='/admin/especialidades/:especialidadId' element={<EditEspecialidades/>}></Route>
                        {/*INSTITUCIONES*/}
                        <Route path='/admin/instituciones' element={<Instituciones />}></Route>
                        <Route path='/admin/instituciones/new' element={<NewInstitucion />}> </Route>
                        <Route path='/admin/instituciones/:institucionId' element={<EditInstitucion />}> </Route>
                         {/*PROVEEDORES*/}
                        <Route path='/admin/proveedores' element={<Proveedores />}></Route>
                        <Route path='/admin/proveedores/detail/:proveedorId' element={<DetalleProveedores />}> </Route>
                        <Route path='/admin/proveedores/new' element={<NewProveedores />}> </Route>
                        <Route path='/admin/proveedores/:proveedorId' element={<EditProveedores />}> </Route>
                        {/*PAQUETERIA*/}
                        <Route path='/admin/paqueteria' element={<Paqueteria/>}></Route>
                        <Route path='/admin/paqueteria/new' element={<NewPaqueteria/>}> </Route>
                        <Route path='/admin/paqueteria/:paqueteriaId' element={<EditPaqueteria/>}> </Route>
                        {/*TIPO EMPLEADOS*/}
                        <Route path='/admin/tipoempleados' element={<TipoEmpleados/>}></Route>
                        <Route path='/admin/tipoempleados/new' element={<NewTipoEmpleados/>}></Route>
                        <Route path='/admin/tipoempleados/:tipoId' element={<EditTipoEmpleados/>}></Route>
                        {/*PAISES*/}
                        <Route path='/admin/paises' element={<Paises/>}></Route>
                        <Route path='/admin/paises/:paisId' element={<EditPais />}> </Route>
                        {/*ESTADOS*/}
                        <Route path='/admin/paises/:paisId/estados' element={<Estados />}> </Route>
                        <Route path='/admin/paises/:paisId/estados/new' element={<NewEstado />}> </Route>
                        <Route path='/admin/paises/:paisId/estados/:estadoId' element={<EditEstado/>}> </Route>
                        {/*MUNICIPIOS*/}
                        <Route path='/admin/paises/:paisId/estados/:estadoId/municipios' element={<Municipios />}> </Route>
                        {/*COLONIAS*/}
                        <Route path='/admin/paises/:paisId/estados/:estadoId/municipios/:municipioId/colonias' element={<Colonias />}> </Route>
                         {/*DOCTORES*/}
                        <Route path='/admin/doctores' element={<Doctores/>}> </Route>
                        <Route path='/admin/doctores/reporte' element={<DoctoresReport/>}> </Route>
                        <Route path='/admin/doctores/new' element={<NewDoctor/>}> </Route>

                        <Route path='/admin/doctores/:doctorId' element={<EditDoctor/>}> </Route>
                        <Route path='/admin/doctores/detail/:doctorId' element={<DoctorDetails/>}/>
                        {/*VISITAS*/}
                        <Route path='/admin/calendario' element={<VisitasDoctores />}> </Route>
                        <Route path='/admin/visitas' element={<VisitasTable />}> </Route>
                        <Route path='/admin/visitas/:visitaId' element={<EditVisita />}> </Route>
                        <Route path='/admin/visitas/detail/:visitaId' element={<DetailVisita />}> </Route>
                        {/*EMPLEADOS*/}
                        <Route path='/admin/empleados' element={<Empleados />}> </Route>
                        <Route path='/admin/empleados/new' element={<NewEmpeados/>}> </Route>
                        <Route path='/admin/empleados/:empleadoId' element={<EditEmpleado/>}> </Route>
                        <Route path='/admin/empleados/detail/:empleadoId' element={<DetailEmpleado/>}></Route>
                        <Route path='/admin/empleados/vacaciones' element={<NewVacation/>}> </Route>
                        <Route path='/admin/empleados/vacaciones/newVacation' element={<NewVacation/>}> </Route>       
                        {/*AGENDA*/}
                        <Route path='/admin/modelo-citas' element={<ModeloCitas/>}> </Route>
                        <Route path='/admin/agenda' element={<Agenda/>}> </Route>
                        <Route path='/admin/veragenda' element={<VerAgenda/>}> </Route>
                        {/*Servicios*/}
                        <Route path='/admin/servicios' element={<Servicios/>}> </Route>
                        <Route path='/admin/servicios/new' element={<NewService/>}> </Route>
                        <Route path='/admin/servicios/:productoId' element={<EditService/>}> </Route>
                        <Route path='/admin/categorias' element={<Categorias  />}> </Route>
                        {/*Pedidos*/}
                        <Route path='/admin/pedidos' element={<Pedidos/>}> </Route>
                        <Route path='/admin/pedidos/:pedidoId' element={<EditPedidos/>}> </Route>
                        <Route path='/admin/pedidos-nuevo' element={<NewPedido/>}> </Route>
                        <Route path='/admin/clientes' element={<Clientes/>}> </Route>
                        <Route path='/admin/clientes/:clienteId' element={<EditClientes/>}> </Route>
                        <Route path='/admin/clientes/new' element={<NuevoClientes/>}> </Route>
                        {/*Cupones*/}
                        <Route path='/admin/cupones' element={<Cupones />}> </Route>
                        <Route path='/admin/cupones/:cuponId' element={<EditCupones/>}> </Route>
                        <Route path='/admin/cupones/new' element={<Nuevo/>}> </Route>
                        {/*Reportes*/}
                        <Route path='/admin/ingresos' element={<IngresosReporte/>}> </Route>
                        <Route path='/admin/ingresos/reporte-global-sucursal' element={<IngresosReporteGlobalSucursal/>}> </Route>
                        <Route path='/admin/ingresos/reporte-global' element={<ReporteGlobal />}> </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default MainRoutes;
