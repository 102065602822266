import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from '../../axios';
import { useNavigate } from 'react-router-dom';

const EmpleadoForm = (props) => {
    const {empleado,setEmpleado,setStep,permisos} = props;
    const [sucursales,SetSucursales] = useState([]);
    const [validate,setValidate] = useState({codigo:true,turno:true, nombre:true, apellidos:true,sucursal:true,employee_type_id:true,fecha_aplicacion:true});
    const [tipo,setTipo] = useState([]);
    const [jefes,setJefes] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        getSucursals();
        getTipoEmpleado();
        getBosses();
    },[]);

    const getSucursals = ()=>{
        axios.get('api/sucursals').then((resp)=>{
            console.log("sucursales",resp.data);
            SetSucursales(resp.data);
            M.FormSelect.init(document.querySelector('#sucursales'));
        }).catch((e)=>console.log("ERROR ",e));
    }

    const getTipoEmpleado = ()=>{
        axios.get('api/employeetipes').then((resp)=>{
            console.log("tipos",resp.data);
            setTipo(resp.data);
            M.FormSelect.init(document.querySelector('#tipo'));
            M.FormSelect.init(document.querySelector('#turno'))
        }).catch((e)=>console.log("ERROR ",e));
    }

    const getBosses = ()=>{
        axios.get('api/employees_bosses').then((resp)=>{
            console.log("jefes",resp.data);
            setJefes(resp.data);
            M.FormSelect.init(document.querySelector('#jefes'))
        }).catch((e)=>console.log("ERROR ",e));
    }
    
    const _updateEmpleado = (e) =>{
        console.log("Guardar...",permisos)
        e.preventDefault();
        let url = '';
        let method = '';
        let accion = '';
        
        if(empleado.id > 0){
            accion = 'Editar';
            url = `api/employees/${empleado.id}`;
            method = 'put';
        }else{
            accion = 'Guardar';
            url = 'api/employees';
            method = 'post';
        }
        
        if(!permisos.employees_create && accion === 'Guardar'){
            M.toast({
                html:`<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>No tienes permisos para ${accion} un Empleado.</span>`,
                classes:"error-notify"
            });
            setTimeout(() => {
                navigate("/admin/empleados");
            }, 3000);

            return null;

        }

        if(!permisos.employees_update && accion === 'Editar'){
            M.toast({
                html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>No tienes permisos para ${accion} un Empleado.</span>`,
                classes:"error-notify"
            })
            return null;
        }

        axios({
            method:method,
            url:url,
            data:empleado
        }).then(resp=>{
            console.log("nuevo empleado",resp);
            setEmpleado(resp.data);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Empleado creado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                if(resp.status === 201) setStep(2);
            }, 3000);
        }).catch(e=>{
            console.log("error",e);
            if(e.response?.data?.errors){
                let errors = e.response.data.errors;
                setValidate({
                    nombre:(errors.nombre)? false:true, 
                    apellidos:(errors.apellidos)? false:true,
                    codigo:(errors.codigo)? false:true,
                    cargo:(errors.cargo)? false:true,
                    fecha_aplicacion:(errors.fecha_aplicacion)? false:true,
                    employee_type_id:(errors.employee_type_id)? false:true
                })
            }

        });
    
    }

    return (
        <div className='p-20'>
           <form  onSubmit={_updateEmpleado} className="row ancho100" id='general-form'>
                <div className="col s12 m2">
                    <div className="input-text">
                        <label htmlFor="code">Codigo de Empleado *</label>
                        <input id="code" type="text"  value={empleado.codigo} onChange={(e)=>setEmpleado({...empleado,codigo:e.target.value})}/>
                        {!validate.codigo &&  <span className="helper-text t-rojo">El codigo de empleado es obligatorio</span>}
                    </div>
                </div>
                <div className="col s12 m5">
                    <div className="input-text">
                        <label htmlFor="name">nombres *</label>
                        <input id="name" type="text" value={empleado.nombre} onChange={(e)=>setEmpleado({...empleado,nombre:e.target.value})}/>
                        {!validate.nombre &&  <span className="helper-text t-rojo">El nombre de el empleado es obligatorio</span>}
                    </div>
                </div>
                <div className="col s12 m5">
                    <div className="input-text">
                        <label htmlFor="apellidos">Apellidos *</label>
                        <input id="apellidos" type="text" value={empleado.apellidos} onChange={(e)=>setEmpleado({...empleado,apellidos:e.target.value})}/>
                        {!validate.apellidos &&  <span className="helper-text t-rojo">Los apellidos de el empleado son obligatorios</span>}
                    </div>
                </div>
                <div className="col s12 m4">
                    <div className="input-text">
                        <label htmlFor="curp">CURP</label>
                        <input  id="curp" type="text" value={empleado.curp} onChange={(e)=>setEmpleado({...empleado,curp:e.target.value})}/>
                        {(empleado.curp?.length < 18 || empleado.curp?.length > 18) ? <span className='helper-text t-rojo right'>{empleado?.curp?.length}/18</span>: <></> }
                    </div>
                </div>
                <div className="col s12 m2">
                    <div className="input-text">
                        <label htmlFor="ini">Iniciales</label>
                        <input id="ini" type="text" value={empleado.iniciales}  onChange={(e)=>setEmpleado({...empleado,iniciales:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m6">
                    <div className='input-text'>
                        <label>Sucursal *</label>
                        <select id="sucursales" onChange={(e)=>setEmpleado({...empleado,sucursal_id:e.target.value})} defaultValue="default" value={empleado.sucursal_id}>
                            <option value="default"  disabled>Seleccione la sucursal</option>
                            {sucursales.map((item) =>
                                <option value={item.id}  key={item.id}>{item.nombre_sucursal}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="col s12 m6">
                    <div className="input-text">
                        <label htmlFor="cargo">Cargo a desempeñar *</label>
                        <input id="cargo" type="text" value={empleado.cargo} onChange={(e)=>setEmpleado({...empleado,cargo:e.target.value})}/>
                    </div>
                </div>
                
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="tel">Telefono Particular</label>
                        <input id="tel" placeholder="Ej. 3330893423" type="text" value={empleado.telefono} onChange={(e)=>setEmpleado({...empleado,telefono:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="cel">Celular</label>
                        <input id="cel" placeholder="Ej. 3330893423" type="text" value={empleado.celular} onChange={(e)=>setEmpleado({...empleado,celular:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="email">E-mail</label>
                        <input id="email" type="email" value={empleado.email} onChange={(e)=>setEmpleado({...empleado,email:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="fechaalta">Fecha de alta *</label>
                        <input type="date" id="fechaalta" value={empleado.fecha_aplicacion} onChange={(e)=>setEmpleado({...empleado,fecha_aplicacion:e.target.value})}/>
                        {!validate.fecha_aplicacion &&  <span className="helper-text t-rojo">La fecha de alta es obligatoria</span>}
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className='input-text'>
                        <label>Jefe</label>
                            <select id="jefes" onChange={(e)=>setEmpleado({...empleado,jefe_id:e.target.value})} defaultValue="default" value={empleado.jefe_id}>
                                <option value="default" disabled >Seleccione el Jefe</option>
                                {jefes.map((item) =>
                                    <option value={item.id}  key={item.id}>{item.nombre + " " + item.apellidos}</option>
                                )}
                            </select>
                    </div>
                </div>
                <div className="col s12 m3 centrar" style={{minHeight:60}}>
                    <div className="switch input-text">
                            <label>
                            Hacer Jefe
                            <input id="visitar" type="checkbox" onChange={(e)=>setEmpleado({...empleado,jefe:e.target.checked})}/>
                            <span className="lever"></span>
                            </label>
                        </div>
                </div>
                <div className="col s12 m6">
                    <div className='input-text'>
                        <label>Tipo de Empleado *</label>
                        <select id="tipo" onChange={(e)=>setEmpleado({...empleado,employee_type_id:e.target.value})} defaultValue="default" value={empleado.employee_type_id}>
                            <option value="default" disabled >Seleccione el tipo</option>
                            {tipo.map((item) =>
                                <option value={item.id}  key={item.id}>{item.descripcion}</option>
                            )}
                        </select>
                        {!validate.employee_type_id &&  <span className="helper-text t-rojo">El tipo de empleado es obligatorio</span>}
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className='input-text'>
                        <label>Turno del Empleado *</label>
                        <select id="turno" onChange={(e)=>setEmpleado({...empleado,turno:e.target.value})} defaultValue="default" value={empleado.turno}>
                            <option value="default" disabled >Seleccione el turno</option>
                            <option value="matutino">Maturino</option>
                            <option value="vespertino">Vespertino</option> 
                        </select>
                        {!validate.turno &&  <span className="helper-text t-rojo">El turno del empleado es obligatorio</span>}
                    </div>
                </div>
               
               
                <div className='col s12 p-t-35'> 
                    <button className="btn waves-effect waves-light right" type="submit" name="action">{empleado.id?'Actualizar':'Siguiente'}
                        <i className="material-icons right">arrow_forward</i>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EmpleadoForm;