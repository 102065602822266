import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import M from 'materialize-css';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

const New = () => {
    const [tipo, setTipo] = useState({descripcion:''})
    const [validate, setValidate] = useState(true);
    const navigate = useNavigate();

    const _save = (e)=>{
        e.preventDefault();

        axios.post('api/employeetipes',tipo)
        .then((res)=>{
            setTipo(res.data)
            setValidate(true);
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Nuevo Tipo</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/tipoempleados")
            }, 3000);
        }).catch(e=>{
            console.log(e)
            e.response.data.errors.descripcion.length > 0 && setValidate(false);      
        })
    }
    return (
    <>
        <div className='container-admin-menu'><Breadcrumb hasButton={false} hasFilters={false} actual={null}/></div>
        <div className='container-admin-container'>
            <div className='container-admin'>
                <h5>Agregar Tipo de empleado</h5>
                <form className="row ancho100" id='general-form'  onSubmit={_save}>
                    
                    <div className="col s12">
                        <div className="input-text">
                            <label htmlFor="descripcion">* Descripcion</label>
                            <input id="descripcion" type="text" className="validate" value={tipo.descripcion} 
                            onChange={(e)=>setTipo({...tipo,descripcion:e.target.value})}/>
                            {!validate &&  <span class="helper-text t-rojo">El campo de descripcion es obligatorio</span>}
                        </div>
                    </div>
                    <div className='col s12 p-t-35'> 
                        <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                            <i className="material-icons right">send</i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>
    );
};

export default New;