import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import '../Usuarios/usuarios.scss'
import M from 'materialize-css';
import axios from '../../axios';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Spinner from '../../Layout/Loaders/Spinner';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';

const Usuarios = () => {
    //M.Dropdown.init(document.querySelector('.dropdown-content'));
    let defaultImg = "/images/default-user.png";
    let ruteImgs = axios.defaults.baseURL;
    const user = useContext(UserContext);
    const [usuarios, setUsuarios] = useState([]);
    const [loader,setLoader] = useState(false);
    //Permisos utilizados en esta vista
    const [permisosUsuario,setPermisosUsuario] = useState({
        users_read:null,
        users_create:null,
        users_update:null,
        users_delete:null
    })
   
    useEffect(() => {    
        setLoader(false);
        axios.get('api/users')
        .then( res=>{
            console.log("res", res.data)
            let elems = document.querySelectorAll('.tooltipped');
            M.Tooltip.init(elems);
            setUsuarios(res.data);
        }).catch(e =>{console.log(e, 'Error todos los usuarios')})
       
    },[]);

    useEffect(()=>{
        setLoader(false);
        if(user.user.id){
            console.log("USERR",user)
            setPermisosUsuario((prevPremisos) => ({
                ...prevPremisos,
                users_read:checkPermiso("users_read",user.user.permissions),
                users_create:checkPermiso("users_create",user.user.permissions),
                users_update:checkPermiso("users_update",user.user.permissions),
                users_delete:checkPermiso("users_delete",user.user.permissions)
            }));
        }
    },[user])

    useEffect(()=>{
        if(permisosUsuario.users_read !==null)  setLoader(true);
    },[permisosUsuario])

    const _delete = (userId) =>{
        if(window.confirm("Realmente deseas borrar a este administrador, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/users/${userId}`)
            .then(res=>{
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setUsuarios(usuarios.filter(u=>u.id!==res.data.user.id))
            }).catch(e=>console.log("error",e))
        }
    }

    const renderTable = () =>{
        console.log("permisos",permisosUsuario)
        if(permisosUsuario.users_read){
            return (
                <div className='table'>
                        <div className='table-header row movil-oculto'>
                            <div className='col s2 m1'> </div>
                            <div className='col s10 m3'> Nombre </div>
                            <div className='col s12 m3'> Correo </div>
                            <div className='col s12 m1'> Rol</div>
                            <div className='col s12 m2'> Sucursal </div>
                            <div className='col s12 m1'> Status</div>
                            <div className='col s12 m1'> Acciones</div>
                        </div>
                        {usuarios.map( (usuario)=>
                            <div className='table-container row' key={usuario.id}>
                                <div className='col s12 m1 m-p-0 movil-oculto'>
                                    <div className='sombra-azul-chica m-p-0' style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'40px', width:'40px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                                        <img className='dentometric m-p-0' src={usuario.image ? ruteImgs+usuario.image:defaultImg} alt='dento metric' 
                                        style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                    </div>
                                </div>
                                <div className='col s12 movil-visible'>
                                    <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                        <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>
                                            <div className='sombra-azul-chica m-p-0' style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'40px', width:'40px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                                                <img className='dentometric m-p-0' src={usuario.image ? ruteImgs+usuario.image:defaultImg} alt='dento metric' 
                                                style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                            </div>
                                        </div>
                                        <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                            
                                            <div className='row cont-textos'>
                                                <div className='col s4 m4 m-p-0 centrar'>
                                                {permisosUsuario.users_update &&
                                                    <Link to={`${usuario.id}`} style={{color:'green'}}
                                                    data-position="top"
                                                    data-tooltip="Editar"
                                                    className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                        <i className="material-icons m-p-0" style={{fontSize:'20px',}}>create</i>
                                                    </Link>
                                                }
                                                </div>
                                                <div className='col s4 m4 m-p-0 centrar'>
                                                {permisosUsuario.users_read &&
                                                    <Link to={`detail/${usuario.id}`} 
                                                    data-position="top"
                                                    data-tooltip="Ver Detalle"
                                                    className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                        <i className="material-icons m-p-0" style={{fontSize:'20px',}}>content_paste</i>
                                                    </Link>
                                                }
                                                </div>
                                                <div className='col s4 m4 m-p-0 centrarmovil-100'>
                                                {permisosUsuario.users_delete &&
                                                    <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} onClick={(e)=>_delete(usuario.id)}>delete_forever</i>
                                                }
                                                </div>
                                            </div>
                                            
                                                </div>
                                            </div>
                                        </div>

                                <div className='col s3 m4 movil-visible'>
                                    <div className='table-header-movil'>Nombre </div>
                                </div>
                                <div className='col s9 m3'> <div className='cont-textos'>{usuario.name}</div></div>
                                <div className='col s3 m4 movil-visible'>
                                    <div className='table-header-movil'> Correo </div>
                                </div>
                                <div className='col s9 m3'> <div className='cont-textos'>{usuario.email}</div> </div>
                                <div className='col s3 m4 movil-visible'>
                                    <div className='table-header-movil'>Rol </div>
                                </div>
                                <div className='col s9 m1'> <div className='cont-textos'>{(usuario.roles.length>0)?usuario.roles[0].name:""}</div> </div>
                                <div className='col s3 m4 movil-visible'>
                                    <div className='table-header-movil'>Sucursal </div>
                                </div>
                                <div className='col s9 m2'> <div className='cont-textos'>{(usuario.sucursal)?usuario.sucursal.nombre_sucursal:"Sin asignar"}</div> </div>
                                <div className='col s3 m4 movil-visible'>
                                    <div className='table-header-movil'>Status </div>
                                </div>
                                <div className='col s9  m1'> 
                                    {usuario.activo?
                                        <div className='cont-textos'><i className="material-icons" style={{fontSize:'14px', color:'green'}}>lens</i> &nbsp;Activo</div>:
                                        <div className='cont-textos'><i className="material-icons" style={{fontSize:'14px', color:'red'}}>lens</i> &nbsp;Inactivo</div>
                                    } 
                                </div>
                                <div className='col s12 m1 movil-oculto'>
                                    <div className='row cont-textos'>
                                    {permisosUsuario.users_update &&
                                        <Link to={`${usuario.id}`} style={{color:'green'}}
                                        data-position="top"
                                        data-tooltip="Editar"
                                        className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                            <i className="material-icons m-p-0" style={{fontSize:'20px',}}>create</i>
                                        </Link>
                                    }
                                    {permisosUsuario.users_read &&
                                        <Link to={`detail/${usuario.id}`} 
                                        data-position="top"
                                        data-tooltip="Ver Detalle"
                                        className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                            <i className="material-icons m-p-0" style={{fontSize:'20px',}}>content_paste</i>
                                        </Link>
                                    }
                                        <div className='col m6 m-p-0 centrar movil-100'>
                                        {permisosUsuario.users_delete &&
                                            <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} onClick={(e)=>_delete(usuario.id)}>delete_forever</i> 
                                        }
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>  
                        )}
                        
                </div>
            );
        }
        return (
          <NoPermissions />
        )
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb hasButton={permisosUsuario.users_create} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    {loader?
                        renderTable():
                        <div className='height-200 centrar'>
                            <Spinner size={"medium"} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Usuarios;