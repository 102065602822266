import React, { useContext, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import esLocale from '@fullcalendar/core/locales/es';
import axios from '../../axios';
import M from 'materialize-css'
import EditCitaModal from './EditCitaModal';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';

const today = new Date();
const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

const VerAgenda = () => {
    const {user} = useContext(UserContext);
    const [calendarView,setCalendarView] = useState('dayGridWeek');
    const [calendarApi,setCalendarApi] = useState();
    const [sucursales,setSucursales] = useState([]);
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState();
    const [citasEvents,setCitasEvents] = useState([]);
    const [citas,setCitas] = useState([]);
    const [citaSeleccionada,setCitaSeleccionada] = useState(null);
    const [permisos,setPermisos] = useState({
        appointments_calendar_read:null,
        appointments_update:null
    })
    const calendar = useRef();

    useEffect(()=>{
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                appointments_calendar_read:checkPermiso("appointments_calendar_read",user.permissions),
                appointments_update:checkPermiso("appointments_update",user.permissions)
            }));
            setCalendarApi(calendar.current.getApi());
            getSucursales();
       }
      
    },[user,sucursalSeleccionada,citaSeleccionada]);

    useEffect(()=>{
        if(sucursalSeleccionada && citaSeleccionada===null){
            _getCitasOfTheMonth(firstDayMonth,lastDayMonth);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sucursalSeleccionada,citaSeleccionada])

    const getSucursales = () =>{
        axios.get('/api/sucursals').then((resp)=>{
            setSucursales(resp.data);
            M.FormSelect.init(document.querySelector('#sucursal'));
        }).catch(e=>console.log("Error: ",e)); 
    }

    const _getCitasOfTheMonth = (fDay,lDay) => {
        const data = {
            start:fDay,
            end:lDay,
            sucursal_id:sucursalSeleccionada
        };
       // console.log("data",data)
        if(permisos.appointments_calendar_read){
            axios.post('api/get_appointments',data).then((resp)=>{
            console.log("TODAS LAS CIKTAS",resp.data)
                let eventParse = resp.data.map(item=>{
                    let paciente = (item.paciente) ? item.paciente:"";
                    let estudio = (item.product_id) ? item.product.nombre_producto:"";
                    return {
                        id:item.id,
                        title:item.hora,
                        start:item.fecha,
                        color:item.paciente ? "#fb6340":"#2dce89",
                        extendedProps: {
                            paciente:paciente,
                            excedido:item.excedido,
                            products:item.products
                        }
                    }
                })
        
                setCitasEvents(eventParse);
                setCitas(resp.data);
            }).catch(e=>console.log("Error: ",e)); 
        }
       
    }


    /** Inicio --- Funciones del calendario **/
    const changeCalendarView = (view) =>{
        setCalendarView(view)
        calendarApi.changeView(view)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
                 <b className='m-p-0'><i>{eventInfo.event.title}</i>&nbsp; 
                    {eventInfo.event.extendedProps.excedido > 0 &&<i className="material-icons" style={{fontSize:16,position:'absolute',top:2,right:5}}>alarm</i>}
                 </b>
                 {eventInfo.event.extendedProps.paciente !== "" ? 
                    <>
                        <p className='m-p-0'><b>Paciente: </b> {eventInfo.event.extendedProps.paciente}</p>
                        <p className='m-p-0'><b>Estudios: </b>
                            {eventInfo.event.extendedProps.products.map((p)=>
                                <p className='m-p-0'> {p.nombre_producto}</p>
                            )}
                        </p>
                    </>
                    :
                    <p className='m-p-0'>Disponible para agendar</p>
                 }
            </>
        )
    };

    const handleChangedMonth = (event) =>{
        let firstDayMonth = new Date(event.startStr).toISOString().split('T')[0];
        let lastDayMonth = new Date(event.endStr).toISOString().split('T')[0];
        _getCitasOfTheMonth(firstDayMonth,lastDayMonth);     
    }

    const _eventDetail = ({event}) =>{
        permisos.appointments_update ?
            setCitaSeleccionada(event.id)
            :
            window.alert("No tienes permiso de editar citas")
    }

    useEffect(()=>{
        if(citaSeleccionada){
            let instance = M.Modal.getInstance(document.querySelector('#modal-edit-cita'));
            instance.open();
        }
    },[citaSeleccionada])

    /** FIN -- Funciones del calendario **/

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container' >
                <div className='container-admin z-depth-1 row calendar-agenda'>
                    <div className="mt-3 mt-md-0 text-md-right col-lg-6 m-p-0 row" style={{textAlign:'right'}}>
                        <div className='row col s12 info-badge border-r-10 m-p-0 centrar-h'>
                            <div className='col s2 m2 m-p-0 row'>
                                <div className="col s3 m4 m-0 p-5">
                                    <img className='dentometric' src='/images/calendar.png' style={{width:'100%'}}
                                    alt='doctor dento metric' />
                                </div>
                                <div className="col s3 m8 m-0 p-5 actual centrar" style={{color:'#ffffff'}}><br></br>Ver Agenda</div>
                            </div>
                          
                            <div className='row col s10'>
                                <div className='col s6 m6 input-field'>
                                    <div className='btn-blanco m-p-0' id='selectiones'>
                                        <select className='m-0' id='sucursal' defaultValue={sucursalSeleccionada} onChange={(e)=>setSucursalSeleccionada(e.target.value)}
                                        style={{borderRadius:'10px', margin:'0px!important', padding:'20px!important', border:'solid red 1px'}}>
                                            <option value="0" className='m-0' disabled selected>Seleccione la sucursal</option>
                                            {sucursales.map( (sucursal)=>
                                            <option value={sucursal.id} key={sucursal.id} className='m-0'>{sucursal.nombre_sucursal}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='m-p-0 col s6 m6 centrar'>
                                    <button type="button" data-calendar-view="month" 
                                    onClick={(e)=>changeCalendarView('dayGridMonth')}
                                    className="btn-neutral btn btn-default btn-sm btn-azul"> Ver Mes</button>
                                    <button type="button" data-calendar-view="basicWeek" 
                                    className="btn-neutral btn btn-default btn-sm btn-azul"
                                    onClick={(e)=>changeCalendarView('dayGridWeek')}>Ver Semana</button>
                                    <button type="button" data-calendar-view="basicDay" 
                                    className="btn-neutral btn btn-default btn-sm btn-azul"
                                    onClick={(e)=>changeCalendarView('dayGridDay')}>Ver Día</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col s12 p-10'>
                        <EditCitaModal citaId={citaSeleccionada} citas={citas} setCitas={setCitas} setCitaSeleccionada={setCitaSeleccionada}  appointments_clean={permisos.appointments_clean}></EditCitaModal>
                       
                            <FullCalendar ref={calendar}
                                plugins={[ dayGridPlugin, interactionPlugin ]}
                                initialView={calendarView}
                                locale={esLocale}
                                selectable={true}
                                selectHelper={true}
                                editable= {true}
                                events={citasEvents}
                                eventContent={renderEventContent}
                                datesSet={handleChangedMonth}
                                eventClick={(e)=>_eventDetail(e)}
                                showNonCurrentDates={false}
                            />
                       
                
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default VerAgenda;