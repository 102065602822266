import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axios';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import M from 'materialize-css';

const Edit = () => {
    const {userId} = useParams();
    const [usuario,setUsuario] = useState({name:"",email:"",password:"",password_confirmation:"",phone:"",sucursal_id:0,rol:{}});
    const [sucursales,setSucursales] = useState([])
    const [roles,setRoles] = useState([])
    const navigate = useNavigate();

    useEffect (() =>{
        axios.get(`api/users/${userId}`)
        .then(res=>{
            console.log(res.data, "editar usuario");
            setUsuario(res.data)
        }).catch(e =>{console.log(e, 'Error ')})

        axios.get('api/sucursals')
        .then(response=>{
            console.log(response.data)
            setSucursales(response.data)
            M.FormSelect.init(document.querySelector('#select-sucur'));
        }).catch(e=>console.log("error",e))

        axios.get('api/roles')
        .then((res)=>{
            console.log("roles",res.data);
            setRoles(res.data)
            M.FormSelect.init(document.querySelector('#select-rol'));
        }).catch(e=>console.log("error ",e))
    },[])

    const _update = (e) => {
        e.preventDefault();
        console.log("guardando...",usuario)
        axios.put(`api/users/${userId}`,usuario)
        .then(res=>{
            console.log("nuevo user..",res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>usuario actualizado exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/usuarios")
            }, 3000);
        }).catch(e=>{
            console.log("error",e)
            if(e.message) M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+e.message+'</span>',classes:"error-notify"})
        })
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                    <h5>Editar Usuario</h5>
                    <form className="row ancho100" id='general-form' onSubmit={_update}>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">Nombre</label>
                                <input id="name" type="text" className="validate" value={usuario.name} onChange={(e)=>setUsuario({...usuario,name:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="phone">Teléfono</label>
                                <input id="phone" type="number" className="validate" value={usuario.phone} onChange={(e)=>setUsuario({...usuario,phone:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="email">E-mail</label>
                                <input id="email" type="email" className="validate" value={usuario.email} disabled/>
                            </div>
                        </div>
                        
                        <div className="col s12 m4">
                            <div className="input-text">
                                <label>Sucursal</label>
                                <select id="select-sucur" value={usuario.sucursal_id} onChange={(e)=>setUsuario({...usuario,sucursal_id:e.target.value})}>
                                    <option value="seleccionar" >Seleccione una sucursal</option>
                                    {sucursales.map((item,i)=>
                                        <option value={item.id}  key={item.id}>{item.nombre_sucursal}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="input-text">
                                <label htmlFor="puesto">Rol</label>
                                <select id="select-rol" value={usuario.rol&&usuario.rol.name}  onChange={(e)=>setUsuario({...usuario,rol:{...usuario.rol,name:e.target.value}})}>
                                    <option value="seleccionar">Seleccione un rol de usuario</option>
                                    {roles.map((item,i)=>
                                        <option value={item.name}  key={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="switch">
                                <label>
                                Off
                                <input type="checkbox" checked={usuario.activo} onChange={(e)=>setUsuario({...usuario,activo:e.target.checked})}/>
                                <span className="lever"></span>
                                On
                                </label>
                            </div>
                        </div>
                        
                        <div className='col s12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Edit;