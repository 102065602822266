import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

const Municipios = () => {
    const [municipios, setMunicipios] = useState([]);
    const [estado,setEstado] = useState({description:""})
    const {estadoId} = useParams();
    
    useEffect(()=>{
        axios.get(`api/municipalities-by-state/${estadoId}`)
        .then((res)=>{
            setMunicipios(res.data)
        }).catch(e=>console.log(e))
    },[estadoId])

    useEffect(()=>{
        axios.get(`api/states/${estadoId}`).then((res)=>{
            setEstado(res.data)
        }).catch(e=>console.log(e))
    },[estadoId])

    const _delete = (id) =>{
        console.log("holis")
    }

    return (
        <>
            <div className='container-admin-menu'> 
                <Breadcrumb hasButton={true} hasFilters={false}/>
            </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <div className='table'>
                        <div className="table-item-counter">Total de Municipios: {municipios.length}</div>
                        <div className='col s12 info-badge'>
                            {estado.description}
                        </div>
                        <div className='table-header row movil-oculto'>
                            <div className='col m1'>ID</div>
                            <div className='col m5'>Municipio</div>
                            <div className='col m2'>No. Colonias</div>
                            <div className='col m2'>Estatus</div>
                            <div className='col m2 centrado'>Acciones</div>
                        </div>
                        {municipios.map(municipio=>
                        <div className='table-container row' key={municipio.id}>
                            <div className='col s8 m1 movil-oculto'>
                                <div className='cont-textos'>{municipio.id}</div>
                            </div>
                            <div className='col s12 movil-visible'>
                                <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                    <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>{municipio.id}</div> </div>
                                    <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                        <div className='cont-textos row'>
                                            <Link to={`${municipio.id}`} style={{color:'green'}}
                                            className="col s6 m6 push-m3 m-p-0 centrar">
                                                <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                            </Link>
                                            <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(municipio.id)}>delete_forever</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Estado</div>
                            </div>
                            <div className='col s8 m5'>
                                <div className='cont-textos'>{municipio.description}</div>
                            </div>
                            <div className='col s8 m2'>
                                <div className='cont-textos'>{municipio.suburbs_count}</div>
                            </div>
                            <div className='col s8 m2'>
                                {municipio.status?
                                    <div className='cont-textos'><i className="material-icons" style={{fontSize:'14px', color:'green'}}>lens</i> &nbsp;Activo</div>:
                                    <div className='cont-textos'><i className="material-icons" style={{fontSize:'14px', color:'red'}}>lens</i> &nbsp;Inactivo</div>
                                } 
                            </div>

                            <div className='col s12 m2 movil-oculto'>
                                <div className='cont-textos row m-p-0'>
                                    <div className='col s4 m4 m-p-0 centrar'>
                                        <Link to={`${municipio.id}`}
                                        className="m-p-0 centrar">
                                            <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                        </Link>
                                    </div>
                                    <div className='col s4 m4 m-p-0 centrar'>
                                        <Link to={`${municipio.id}/colonias`}
                                        className="col s4 m4 m-p-0 centrar">
                                            <i className="material-icons m-p-0" style={{fontSize:'20px', color:'#5e72e4'}}>library_books</i>
                                        </Link>
                                    </div>
                                    <div className='col s4 m4 m-p-0 centrar'>
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(municipio.id)}>delete_forever</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Municipios;