import React, {useEffect,useState} from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';

const Perfil = () => {
    const {userId} = useParams();
    const [usuario,setUsuario] = useState({name:"",email:"",password:"",password_confirmation:"",phone:"",sucursals_id:0});
    let defaultImg = "/images/default-user.png";
    
    useEffect(()=>{
        console.log("perfil")
        axios.get(`api/users/${userId}`)
        .then((res)=>{
            console.log(res.data)
            setUsuario(res.data)
        })
        .catch(e=>console.log("error",e))
    },[])

    const _updateImg = (file) =>{
        console.log(file)
        const fData = new FormData();
        fData.append('id', usuario.id)
		fData.append("file", file);

        axios.post('api/update-profile-image',fData).then((resp)=>{
            console.log("Response ",resp)
        }).catch(e=>console.log("error",e))
    }
    
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb actual={usuario.name}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1' >
                    <h5> Mi perfil</h5>
                    
                    <div className='col m12 row m-0 p-10 grey lighten-5'> 
                        <div className='row cont-row  m-p-0'>
                            <div className='col s12 m2 m-p-0'>
                                <div className='valign-wrapper' style={{height:150,position:'relative'}}>
                                    <img src={usuario.image ? usuario.image:'/images/perfil2.png'} alt="user profile image" 
                                    className="responsive-img" style={{height:140,borderRadius:10,}}/>
                                </div>
                            </div>
                            <div className='col s12 m5 m-p-0'> 
                                <p className='m-5'><label>ID:<b> {usuario.id} </b> </label></p>
                                <p className='m-5'><label>Nombre:<b> {usuario.name} </b> </label></p>
                                <p className='m-5'><label>Rol:<b> {usuario.rol&&usuario.rol.name}</b></label></p>
                                <p className='m-5'><label>Fecha de ingreso:<b> {new Date(usuario.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</b></label></p>
                            </div>
                            <div className='col s12 m5 m-p-0'>
                                <p className='m-5'><label>RFC:<b> {usuario.rfc}</b></label></p>
                                <p className='m-5'><label>CURP:<b> {usuario.curp}</b></label></p>
                                <p className='m-5'><label>Numero Seguro Socicial:<b> {usuario.nss}</b></label></p>
                                <div className='col m12'>
                                    <label>Sucursal</label>
                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>location_city</i>
                                        <span>{(usuario.sucursal)?usuario.sucursal.nombre_sucursal:'Sin asignar'}</span>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col s12 m6 m-t-20'>
                            <label>Email</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>mail</i>
                                <span>{usuario.email}</span>  
                            </div>
                        </div>
                        <div className='col s12 m6 m-t-20'>
                        <label>Telefono</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>call</i>
                                <span>{usuario.phone}</span>  
                            </div>
                        </div>
                    
                        <div className='col s12 m6 m-t-20'>
                            <label>Nueva Contraseña</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>bpn_key</i>
                                <span>{usuario.email}</span>  
                            </div>
                        </div>
                        <div className='col s12 m6 m-t-20'>
                            <label>Confirmar Contraseña</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>bpn_key</i>
                                <span>{usuario.phone}</span>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Perfil;