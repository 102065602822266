import React, { useContext, useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from '../../../axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import Loader from '../../../Layout/Loaders/Loader';
import NoPermissions from '../../NoPermissions/NoPermissions';

const EditClientes = () => {
    const  {clienteId} = useParams();
    const navigate = useNavigate();
    const [cliente,setCliente] = useState({
        nombre:'',
        direccion:'',
        cp:'',
        ciudad:'',
        fecha_nacimiento:'',
        telefono:'',
        celular:'',
        rfc:'',
    });

    useEffect(()=>{
        axios.get(`api/patients/${clienteId}`).then((resp)=>{
            setCliente(resp.data)
        }).catch(e=>console.log(e))
    },[clienteId])
    
    const {user} = useContext(UserContext);
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        patient_create:null,
        patient_read:null,
        patient_update:null,
        patient_delete:null
    });
    useEffect(()=>{
        console.log('user', user)
        if(user.id){
            getClientes();
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                patient_create:checkPermiso("patient_create",user.permissions),
                patient_read:checkPermiso("patient_read",user.permissions),
                patient_update:checkPermiso("patient_update",user.permissions),
                patient_delete:checkPermiso("patient_delete",user.permissions)
            }));
        }
    },[user]);

    useEffect(()=>{
        (permisos.patient_read !==null) && setLoaded(true);
    },[permisos])

    const getClientes = () =>{
        axios.get('api/patients')
        .then((res)=>{
            setCliente(res.data)
        }).catch(e=>console.log(e))
    }


    const _editcliente = (e) =>{
        e.preventDefault();
        console.log("cliente update..",cliente)
        axios.put(`api/patients/${clienteId}`,cliente).then(resp=>{
            console.log('clienteEtado', resp.data);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>cliente actualizado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/clientes")
            }, 3000);
        }).catch(e=>console.log(e))
    }

    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb hasButton={permisos.patient_create} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                {loaded ?
                    permisos.patient_read?
                    <>
                
                        <div className='row col s12 m-p-0 info-badge border-r-10'>
                            <div className="col s12 m1 m-p-0 centrar">
                                <div className='sombra-azul-chica col s12 m2 m-p-0' 
                                style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'36px', width:'36px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                                    <img className='dentometric m-p-0' src='/images/paciente.png' alt='dento metric' 
                                    style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                </div>
                            </div>
                            <div className="col s12 m9 m-0 p-8">
                                EDITAR - Datos de el Paciente
                            </div>  
                            <div className="col s12 m2 m-0 p-8 t-right"> 
                                <Link to="../clientes">Regresar</Link>
                            </div>  
                        </div>

                        <form onSubmit={_editcliente} className="row ancho100" id='general-form'>
                            <div className="col s12 m6 m-t-25">
                                <div className="input-text">
                                    <label htmlFor="name"> Nombres </label>
                                    <input id="name" type="text"  
                                    value={cliente.nombre}
                                    onChange={(e)=>setCliente({...cliente,nombre:e.target.value})}
                                />
                                </div>
                            </div>
                            <div className="col s12 m3 m-t-25">
                                <div className="input-text">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input id="telefono" type="text" 
                                    value={cliente.telefono}
                                    onChange={(e)=>setCliente({...cliente,telefono:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m3 m-t-25">
                                <div className="input-text">
                                    <label htmlFor="cel">Celular</label>
                                    <input id="cel" type="text" 
                                    value={cliente.celular}
                                    onChange={(e)=>setCliente({...cliente,celar:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m10">
                                <div className="input-text">
                                    <label htmlFor="direccion"> Direccion </label>
                                    <input id="direccion" type="text" 
                                    value={cliente.direccion}
                                    onChange={(e)=>setCliente({...cliente,direccion:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m2">
                                <div className="input-text">
                                    <label htmlFor="cp">CP</label>
                                    <input id="cp" type="text" 
                                    value={cliente.cp}
                                    onChange={(e)=>setCliente({...cliente,cp:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m6">
                                <div className="input-text">
                                    <label htmlFor="ciudad">Ciudad</label>
                                    <input id="ciudad" type="text" 
                                    value={cliente.ciudad}
                                    onChange={(e)=>setCliente({...cliente,ciudad:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col s12 m3">
                                <div className="input-text">
                                    <label htmlFor="fNacimiento">Fecha Nacimiento</label>
                                    <input id="fNacimiento" type="date" 
                                    value={cliente.fecha_nacimiento}
                                    onChange={(e)=>setCliente({...cliente,fecha_nacimiento:e.target.value})}/>
                                </div>
                            </div>
                        
                            <div className="col s12 m3">
                                <div className="input-text">
                                    <label htmlFor="rfc"> RFC </label>
                                    <input id="rfc" type="text" maxLength={19}
                                        value={cliente.rfc}
                                        onChange={(e)=>setCliente({...cliente,rfc:e.target.value})}/>
                                </div>
                            </div>

                            <div className="col s12 t-right m-20"> 
                                <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar datos
                                    <i className="material-icons right">save</i>
                                </button>
                            </div>
                        </form>
                    </>
                    : <NoPermissions />
                :
                <div className='height-200 centrar'>
                    <Loader />
                </div>
                }
                </div>
            </div>
        </>
    );
};

export default EditClientes;