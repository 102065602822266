import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import { useNavigate } from 'react-router-dom';

const PedidosCaja = (props) => {
    const {productos,total,order,orderPayment,setOrderPayment} = props;
    const [subtotal,setSubtotal] = useState(total);
    const [descuentoAdmin,setDescuentoAdmin] = useState(0);
    const [ivaOption,setIvaOption] = useState(.16)
    const [iva,setIva] = useState(0);
    const [subTotalIva,setSubtotalIva] = useState(total);
    const [totalFinal,setTotalFinal] = useState(0);
    const [anticipo,setAnticipo] = useState(0);
    const [saldo1,setSaldo1]=useState(0);
    const [anticipo2,setAnticipo2] = useState(0);
    const [saldo2,setSaldo2]=useState(0);
    const [anticipo3,setAnticipo3] = useState(0);
    const [saldo3,setSaldo3]=useState(0);
    const [pagoCon,setPagoCon] = useState(0)
    const [cambio,setCambio] = useState(0);
    const [real,setReal] = useState(0);
    const [buscado,setBuscado] = useState(0)
    const [encontrado,setEncontrado] = useState(0);
    const navigate = useNavigate();
    
    useEffect(()=>{
        _calcularTotales();
    },[total,descuentoAdmin,ivaOption]);
    

    useEffect(()=>{
        const calcularAnticipos = () =>{
            //anticipo 1
            let saldoTemporal = totalFinal-anticipo;
            setSaldo1(saldoTemporal.toFixed(2));

            //anticipo 2
            console.log("anticipo 2",saldoTemporal)
            console.log("anticipo 2",anticipo2)
            let saldoTemporal2 = saldoTemporal.toFixed(2)-anticipo2;
            setSaldo2(saldoTemporal2.toFixed(2));

             //anticipo 3 
             let saldoTemporal3 = saldoTemporal2.toFixed(2)-anticipo3;
             setSaldo3(saldoTemporal3.toFixed(2));
        }
        calcularAnticipos();
    },[anticipo, anticipo2, anticipo3, totalFinal]);

    useEffect(()=>{
        calcularBuscado();
    },[real,buscado]);

    //calcular porcentage buscado       
    const calcularBuscado = () =>{
       let resultadito = (((buscado*100)/real)-100)*-1;
       setEncontrado(resultadito)
       console.log(resultadito)
    }

    const _calcularTotales = () =>{
        //calcular descuento del admin
        let descuentoAplicado = (descuentoAdmin * total) / 100;
        descuentoAplicado = total-descuentoAplicado;
        setSubtotal(descuentoAplicado.toFixed(2));
        //calcular Iva
        let ivaTemp = (descuentoAplicado * ivaOption).toFixed(2);
        setIva(ivaTemp);
        //calcular TOTAL
        let tempTotal = Number(descuentoAplicado)+Number(ivaTemp);
        setSubtotalIva(tempTotal.toFixed(2));
        setTotalFinal(tempTotal.toFixed(2));
    }

    const _savePayment = (e) =>{
        e.preventDefault();
        let dataPayload ={
            order_id:order.id,
            iva_porciento:ivaOption,
            descuento:descuentoAdmin,
            anticipo1:anticipo,
            anticipo2:anticipo2,
            anticipo3:anticipo3,
            subtotal:subtotal,
            total:totalFinal,
            saldo:saldo1,
            saldo2:saldo2,
            saldo3:saldo3,
            observaciones:orderPayment.observaciones,
            metodo_de_pago:orderPayment.metodo_de_pago,
            factura:0
        };

        axios.put(`api/order-payment/${orderPayment.id}`,dataPayload).then(resp=>{
           navigate("/admin/pedidos");
        }).catch(e=>console.log("Error",e));
    }

    const handleCambio = (value) =>{
      
        let pagoCon = value;
        let resultado = pagoCon - totalFinal;
        setCambio(resultado.toFixed(2));
    }

    return (
        <div className='row m-0 p-10-h-20'>
            <div className='col s12 titles-badge centrado'>DETALLE DEL PEDIDO</div>
            <div className='col s12 table table-doc m-0 p-5' 
            style={{borderRadius:'0 0 20px 20px', border:'solid 1px #dcdcdc', background:'#fff', marginBottom:'15px !important'}}>
                <div className='table-header row'>
                    <div className='row col s12'>
                    <div className='col s1 centrar'>ID</div>
                    <div className='col s4 centrar'>Productos</div>
                    <div className='col s2 centrar'>% Unidad</div>
                    <div className='col s1 centrar'>Cantidad</div>
                    <div className='col s1 centrar'>%</div>
                    <div className='col s2 centrar'>$ con %</div>
                    <div className='col s1 centrar'>Dias</div>
                    </div>
                </div>
                
                <div className='table-container'>
                    {productos.map(producto=>
                        <div className='row col s12'>
                            <div className='col s12 m1 centrar' style={{minHeight:40}}>
                                <div>{producto.id}</div>
                            </div>
                            <div className='col s12 m4 centrar'>
                            <div>{producto.nombre_producto}</div>
                            </div>
                            <div className='col s12 m2 centrar'>
                            <div>${producto.precio_unidad}</div>
                            </div>
                            <div className='col s12 m1 centrar'>
                                <div>{producto.cantidad}</div>
                            </div>
                            <div className='col s12 m1 centrar'>
                                <div>{producto.descuento} %</div>
                            </div>
                            <div className='col s12 m2 centrar'>
                            <div>${producto.precio_descuento}</div>
                            </div>
                            <div className='col s12 m1 centrar'>
                                <div>{producto.dias_entrega}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='col s12 titles-badge centrado'>PAGOS</div>
            <div className='col s12 table table-doc m-0 p-5' 
            style={{borderRadius:'0 0 20px 20px', border:'solid 1px #dcdcdc', background:'#fff', marginBottom:'15px !important'}}> 
                <form  className="row m-p-0 ancho100" id='general-form'>
                    <div className='col s12 m3 row'>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="subtotal">SubTotal</label>
                                <input id="subtotal" type="number" value={total} disabled/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="descuento" step="any">Descuento</label>
                                <input id="descuento" type="number" value={descuentoAdmin} onChange={(e)=>setDescuentoAdmin(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="subtotalFinal">Subtotal con descuento</label>
                                <input id="subtotalFinal" type="text" value={subtotal} disabled/>
                            </div>
                        </div>
                        <div className="row col s12">
                            <div className=" col s4 input-text ">
                                <label htmlFor="name">IVA</label>
                                <select className='browser-default custom-select' value={ivaOption} onChange={(e)=> setIvaOption(e.target.value)}>
                                    <option value={0}>0%</option>
                                    <option value={.1}>10%</option>
                                    <option value={.16}>16%</option>
                                </select>
                            </div>

                            <div className="col s8 input-text p-5">
                                <label htmlFor="iva">Total IVA</label>
                                <input  id="iva" type="text" value={iva}/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="total">Total</label>
                                <input id="total" type="text" value={subTotalIva}/>
                            </div>
                        </div>
                    </div>
                    <div className='col s12 m3 row'>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="totalFinal">Total 2</label>
                                <input id="totalFinal" type="text" value={totalFinal}/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="anticipo">Anticipo</label>
                                <input id="anticipo" type="number"  value={anticipo} step="any" onChange={(e)=>setAnticipo(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="saldo">Saldo</label>
                                <input id="saldo" type="numer" step="any" value={saldo1} disabled/>
                            </div>
                        </div>
                    </div>
                    <div className='col s12 m3 row'>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="pagoDos">2° Pago</label>
                                <input id="pagoDos" type="number" stemp="any" value={anticipo2} step="any" onChange={(e)=>setAnticipo2(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="saldo2">Saldo 2</label>
                                <input id="saldo2" type="number" stemp="any" value={saldo2} disabled/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="pagoTres">3° Pago</label>
                                <input id="pagoTres" type="number" stemp="any" value={anticipo3} step="any" onChange={(e)=>setAnticipo3(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="saldo3">Saldo 3</label>
                                <input id="saldo3" type="number" stemp="any" value={saldo3} disabled/>
                            </div>
                        </div>
                        <div className="col s4 m-p-0">
                            <div className="input-text m-p-0">
                                <label htmlFor="condicion">Condicion de pago</label>
                            </div>
                        </div>
                        <div className="col s8">
                            <div className="input-text">
                               <select className='browser-default custom-select' onChange={(e)=>setOrderPayment({...orderPayment,metodo_de_pago:e.target.value})}>
                                    <option value="">Seleccione una opcion</option>
                                    <option value="efectivo">Efectivo</option>
                                    <option value="tarjeta credito">Tarjeta de credito</option>
                                    <option value="targeta debito">Tarjeta de debito</option>
                                    <option value="transferencia">Transferencia</option>
                                    <option value="cheque">Cheque</option>
                               </select>
                            </div>
                        </div>
                    </div>
                    <div className='col s12 m3'>
                        <div className='col s12 row m-p-0'>
                            <div className="col s3  m-p-0">
                                <div className="input-text  m-p-0">
                                    <label htmlFor="totales">Total</label>
                                </div>
                            </div>
                            <div className="col s9">
                                <div className="input-text">
                                    <input id="totales" type="number" step="any" value={totalFinal} disabled/>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 row m-p-0'>
                            <div className="col s3  m-p-0">
                                <div className="input-text  m-p-0">
                                    <label htmlFor="con">Paga con</label>
                                </div>
                            </div>
                            <div className="col s9" >
                                <div className="input-text">
                                    <input id="con" type="number" step="any" onChange={(e)=>handleCambio(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 row m-p-0'>
                            <div className="col s3  m-p-0">
                                <div className="input-text  m-p-0">
                                    <label htmlFor="cambio">Cambio</label>
                                </div>
                            </div>
                            <div className="col s9">
                                <div className="input-text">
                                    <input id="cambio" type="numer" step="any" value={cambio} disabled/>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 row m-p-0'>
                            <div className="col s3  m-p-0">
                                <div className="input-text  m-p-0">
                                    <label htmlFor="real">Real</label>
                                </div>
                            </div>
                            <div className="col s9">
                                <div className="input-text">
                                    <input id="real"  value={real}  type="number" onChange={(e)=>setReal(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 row m-p-0'>
                            <div className="col s3  m-p-0">
                                <div className="input-text  m-p-0">
                                    <label htmlFor="buscado">Buscado</label>
                                </div>
                            </div>
                            <div className="col s9">
                                <div className="input-text">
                                    <input id="buscado" value={buscado} type="number" onChange={(e)=>setBuscado(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 row m-p-0'>
                            <div className="col s3  m-p-0">
                                <div className="input-text  m-p-0">
                                    <label htmlFor="desc">% Desc</label>
                                </div>
                            </div>
                            <div className="col s9">
                                <div className="input-text">
                                    <input id="desc" value={encontrado} type="text"/>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    <div className='col s12 m12 row m-p-0 t-right'>
                        <button className='btn-info width-20 m-20 negritas' onClick={(e)=>_savePayment(e)}>Cerrar Pedido</button>
                    </div>
                </form>   
            </div>
        </div>
    );
};

export default PedidosCaja;