import React,{useState, createContext, useEffect} from 'react';
import axios from './axios'

export const UserContext = createContext();

export const UserProvider = props => {
	const [user, setUser] = useState({});
	const [auth, setAuth] = useState(true);

	useEffect(()=>{
        axios.get('/api/user')
        .then( res=>{
            setUser(res.data);
        }).catch(e =>alert(e))
	},[])

	return (
		<UserContext.Provider value={{user,setUser,auth,setAuth}}>
			{props.children}
		</UserContext.Provider>
	)
}
