import React, { useEffect, useState } from 'react';
import axios from '../../../axios';
import M from 'materialize-css';

const CategoriaPrecioForm = (props) => {
    const {categoria,setProductoSelected,setUpdated} = props;
    const [listado,setListado]=useState({precio:0.00})

    useEffect(()=>{
        console.log("categortia",props.categoria)
        if(categoria.pivot){
            setListado(categoria.pivot)
        }  
    },[])

    const _savePrecio = (precio) =>{
        console.log("el listado",listado)
        console.log("precio",precio);
        axios.post(`api/save-category-price`,listado)
        .then((resp)=>{
            console.log("response",resp);
            if(resp.status === 200){
                updateService();
                M.toast({
                    html:`<i class="material-icons">sentiment_very_satisfied</i> &nbsp;&nbsp;<span>${resp.data.message}</span>`,
                    classes:"success-notify"
                })
            }
        })
        .catch(e=>console.log("Error:",e)); 
    }

    const deletePrecio = () => {
        console.log("borrar",listado)
        axios.post(`api/delete-category-price`,listado)
        .then((resp)=>{
            console.log("response",resp);
            if(resp.status === 200){
                updateService();
                M.toast({
                    html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>${resp.data.message}</span>`,
                    classes:"success-notify"
                })
            }
        })
        .catch(e=>console.log("Error:",e)); 
    }

    const updateService = () =>{
        axios.get(`api/products/${listado.product_id}`).then((resp)=>{
            console.log("producto refrescado..",resp)
            setProductoSelected(resp.data);
            setUpdated(true);
        }).catch(e=>console.log("Error:",e))
    }

    return (
        <div className='row p-10' id='general-form'>
            <div className='col s12 m10 m-t-10'>
                <div className='input-text'>
                    <label htmlFor="precio">Precio:</label>
                    <input id="precio" type="number" step="any" 
                        value={listado.precio} 
                        onChange={(e)=>setListado({...listado,precio:e.target.value})}
                        onBlur={(e)=>_savePrecio(e.target.value)}
                        />
                </div>
            </div>
            <div className='col s12 m2 centrar' style={{height:50}}>
                <button className="btn-rounded" onClick={deletePrecio}><i className="material-icons">delete</i></button>
            </div>
        </div>
    );
};

export default CategoriaPrecioForm;