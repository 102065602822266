import React, {useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import Pagination from '../../Layout/Pagination/Pagination';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import { UserContext } from '../../UserContext';
import Loader from '../../Layout/Loaders/Loader';
import NoData from '../../Layout/Notificaciones/NoData';

const Especialidades = () => {
    const [especialidades, setEspecialidades] = useState([]);
     //estados para componente de paginacion
     const [itemsPerPage,setItemsPerPage] = useState(10);
     const [skip, setSkip] = useState(0);
     const [currentPage,setCurrentPage] = useState(1);
     const [loaded,setLoaded] = useState(false);
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({
        doctor_specialties_create:null,
        doctor_specialties_read:null,
        doctor_specialties_update:null,
        doctor_specialties_delete:null
    });

    useEffect(()=>{
        if(user.user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                doctor_specialties_create:checkPermiso("doctor_specialties_create",user.user.permissions),
                doctor_specialties_read:checkPermiso("doctor_specialties_read",user.user.permissions),
                doctor_specialties_update:checkPermiso("doctor_specialties_update",user.user.permissions),
                doctor_specialties_delete:checkPermiso("doctor_specialties_delete",user.user.permissions)
            }));

            axios.get('api/specialties').then(res=>{
                setEspecialidades(res.data)
            }).catch(e=>console.log("error ",e))
        }
       
    },[user])

    useEffect(()=>{
        (permisos.doctor_specialties_create !==null) && setLoaded(true);
    },[permisos])

    useEffect(()=>{
        axios.get('api/specialties').then(res=>{
            setEspecialidades(res.data)
        })
        .catch(e=>console.log("error",e))
    },[])

    const _delete = (id) =>{
        console.log("holis")
        if(window.confirm("Realmente deseas borrar a esta especialidad, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/specialties/${id}`)
            .then((res)=>{
                console.log(res)
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setEspecialidades(especialidades.filter(u=>u.id!==res.data.especialidad.id))
            }).catch(e=>console.log(e))
        }
    }

    //FUNCION PARA PAGINADOR
     const paginate = (paginateNumber)=>{
        setCurrentPage(paginateNumber)
    }

    //current Items Paginador
    const indexLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexLastItem - itemsPerPage;
    const currentItems = especialidades.slice(indexOfFirstItem,indexLastItem)

    
    return (
        <>
            <div className='container-admin-menu'> 
                <Breadcrumb hasButton={permisos.doctor_specialties_create} hasFilters={false} />
            </div> 
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                {loaded ?
                permisos.doctor_specialties_read ?
                    <>
                    <div className='table'>
                        <div className="table-item-counter">Total especialidades: {especialidades.length}</div>
                        <div className='table-header row movil-oculto'>
                            <div className='col m1'>ID</div>
                            <div className='col m3'>Especialidad </div>
                            <div className='col m6'>Notas</div>
                            <div className='col m2 t-right'>Acciones</div>
                        </div>
                        {currentItems.length > 0 ? currentItems.map(especialidad=>
                        <div className='table-container row' key={especialidad.id}>
                            <div className='col s8 m1 movil-oculto'>
                                <div className='cont-textos'>{especialidad.id}</div>
                            </div>
                            <div className='col s12 movil-visible'>
                                <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                    <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>{especialidad.id}</div> </div>
                                    <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                        <div className='cont-textos row'>
                                            <Link to={`${especialidad.id}`} style={{color:'green'}}
                                            className="col s6 m6 push-m3 m-p-0 centrar">
                                                <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                            </Link>
                                            <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(especialidad.id)}>delete_forever</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Especialidad </div>
                            </div>
                            <div className='col s8 m3'>
                                <div className='cont-textos'>{especialidad.especialidad}</div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Notas</div>
                            </div>
                            <div className='col s8 m6'>
                                <div className='cont-textos'>{especialidad.notas}</div>
                            </div>

                            <div className='col s12 m2 movil-oculto'>
                                <div className='cont-textos row'>
                                    {permisos.doctor_specialties_update &&
                                    <Link to={`${especialidad.id}`} style={{color:'green'}}
                                    className="col s6 m6 push-m3 m-p-0 centrar">
                                        <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                    </Link>
                                    }
                                    {permisos.doctor_specialties_delete &&
                                    <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(especialidad.id)}>delete_forever</i>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )
                        :
                        <NoData modulo="Especialidades"></NoData>
                        }
                    </div>
                    {currentItems.length > 0 ?
                        <Pagination 
                            currentPage={currentPage}
                            skip={skip}
                            totalItems={especialidades.length}  
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}   
                            paginate={paginate}
                        />
                    :
                        <p>&nbsp;</p>
                    }
                    </>
                    : <NoPermissions />
                :
                <div className='height-200 centrar'>
                    <Loader/>
                </div>
                }
                </div>
            </div> 
        </>
    );
};

export default Especialidades;