import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import axios from '../../../axios';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import Loader from '../../../Layout/Loaders/Loader';
import NoPermissions from '../../NoPermissions/NoPermissions';

const Cupones = () => {
    const user = useContext(UserContext);
    const [cupones, setCupones] = useState([]);
    const [loaded,setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permisos,setPermisos] = useState({
        coupons_create:null,
        coupons_read:null,
        coupons_update:null,
        coupons_delete:null
    });
    
    useEffect(()=>{
        if(user.user.id){
            getCoupon();
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                coupons_create:checkPermiso("coupons_create",user.user.permissions),
                coupons_read:checkPermiso("coupons_read",user.user.permissions),
                coupons_update:checkPermiso("coupons_update",user.user.permissions),
                coupons_delete:checkPermiso("coupons_delete",user.user.permissions)
            }));
        }
    },[user]);

    useEffect(()=>{
        (permisos.coupons_create !==null) && setLoaded(true);
    },[permisos])

    const getCoupon = () =>{
        axios.get('api/coupons')
        .then((res)=>{
            console.log('coupons',res)
            setCupones(res.data)
        }).catch(e=>console.log(e))
    }

    useEffect(() => {   
        axios.get('api/coupons')
        .then( res=>{
            console.log(res.data, 'todos los cupones')
            setCupones(res.data);
        }).catch(e =>{console.log(e, 'Error todos los cupones')})
    },[])

    const _delete = (id) =>{
        if(window.confirm("Realmente deseas borrar a este cupon, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/coupons/${id}`)
            .then((res)=>{
                console.log('coupons', res)
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setCupones(cupones.filter(u=>u.id!==res.data.cupon.id))
            }).catch(e=>console.log(e))
        }
    }

    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb hasButton={permisos.cupons_create} hasButton={true} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                {loaded ?
                    permisos.coupons_read?
                    <>
                        <div className='container-admin-container m-p-0'>
                            <div className='row col s12 info-badge border-r-10 m-p-0' style={{bordeRadius:'10px!important', padding:'0px!important'}}>
                                <div className="col s12 m12 row m-0 ">
                                    <div className='col s12 m3 m-p-0' 
                                    style={{height:'45px', width:'44px', padding:0, margin:'0!important',}}>
                                        <img className='dentometric m-p-0' src='/images/cupones1.png' alt='dento metric' 
                                        style={{height:'100%', width:'100%'}} />
                                    </div>
                                    <div className="col s12 m6 m-6 centar"> <br></br> Listado de cupones </div> 
                                </div>
                            </div>
                        </div>
                        {cupones.map( (cupon)=>
                            <div className='table col s12 m6 p-5 border-r-6' style={{border:cupon.activo ? 'solid 1px #999' :'solid 1px #dcdcdc', background: cupon.activo ? '#fff' : '#dcdcdc' }}>
                                <div className='table-header row m-p-0 movil-oculto'>
                                    <div className='col s12 m1 row'> <span className='p-5'>ID</span> </div>
                                    <div className='col s12 m4'> Cupon</div>
                                    <div className='col s12 m3'> Tipo</div>
                                    <div className='col s12 m3'> Vigencia</div>
                                    <div className='col s12 m1 row m-p-0'>
                                        <div style={{color:'green'}}
                                        data-tooltip="Editar"
                                        className="col s12 m6 m-0 p-0-2 centrar movil-100 tooltipped" >
                                            {permisos.coupons_update &&
                                            <Link to={`${cupon.id}`} >
                                                <i className="material-icons m-p-0 t-verde" 
                                                style={{color:'green!important', cursor:'pointer',fontSize:'20px',}}>create</i>
                                            </Link>}
                                        </div>
                                        <div
                                        data-tooltip="Borrar"
                                        className="col s12 m6 m-0 p-0-2 centrar movil-100 tooltipped">
                                            {permisos.coupons_update &&
                                            <i className="material-icons m-p-0 t-rojo" 
                                            onClick={(e)=>_delete(cupon.id)}
                                            style={{color:'red!important',cursor:'pointer', fontSize:'20px',}}>delete_forever</i>
                                            }
                                        </div>
                                    </div>                          
                                </div> 
                                <div className='table-container row'>
                                    <div className='col s12 m1 row'>
                                        <span className='p-5'>{cupon.id}</span>
                                        <div className='col s12' 
                                        style={{height:'20px', width:'26px', padding:0, margin:'0!important',}}>
                                        <img className='dentometric m-p-0' src='/images/cupones.png' alt='dento metric' 
                                        style={{height:'100%', width:'100%'}} />
                                        </div>
                                    </div>

                                    <div className='col s12 m4'> {cupon.nombre}</div>
                                    <div className='col s12 m3'> {cupon.tipo}</div>
                                    <div className='col s12 m3'> {cupon.vigencia}</div>
                                    <div className='col s12 m1'> 
                                    {cupon.activo ?  
                                        <div clasname='p-0' style={{position:'relative!important', top:'-55px!important'}}>
                                            <i class="material-icons m-p-0" style={{color:'#11cdef', position:'relative!important',}}>assignment_turned_in</i>
                                            <label className=' m-p-0'  style={{color:'#000',fontSize:10, position:'relative!important', top:'-55px!important'}}> Activo </label> 
                                        </div> :  
                                        <div clasname='p-0' style={{position:'relative!important', top:'-55px!important'}}>
                                            <i className="material-icons m-p-0" style={{color:'#999', position:'relative!important',}}>cancel</i> 
                                            <label className=' m-p-0' style={{color:'#000',fontSize:10, position:'relative!important', top:'-55px!important'}}> Inactivo </label>
                                        </div> 
                                    }
                                    </div>
                                </div>                    
                            </div>
                        )}
                    </>
                    : <NoPermissions/>
                :
                <div className='height-200 centrar'>
                    <Loader/>
                </div>
                }
                </div>
            </div>
        </>
    );
};

export default Cupones;