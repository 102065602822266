import React,{useState} from 'react';
import { Document,Page, View ,Text, Image, StyleSheet} from '@react-pdf/renderer'; 

// https://dev.to/przpiw/react-pdf-rendering-4g7b

const styles = StyleSheet.create({
    page:{
        margin: 5,
        padding: 5
    }, 
    row:{
        width:'100%',
        marginLeft:'auto',
        display: 'flex',
        flexDirection: 'row',
        flexWrap:'wrap',
        padding:5
    },
    col_12:{
        width:'100%',
        marginLeft:'auto',
        padding:5
    },
    col_10:{
        width:'80%',
        marginLeft:'auto',
        paddin:5
    },
    col_6:{
        width:'50%',
        marginLeft:'auto',
        paddin:5
    },
    col_4:{
        width: "33%",
        marginLeft: 'auto',
        paddin:5
    },
    col_3:{
        width:'25%',
        marginLeft:'auto',
        paddin:5
    },
    col_2:{
        width:'20%',
        marginLeft:'auto',
        paddin:5
    },
    divider:{
        height:10,
        borderTop: 0,
        borderBottom: 2, 
        borderLeft: 0, 
        borderRight: 0, 
        borderColor: '#666666', 
        borderStyle: 'solid',
    },
    textBox:{
        minHeight:80,
        border:1,
        borderColor:'#666666',
        borderTopLeftRadius:5,
        borderTopRightRadius:5,
        borderBottomRightRadius:5,
        borderBottomLeftRadius:5
    },
    section_todos: {
        margin: 0,
        padding:0,
        height:'auto',
        display: 'flex',
        flexDirection: 'row',
    },
    section_partes1: {
        margin: 1,
        padding: 5,
        width: '100%',
        borderTop: 0, borderBottom: 1, borderLeft: 0, borderRight: 0, borderColor: '#000000', borderStyle: 'solid',
      },
    section_partes: {
        margin: 1,
        padding: 5,
        width: '100%',
      },
    logo:{
        height:50,
        width:70
    },
    seccion20: {
        width: '20%',
        height:'50px',
        margin: 1,
        padding:1,
        textAlign: 'rigth',
        border: '1px',
        BorderStyle: 'solid'
    },
    seccion25: {
        width: '25%',
        margin: 1,
        padding:1,
    },
    seccion33: {
        width: '33%',
        margin: 1,
        padding:1,
    },
    seccion50: {
        width: '50%',
        height:'50px',
        margin: 1,
        padding:1,
    },
    colum:{
        height:'auto',
        margin: 0,
        padding:1,
    },
    subtext: {
        color: '#666666',
        fontSize:10,
        fontWeight:400,
        lineHeight:1,
        padding:2,
        marginLeft:1,
    },
    label: {
        color: '#000000',
        fontSize:10,
        fontWeight:700,
        lineHeight:1,
        padding:0
    },
  });

const DoctoresReport = ({doctores}) => {
    const today = new Date().toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' });
    const [profile, setProfile] = useState({
        type: 'Profile',
        name: 'DOCTORES',
        fecha: today,
        profileImageURL: '/images/logo.png',
        display: true,
        about: 'About...',
        id: 1,
        fechaAltaEmpleado:'19/09/2006'
    })
    
    //   const handleChange = (name, value) => {
    //     setProfile({ ...profile, [name]: value })
    //   }
    //   const LeftSection = ({ profile }) => {
    //     return (
    //       <View style={styles.section_left}>
    //         <Profile profile={profile} />
    //       </View>
    //     )
    // }
    const HeaderLogo = ({ about }) => {
        return (
            <View style={styles.col_2}>
                <Image style={styles.logo} src={profile.profileImageURL} />
            </View>
        )
      }  
    const Header = ({ about }) => {
    return (
        <View style={styles.col_6}>
            <Text style={styles.label}> Tipo de Reporte </Text>
            <Text style={styles.subtext}>{profile.name}</Text>
        </View>
    )
    }  
    const HeaderDate = ({ about }) => {
    return (
        <View style={styles.col_3}>
            <Text style={[styles.label,{textAlign:'right'}]}> Fecha de reporte </Text>
            <Text style={[styles.subtext,{textAlign:'right'}]}>{profile.fecha}</Text>
        </View>
    )
    }  
    
    const SeccionPersonal = ({ doctor }) => {
        return (
        <>
            <View style={styles.row}>
                <View style={[styles.col_3,{textAlign:'left'}]}>
                    <Text style={styles.label}>ID:<Text style={styles.subtext}>{doctor.id}</Text></Text> 
                </View>
                <View style={[styles.col_3]}>
                    <Text style={styles.label}>Fecha de alta: <Text style={styles.subtext}>{doctor.created_at}</Text></Text>
                </View>
                <View style={styles.col_3}>
                </View>
                <View style={styles.col_3}>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.col_3}>
                    <Text style={styles.label}> Nombre: </Text>
                    <Text style={styles.subtext}>{doctor.nombre}</Text>
                </View>
                <View style={styles.col_3}>
                    <Text style={styles.label}> Compañía:</Text>
                    <Text style={styles.subtext}>{doctor.compania}</Text>
                </View>
                <View style={styles.col_3}>
                    <Text style={styles.label}>Telefono Particular:</Text>
                    <Text style={styles.subtext}>{doctor.telefono}</Text>
                </View>
                <View style={styles.col_3}>
                    <Text style={styles.label}>Celular:</Text>
                    <Text style={styles.subtext}>{doctor.celular}</Text>
                </View>
            </View>
        </>
        )
    }
  
    const SeccionContact = ({ doctor }) => {
        return (
            <View style={styles.row}>
                <View style={styles.col_3}>
                    <Text style={styles.label}> Email: </Text>
                    <Text style={styles.subtext}>{doctor.email}</Text>
                </View>
                <View style={styles.col_3}>
                    <Text style={styles.label}> Pagina Web: </Text>
                    <Text style={styles.subtext}>{doctor.pagina}</Text>
                </View>
                <View style={styles.col_3}>
                    <Text style={styles.label}>RFC:</Text>
                    <Text style={styles.subtext}>{doctor.rfc}</Text>
                </View>
                <View style={styles.col_3}>
                    <Text style={styles.label}>Visitado:</Text>
                    <Text style={styles.subtext}>{doctor.visitar}</Text>
                </View>
        </View>
        )
    }
    const SeccionInstituciones = ({ doctor }) => {
        return (
            <View style={styles.row}>
                <View style={styles.col_3}>
                    <Text style={styles.label}> Especialidades: </Text>
                    {doctor.specialties &&
                        doctor.specialties.map((esp)=>
                            <Text key={esp.id} style={styles.subtext}>{esp.especialidad}.</Text>
                        )
                    }
                    
                </View>

                <View style={styles.col_3}>
                <Text  style={styles.label}>Instituciones</Text>
                    {doctor.institutions &&
                        doctor.institutions.map((ele)=>
                            <Text key={ele.id} style={styles.subtext}>{ele.institucion}.</Text>
                        )
                    }
                </View>
                
                <View style={styles.col_6}>
                    <Text style={styles.label}> Consultorios:</Text>
                    {doctor.doctor_offices &&
                        doctor.doctor_offices.map((ofi)=>
                            <>
                            <Text key={ofi.id} style={styles.subtext}> * {ofi.nombre} - {ofi.direccion}, {ofi.municipio} ,{ofi.estado}</Text>
                            <Text key={ofi.id} style={styles.subtext}>entre {ofi.entre_calles} </Text>
                            </> 
                        )
                    }
                </View>
              
            </View>
        )
    }

    const SeccionObservaciones = ({ doctor }) => {
        return (
            <View style={[styles.col_4,styles.textBox]}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Observaciones </Text>
                    <Text style={styles.subtext}>{doctor.observaciones_importantes}</Text>
                </View>
            </View>
        )
    }
    const SeccionObservacionesGral = ({ doctor }) => {
        return (
            <View style={[styles.col_4,styles.textBox]}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Observaciones Generales</Text>
                    <Text style={styles.subtext}>{doctor.observaciones_generales}</Text>
                </View>
            </View>
        )
    }
    const SeccionObservacionesImp = ({ doctor }) => {
        return (
            <View style={[styles.col_4,styles.textBox]}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Observaciones Generales</Text>
                    <Text style={styles.subtext}>{doctor.observaciones_dental}</Text>
                </View>
            </View>
        )
    }

    

    return (  
    <Document>
        {/* Start of the document*/}
        <Page size={'A4'} orientation={'landscape'} style={styles.page}>
            <View >
                <View style={styles.row}>
                    <HeaderLogo about={profile.about}></HeaderLogo>
                    <Header about={profile.about}></Header>
                    <HeaderDate about={profile.about}></HeaderDate>
                </View>
                {doctores.map((doctor)=>
                    <>
                        <View style={styles.row}>
                            <SeccionPersonal doctor={doctor}></SeccionPersonal>
                            <SeccionContact doctor={doctor}></SeccionContact>
                            <SeccionInstituciones doctor={doctor}></SeccionInstituciones>
                            <View style={styles.row}>
                                <SeccionObservaciones doctor={doctor}></SeccionObservaciones>
                                <SeccionObservacionesGral doctor={doctor}></SeccionObservacionesGral>
                                <SeccionObservacionesImp doctor={doctor}></SeccionObservacionesImp>
                            </View>
                            <View style={styles.col_12}>
                                <View style={styles.divider}></View>
                            </View>
                        </View>
                    </>
                 )}
            </View>
        </Page>
    </Document>
    );
};

export default DoctoresReport;