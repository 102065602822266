import React, { useState, useEffect } from 'react';
import ProductRow from './ProductRow';
import axios from '../../axios';

const DetallePedido = (props) => {
    const {productos,setProductos,total,setTotal,order,setOrder,orderPayment,setOrderPayment} = props;
    const [empleados,setEmpleados] = useState([]);
    const [categorias,setCategorias] = useState([]);
    const [catSelected,setCatSelected] = useState("");
    const [productosSelect,setProductosSelect] = useState([]);
    
    useEffect(()=>{
       _getProductos();
       getEmpleados();
       getCategorias();
    },[])

    useEffect(()=>{
        //calcular total
        let reduce = productos.reduce((acumulador, producto) => acumulador + (parseFloat((producto.precio_unidad*producto.cantidad)-producto.precio_descuento)), 0);
        //aplicamos descuento
       setTotal(reduce.toFixed(2));
    },[productos])

    const _getProductos = () =>{
        axios.get('api/products').then((resp)=>{
            setProductosSelect(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

    const getEmpleados = () =>{
        axios.get('api/employees').then((resp)=>{
            console.log("empleados",resp.data)
            setEmpleados(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

    const getCategorias = ()=>{
        axios.get('api/categories').then((resp)=>{
            console.log("categorias",resp.data)
            setCategorias(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

   const _addProducto = () =>{
        setProductos(productos => [...productos,{id:null,order_id:order.orderId,nombre_producto:"",precio_unidad:0,cantidad:0,descuento:0,precio_descuento:0,dias_entrega:0}] );
   }

   const handleSelectedCat = (cat) =>{
    if(catSelected>0){
        alert("No tienes permisos para cambiar de categoria");
        return;
    }
    setCatSelected(cat)
   }
   const _renderCatName = () => {
        let catName = '';
        catName = categorias.find((cat) => {
            console.log('categoriaSSS', cat);
            if(catSelected == cat.id){
                return cat;
            }
            return null;
        })
        return(
            <div> {catName.nombre_categoria} </div>
        )
   }

    return (
        <div className='row'>
            <div className='col s12 info-badge border-r-10 m-p-0 centrar-h'>
                Detalle del pedido
            </div>
            <div className='row col s12 p-10' id='general-form'>
                <div className='row col s12 m-10-0 p-12'>
                    <div className='col s12 m2 p-10' style={{fontSize:'12px', fontWeight:900}}> Fecha de envio: </div>
                    <div className='col s12 m3 p-10'>
                        <div className="input-text" style={{height:'24!important'}}>
                            <input id="name" type="date" style={{height:'24!important'}}
                            value={order.fecha_envio} onChange={(e)=>setOrder({...order,fecha_envio:e.target.value})}/>
                        </div> 
                    </div>
                    <div className='col s12 m2 p-10' style={{fontSize:'12px', fontWeight:900}}> Categorias de precios </div>
                    <div className='col s12 m3 p-10'>
                        <div className="input-text">
                            <select className='browser-default custom-select' value={catSelected} onChange={(e)=>handleSelectedCat(e.target.value)}>
                                <option value="">Selecciona uno..</option>
                                {categorias.map(cat=>
                                    <option key={cat.id} value={cat.id}>{cat.nombre_categoria}</option>
                                )}
                            </select>
                        </div> 
                    </div> 
                </div>
                {catSelected > 0 ?
                <>
                    <div className='col s12 m3' style={{position:'relative', float:'right'}}>
                        <div className='col s12 m9 p-10 t-right' style={{fontSize:'12px', fontWeight:900}}> Agregar <br></br>Productos </div>
                        <div className='col s6 m3 p-20'>  
                            <button className="btn-floating pulse cyan right" onClick={()=>_addProducto()}>
                                <i className="material-icons">add</i>
                            </button>
                        </div>
                    </div>
                    <div className='row col s12 m-p-0'>
                        <div className='col s1  m-p-0 centrar font12' style={{height:8}}>ID</div>
                        <div className='col s4  m-p-0 centrar font12' style={{height:8}}>Productos</div>
                        <div className='col s2  m-p-0 centrar font12' style={{height:8}}>$ Unitario</div>
                        <div className='col s1  m-p-0 centrar font12' style={{height:8}}>Cantidad</div>
                        <div className='col s1  m-p-0 centrar font12' style={{height:8}}>%</div>
                        <div className='col s1  m-p-0 centrar font12' style={{height:8}}>% al $</div>
                        <div className='col s1  m-p-0 centrar font12' style={{height:8}}>Dias</div>
                        <div className='col s1  m-p-0 centrar font12' style={{height:8}}></div>
                    </div>

                    <div className='row'>
                        {productos.map((producto,index)=>
                            <ProductRow 
                                productosSelect={productosSelect} 
                                producto={producto} 
                                productos={productos} 
                                setProductos={setProductos} 
                                indice={index} 
                                catSelected={catSelected} 
                                orderId={order.id}
                                />
                        )}
                    </div>

                    <div className='row col s12 m-p-0'>
                        <div className='col s12 m12'>
                            <div className='col s12 m6'></div>
                            <div className='col s12 m4'>
                                {
                                    catSelected !== null ? 
                                    <> 
                                        <div className='col s12 negritas t-right' style={{fontSize:'12px', fontWeight:900}}>  Categorias de precios:  </div>                                
                                        <div className='col s12 p-2 t-right' style={{fontWeight:700, color:'#525f7f'}}> {_renderCatName()} </div>
                                    </> 
                                    :
                                    <></>
                                }
                            </div>
                            <div className='col s12 m2'>
                                <div className='col s12 negritas t-right' style={{fontSize:'12px', fontWeight:900}}> Subtotal </div> 
                                <div className='col s12 p-2 t-right' style={{fontWeight:700, color:'#525f7f'}}> {total} </div> 
                            </div>
                        </div>
                    </div>

                    <div className='row p-10'>
                        <div className='divider'></div>
                        <div className='row col s12 m6 m-t-20'>
                            <div className='col s12 m-p-0'>
                                <div className="input-text p-2">
                                    <label htmlFor="atendio-select">Atendio RX</label>
                                    <select className='browser-default custom-select' id="atendio-select" onChange={(e)=>setOrder({...order,employee_entregado_rx_por_id:e.target.value})}>
                                        <option value="">Selecciona uno...</option>
                                        {empleados.map((empleado)=>
                                            <option value={empleado.id}>{`${empleado.nombre} ${empleado.apellidos}`}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className='row'>
                    <div className='col s12 p-20 centrar'>
                        <div className='notification-warning p-10'>Selecciona una categoría para agregar productos</div>                   
                    </div>                         
                </div>
                }
            </div>
                           
        </div>
      
    );
};

export default DetallePedido;