import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from 'axios';

const options ={
    dismissible:false,
    opacity:0.8,
    endingTop:'10%'
}

const DireccionModal = (props) => {
    const [address,setAddress] = useState({
        doctor_id:props.doctorId,
        nombre:'',
        direccion:'',
        horario:'',
        entre_calles:'',
        telefonos:[],
        cp:'',
        pais:'',
        estado:'',
        municipio:'',
        colonia:'',
        country_id:'0',
        state_id:'0',
        municipality_id:'',
        suburb_id:'',
        tipo:'Consultorio Particular'
    });
    const [paises, setPaises] = useState([]);
    const [estados,setEstados] = useState([]);
    const [municipios,setMunicipios] = useState([]);
    const [temporalTel,setTemporalTel] = useState('');
    const [nuevoFlag,setNuevoFlag] = useState(1)
    const [suburbs,setSuburbs] = useState([]);
    
    useEffect(()=>{
        console.log("las props",props);
        setNuevoFlag(props.consultorio?0:1)
        var elems = document.querySelector('#modaladdress');
        M.Modal.init(elems, options);
        if(props.consultorio && props.consultorio.id){
            setAddress(props.consultorio);
            getDirecction(props.consultorio.country_id)
        }
    },[props])

    useEffect(()=>{
        axios.get('/api/countries').then(res=>{
            console.log(res.data)
            setPaises(res.data);
            /*var countryArray = res.data;
            var dataCountry = {};
            for (var i = 0; i < countryArray.length; i++) {
              dataCountry[countryArray[i].description] = null; 
            }
            M.Autocomplete.init(document.querySelectorAll('#autocomplete-input'), {
                data: dataCountry,
                minLength:0, 
                limit:5,
                onAutocomplete:handleCountry
            });*/
        }).catch();
    },[])

    const getDirecction = (paisId) =>{
        console.log("consultando...")
        axios.get(`api/states-by-country/${paisId}`)
        .then(res=>{
            console.log(res.data)
            setEstados(res.data)
        }).catch(e=>console.log(e))
    }

    const _telefonosHandle = (e) =>{
        e.preventDefault()
        console.log(temporalTel)
        if(temporalTel){
            setAddress({...address,telefonos:[...address.telefonos,temporalTel]});
            setTemporalTel('');
        }else{
            alert("agrega un telefono valido")
        } 
    }
    const _removeTelefono = (tel) =>{
        let temporalTels = address.telefonos.filter(t=>t!==tel);
        console.log(temporalTels)
        setAddress({...address,telefonos:temporalTels});
    }
    const closeModalAddress = () =>{
        let instance = M.Modal.getInstance(document.querySelector('#modaladdress'));
        instance.close();
        setAddress({doctor_id:props.doctorId,nombre:'',direccion:'',horario:'',entre_calles:'',telefonos:[],cp:'',pais:'',estado:'',municipio:'',colonia:'',country_id:'',
                    state_id:'',municipality_id:'',suburb_id:'',tipo:'Consultorio Particular'})
        setEstados([])
        setMunicipios([])
        setSuburbs([])
                   
    }
    const _setAddress = () =>{
        console.log("holis",address)
        if(address.id){
            axios.put(`api/doctor_offices/${address.id}`,address).then(res=>{
                console.log(res)
                if(res.status === 200){
                    M.toast({
                        html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Consultorio editado exitosamente</span>',
                        classes:"success-notify"
                    })
                    props.setEditFlag(true)
                    setTimeout(() => {
                        closeModalAddress();
                    }, 3000);
                }
            }).catch(e=>console.log(e))
        }else{
            axios.post(`api/doctor_offices`,address).then(res=>{
                console.log(res)
                if(res.status === 201){
                    M.toast({
                        html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Consultorio creado exitosamente</span>',
                        classes:"success-notify"
                    })
                    props.setEditFlag(true)
                    setTimeout(() => {
                        closeModalAddress();
                    }, 3000);
                }
            }).catch(e=>console.log(e))
        }  
    }

    const handleCountry = (paisId) =>{
        if(paisId > 0){
            let paisTemp = paises.find(p=>p.id==paisId);
            setAddress({...address,country_id:paisId,pais:paisTemp.description});
            axios.get(`api/states-by-country/${paisId}`)
            .then(res=>{
                setEstados(res.data)
            }).catch(e=>console.log(e))
        }else{
            setEstados([])
        }
    }

    const handleState = (estadoId) =>{
        if(estadoId > 0){
            let estadoTemp = estados.find(p=>p.id==estadoId);
            setAddress({...address,state_id:estadoId,estado:estadoTemp.description});
            axios.get(`api/municipalities-by-state/${estadoId}`)
            .then(res=>{
                console.log("res",res)
                setMunicipios(res.data)
            }).catch(e=>console.log(e))
        }else{
            setMunicipios([])
        }
    }

    const handleMunipio = (municipioId) =>{
        if(municipioId > 0){
            let munTemp = municipios.find(m=>m.id==municipioId);
            setAddress({...address,municipality_id:municipioId,municipio:munTemp.description});
            axios.get(`api/suburbs-by-munipality/${municipioId}`)
            .then(res=>{
                console.log("colonias",res.data)
                setSuburbs(res.data)
            }).catch(e=>console.log(e))
        }else{
            setSuburbs([])
        }
    }
    const handleCol = (colId) =>{
        let colTemp = suburbs.find(s=>s.id==colId);
        setAddress({...address,suburb_id:colId,colonia:colTemp.description});
    }

    return (
        <div id="modaladdress" className="modal">
            <div className="modal-content">
            <div className='close-btn' onClick={closeModalAddress}>
                <i className="material-icons">close</i>
            </div>
                <h5>{nuevoFlag? 'Agregar ': 'Editar '} un consultorio</h5>
                <div className='row' id='general-form'>
                    <div className="col s6">
                        {/*<div className='input-field'>
                            <label htmlFor="autocomplete-input">País</label>
                            <input type="text" id="autocomplete-input" class="autocomplete"/>
                        </div>¨*/}
                        <div className='input-text'>
                            <label>País</label>
                            <select className='browser-default' id="pais" value={address.country_id} onChange={(e)=>handleCountry(e.target.value)}>
                                <option value="0" selected>Seleccione el Pais</option>
                                {paises.map((item) =>
                                    <option value={item.id}  key={item.id}>{item.description}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col s6">
                        {estados.length > 0 &&
                            <div className='input-text'>
                                <label>Estados</label>
                                <select className='browser-default' id="estados" value={address.state_id}  onChange={(e)=>handleState(e.target.value)}>
                                    <option value="" disabled selected>Seleccione el Estado</option>
                                    {estados.map((item) =>
                                        <option value={item.id}  key={item.id}>{item.description}</option>
                                    )}
                                </select>
                            </div>
                        }
                    </div> 
                    <div className='col s6'>
                        {municipios.length > 0 &&
                            <div className='input-text'>
                                <label>Municipios</label>
                                <select className='browser-default' id="muni" value={address.municipality_id} onChange={(e)=>handleMunipio(e.target.value)}>
                                    <option value="" disabled selected>Seleccione el Municipio</option>
                                    {municipios.map((item) =>
                                        <option value={item.id}  key={item.id}>{item.description}</option>
                                    )}
                                </select>
                            </div>
                        }
                    </div> 
                    <div className='col s6'>
                        {suburbs.length > 0 &&
                            <div className='input-text'>
                                <label>Colonia</label>
                                <select className='browser-default' id="col" value={address.suburb_id} onChange={(e)=>handleCol(e.target.value)}>
                                    <option value="" disabled selected>Seleccione la colonia</option>
                                    {suburbs.map((item) =>
                                        <option value={item.id}  key={item.id}>{item.description}</option>
                                    )}
                                </select>
                            </div>
                        }
                    </div>
                  
                    <div className="col s10">
                        <div className="input-text ">
                            <label htmlFor="dir">Direccion</label>
                            <input id="dir" type="text" className="validate" value={address.direccion} 
                            onChange={(e)=>setAddress({...address,direccion:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s2">
                        <div className="input-text ">
                            <label htmlFor="name">CP</label>
                            <input id="cp" type="text" className="validate" value={address.cp} 
                            onChange={(e)=>setAddress({...address,cp:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12">
                        <div className="input-text ">
                            <label htmlFor="entre">Entre calles</label>
                            <input id="entre" type="text" className="validate" value={address.entre_calles} 
                            onChange={(e)=>setAddress({...address,entre_calles:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12">
                        <div className="input-text ">
                            <label htmlFor="name">Nombre del consultorio</label>
                            <input id="name" type="text" className="validate" value={address.nombre} 
                            onChange={(e)=>setAddress({...address,nombre:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12">
                        <div className='row'>
                            <div className='col s8'>
                                <div className="input-text">
                                    <label htmlFor="tel">Telefonos</label>
                                    <input id="tel" type="text" className="validate" 
                                        value={temporalTel}
                                        onChange={(e)=>setTemporalTel(e.target.value)}/>                                     
                                </div>
                            </div>
                            <div className='col s4 centrar-l'>
                                <button className='btn-info m-t-25' onClick={(e)=>_telefonosHandle(e)}>Agregar Telefono</button>
                            </div>
                        </div>
                        {address.telefonos.map((tel,index)=>
                            <div className='col' key={index}>
                                <div className='chip centrar-l p-10'>
                                    <span>{tel}</span>  
                                    <i class="material-icons" style={{fontSize:16,cursor:'pointer'}} onClick={(e)=>_removeTelefono(tel)}>close</i>
                                </div>   
                            </div>
                        )}
                    </div>
                    <div className="col s12">
                        <div className="input-text">
                            <label htmlFor="horario">Horarios</label>
                            <textarea id="horario" className="materialize-textarea"
                            value={address.horario}
                            onChange={(e)=>setAddress({...address,horario:e.target.value})}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="waves-effect waves-green btn-flat" onClick={(e)=>_setAddress()}>Guardar</button>
            </div>
        </div>
    );
};

export default DireccionModal;