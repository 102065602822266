import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import axios from '../../axios';
import M from 'materialize-css';
import ServiciosCategoriasModal from './ServiciosCategoriasModal';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import { UserContext } from '../../UserContext';
import Loader from '../../Layout/Loaders/Loader';

const Servicios = () => {
    const [productos, setProductos] = useState([]);
    const [productoSelected,setProductoSelected] = useState({});
    const [updated,setUpdated] = useState(false);
    const [loaded,setLoaded] = useState(false);
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({
        category_product_create:null,
        category_product_update:null,
        category_product_delete:null,
        products_btn_read_category:null
    });

    useEffect(()=>{
        if(user.user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                category_product_create:checkPermiso("category_product_create",user.user.permissions),
                category_product_update:checkPermiso("category_product_update",user.user.permissions),
                category_product_delete:checkPermiso("category_product_delete",user.user.permissions),
                products_btn_read_category:checkPermiso("products_btn_read_category",user.user.permissions)
            }));
        }
    },[user])

    useEffect(()=>{
        (permisos.category_product__create !==null) && setLoaded(true);
    },[permisos])


    useEffect(() => {   
        console.log("El updated",updated)     
        axios.get('api/products')
        .then( res=>{
            console.log('todos los producto',res.data)
            setProductos(res.data);
            setUpdated(false)
        }).catch(e =>{console.log(e, 'Error todos los producto')})
    },[updated])

    const _delete = (productoId) =>{
        if(window.confirm("Realmente deseas borrar a esta producto, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/products/${productoId}`)
            .then(res=>{
                M.toast({html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setProductos(productos.filter(u=>u.id!==res.data.producto.id))
            }).catch(e=>console.log("error",e))
        }
    }

    const _handleModal = (product) =>{
        console.log("seleccionado",product)
        setProductoSelected(product);
        let instance = M.Modal.getInstance(document.querySelector('#servicios-categorias-modal'));
        instance.open();
    }

    const _handleChecked = (e,servicio) =>{
        let value = e.target.checked;
        let servicioTemporal = servicio;
        servicioTemporal.activo = value?1:0; 
        _updateActive(servicioTemporal)
    }

    const _updateActive = (producto) =>{
    
        axios.put(`api/products/${producto.id}`,producto)
        .then(resp=>{
            setProductos(productos.map((item)=>{
                if(item.id===resp.data.id)  return resp.data;   
                return item;
            }));
            M.toast({html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Servicio actualizado exitosamente</span>',classes:"success-notify"})
        }).catch(e=>{
            console.log("error",e)
            if(e.message) M.toast({html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+e.message+'</span>',classes:"error-notify"})
        })
    }

    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb hasButton={true} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                {loaded ?
                permisos.category_product_create ?
                    <>
                    <ServiciosCategoriasModal 
                        productoSelected={productoSelected}
                        setProductoSelected={setProductoSelected}
                        setUpdated={setUpdated}
                    />
                    <div className='table'>
                        <div className='table-header row movil-oculto'>
                            <div className='col s2 m1'> ID </div>
                            <div className='col s10 m4'> Servicio/Producto </div>
                            <div className='col s10 m2'> Categorias </div>
                            <div className='col s12 m1'> Precio Base</div>
                            <div className='col s12 m2 centrado' style={{lineHeight:1}}> Duracion
                                <span style={{fontSize:'10px'}}> <br/>(Mins)</span></div>
                            <div className='col s12 m1 t-right'> Activo &nbsp; &nbsp; </div>
                            <div className='col s12 m1 t-right'> Acciones </div>
                        </div>  
                        {productos.map( (producto)=>
                        <div className='table-container row' key={producto.id}>
                            <div className='col s8 m1 movil-oculto'>
                                <div className='cont-textos'>{producto.id}</div> 
                            </div>
                            <div className='col s12 movil-visible'>
                                <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                    <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>id</div> </div>
                                    <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                        <div className='cont-textos row'>
                                            <div style={{color:'green'}}
                                            className="col s6 m6 push-m3 m-p-0 centrar">
                                                <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                            </div>
                                            <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}}>delete_forever</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Servicio </div>
                            </div>
                            <div className='col s8 m4'> <div className='cont-textos'>{producto.nombre_producto}</div></div>
                            <div className='col s4 movil-visible' >
                                <div className='table-header-movil'>Nombre </div>
                            </div>
                            <div className='col s8 m2'> 
                            {permisos.products_btn_read_category &&
                                <div className='cont-textos'><button className='btn-info' onClick={(e)=>_handleModal(producto)}>Ver Categorias</button></div>
                            }
                            </div>
                            <div className='col s8 m1 m-p-0'> <div className='cont-textos' style={{padding:'0!important'}}> 
                                $ <span className='t-right' style={{width:'94%'}}> {producto.precio_unidad} </span></div> </div>
                            <div className='col s4 m4 movil-visible'>
                                <div className='table-header-movil'>.</div>
                            </div>
                            <div className='col s8 m2'> <div className='centrar cont-textos'>{producto.duracion_aproximada} min</div> </div>
                            <div className='col s4 m4 movil-visible'>
                                <div className='table-header-movil'>a </div>
                            </div>
                            <div className='col s4 m1 movil-visible'>
                                <div className='table-header-movil'>b1 </div>
                            </div>
                            <div className='col s8 m1'> 
                                <div className='cont-textos centrar'>
                                    {permisos.products_btn_read_category &&
                                    <div className="switch input-text">
                                        <label className='centrado'>
                                        <input id="activo" type="checkbox" checked={producto.activo} onChange={(e)=>_handleChecked(e,producto)}/>
                                        <span className="lever"></span>
                                        </label>
                                    </div>
                                    }
                                </div> 
                            </div>
                            <div className='col s12 m1 movil-oculto'>
                                <div className='row cont-textos'>
                                    <Link to={`${producto.id}`} style={{color:'green'}}
                                    data-tooltip="Editar"
                                    className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                        <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                    </Link>
                                    <div className='col m6 m-p-0 centrar movil-100'>
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} 
                                        onClick={(e)=>_delete(producto.id)}>delete_forever</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    </>
                    : <NoPermissions />
                :
                <div className='height-200 centrar'>
                    <Loader/>
                </div>
                }
                </div>
            </div>
        </>
    );
};

export default Servicios;