import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import Pagination from '../../../Layout/Pagination/Pagination';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import Spinner from '../../../Layout/Loaders/Spinner';

const Instituciones = () => {
    const [instituciones,setInstituciones] = useState([])
    //estados para componente de paginacion
    const [itemsPerPage,setItemsPerPage] = useState(10)
    const [skip, setSkip] = useState(0)
    const [currentPage,setCurrentPage] = useState(1)
    const user = useContext(UserContext);
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        doctor_institutions_create:null,
        doctor_institutions_read:null,
        doctor_institutions_update:null,
        doctor_institutions_delete:null
    });

    useEffect(()=>{
        if(user.user.id){
            axios.get('api/institutions')
            .then((res)=>{
                console.log(res)
                setInstituciones(res.data)
            }).catch(e=>console.log(e))
            
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                doctor_institutions_create:checkPermiso("doctor_institutions_create",user.user.permissions),
                doctor_institutions_read:checkPermiso("doctor_institutions_read",user.user.permissions),
                doctor_institutions_update:checkPermiso("doctor_institutions_update",user.user.permissions),
                doctor_institutions_delete:checkPermiso("doctor_institutions_delete",user.user.permissions)
            }));
        }
    },[user])

    useEffect(()=>{
        (permisos.doctor_institutions_read !==null) && setLoaded(true);
    },[permisos])

    

    const _delete = (id) =>{
        if(window.confirm("Realmente deseas borrar a esta especialidad, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/institutions/${id}`)
            .then((res)=>{
                console.log(res)
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setInstituciones(instituciones.filter(u=>u.id!==res.data.institucion.id))
            }).catch(e=>console.log(e))
        }
    }
    //FUNCION PARA PAGINADOR
    const paginate = (paginateNumber)=>{
        setCurrentPage(paginateNumber)
    }

    //current Items Paginador
    const indexLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexLastItem - itemsPerPage;
    const currentItems = instituciones.slice(indexOfFirstItem,indexLastItem)

    return (
        <>
        <div className='container-admin-menu'> 
            <Breadcrumb hasButton={permisos.doctor_institutions_create} hasFilters={false} />
        </div> 
        <div className='container-admin-container'>
       
            <div className='container-admin z-depth-1' >
             {loaded ?
                permisos.doctor_institutions_read ?    
                <>
                    <div className='table'>
                        <div className="table-item-counter">Total Instituciones: {instituciones.length}</div>
                        <div className='table-header row movil-oculto'>
                            <div className='col m1'>ID</div>
                            <div className='col m9'>Institucion </div>
                            <div className='col m2 t-right'>Acciones</div>
                        </div>
                        {currentItems.map(institucion=>
                        <div className='table-container row' key={institucion.id}>
                            <div className='col s8 m1 movil-oculto'>
                                <div className='cont-textos'>{institucion.id}</div>
                            </div>
                            <div className='col s12 movil-visible'>
                                <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                    <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>{institucion.id}</div> </div>
                                    <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                        <div className='cont-textos row'>
                                            {permisos.doctor_institutions_update&&
                                            <Link to={`${institucion.id}`} style={{color:'green'}}
                                            className="col s6 m6 push-m3 m-p-0 centrar">
                                                <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                            </Link>
                                            } 
                                            {permisos.doctor_institutions_delete&&
                                            <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(institucion.id)}>delete_forever</i>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        
                                <div className='table-header-movil'>Institucion </div>
                            </div>
                            <div className='col s12 m9'>
                                <div className='cont-textos t-cut'>{institucion.institucion}</div>
                            </div>

                            <div className='col s12 m2  movil-oculto'>
                                <div className='cont-textos row'>
                                    {permisos.doctor_institutions_update&&
                                    <Link to={`${institucion.id}`}
                                    className="col s6 m6 push-m3 m-p-0 centrar">
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',}}>create</i>
                                    </Link>
                                    }
                                    {permisos.doctor_institutions_delete&&
                                    <div className='col s6  m6 push-m3 m-p-0 centrar'>
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(institucion.id)}>delete_forever</i>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    <Pagination 
                        currentPage={currentPage}
                        skip={skip}
                        totalItems={instituciones.length}  
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}   
                        paginate={paginate}
                    />
                 </>
                :
                <NoPermissions></NoPermissions>
            :
            <div className='height-200 centrar'>
                <Spinner size={"medium"} />
            </div>   
            }
            </div>
       
        </div> 
    </>
    );
};

export default Instituciones;