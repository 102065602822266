import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import M from 'materialize-css';

const EmpleadoBaja = (props) => {
    const {empleado, setEmpleadoToDelete,_getAllEmployees} = props;
    const [bajaEmpleado,setBajaEmpleado] = useState({employee_id:null,fecha_baja:"",motivo_baja:"",comentarios:""});

    useEffect(()=>{
        console.log(empleado)
        if(empleado.id){
            setBajaEmpleado({...bajaEmpleado,employee_id:empleado.id});
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[empleado])

    const _darBajaEmpleado = () =>{
        console.log("borando...",bajaEmpleado)
        let elem = document.querySelector('#baja-empleado-modal');
        let instance = M.Modal.getInstance(elem);
       
        if(window.confirm("Realmente deseas dar de baja a este empleado?")) {
            if(empleado.id){
                axios.post('api/baja_employee',bajaEmpleado)
                .then(resp=>{
                    console.log(resp)
                    if(resp.status === 200){
                        M.toast({
                            html: `<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>${resp.data.message}</span>`,
                            classes:"success-notify"
                        });
                        _getAllEmployees();
                        setTimeout(() => {
                           setEmpleadoToDelete({id:null,nombre:""})
                           instance.close();
                        }, 3000);
                    }else{
                        M.toast({
                            html: `<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>${resp.data.message}</span>`,
                            classes:"error-notify"
                        });
                    }
                }).catch(e=>console.log(e));  
            }
        }
    }

    return (
        <div id="baja-empleado-modal" className="modal">
            <div className="modal-content">
                <div className='close-btn modal-close'>
                    <i className="material-icons">close</i>
                </div>
                <h6>Baja de empleado</h6>
                <div className='row' id='general-form'>
                    <div className='col s12 m-10 input-text'> 
                        <label>Nombre del empleado: <strong>{empleado.nombre}</strong></label>
                    </div>
                    <div className="col s12">
                        <div className="input-text">
                            <label htmlFor="fecha">Fecha de Baja</label>
                            <input id="fecha" type="date" value={bajaEmpleado.fecha_baja} onChange={(e)=>setBajaEmpleado({...bajaEmpleado,fecha_baja:e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 m6">
                        <div className="input-text">
                            <label htmlFor="motivo">Motivo de la Baja</label>
                            <textarea id="motivo" className="materialize-textarea validate" value={bajaEmpleado.motivo_baja}
                            onChange={(e)=>setBajaEmpleado({...bajaEmpleado,motivo_baja:e.target.value})}
                            ></textarea>
                        </div>
                    </div>
                    <div className="col s12 m6">
                        <div className="input-text">
                            <label htmlFor="comentarios">Comentarios</label>
                            <textarea id="comentarios" className="materialize-textarea validate" value={bajaEmpleado.comentarios}
                            onChange={(e)=>setBajaEmpleado({...bajaEmpleado,comentarios:e.target.value})}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn-delete" onClick={(e)=>_darBajaEmpleado()}>CONFIRMAR BAJA</button>
            </div>
        </div> 
    );
};

export default EmpleadoBaja;