import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';

const Detalle = () => {
    let {proveedorId} = useParams();
    const [proveedor,setProveedores] = useState({id:0});
    useEffect (() =>{
        axios.get(`api/suppliers/${proveedorId}`)
        .then(res=>{
            console.log(res.data, "editar proveedor");
            setProveedores(res.data);
        }).catch(e =>{console.log(e, 'Error ')})
    },[])


    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb actual={proveedor.nombre_proveedor}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5 style={{padding:'10px'}}>
                        <div style={{paddingBottom:'15px'}}>Detalle de el proveedor </div>
                        <span style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="name">
                            Nombre de Constacto &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <span style={{textDecoration:'underline',color:'#0033ff'}}>{proveedor.nombre_contacto}</span>
                    </h5>
                    <div className="row ancho100 m-p-0">                     
                        <div className="col s12 m3">
                                <label style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="name">Nombre de la compañia</label>
                                <div style={{border:'solid #dee2e6 1px', borderRadius:'4px', padding:'5px'}}>{proveedor.nombre_compania}</div>
                        </div>
                        <div className="col s12 m9 row ancho100">                     
                            {!proveedor.observaciones
                                ?""
                                :<div className="col s12 m12">
                                        <label style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="observaciones">Observaciones</label>
                                        <div style={{border:'solid #dee2e6 1px', borderRadius:'4px', padding:'5px'}}>{proveedor.observaciones}</div>
                                </div>
                            }
                            {!proveedor.pagia_web
                                ?""
                                :<div className="col s12 m6">
                                        <label style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="web">Página WEB</label>
                                        <div style={{border:'solid #dee2e6 1px', borderRadius:'4px', padding:'5px'}}>{proveedor.pagia_web}</div>
                                </div>
                            }
                            {!proveedor.rfc
                                ?""
                                :<div className="col s12 m6">
                                        <label style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="rfc">RFC</label>
                                        <div style={{border:'solid #dee2e6 1px', borderRadius:'4px', padding:'5px'}}>{proveedor.rfc}</div>
                                </div>
                            }
                            {!proveedor.telefono 
                                ?""
                                :
                                <div className="col s12 m4">
                                        <label style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="tel1">Telefono</label>
                                        <div style={{border:'solid #dee2e6 1px', borderRadius:'4px', padding:'5px'}}>{proveedor.telefono}</div>
                                </div>
                            }
                            {!proveedor.telefono_celular
                                ?""
                                :<div className="col s12 m4">
                                        <label style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="tel2">Celular</label>
                                        <div style={{border:'solid #dee2e6 1px', borderRadius:'4px', padding:'5px'}}>{proveedor.telefono_celular}</div>
                                </div>
                            }
                            {!proveedor.telefono_particular
                                ?""
                                :<div className="col s12 m4">
                                        <label style={{fontSize:'10px',fontWeight:'700',color:'#525f7f',textTransform:'uppercase',}} htmlFor="tel3">Tel. Particular</label>
                                        <div style={{border:'solid #dee2e6 1px', borderRadius:'4px', padding:'5px'}}>{proveedor.telefono_particular}</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detalle;