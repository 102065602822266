import React, { useContext, useEffect, useState } from 'react';
import axios from '../../axios';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import Loader from '../../Layout/Loaders/Loader';
import NoPermissions from '../NoPermissions/NoPermissions';
const defaultImg = "/images/doc.png";

const Detail = () => {
    const {user} = useContext(UserContext);
    const  {visitaId} = useParams();
    const [visita,setVisita] = useState();
    const [loader,setLoader] = useState(true);
    const [permisos,setPermisos] = useState({
        visits_read:null, 
    })


    useEffect(()=>{
        if(user.id){
           setPermisos((prevPremisos) => ({
               ...prevPremisos,
               visits_read:checkPermiso("visits_read",user.permissions),
           }));
        }
    },[user]);

     useEffect(()=>{
        if(permisos.visits_read !== null){
            if(visitaId > 0){
                axios.get(`api/visits/${visitaId}`).then((resp)=>{
                    console.log("visita",resp.data)
                    setVisita(resp.data);
                    setLoader(false);
                }).catch(e=>console.error(e));
            }
        }
    },[permisos,visitaId]);

    const renderStatus = (fecha) =>{
        let fechaVisita = new Date(fecha).getTime();
        let today = new Date().getTime();
        let status, notify = '';

        if(today < fechaVisita) {
           status = 'Pendiente';
           notify = 'badge-error ';
        }else{
            status = 'Visitado';
            notify = 'badge-success ';
        }

        return(
            <div className={` badge ${notify} centrado font16`}>
                {status}
            </div>
        );
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                    {loader ?
                        <Loader></Loader>
                        :
                        permisos.visits_read ? 
                            <>
                                <div className='col s12 info-badge border-r-10 '>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m10'>
                                            <span className='p-5 font18'>Visitar a doct@r: {visita.doctor&&visita.doctor.nombre+" "+visita.doctor.apellidos}</span>
                                        </div>
                                        <div className='col s12 m2 p-10'>
                                            {renderStatus(visita.fecha_visita)}
                                        </div>
                                    </div>
                                </div>

                                <div className='col m12 valign-wrapper grey lighten-5' style={{padding:5}}>
                                    <div className='valign-wrapper' style={{height:150,position:'relative'}}>
                                        <img src={defaultImg} alt={visita.doctor.nombre} className="responsive-img" style={{height:140,borderRadius:10}}/>
                                    </div>
                                    <div>
                                        <p className='m-5'>ID: {visita.doctor.id}</p>
                                        <p className='m-5'><label>Fecha de creación: {new Date(visita.doctor.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</label></p>
                                        <p className='m-5'><label>Compañia: {visita.doctor.compania}</label></p>
                                    </div>
                                  
                                </div>

                                <div className='col m12 grey lighten-5' style={{padding:5}}>
                                    <div className='col s12 m6 m-t-20'>
                                        <label>Causa de la Visita</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>explore</i>
                                            <span>{visita.causa_visita}</span>  
                                        </div>
                                    </div>

                                    <div className='col s12 m4 m-t-20'>
                                        <label>Fecha de la visita</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>event_available</i>
                                            <span>{new Date(visita.fecha_visita).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</span>  
                                        </div>
                                    </div>

                                    <div className='col s12 m2 m-t-20'>
                                        <label>Hora de la visita</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>watch_later</i>
                                            <span>{visita.hora}</span>  
                                        </div>
                                    </div>

                                    <div className='col s12 m6 m-t-20'>
                                        <label>Direccion</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>add_location</i>
                                            <span>{visita.direccion}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m6 m-t-20'>
                                        <label>Representante</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>face</i>
                                            <span>{visita.representante}</span>  
                                        </div>
                                    </div>

                                    <div className='col s12 m-t-20'>
                                        <label>Observaciones</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>assignment</i>
                                            <span>{visita.representante}</span>  
                                        </div>
                                    </div>
                                </div>
                            </>
                        :
                        <NoPermissions />
                }
                  
                </div>
            </div>
        </div>
    );
};

export default Detail;