import React, { useContext, useEffect, useState } from 'react';
import M from 'materialize-css';
import CategoriaPrecioForm from './Categorias/CategoriaPrecioForm';
import axios from '../../axios';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import { UserContext } from '../../UserContext';
import Loader from '../../Layout/Loaders/Loader';

const options ={
    opacity:0.8,
    endingTop:'10%',
    dismissible:false
}

const ServiciosCategoriasModal = (prods) => {  
    const {productoSelected,setProductoSelected,setUpdated} = prods;
    const [categorias,setCategorias] = useState([]);
   // const [servicioCats,setServicioCats] = useState([]);
    const [catIds,setCatIds] = useState([]);
    const [selectCategorias,setSelectCategorias] = useState(null);
    const [categories,setCategories] = useState([]);
  
    const [loaded,setLoaded] = useState(false);
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({
        products_create:null,
        products_read:null,
        products_update:null
    });
   
    useEffect(()=>{
        if(user.user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                products_create:checkPermiso("products_create",user.user.permissions),
                products_update:checkPermiso("products_update",user.user.permissions),
                products_read:checkPermiso("products_read",user.user.permissions),
            }));
        }
    },[user])

    useEffect(()=>{
        (permisos.products_read !==null) && setLoaded(true);
    },[permisos])

    useEffect(()=>{
        if(productoSelected.id){    
            console.log("producto montado",productoSelected);
            let tempcats = productoSelected.categories.map(p=>p.id);
            setCatIds(tempcats);
            setCategories(productoSelected.categories);
            var elem = document.querySelector('.collapsible.popout');
            M.Collapsible.init(elem, {accordion: true});
        }

        axios.get('api/categories/active/1').then((resp)=>{
            setCategorias(resp.data);
            setSelectCategorias(M.FormSelect.init(document.querySelector('#categorias')));
        }).catch(e=>console.log("Error: ",e))

    },[productoSelected])
    
    const _closeModal = () => {   
        console.log("xlosed modal..")
        setCatIds([]);
        setProductoSelected({})
        let instance = M.Modal.getInstance(document.querySelector('#servicios-categorias-modal'));
        instance.close();
    }

    const setCatHandler = () =>{
        setCatIds(selectCategorias.getSelectedValues());
        console.log(selectCategorias)
    }

    const saveCatsIds = () =>{
        let catIds = selectCategorias.getSelectedValues();
        console.log(catIds);
        axios.post(`api/set-product-categories/${productoSelected.id}`,{catIds:catIds}).then((resp)=>{
            console.log(resp)
            if(resp.status === 200){
                setCategories(resp.data.servicio.categories);
                setUpdated(true);
                M.toast({
                    html:`<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>${resp.data.message}</span>`,
                    classes:"success-notify"
                })
            }
          
        }).catch(e=>console.log("Error: ",e))
     
    }

    return ( 
        <div id="servicios-categorias-modal" className="modal border-r-6" style={{height:'90vh',}}>
        {loaded ?
            permisos.products_read ?
            <>
                <div className="modal-content m-0 p-10">
                    
                    <div className='close-btn' onClick={_closeModal}>
                        <i className="material-icons">close</i>
                    </div>
                    <div className='row m-p-0' id='general-form'>
                        <div className="col s12 m12">
                            <h6>{productoSelected.nombre_producto} - categorias y precios</h6>
                        </div>
                        <div className='col s12 m12 row m-p-0 centrar'>
                            <div className="col s12 m8">
                                <div className='input-text' id='modal-ca'>
                                    <label>Categorias</label>
                                    <select className='p-0t' id="categorias" value={catIds} multiple  onChange={(e)=>{setCatHandler()}}>
                                        <option value="" disabled>Seleccione las Categorias</option>
                                        {categorias.map((item) =>
                                            <option value={item.id}  key={item.id}
                                            >{item.nombre_categoria}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='col s12 m4 centrar'><label></label>
                                <button className='btn-info' onClick={saveCatsIds}>Guardar Categorias</button>
                            </div>
                        </div>
                        {categories &&
                            <div className='row col s12'>
                                <blockquote className='m-0 p-0-10'>
                                    <h6> Listado de precios por categoria</h6>
                                </blockquote>
                                <ul className="collapsible popout p-10" style={{border:'solid blue', maxHeight:'200px', overflowY:'scroll'}}>
                                    {categories.map((cat)=>
                                       <li key={cat.id}>
                                            <div className="collapsible-header p-5"><i className="material-icons">attach_money</i>{cat.nombre_categoria}</div>
                                            <div className="collapsible-body p-0">
                                                <CategoriaPrecioForm categoria={cat} setProductoSelected={setProductoSelected} setUpdated={setUpdated}></CategoriaPrecioForm>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        }
                       
                    </div>
                </div>

                <div className="modal-footer m-p-0">
                    <div className='col s12'>
                        <div className="btn waves-effect waves-light" onClick={_closeModal}>Cerrar</div>
                    </div>
                </div>
            </>
                : <NoPermissions />
            :
            <div className='height-200 centrar'>
                <Loader/>
            </div>
            }
        </div>
    );
};

export default ServiciosCategoriasModal;