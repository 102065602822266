import React,{ useState,useEffect } from 'react';
import axios from '../../axios';
import M from 'materialize-css';

const EmpleadoAddress = (props) => {
    const {empleadoId, empleadoAddress, setEmpleadoAddress, setStep,permisos} = props;
    const [paises, setPaises] = useState([]);
    const [estados,setEstados] = useState([]);
    const [municipios,setMunicipios] = useState([]);
    const [suburbs,setSuburbs] = useState([]);
    const [validate,setValidate] = useState({direccion:true, cp:true, entre_calles:true,pais:true,estado:true,municipio:true,colonia:true});

    useEffect(()=>{
        console.log("empleadoID",empleadoId);
        if(empleadoId){
            setEmpleadoAddress({...empleadoAddress,employee_id:empleadoId});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[empleadoId]);

    useEffect(()=>{
        getPaises();
    },[]);

    useEffect(()=>{
        if(empleadoAddress.id ){ 
            getEstados(empleadoAddress.country_id); 
            getMunicipios(empleadoAddress.state_id);
            getColonias(empleadoAddress.municipality_id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[empleadoAddress.id])

    const getPaises = () =>{
        axios.get('/api/countries').then(res=>{
            setPaises(res.data);
        }).catch();
    }

    const getEstados = (paisId) =>{
        axios.get(`api/states-by-country/${paisId}`)
        .then(res=>{
            setEstados(res.data)
        }).catch(e=>console.log(e))
    }
    
    const getMunicipios = (estadoId) =>{
        axios.get(`api/municipalities-by-state/${estadoId}`)
            .then(res=>{
                setMunicipios(res.data)
            }).catch(e=>console.log(e))
    }

    const getColonias = (municipioId) =>{
        axios.get(`api/suburbs-by-munipality/${municipioId}`)
            .then(res=>{
                console.log("colonias",res.data)
                setSuburbs(res.data)
            }).catch(e=>console.log(e))
    }

    const handleCountry = (paisId) =>{
        if(paisId > 0){
            let paisTemp = paises.find(p=>p.id==paisId);
            setEmpleadoAddress({...empleadoAddress,country_id:paisId,pais:paisTemp.description});
            axios.get(`api/states-by-country/${paisId}`)
            .then(res=>{
                setEstados(res.data)
            }).catch(e=>console.log(e))
        }else{
            setEstados([])
        }
    }

    const handleState = (estadoId) =>{
        if(estadoId > 0){
            let estadoTemp = estados.find(p=>p.id==estadoId);
            setEmpleadoAddress({...empleadoAddress,state_id:estadoId,estado:estadoTemp.description});
            axios.get(`api/municipalities-by-state/${estadoId}`)
            .then(res=>{
                console.log("res",res)
                setMunicipios(res.data)
            }).catch(e=>console.log(e))
        }else{
            setMunicipios([])
        }
    }

    const handleMunipio = (municipioId) =>{
        if(municipioId > 0){
            let munTemp = municipios.find(m=>m.id==municipioId);
            setEmpleadoAddress({...empleadoAddress,municipality_id:municipioId,municipio:munTemp.description});
            axios.get(`api/suburbs-by-munipality/${municipioId}`)
            .then(res=>{
                console.log("colonias",res.data)
                setSuburbs(res.data)
            }).catch(e=>console.log(e))
        }else{
            setSuburbs([])
        }
    }
    const handleCol = (colId) =>{
        let colTemp = suburbs.find(s=>s.id==colId);
        setEmpleadoAddress({...empleadoAddress,suburb_id:colId,colonia:colTemp.description});
    }

    const _updateAddress = () =>{
        console.log(empleadoAddress)
        let url = '';
        let method = '';
        let accion = '';

        if(empleadoAddress.id > 0){
            url = `api/employees_adress/${empleadoAddress.id}`;
            method = 'put';
            accion = 'Editar';
        }else{
            url = 'api/employees_adress';
            method = 'post';
            accion = 'Guardar';
        }

        if(!permisos.employee_addresses_create && accion === 'Guardar'){
            M.toast({
                html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>No tienes permisos para ${accion} la direccion.</span>`,
                classes:"error-notify"
            })
            setStep(4);
            return null;
            
        }

        if(!permisos.employee_addresses_update && accion === 'Editar'){
            
            M.toast({
                html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>No tienes permisos para ${accion} al direccion.</span>`,
                classes:"error-notify"
            })
            return null;
        }


        axios({
            method:method,
            url:url,
            data:empleadoAddress
        }).then((response)=>{
            console.log("new domicilio",response.data);
            setEmpleadoAddress(response.data);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Datos Guardados Exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                if(response.status === 201) setStep(4);
            }, 3000);
        }).catch(e=>{
            console.log(e);
            if(e.response?.data?.errors){
                let errors = e.response.data.errors;
                setValidate({
                    direccion:(errors.direccion)? false:true, 
                    cp:(errors.cp)? false:true, 
                    entre_calles:(errors.entre_calles)? false:true,
                    pais:(errors.pais)? false:true,
                    estado:(errors.estado)? false:true,
                    municipio:(errors.municipio)? false:true,
                    colonia:(errors.colonia)? false:true
                })
            }
        });
    }

    return (
        <div className='p-20'>
            <h6>{empleadoId? ' Agregar ': ' Editar '} Domicilio</h6>
            <div className='row' id='general-form'>
                <div className="col s10">
                    <div className="input-text ">
                        <label htmlFor="dir">Direccion *</label>
                        <input id="dir" type="text" className="validate" placeholder="Numero y calle" value={empleadoAddress.direccion} 
                        onChange={(e)=>setEmpleadoAddress({...empleadoAddress,direccion:e.target.value})}/>
                         {!validate.direccion &&  <span className="helper-text t-rojo">La Dirección del empleado es obligatoria</span>} {!validate.direccion &&  <span className="helper-text t-rojo">La ddireccion del empleado es obligatoria</span>}
                    </div>
                   
                </div>
                <div className="col s2">
                    <div className="input-text ">
                        <label htmlFor="name">CP *</label>
                        <input id="cp" type="text" className="validate" value={empleadoAddress.cp} 
                        onChange={(e)=>setEmpleadoAddress({...empleadoAddress,cp:e.target.value})}/>
                         {!validate.cp &&  <span className="helper-text t-rojo">El CP del empleado es obligatorio</span>}
                    </div>
                   
                </div>
                <div className="col s6">
                    <div className="input-text ">
                        <label htmlFor="entre">Entre calles *</label>
                        <input id="entre" type="text" className="validate" value={empleadoAddress.entre_calles} 
                        onChange={(e)=>setEmpleadoAddress({...empleadoAddress,entre_calles:e.target.value})}/>
                    </div>
                </div>
                <div className="col s6">
                    <div className="input-text ">
                        <label htmlFor="entre">Telefono</label>
                        <input id="tel" type="text" className="validate" value={empleadoAddress.telefono} 
                        onChange={(e)=>setEmpleadoAddress({...empleadoAddress,telefono:e.target.value})}/>
                    </div>
                </div>
                <div className="col s6 m4">
                    <div className='input-text'>
                        <label>País *</label>
                        <select className='browser-default' id="pais" value={empleadoAddress.country_id} onChange={(e)=>handleCountry(e.target.value)}>
                            <option value="0" selected>Seleccione el Pais</option>
                            {paises.map((item) =>
                                <option value={item.id}  key={item.id}>{item.description}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="col s12 m4">
                {estados.length > 0 &&
                    <div className='input-text'>
                        <label>Estados *</label>
                        <select className='browser-default' id="estados" value={empleadoAddress.state_id}  onChange={(e)=>handleState(e.target.value)}>
                            <option value="" disabled selected>Seleccione el Estado</option>
                            {estados.map((item) =>
                                <option value={item.id}  key={item.id}>{item.description}</option>
                            )}
                        </select>
                    </div>
                }
                </div> 
                <div className='col s12 m4'>
                {municipios.length > 0 &&
                    <div className='input-text'>
                        <label>Municipios *</label>
                        <select className='browser-default' id="muni" value={empleadoAddress.municipality_id} onChange={(e)=>handleMunipio(e.target.value)}>
                            <option value="" disabled selected>Seleccione el Municipio</option>
                            {municipios.map((item) =>
                                <option value={item.id}  key={item.id}>{item.description}</option>
                            )}
                        </select>
                    </div>
                }
                </div> 
                <div className='col s12 m4'>
                    {suburbs.length > 0 &&
                        <div className='input-text'>
                            <label>Colonia *</label>
                            <select className='browser-default' id="col" value={empleadoAddress.suburb_id} onChange={(e)=>handleCol(e.target.value)}>
                                <option value="" disabled selected>Seleccione la colonia</option>
                                {suburbs.map((item) =>
                                    <option value={item.id}  key={item.id}>{item.description}</option>
                                )}
                            </select>
                        </div>
                    }
                </div>
                <div className="col s12">
                    <button className="btn waves-effect waves-light right" onClick={(e)=>_updateAddress()}>{empleadoAddress.id?'Actualizar':'Guardar'}</button>
                </div>
            </div>           
        </div>
    );
};

export default EmpleadoAddress;