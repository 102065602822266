import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';
import axios from '../../axios';

const DoctorTable = (props) => {
    const {doctores,permisos} = props;
    const _delete = (doctorId) =>{
        if(window.confirm("Realmente deseas borrar a este doctor, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/doctors/${doctorId}`)
            .then(res=>{
                
            }).catch(e=>console.log("error",e))
        }
    }

    const _specialtyRender = (especialidades) =>{
        console.log(especialidades)
       
        return(
            <>
            {especialidades.map((especialidad)=>
                <div>{especialidad.especialidad}</div>
            )}   
            </>
        );
        
    }

    return (
        <div className='m-p-0'>
            
            <div className='m-p-0'>
                <div className='m-p-0'>
                    <div className='col table table-doc m-p-0' style={{width:'100%'}}>
                        <div className='table-header row m-p-0 movil-oculto'>
                            <div className='col s10 m1'> ID </div>
                            <div className='col s12 m1'> Nombre </div>
                            <div className='col s12 m3'> Correo </div>
                            <div className='col s12 m1'> Telefono</div>
                            <div className='col s12 m1'> Celular</div>
                            {/** <div className='col s12 m1'> Especialidad</div> */}
                            <div className='col s12 m2'> Consultorio </div>
                            <div className='col s12 m2'> Institucion </div>
                            <div className='col s12 m1'> Acciones</div>
                        </div>
                        
                        <div className='table-container' style={{fontSize:'12px'}}>
                        {doctores.map((doctor)=>    
                            <div className='row'>
                                <div className='col s12 m1'>
                                    <div className='row centrado cont-textos'>
                                        <div className='col s3 m4 movil-visible'>
                                            <div className='table-header-movil'> Id </div>
                                        </div>
                                        <div className='col s4 m6 m-p-0 centrar'>
                                            <div className='sombra-azul-chica m-p-0' 
                                            style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'32px', width:'32px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                                                <img className='dentometric m-p-0' src='/images/doc.png' alt='dento metric' 
                                                style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                            </div>
                                        </div>
                                        <div className='col s5 m6 m-p-0 centrar'>
                                            <div>{doctor.id} </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className='col s12 m1 centrar row'>
                                    <div className='col s3 m4 movil-visible'>
                                        <div className='table-header-movil'> Nombre </div>
                                    </div>
                                    <div className='col s9 m12 t-centro'>{doctor.nombre} {doctor.apellidos} </div>
                                </div>
                                <div className='col s12 m3 centrar row'>
                                    <div className='col s3 m4 movil-visible'>
                                        <div className='table-header-movil'> Correo </div>
                                    </div>
                                    <div className='col s9 m12 t-centro'> {doctor.email} </div>
                                </div>
                                <div className='col s12 m1 centrar row'>
                                    <div className='col s3 m4 movil-visible'>
                                        <div className='table-header-movil'> Telefono </div>
                                    </div>
                                    <div className='col s9 m12 t-centro'>- {doctor.telefono} </div>
                                </div>
                                <div className='col s12 m1 centrar row'>
                                    <div className='col s3 m4 movil-visible'>
                                        <div className='table-header-movil'> Celular </div>
                                    </div>
                                    <div className='col s9 m12 t-centro'>{doctor.celular} </div>
                                </div>
                                {/** <div className='col s3 m1'>
                                    {_specialtyRender(doctor.specialties)}
                                </div> */}
                                <div className='col s12 m4 movil-visible'>
                                    <div className='table-header-movil'> Oficina </div>
                                </div>
                                <div className='col s12 m2 centrar'>
                                    <ul className="col s12 m12 m-p-0" style={{width:'100%'}}>
                                    {doctor.doctor_offices.map((ofice)=>    
                                        <li className='m-0 p-5 li-list'>
                                            <i className="material-icons m-p-0" style={{color:'#000000', fontSize:'12px', paddingTop:'0!important'}}>home</i>
                                            &nbsp;&nbsp; {ofice.nombre}</li>
                                    )}  
                                    </ul>
                                </div>
                                <div className='col s12 m12 movil-visible'>
                                    <div className='table-header-movil'> Instituciones </div>
                                </div>
                                <div className='col s12 m2 centrar'>
                                    <ul className="col s12 m12 m-p-0" style={{width:'100%'}}>
                                    {doctor.institutions.map((insti)=>    
                                        <li className='m-0 p-5 li-list'>
                                        <i className="material-icons m-p-0" 
                                        style={{color:'#000000', fontSize:'12px', paddingTop:'0!important'}}>business</i> &nbsp;&nbsp;
                                        &nbsp;&nbsp; {insti.institucion}</li>
                                    )}  
                                    </ul>
                                </div>
                                <div className='col s12 m1 movil-oculto'>
                                    <div className='row centrado cont-textos'>
                                        {permisos.doctors_update &&
                                            <Link to={`${doctor.id}`} style={{color:'green'}}
                                            data-position="top"
                                            data-tooltip="Editar"
                                            className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',}}>create</i>
                                            </Link>
                                        }
                                       
                                        <Link to={`detail/${doctor.id}`} 
                                        data-position="top"
                                        data-tooltip="Ver Detalle"
                                        className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                            <i className="material-icons m-p-0" style={{fontSize:'20px',}}>content_paste</i>
                                        </Link>
                                        {permisos.doctors_delete && 
                                        <div className='col m6 m-p-0 centrar movil-100'>
                                            <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} onClick={(e)=>_delete(doctor.id)}>delete_forever</i>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        </div>    
                        
                    </div>
                </div>
            </div>
                
   
    
        </div>
    );
};

export default DoctorTable;