import { createBrowserHistory } from 'history';

window.axios = require('axios');
window.axios.defaults.baseURL = 'http://127.0.0.1:8000';
//window.axios.defaults.baseURL ='http://192.168.56.1:8001';
window.axios.defaults.baseURL = 'https://apiv1.paseotlaquepaque.mx';
/*window.axios.interceptors.response.use(
    function (response) { 
        return response; 
    }, 
    function (error) { 
        console.log("EL ERROR",error.response.config.url)
        if (401 === error.response.status && (error.response.config.url !== '/api/user')) { 
            console.log('redirige....')
            //window.location.replace('login');
           createBrowserHistory().push('/');
           window.location.reload();
        }
    });
*/
window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
export default window.axios;
