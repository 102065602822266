import React, { useContext, useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from '../../../axios';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import { UserContext } from '../../../UserContext';
import Loader from '../../../Layout/Loaders/Loader';

const options ={
    opacity:0.8,
    endingTop:'10%'
}

const NewCatModal = (props) => {
    const [categoria,setCategoria] = useState({nombre_categoria:'',description:''});
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({
        categories_read:null,
        categories_update:null,
    });
    const [validate, setValidate] = useState(true);
    const [loaded,setLoaded] = useState(false);

    useEffect(()=>{
        var elems = document.querySelector('#categorias-modal');
        M.Modal.init(elems,options);
        console.log('else');
    },[])

    useEffect(()=>{
            if(user.user.id){
                setPermisos((prevPremisos) => ({
                    ...prevPremisos,
                    categories_create:checkPermiso("categories_create",user.user.permissions),
                    categories_update:checkPermiso("categories_update",user.user.permissions),
                    categories_read:checkPermiso("categories_read",user.user.permissions)
                }));
            }   
    },[user])

    useEffect(()=>{
            axios.get(`api/categories/${props.categoriaSeleccionada.id}`)
            .then((res)=>{
                setCategoria(res.data)
            }).catch(e=>console.log(e))
    },[props.categoriaSeleccionada])

    useEffect(()=>{
        (permisos.categories_read !==null) && setLoaded(true)
    },[permisos]);

    useEffect(()=>{
        if(props.categoriaSeleccionada.id){
            axios.get(`api/categories/${props.categoriaSeleccionada.id}`)
            .then( res=>{
                console.log('Categoria seleccionada', res.data);
                setCategoria(res.data);
            }).catch(e =>{console.log(e, 'Error todos los producto')})
        }
    },[props.categoriaSeleccionada])

    const _saveCat = (e)=>{
        e.preventDefault();
        const url = categoria.id? `api/category/${categoria.id}` : 'api/categories';
        axios.post(url,categoria).then((resp)=>{
            console.log(resp.data);
            if(resp.status === 200){
                if(categoria.id){
                    props.setCatServicios(props.catServicios.map( (cat) => {
                        if(resp.data.id == cat.id){
                            return resp.data;
                        }
                        else return cat;
                    } ))
                }   
            }
            else if(resp.status == 201){
                props.setCatServicios(catServicios=>[...catServicios,resp.data]);
            }
            setCategoria({nombre_categoria:'',description:''})
            M.toast({
                html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Categoria actualizada correctamente</span>`,
                classes:"success-notify"
            })
            setTimeout(() => {
                _closeModal();
            }, 2000);
        }).catch();
    }

    const _closeModal = () => {
        let instance = M.Modal.getInstance(document.querySelector('#categorias-modal'));
        instance.close();
        setCategoria({nombre_categoria:'',description:''})
        if(props.categoriaSeleccionada.id){
            props.setCategoriaSeleccionada({})
        }
        //instance.destroy();
    }


    return (
        <div id="categorias-modal" className="modal">
        {loaded ?
            <>
            {permisos.categories_read || permisos.categories_create ?
                <>
                <form onSubmit={_saveCat} id="general-form">
                    <div className="modal-content  p-20">
                        {props.categoriaSeleccionada.id ? <h5>Editar Categoría</h5>:<h5>Nueva Categoría</h5>}
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">Nombre de la categoría *</label>
                                <input id="name" type="text" className="validate" value={categoria.nombre_categoria} 
                                onChange={(e)=>setCategoria({...categoria,nombre_categoria:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className='input-text'>
                                <label htmlFor="description">Descripcion:</label>
                                <textarea id="description" className="materialize-textarea validate" value={categoria.description} onChange={(e)=>setCategoria({...categoria,description:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className='col s12 p-t-35'>
                            <div className="btn waves-effect waves-light" onClick={_closeModal}> <span className='p-0-10'> Cancelar </span> </div>
                            {(permisos.categories_update || permisos.categories_create) &&
                            <button className="btn waves-effect waves-light modal-close" type="submit" name="action">Guardar
                                <i className="material-icons right">send</i>
                            </button>
                            }
                        </div>
                    </div>
                </form>
                </>
                    :
                    <NoPermissions />
                    }
                </>  
                :
                <div className='height-200 centrar'>
                    <Loader/>
                </div>
            }
        </div>
    );
};

export default NewCatModal;