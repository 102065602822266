import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import axios from '../../axios';
import { useNavigate, useParams } from 'react-router-dom';
import VisitaFormulario from './VisitaFormulario';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Loader from '../../Layout/Loaders/Loader';

const Edit = () => {
    const  {doctorId} = useParams();
    const {user} = useContext(UserContext);
    const [doctor,setDoctor] = useState({
        nombre:'',
        apellidos:'',
        telefono:'',
        celular:'',
        email:'',
        fax:'',
        rfc:'',
        compania:'',
        descuento:0,
        observaciones_generales:'',
        observaciones_importantes:'',
        observaciones_dental:'',
        boletin:null,
        visitar:null,
        especialidades:[],
        instituciones:[]
    });
   
    const [especialidades,setEspecialidades] = useState([]);
    const [instituciones,setInstituciones] = useState([]);
    const navigate = useNavigate();
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        doctors_read:null,
        doctors_update:null,
        doctors_btn_visit:null
    });
    var selectEspecialidad = {};
    var selectInstitucion = {}; 

    useEffect(()=>{
       setLoaded(false);
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                doctors_read:checkPermiso("doctors_read",user.permissions),
                doctors_update:checkPermiso("doctors_update",user.permissions),
                doctors_btn_visit:checkPermiso("doctors_btn_visit",user.permissions)
            }));

            axios.get(`api/doctors/${doctorId}`).then((resp)=>{
                console.log("doctor",resp.data);
                setDoctor(resp.data)
            }).catch(e=>console.log(e))
        }
    },[doctorId, user ])

    useEffect(()=>{
        (permisos.doctors_read !== null) && setLoaded(true);
    },[permisos])

    useEffect(()=>{
        axios.get('api/specialties').then((res)=>{
            console.log(res.data)
            setEspecialidades(res.data);  
        }).catch(e=>console.log(e))

        axios.get('api/institutions').then((res)=>{
            console.log(res.data)
            setInstituciones(res.data);  
        }).catch(e=>console.log(e))
        
    },[])

    selectEspecialidad = M.FormSelect.init(document.querySelector('#especialidades'));
    selectInstitucion = M.FormSelect.init(document.querySelector('#instituciones'));

    const setEspacialidadesHandler = () =>{
        setDoctor({...doctor,especialidades:selectEspecialidad.getSelectedValues()});
    }
    const setInstitucionesHandler = () =>{
        setDoctor({...doctor,instituciones:selectInstitucion.getSelectedValues()});
    }

    
    
    const _editDoctor = (e) =>{
        e.preventDefault();
        console.log("doctor update..",doctor)
        axios.put(`api/doctors/${doctorId}`,doctor).then(resp=>{
            console.log(resp.data)
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Doctor actualizado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/doctores")
            }, 3000);
        }).catch(e=>console.log(e))
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                {loaded ? 
                    <>
                        {/*Validar si visitaron*/}
                        {doctor.visitar == 1?
                            <VisitaFormulario doctorId={doctorId} doctor_offices={doctor.doctor_offices}></VisitaFormulario>
                            :
                            <div className='col m12 grey lighten-5 border-r-10' style={{padding:5, }}>
                                <div className='font12 negritas p-5'> Puedes agendar una visita al doctor </div>
                            </div>
                        }
                        <h5 className='p-5'>EDITAR DOCTOR</h5>
                        {permisos.doctors_read ?
                            <form  onSubmit={_editDoctor} className="row ancho100" id='general-form'>
                                <h5 className='col s12 m12 p-5'>DATOS DE EL DOCTOR</h5>
                                {/*Validar si visitaron*/}
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="name">nombres</label>
                                        <input id="name" type="text"  
                                        value={doctor.nombre}
                                        onChange={(e)=>setDoctor({...doctor,nombre:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="apellidos">Apellidos</label>
                                        <input id="apellidos" type="text" 
                                        value={doctor.apellidos}
                                        onChange={(e)=>setDoctor({...doctor,apellidos:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className='input-text'>
                                        <label>Especialidades</label>
                                        <select id="especialidades" multiple value={doctor.especialidades} onChange={(e)=>setEspacialidadesHandler()}>
                                            <option value="" disabled>Seleccione las especialidades</option>
                                            {especialidades.map((item) =>
                                                <option value={item.id}  key={item.id}>{item.especialidad}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className='input-text'>
                                        <label>Instituciones</label>
                                        <select id="instituciones" multiple value={doctor.instituciones}onChange={(e)=>setInstitucionesHandler()}>
                                            <option value="" disabled >Seleccione las instituciones</option>
                                            {instituciones.map((item) =>
                                                <option value={item.id}  key={item.id}>{item.institucion}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="rfc">RFC</label>
                                        <input id="rfc" type="text" 
                                        value={doctor.rfc}
                                        onChange={(e)=>setDoctor({...doctor,rfc:e.target.value})} data-length="15" maxLength='15'/>
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="company">Compañia</label>
                                        <input id="company" type="text"
                                        value={doctor.compania} 
                                        onChange={(e)=>setDoctor({...doctor,compania:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="tel">Telefono Particular</label>
                                        <input id="tel" placeholder="Ej. 3330893423" type="text" 
                                        value={doctor.telefono}
                                        onChange={(e)=>setDoctor({...doctor,telefono:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="cel">Celular</label>
                                        <input id="cel" placeholder="Ej. 3330893423" type="text" 
                                        value={doctor.celular}
                                        onChange={(e)=>setDoctor({...doctor,celular:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="email">E-mail</label>
                                        <input id="email" type="email" 
                                        value={doctor.email}
                                        onChange={(e)=>setDoctor({...doctor,email:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="pagina">Pagina Web</label>
                                        <input id="pagina" type="text" onChange={(e)=>setDoctor({...doctor,pagina:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="fax">Fax</label>
                                        <input id="fax" type="text" 
                                        value={doctor.fax}
                                        onChange={(e)=>setDoctor({...doctor,fax:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m2">
                                    <div className="input-text">
                                        <label htmlFor="descuento">Descuento %</label>
                                        <input id="descuento" type="number" 
                                        value={doctor.descuento}
                                        onChange={(e)=>setDoctor({...doctor,descuento:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s6 m3 centrar" style={{minHeight:90}}>       
                                    <div className="switch input-text">
                                        <label>
                                        VISITAR
                                        <input id="visitar" type="checkbox" 
                                        checked={doctor.visitar}
                                        onChange={(e)=>setDoctor({...doctor,visitar:e.target.checked})}/>
                                        <span className="lever"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col s6 m3 centrar" style={{minHeight:90}}>       
                                    <div className="switch input-text">
                                        <label>
                                        BOLETIN
                                        <input id="boletin" type="checkbox" 
                                        checked={doctor.boletin}
                                        onChange={(e)=>setDoctor({...doctor,boletin:e.target.checked})}/>
                                        <span className="lever"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="col s12 m12">
                                    <div className="input-text">
                                        <label htmlFor="observa1">Observaciones Generales</label>
                                        <textarea id="observa1" className="materialize-textarea validate"
                                        value={doctor.observaciones_generales}
                                        onChange={(e)=>setDoctor({...doctor,observaciones_generales:e.target.value})}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col s12 m12">
                                    <div className="input-text">
                                        <label htmlFor="observa2">Observaciones Importantes</label>
                                        <textarea id="observa2" className="materialize-textarea validate"
                                        value={doctor.observaciones_importantes}
                                        onChange={(e)=>setDoctor({...doctor,observaciones_importantes:e.target.value})}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col s12 m12">
                                    <div className="input-text">
                                        <label htmlFor="observa3">Observaciones Dental</label>
                                        <textarea id="observa3" className="materialize-textarea validate"
                                        value={doctor.observaciones_dental}
                                        onChange={(e)=>setDoctor({...doctor,observaciones_dental:e.target.value})}
                                        ></textarea>
                                    </div>
                                </div>
                                {permisos.doctors_update &&
                                <div className='col s12 p-t-35'> 
                                    <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar datos
                                        <i className="material-icons right">save</i>
                                    </button>
                                </div>
                                }
                            </form>
                            :
                            <NoPermissions />
                        }
                    </>
                    :
                    <Loader />
                }
                </div>
            </div>
        </div>
    );
};

export default Edit;