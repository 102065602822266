import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from '../../axios';

const options ={
    opacity:0.8,
    endingTop:'10%'
}

const AddDiasModal = (props) => {
    const [data,setData] = useState({sucursal_id:props.sucursal_id,num_dias:0});
    const [lastDayAdded,setLastDayAdded] = useState({fecha:null});

    useEffect(()=>{
        var elems = document.querySelector('#modal-add-dias');
        M.Modal.init(elems,options);
    },[])

    useEffect(()=>{
        if(props.sucursal_id){
            axios.get(`api/get-last-appointment/${props.sucursal_id}`).then(resp=>{
                console.log("ultimo dia",resp)
                setLastDayAdded(resp.data)
            }).catch(e=>console.log("Error: ",e))
        } 
    },[props.sucursal_id])

    const _closeModal = () => {
        let instance = M.Modal.getInstance(document.querySelector('#modal-add-dias'));
        instance.close();
    }

    const _addDias = () =>{
        axios.post('api/set_appointments_range',data).then(resp=>{
           if(resp.status === 200){
                M.toast({
                    html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>${resp.data.message}</span>`,
                    classes:"success-notify"
                });
                setTimeout(() => {
                    _closeModal();
                }, 3000);
           }
        }).catch(e=>console.log("Error:",e))
    }

    return (
        <div id="modal-add-dias" className="modal">
            <div className="modal-content">
                <div className='close-btn' onClick={_closeModal}>
                    <i className="material-icons">close</i>
                </div>
                <div className='row' id='general-form'>
                    <div className="col s12 p-10">
                        {lastDayAdded.fecha ? 
                            <div className='font16 negritas'>Ultima fecha agregada {lastDayAdded.fecha}, se agregaran fechas a partir de este día.</div>:
                            <div className='font16 negritas'>No se han agregado dias, se agregaran fechas apartir de mañana.</div>
                        }
                    </div>
                    <div className="col s12">
                        <div className='input-text'>
                            <label htmlFor="dia">Agregar dias a la agenda:</label>
                            <input id="dia" type="number" value={data.num_dias} onChange={(e)=>setData({...data,num_dias:e.target.value})}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="waves-effect waves-green btn-flat">Cancelar</button>
                <button className="waves-effect waves-green btn-flat" onClick={(e)=>_addDias()}>Guardar</button>
            </div>
        </div>
    );
};

export default AddDiasModal;