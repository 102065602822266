import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import M from 'materialize-css';
import { useNavigate } from 'react-router-dom';

const EntregarPedidoForm = (props) => {
    const [empleados,setEmpleados] = useState([]);
    const [data,setData] = useState({fecha_entrega:new Date(),employee_id:null,observaciones_envio:'',recibido:''});
    const {orderID} = props;
    const navigate = useNavigate();

    useEffect(()=>{
        getEmpleados();
    },[]);

    const getEmpleados = () =>{
        axios.get('api/employees').then((resp)=>{
            setEmpleados(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

    const updateEntregado = (e)=>{
        e.preventDefault();
        axios.put(`api/delivering-order/${orderID}`,data).then((resp)=>{
            console.log("resp",resp);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Pedido actualizado exitosamente</span>',
                classes:"success-notify"
            });
            setTimeout(() => {
                navigate("/admin/pedidos")
            }, 3000);
          
        }).catch(e=>console.log("Error: ",e));
        
    }

    return (
       
        <div className='row p-10'>
            <div className='col s12'>
                <h5>Entregar pedido</h5>
            </div>
            <form  id='general-form' onSubmit={(e)=>updateEntregado(e)}>
                <div className='col s12  m-p-0'>
                    <div className="input-text p-5">      
                        <label htmlFor="fecha_entregado">Fecha de Entregado</label>
                        <input id="fecha_entregado" type="date" onChange={(e)=>setData({...data,fecha_entrega:e.target.value})}/>
                    </div>
                </div>
                <div className='col s12 m6 m-p-0'>
                    <div className="input-text p-5">      
                        <label htmlFor="recibido_por">Recibido por</label>
                        <input id="recibido_por" type="text" onChange={(e)=>setData({...data,recibido:e.target.value})}/>
                    </div>
                </div>
                <div className='col s12 m6 m-p-0'>
                    <div className="input-text p-2">
                        <label htmlFor="entregado-select">Entregado por</label>
                        <select className='browser-default custom-select' id="entregado-select"  onChange={(e)=>setData({...data,employee_id:e.target.value})} >
                            <option value="">Selecciona uno...</option>
                            {empleados.map((empleado)=>
                                <option value={empleado.id}>{`${empleado.nombre} ${empleado.apellidos}`}</option>
                            )}
                        </select>
                    </div>
                </div>
                
                <div className='row col s12 m-t-25'>
                    <div className="col s12 input-text">
                        <label htmlFor="observa-pedido">Observaciones del pedido</label>
                        <textarea className='p-10' 
                            id="observa-pedido" 
                            type="text" 
                            style={{background:'#ffffff', minHeight:'150px'}} 
                            onChange={(e)=>setData({...data,observaciones_envio:e.target.value})}></textarea>
                    </div>
                </div>
                <div className='col s12 p-t-35'> 
                    <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                        <i className="material-icons right">save</i>
                    </button>
                </div>
            </form> 
        </div>
       
    );
};

export default EntregarPedidoForm;