import React,{ useContext, useEffect, useState}  from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import M from 'materialize-css';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import Spinner from '../../../Layout/Loaders/Spinner';

const Edit = () => {
    let {paqueteriaId} = useParams();
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({deliveries_update:null});
    const [paqueteria,setPaqueteria] = useState({paqueteria:''});
    const [validate, setValidate] = useState(true);
    const [loaded,setLoaded] = useState(false);
    const navigate = useNavigate()
    
    useEffect(()=>{
        if(user.user.id){
            setPermisos((prevPremisos) => ({...prevPremisos,deliveries_update:checkPermiso("deliveries_update",user.user.permissions)}));

            axios.get(`api/deliveries/${paqueteriaId}`)
            .then((res)=>{
                setPaqueteria(res.data);
            }).catch(e=>console.log(e))
        }
    },[paqueteriaId,user])

    useEffect(()=>{
        (permisos.deliveries_update !==null) && setLoaded(true)
    },[permisos]);

    const _update = (e) =>{
        e.preventDefault()
        axios.put(`api/deliveries/${paqueteriaId}`,paqueteria)
        .then((res)=>{
            setPaqueteria(res.data)
            setValidate(true)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>paqueteria actualizada exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/paqueteria")
            }, 4000);
        }).catch(e=>{
            console.log(e);
            e.response.data.errors.descripcion.length > 0 && setValidate(false); 
        })
    }

    return (
        <>
            <div className='container-admin-menu'><Breadcrumb/></div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                {loaded ?
                    <>
                    {permisos.deliveries_update ?
                        <>
                            <h5>Editar Paqueteria</h5>
                            <form className="row ancho100" id='general-form'  onSubmit={_update}>
                                <div className="col s12">
                                    <div className="input-text">
                                        <label htmlFor="name">Paqueteria</label>
                                        <input id="name" type="text" className="validate" value={paqueteria.descripcion} 
                                        onChange={(e)=>setPaqueteria({...paqueteria,descripcion:e.target.value})}/>
                                        {!validate &&  <span class="helper-text t-rojo">El campo paqueteria es obligatorio</span>}
                                    </div>
                                </div>
                                <div className='col s12 p-t-35'> 
                                    <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                        <i className="material-icons right">send</i>
                                    </button>
                                </div>
                            </form>
                        </>
                        :
                        <NoPermissions />
                        }
                    </>  
                    :
                    <div className='height-200 centrar'>
                        <Spinner size={"medium"} />
                    </div>
                }
                </div>
            </div>
        </>
    );
};

export default Edit;