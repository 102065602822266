import React, {useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import NewCatModal from './NewCatModal';
import axios from '../../../axios';
import M from 'materialize-css';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import { UserContext } from '../../../UserContext';
import Loader from '../../../Layout/Loaders/Loader';

const Categorias = () => {
    const [catServicios, setCatServicios] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState({});
    const [catToUpdate,setCatToUpdate]= useState({});
    const [loaded,setLoaded] = useState(false);
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({
        categories_create:null,
        categories_read:null,
        categories_update:null,
        categories_delete:null
    });

    useEffect(()=>{
        if(user.user.id){
            console.log("user",user);
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                categories_create:checkPermiso("categories_create",user.user.permissions),
                categories_read:checkPermiso("categories_read",user.user.permissions),
                categories_update:checkPermiso("categories_update",user.user.permissions),
                categories_delete:checkPermiso("categories_delete",user.user.permissions)
            }));
            axios.get('api/categories').then(res=>{
                setCatServicios(res.data)
            }).catch(e=>console.log("error ",e))
        }
    },[user])

    useEffect(()=>{
        (permisos.categories_create !==null) && setLoaded(true);
    },[permisos])

    useEffect(() => {        
        axios.get('api/categories')
        .then( res=>{
            setCatServicios(res.data);
        }).catch(e =>{console.log(e, 'Error todos los producto')})
    },[]);

    useEffect(()=>{
        if(catToUpdate.id){
          axios.post(`api/category/${catToUpdate.id}`,catToUpdate)
          .then(resp=>{
            if(resp.status === 200){
                setCatServicios(catServicios.map((item)=>{
                    if(item.id===resp.data.id)  return resp.data;   
                    return item;
                }));
    
                M.toast({
                      html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Categoría actualizada correctamente</span>`,
                      classes:"success-notify"
                })
            } 
          })
          .catch(e=>console.log("Error: ",e))
        }
    },[catToUpdate, catToUpdate.activo])

    const _handlerModal = (categoria) =>{
        setCategoriaSeleccionada(categoria);  
    }

    useEffect(()=>{
        console.log('trae Categoria', categoriaSeleccionada);
        if(categoriaSeleccionada.id){
            let instance = M.Modal.getInstance(document.querySelector('#categorias-modal'));
            instance.open();
            console.log('instancia', instance);
        }
    },[categoriaSeleccionada])

    const _delete = (catId) =>{
        if(window.confirm("Realmente deseas borrar a esta categoría, si la borras no se podra recuperar despues")) {
            axios.delete(`api/categories/${catId}`)
            .then(res=>{
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setCatServicios(catServicios.filter(u=>u.id!==res.data.categoria.id))
            }).catch(e=>console.log("error",e))
        }

    }

    const _handleChecked = (e,categoria) =>{
        let value = e.target.checked;
        let categoriaTemporal = categoria;
        categoriaTemporal.activo = value?1:0; 
        setCatToUpdate(categoriaTemporal);
    }
    
    return (
        <>
            <div className='container-admin-menu'> <Breadcrumb hasModalButton={permisos.categories_create? 'categorias-modal' : ''} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                <NewCatModal 
                    categoriaSeleccionada = {categoriaSeleccionada} 
                    setCategoriaSeleccionada = {setCategoriaSeleccionada} 
                    catServicios = {catServicios}
                    setCatServicios = {setCatServicios} />

                {loaded ?
                permisos.categories_read ?
                     
                    <div className='table'>
                        <div className='table-header row movil-oculto'>
                            <div className='col s2 m1'> ID </div>
                            <div className='col s10 m3'> Nombre Categoria </div>
                            <div className='col s12 m6'> Descripcion</div>
                            <div className='col s12 m1 t-right'> Activo &nbsp; &nbsp; </div>
                            <div className='col s12 m1 t-right'> Acciones </div>
                        </div>  
                        {catServicios.map( (categoria)=>
                        <div className='table-container row' key={categoria.id}>
                            <div className='col s4 m3 movil-visible'>
                                <div className='table-header-movil'>id </div>
                            </div>
                            <div className='col s8 m1'> <div className='cont-textos'>{categoria.id}</div></div>

                            <div className='col s4 m3 movil-visible'>
                                <div className='table-header-movil'>Nombre </div>
                            </div>
                            <div className='col s8 m3'> <div className='cont-textos'>{categoria.nombre_categoria}</div></div>
                        
                            <div className='col s4 m3 movil-visible'>
                                <div className='table-header-movil'>Descripción </div>
                            </div>
                            <div className='col s8 m6'> <div className='cont-textos'>{categoria.description}</div></div>

                            <div className='col s8 m1'> 
                                <div className='cont-textos centrar'>
                                    <div className="switch input-text">
                                        <label className='centrado'>
                                            <input id="activo" type="checkbox" defaultChecked={categoria.activo} checked={categoria.activo} onChange={(e)=>_handleChecked(e,categoria)}/>
                                            <span className="lever"></span>
                                        </label>
                                    </div>
                                </div> 
                            </div>
                            <div className='col s12 m1 movil-oculto'>
                                <div className='row cont-textos'>
                                    {permisos.categories_update &&
                                    <button style={{border:'transparent',background:'none'}}
                                    onClick={(e)=> _handlerModal(categoria) }
                                    data-tooltip="Editar"
                                    className="col m6 m-p-0 centrar movil-100 tooltipped">
                                        <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',color:'green'}}>create</i>
                                    </button>
                                    }
                                    {permisos.categories_delete &&
                                    <div className='col m6 m-p-0 centrar movil-100'>
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} 
                                        onClick={(e)=>_delete(categoria.id)}>delete_forever</i>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    : <NoPermissions />
                :
                <div className='height-200 centrar'>
                    <Loader/>
                </div>
                }
                </div>
            </div>
        </>
    );
};

export default Categorias;