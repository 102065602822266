import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

const Ingresos = () => {    
    const [dia,setDia] = useState(new Date());

    const handleDate = (e) => {
        let date =  new Date(e.target.value);
        date.setDate(date.getDate()+1);
        setDia(date);
    }
    
    return (
        <>
            <div className='container-admin-menu'> 
                <Breadcrumb  hasFilters={false} />
            </div> 
            
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <div className='row col s12 info-badge border-r-10 m-p-0 centrar-h' style={{bordeRadius:'10px!important', padding:'0px!important',}}>
                        <div className="col s12 m6 p-0">
                        <h5>Reporte Global de Ingresos por Sucursal</h5>
                        <div>Fecha Analisis: {dia.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })} </div>
                        </div>
                        <div className="row col s12 m6 input-text">
                            <div className='col s12 m6 right'>
                                <label htmlFor="dia">Seleccionar día</label>
                                <input id="dia" type="date" onChange={handleDate}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row m-0 p-10 cont-row">
                            <div className='col m6 s12 m-0 p-10'>
                                <div className='info-badge border-r-10 m-p-0 centrar' style={{bordeRadius:'10px!important', padding:'0px!important',}}>
                                    <h5>Matutino</h5>
                                </div>
                            </div>
                            <div className='col m6 s12 m-0 p-10'>
                                <div className=' info-badge border-r-10 m-p-0 centrar' style={{bordeRadius:'10px!important', padding:'0px!important',}}>
                                    <h5>Vespertino</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row m-p-0 cont-row">
                            <div className='col m6 s12 table table-doc row m-t-10 z-depth-1 p-10 border-r-6 centrar'>
                                <div class="col m11 s12 container p-10">
                                    <div className='row m-p-0 table-header'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            246
                                        </div>
                                        <div className='col s12 m10' style={{border:'solid 1px'}}>
                                            Yadira Cortes Zamora
                                        </div>
                                    </div>
                                    <div className='row m-p-0 table-container'>
                                        <div className='row m-p-0' style={{display:'flex'}}>
                                            <div className='col s12 m2' style={{border:'solid 1px'}}></div>
                                            <div className='col s12 m4' style={{border:'solid 1px'}}>
                                                PEDIDOS:
                                            </div>
                                            <div className='col s12 m6' style={{border:'solid 1px'}}>
                                                SUBTOTAL:
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            EFECTIVO
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            3
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $1,647.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            TARJETAS
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            3
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $3897.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            DEBITO
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            2
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $698.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            GASTOS
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $0.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0 table-container'>
                                        <div className='row m-p-0'>
                                            <div className='col s12 m10' style={{border:'solid 1px'}}>
                                                TOTAL DE EFECTIVO
                                            </div>
                                            <div className='col s12 m2' style={{border:'solid 1px'}}>
                                                $1647.00
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            TOTAL
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            8
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $6242.00
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col m6 s12 table table-doc row m-t-10 z-depth-1 p-10 border-r-6 centrar'>
                                <div class="col m11 s12 container p-10">
                                    <div className='row m-p-0 table-header'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            246
                                        </div>
                                        <div className='col s12 m10' style={{border:'solid 1px'}}>
                                            Yadira Cortes Zamora
                                        </div>
                                    </div>
                                    <div className='row m-p-0 table-container'>
                                        <div className='row m-p-0' style={{display:'flex'}}>
                                            <div className='col s12 m2' style={{border:'solid 1px'}}></div>
                                            <div className='col s12 m4' style={{border:'solid 1px'}}>
                                                PEDIDOS:
                                            </div>
                                            <div className='col s12 m6' style={{border:'solid 1px'}}>
                                                SUBTOTAL:
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            EFECTIVO
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            3
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $1,647.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            TARJETAS
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            3
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $3897.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            DEBITO
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            2
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $698.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            GASTOS
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $0.00
                                        </div>
                                    </div>
                                    <div className='row m-p-0 table-container'>
                                        <div className='row m-p-0'>
                                            <div className='col s12 m10' style={{border:'solid 1px'}}>
                                                TOTAL DE EFECTIVO
                                            </div>
                                            <div className='col s12 m2' style={{border:'solid 1px'}}>
                                                $1647.00
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-p-0'>
                                        <div className='col s12 m2' style={{border:'solid 1px'}}>
                                            TOTAL
                                        </div>
                                        <div className='col s12 m4' style={{border:'solid 1px'}}>
                                            8
                                        </div>
                                        <div className='col s12 m6' style={{border:'solid 1px'}}>
                                            $6242.00
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ingresos;