import React,{useEffect, useState} from 'react';
import M from 'materialize-css';
import Ranges from '../../Layout/DatePickers/Ranges';

const FiltrosFecha = (props) => {
    const {openDateFilters,setOpenDateFilters,searchByDate} = props;
    const [option,setOption] = useState('no_date');
    const [searchDates,setSearchDates] = useState({start:null,end:null});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    useEffect(()=>{
        console.log("EL modal---",openDateFilters)
        if(openDateFilters){
            let instance = M.Modal.getInstance(document.querySelector('#date-range-modal'));
            instance.open();
        } 
    },[openDateFilters])
    
    const _closeModal = () => {   
        console.log("xlosed modal..")
        let instance = M.Modal.getInstance(document.querySelector('#date-range-modal'));
        instance.close();
        setStartDate('');
        setEndDate('');
        setOpenDateFilters(false);
    }

    const _search = () =>{
        if(option === 'no_date'){
            alert('Selecciona uno de los tipos de filtro para poderlo aplicar')
        }
        else{
            console.log("Opcion",option);
            console.log("dates",searchDates);
            let obj = {
                start:searchDates.start,
                end:searchDates.end,
                field:option
            }
            searchByDate(obj);
            _closeModal();
        }
    }
    const _filterByDate = (value) =>{
        console.log('Tipo de filtro por fecha', value);
        if(value === 'no_date'){
            alert('Selecciona uno de los tipos de filtro para poderlo aplicar')
        }
        else{
            setOption(value)
        }
    }


    return (
        <div id="date-range-modal" className="modal border-r-6" style={{height:'90vh',}}>
                <div className="modal-content m-0 p-10">
                    <div className='close-btn' onClick={_closeModal}>
                        <i className="material-icons">close</i>
                    </div>
                    <div className='row m-p-0' style={{minHeight:'auto!important',}}>
                        <div className='col s12'>
                            <div className='col s12 m1 m-p-0' style={{height:'38px', width:'38px'}}>
                                <img className='m-p-0 dentometric' src='/images/FILTER1.png' alt='dento metric' 
                                style={{height:'100%', width:'100%'}} />
                            </div>
                            <div className='row col s12 m10 m-p-0 font18'> <b style={{color:'gray', paddingLeft:20}}>Filtrar busqueda por fecha</b> </div>
                                
                        </div>
                        <div className="col s7 m-0 p-10-0">
                            <div className='row m-0 p-10-0'>    
                                <h6>Selecciona una de las opciones para llevar a cavo el filtro:</h6> 
                                <select className='col m12 browser-default m-0 p-0-3 custom-select'
                                    defaultValue={'no_date'}
                                    onChange={(e)=>_filterByDate(e.target.value)}
                                    >  
                                    <option value={'no_date'}>Selecciona uno de los tipos de filtro</option>
                                    <option value={'fecha_pedido'}>Fecha de creacion de pedido</option>
                                    <option value={'fecha_entrega'}>Fecha de envio de pedido</option>
                                    <option value={'fecha_envio'}>Fecha de entrega de pedido</option>
                                </select>
                            </div>
                        </div>
                        <div className="col s5 centrar">
                            <Ranges 
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                searchDates={searchDates} 
                                setSearchDates={setSearchDates}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-footer col s12 m-p-0">
                    <div className="btn waves-effect waves-light row" onClick={_search}>
                        <div className='rowcol s12 m12'>
                            <div className='col s1 m-0' style={{padding:'8px 0 0 0', height:'30px', width:'26px'}}>
                                <img className='m-p-0 dentometric' src='/images/filter1.png' alt='dento metric' 
                                style={{height:'100%', width:'100%'}} />
                            </div>
                            <div className='col s8 m-p-0 font12'> <b style={{color:'#fff!important', paddingLeft:10}}>Buscar</b> </div>
                        </div>
                    </div>
                    <div className="btn waves-effect waves-light row" onClick={_closeModal}>
                        <div className='rowcol s12 m12'>
                            <div className='col s1 m-0' style={{padding:'8px 0 0 0', height:'30px', width:'26px'}}>
                                <img className='m-p-0 dentometric' src='/images/clear-filters.png' alt='dento metric' 
                                style={{height:'100%', width:'100%'}} />
                            </div>
                            <div className='col s8 m-p-0 font12'> <b style={{color:'#fff!important', paddingLeft:10}}>Cancelar</b> </div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default FiltrosFecha;