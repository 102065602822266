import 'materialize-css'
import MainRoutes from './Routes';
import {UserProvider} from './UserContext';


function App(){
  	return (
		<UserProvider>
			<MainRoutes />
		</UserProvider>
	);
}

export default App;
