import React from 'react';

const PermisosModal = (props) => {
    const {permisosCurrent,_closePermisosModal } = props;
    console.log('car', props)

    return (
        <div id="modal-permisos" className="border-r-6 modal"  style={{height:'60vh',overflow:'hidden'}}>
            <div className="modal-content">
                <div className='close-btn' onClick={_closePermisosModal}>
                    <i className="material-icons">close</i>
                </div>
            
                <h5 className='m-10 p-10'> <i class="material-icons p-5">lock_open </i> Listado de permisos asigandos a este rol </h5>

                <ul className="collection z-depth-2" 
                style={{height:'50vh', overflowY:'scroll', overflowX:'hidden'}}>
                {permisosCurrent.map((permiso)=>
                    <li className="collection-item col s12 m12 m-p-0">
                        <div className='m-p-0 row t-blanco' style={{background:'#11CDEF',}}>
                            <div className='col s1 m1 centrar m-p-0'>
                                <i class="material-icons p-5">lock_open </i>
                            </div>
                            <div className='col s11 m11 m-0 p-8 negritas t-blanco'> <b >{permiso.name}</b> </div>
                        </div>
                        <div className='m-p-0 p-10 sombra-azul-chica'><i class="material-icons m-p-0 font16">chevron_right </i> {permiso.description}</div>
                    </li>
                )}
                </ul> 
           
        </div>
    </div>
    );
};

export default PermisosModal;