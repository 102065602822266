import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import DireccionModal from './DireccionModal';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';

const defaultImg = "/images/doc.png";

const Detail = () => {
    const {doctorId} = useParams();
    const {user} = useContext(UserContext);
    const [doctor,setDoctor] = useState({})
    const [consultorios,setConsultorios] = useState([])
    const [consultorioToEdit,setConsultorioToEdit] = useState({});
    const [editFlag,setEditFlag] = useState(false);
    const [permisos,setPermisos] = useState({
        doctor_offices_create:null, 
        doctor_offices_read:null,
        doctor_offices_update:null,
        doctor_offices_delete:null,
        doctors_btn_visit:null
    })

    useEffect(()=>{
     if(user.id){
        setPermisos((prevPremisos) => ({
            ...prevPremisos,
            doctor_offices_create:checkPermiso("doctor_offices_create",user.permissions),
            doctor_offices_read:checkPermiso("doctor_offices_read",user.permissions),
            doctor_offices_update:checkPermiso("doctor_offices_update",user.permissions),
            doctor_offices_delete:checkPermiso("doctor_offices_delete",user.permissions),
            doctors_btn_visit:checkPermiso("doctors_btn_visit",user.permissions)      
        }));
     }
    },[user]);
    
    useEffect(()=>{
        axios.get(`api/doctors/${doctorId}`).then((resp)=>{
            console.log("doctor",resp.data);
            setDoctor(resp.data);
        }).catch(e=>console.log(e)) 
        //get Consultorios
        axios.get(`api/all_doctor_offices/${doctorId}`).then((resp)=>{
            console.log("consultorios",resp.data)
            setConsultorios(resp.data)
        }).catch(e=>console.log(e))
        
    },[doctorId,editFlag]);

    const _deleteConsultorio = (consultorio) =>{
        if(window.confirm("Realmente deseas borrar este consultorio, si lo borras no se podra recuperar despues")){
            axios.delete(`api/all_doctor_offices/${doctorId}`)
            .then((response)=>{
                console.log("borrar",response)
                setConsultorios(consultorios.filter(c=>c.id !==consultorio.id));
            }).catch(e=>console.error(e))
        }
    }
    
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb actual={doctor.nombre}/></div>
            <div className='container-admin-container'>
            <div className='container-admin  z-depth-1'>
                <DireccionModal doctorId={doctorId} consultorio={consultorioToEdit} setEditFlag={setEditFlag}/>
                <h5>Detalles del Doctor</h5>
                <div className='row'>
                    <div className='col m12 valign-wrapper grey lighten-5' style={{padding:5}}>
                        <div className='valign-wrapper' style={{height:150,position:'relative'}}>
                            <img src={defaultImg} alt={doctor.nombre} className="responsive-img" style={{height:140,borderRadius:10}}/>
                            <label style={{position:'absolute',right:5,top:5,cursor:'pointer'}}>
                                {/*
                                 <i className="material-icons" style={{color:'#5E72E4'}}>create</i>
                                 <input type="file"  style={{display:'none'}} 
                                onChange={(e)=>_updateImg(e.target.files[0])} 
                                accept="image/png, image/gif, image/jpeg" />*/}
                            </label>
                        </div>
                        <div className='m-5'>
                            <p className='m-5'>ID: {doctor.id}</p>
                            <p className='m-5'><label>Fecha de creación: {new Date(doctor.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</label></p>
                            <p className='m-5'><label>Compañia: {doctor.compania}</label></p>
                        </div>
                        {permisos.doctors_btn_visit &&
                        <div className='col s12 m4 m-t-20 m-5 p-10 centrar-r'>
                            <Link to={`../doctores/${doctor.id}`} style={{color:'green'}}
                                data-position="top"
                                data-tooltip="Editar" 
                                className="btn-blanco waves-effect waves-light" 
                                type="submit" name="action">Visitar</Link>
                        </div>
                        }
                    </div>
                    
                    <div className='col s12 m4 m-t-20'>
                        <label>Apellidos</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>person</i>
                            <span>{doctor.apellidos}</span>  
                        </div>
                    </div>
                    <div className='col s12 m4 m-t-20'>
                        <label>Nombre(s)</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>person</i>
                            <span>{doctor.nombre}</span>  
                        </div>
                    </div>
                    <div className='col s12 m4 m-t-20'>
                        <label>RFC</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>account_balance</i>
                            <span>{doctor.rfc}</span>  
                        </div>
                    </div>
                    <div className='col s12 m4 m-t-20'>
                    <label>Telefono Particular</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>call</i>
                            <span>{doctor.telefono}</span>  
                        </div>
                    </div>
                    <div className='col s12 m4 m-t-20'>
                        <label>Telefono Celular</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>phone_android</i>
                            <span>{doctor.celular}</span>  
                        </div>
                    </div>
                    <div className='col s12 m4 m-t-20'>
                        <label>Email</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>mail</i>
                            <span>{doctor.email}</span>  
                        </div>
                    </div> 
                    <div className='col s12 m4 m-t-20'>
                        <label>Pagina Web</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>web</i>
                            <span>{doctor.pagina}</span>  
                        </div>
                    </div> 
                    <div className='col s12 m4 m-t-20'>
                        <label>Fax</label>
                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>print</i>
                            <span>{doctor.fax}</span>  
                        </div>
                    </div> 

                    <div className='col s12 m6 m-t-20'>
                        <label>Observaciones Generales</label>
                        <div style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",minHeight:85,padding:5,width:"100%"}}>
                            <span>{doctor.observaciones_generales}</span>  
                        </div>
                    </div> 
                    
                    <div className='col s12 m6 m-t-20'>
                        <label>Observaciones Generales</label>
                        <div  style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",minHeight:85,padding:5,width:"100%"}}>
                            <span>{doctor.observaciones_importantes}</span>  
                        </div>
                    </div> 
                    <div className='col s12 m6 m-t-20'>
                        <label>Observaciones Dental</label>
                        <div  style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",minHeight:85,padding:5,width:"100%"}}>
                            <span>{doctor.observaciones_dental}</span>  
                        </div>
                    </div> 

                </div>
                <div className='row'>
                    <div className='col s12 m-t-10' style={{position:"relative"}}>
                        <h6><blockquote>Consultorios</blockquote></h6>
                        {permisos.doctor_offices_create &&
                        <button data-target="modaladdress" className="btn-floating pulse cyan modal-trigger" style={{position:'absolute',top:5,right:20}}>
                            <i className="material-icons">add</i>
                        </button>
                        }
                    </div>
                    
                    {permisos.doctor_offices_read && consultorios.map((consultorio)=>
                        <div className='col s12 m6 l4'>
                            <div className="card consultorio-card darken-1 z-depth-1">
                                <div className="card-content p-10">
                                    <h6>{consultorio.nombre}</h6>
                                    <div className='centrar-l'>
                                        <span><i className="material-icons">place</i> </span>
                                        <div>
                                            {consultorio.direccion}, Col. {consultorio.colonia}
                                            <br></br><span>Entre calles.</span> {consultorio.entre_calles}
                                        </div>
                                    </div>
                                    <div className='centrar-l'>
                                        <span><i className="material-icons">public</i> </span>
                                        <div>
                                            {consultorio.municipio}, {consultorio.estado}, {consultorio.pais}
                                            <br></br><span>Codigo postal:</span> {consultorio.cp}
                                        </div>
                                    </div>
                                    <div className='centrar-l'>
                                        <span><i className="material-icons">schedule</i></span> 
                                        <div>{consultorio.horario}</div>
                                    </div>     
                                    <div className='centrar-l'>
                                        <span><i className="material-icons">phone</i></span> 
                                        <div>{consultorio.telefonos&&consultorio.telefonos.join(', ')}</div>
                                    </div>     
                                </div>
                                <div className="card-action">
                                {permisos.doctor_offices_delete&&
                                    <button className="btn-delete waves-effect z-depth-1 scale-transition scale-in" onClick={(e)=>_deleteConsultorio(consultorio)}>
                                        <span className='centrar'>Borrar &nbsp;
                                            <i className="material-icons" style={{fontSize:18}}>delete</i>
                                        </span>
                                    </button>
                                }
                                {permisos.doctor_offices_update&&
                                    <button data-target="modaladdress" className="btn-info waves-effect z-depth-1 scale-transition scale-in modal-trigger" onClick={(e)=>setConsultorioToEdit(consultorio)}>
                                        <span className='centrar'>Editar &nbsp;
                                            <i className="material-icons" style={{fontSize:18}}>edit</i>
                                        </span>
                                    </button>
                                }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='row'>
                    <div className='col s12 m-t-10'>
                        <h6><blockquote>Especialidades</blockquote></h6>
                    </div>
                    {doctor.specialties&& doctor.specialties.map((item)=>
                    <div className='col' key={item.id}>
                        <div className='chip centrar-l p-10'>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>assignment</i>
                            <span>{item.especialidad}</span>  
                            <i className="close material-icons">close</i>
                        </div>   
                    </div>
                    )}    
                </div>
                <div className='row'>
                    <div className='col s12 m-t-10'>
                        <h6><blockquote>Instituciones </blockquote></h6>
                    </div>
                    {doctor.institutions&& doctor.institutions.map((item)=>
                    <div className='col' key={item.id}>
                        <div className='chip centrar-l p-10'>
                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>location_city</i>
                            <span>{item.institucion}</span>
                            <i className="close material-icons">close</i>
                        </div>   
                    </div>
                    )}    
                </div>
            </div>
        </div>
    </div>
    );
};

export default Detail;