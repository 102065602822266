import React, { useEffect} from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import axios from '../../axios';


const Dropdown = (props) => {
    const {doctorID,permisos} = props;
    useEffect(()=>{
        var elems = document.querySelectorAll('.dropdown-trigger');
        var mdrop = M.Dropdown.init(elems);
    },[])

    const _delete = (doctorID) =>{
        if(window.confirm("Realmente deseas borrar a este doctor, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/doctors/${doctorID}`)
            .then(res=>{
                
            }).catch(e=>console.log("error",e))
        }
    }
    return (
        <div className='contain-options'>
            <button className='dropdown-trigger m-p-0' data-target={'dropdown1'+doctorID}>
                <div className='m-p-0'>
                    <span><i className="material-icons">fiber_manual_record</i></span>
                    <span><i className="material-icons">fiber_manual_record</i></span>
                    <span><i className="material-icons">fiber_manual_record</i></span>
                </div>
            </button>
            <ul id={'dropdown1'+doctorID} className='dropdown-content contain-drop ludropdown'>
                
                <li>
                    <Link style={{color:'#0033ff',}}
                        to={`detail/${doctorID}`} 
                        data-position="top"
                        data-tooltip="Ver Detalle" className='row m-p-0'>
                        <i className="material-icons">content_paste</i>
                        <div className='botton'>Detalle</div>
                    </Link>
                </li>
                
                {permisos.doctors_update &&
                <li>
                    <Link to={`${doctorID}`} style={{color:'green'}}
                        data-position="top"
                        data-tooltip="Editar" className='row m-p-0'>
                        <i className="material-icons">create</i>
                        <div className='botton'>Editar</div> 
                    </Link>
                </li>
                }
                {permisos.doctors_delete &&
                <li>
                    <button onClick={(e)=>_delete(doctorID)}  style={{color:'red'}}>
                        <i className="material-icons" style={{fontSize:'20px!important'}}>delete_forever</i>
                        <div className='botton'>Borrar</div> 
                    </button>
                </li>
                }
                <li>
                    <button>
                        <i className="material-icons" style={{fontSize:'30px!important'}}>cancel</i>
                        <div className='botton'>Cancelar</div> 
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default Dropdown;