import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import axios from '../../../axios';
import M from 'materialize-css';
import { useNavigate, useParams } from 'react-router-dom';


const Edit = () => {
    const [estado,setEstado] = useState({description:"",code:""});
    const {paisId,estadoId} = useParams();
    let navigate = useNavigate();
    useEffect(()=>{
        axios.get(`api/states/${estadoId}`)
        .then(res=>{
            console.log(res.data)
            setEstado(res.data)
        }).catch(e=>console.log("error",e))
    },[estadoId])

    const _update = (e)=>{
        e.preventDefault();
        axios.put(`api/states/${estadoId}`,estado)
        .then((res)=>{
            console.log(res.data)
            setEstado(res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Estado actualizado exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/paises/"+paisId+"/estados")
            }, 3000);
        }).catch(e=>console.log(e))
    }
    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb actual={estado.description}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5>Editar estado</h5>
                    <form className="row ancho100" id='general-form'  onSubmit={_update}>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">Estado</label>
                                <input id="name" type="text" className="validate" value={estado.description} 
                                onChange={(e)=>setEstado({...estado,description:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">Código</label>
                                <input id="name" type="text" className="validate" value={estado.code} 
                                onChange={(e)=>setEstado({...estado,code:e.target.value})}/>
                            </div>
                        </div>
                     
                        <div className="col s12">
                            <div className="switch">
                                <label>
                                Activo
                                <input type="checkbox" checked={estado.status} onChange={(e)=>setEstado({...estado,status:e.target.checked})}/>
                                <span className="lever"></span>
                                Inactivo
                                </label>
                            </div>
                        </div>

                        <div className='col s12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>   
        </>
    );
};

export default Edit;