import React, { useEffect, useState } from 'react';


const ProductRow = (props) => {
    const {productosSelect,productos, setProductos,producto,indice,catSelected,orderId} = props;
    const [selectedProducto,setSelectedProducto] = useState(props.producto);
   
    useEffect(()=>{
        if(selectedProducto && selectedProducto.id){
            const tempArray = productos.map((p,i)=>i==indice?selectedProducto:p);
            setProductos(tempArray);
        }
    },[selectedProducto]);
    
    const handleSearch = (event) =>{
        if (event.keyCode === 13) {
           _findProduct(event.target.value);
        }
    }

    const _handleCantidad = (e) =>{
        setSelectedProducto({...selectedProducto,cantidad:e.target.value});
    }

    const _handleDescuento = (e) =>{
        console.log(e.target.value);
        console.log(selectedProducto.precio_unidad);
        let descuento = (selectedProducto.precio_unidad * parseInt(e.target.value)) / 100;
        console.log("descuento",descuento);
        setSelectedProducto({...selectedProducto,descuento:e.target.value,precio_descuento:descuento});
    }
    //Obtiene el precio en base ala categoria
    const getPrecio = (categorias = null,precio)=>{
        console.log("categorias",categorias)
        console.log("categorias seleccionada",catSelected)
        if(categorias){
           let temporal = categorias.find((item)=>item.id==catSelected);
           console.log("temporal",temporal)
           if(temporal && temporal.pivot) return temporal.pivot.precio;
        }
        return precio;
        
    }

    const _findProduct = async (value) =>{
        let tempProduct = productosSelect.find(p=>p.id===parseInt(value));
        if(tempProduct && tempProduct.id) {
            console.log("producto seleccionado",tempProduct)
            let precioUnidad = await getPrecio(tempProduct.categories,tempProduct.precio_unidad);
            const newObj = {
                id:tempProduct.id,
                order_id:orderId,
                nombre_producto:tempProduct.nombre_producto,
                precio_unidad:precioUnidad,
                cantidad:1,
                descuento:0,
                precio_descuento:0,
                dias_entrega:0
            }
            const tempProductos = productos.map((p,i)=>i==indice?newObj:p);
            setSelectedProducto(newObj);
            setProductos(tempProductos);
        }
    }

    const _removeProduct = (event) =>{
        const tempArray = productos.filter((p,i)=>p.id!=selectedProducto.id);
        setProductos(tempArray);
    }

    return (
            <div className='row col s12 p-20' id="general-form">
                <div className='col s12 m1'>
                    <div className="input-text">
                        <input id="id" type="number" value={selectedProducto.id}  
                            onKeyDown={(e)=>handleSearch(e)} 
                            onChange={(e)=>setSelectedProducto({...selectedProducto,id:e.target.value})}
                            />
                    </div>
                </div>
                <div className='col s12 m4'>
                    <div className="input-text">
                        <input id="producto" type="text"  value={selectedProducto.nombre_producto} 
                        onChange={(e)=>console.log(e)}/>
                    </div>
                </div>
                <div className='col s12 m2'>
                    <div className="input-text">
                        <input id="precio-unidad" type="number" step="any" value={producto.precio_unidad} disabled/>
                    </div>
                </div>
                <div className='col s12 m1'>
                    <div className="input-text">
                        <input id="cantidad" type="number"  value={selectedProducto.cantidad}  
                        onChange={(e)=>_handleCantidad(e)}/>
                    </div>
                </div>
                <div className='col s12 m1'>
                    <div className="input-text">
                        <input id="descuento" type="number"  value={selectedProducto.descuento} onChange={(e)=>_handleDescuento(e)}/>
                    </div>
                </div>
                <div className='col s12 m1'>
                    <div className="input-text">
                        <input id="precio-descuento" type="number"  disabled value={selectedProducto.precio_descuento} />
                    </div>
                </div>
                <div className='col s12 m1'>
                    <div className="input-text">
                        <input id="dias" type="number"  
                            disabled
                            value={selectedProducto.dias_entrega} 
                            onChange={(e)=>setSelectedProducto({...selectedProducto,dias_entrega:e.target.value})}/>
                    </div>
                </div>
                <div className='col s12 m1 centrar'>
                    <button className="centrar btn-rounded" onClick={(e)=>_removeProduct(e)}>
                    <i className="material-icons">delete</i>
                    </button>
                </div>
            </div>
       
    );
};

export default ProductRow;