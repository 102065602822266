import React, { useContext } from 'react';
import { UserContext } from '../../UserContext';
import '../TopMenu/topMenu.scss';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

const TopMenu = () => {
    const {user} = useContext(UserContext)
    return (
        <div>
            <div className='container-menu-top'>

                <div className='row ' 
                style={{borderBottom: 'solid 1px rgba(255, 255, 255, .3)', marginBottom:'0!important',}}>  
                    <div className='col m6'></div>
                    <div className='col m6 row m-p-0'> 
                        <div className='row cont-row'>
                            <div className='col s2 m3'> &nbsp;</div>
                            
                            <div className='col s2 m1 p-l-5' style={{textAlign:'right',}}> 
                                <div className='sombra-azul-chica' style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'41px', width:'42px', padding:0, margin:0, marginTop:'-6px'}}>
                                    <img className='dentometric' src='/images/logo.png' alt='dento metric' 
                                    style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                </div>
                            </div>
                            <div className='col s7 m6 name-user centrar'>
                                <Link to={`usuarios/perfil/${user.id}`} 
                                data-position="top"
                                data-tooltip="Ver Perfil"
                                className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                    <p className='m-0 p-1-6' style={{color:'#fff'}}> {user.name} </p>
                                </Link>
                            </div>

                            <div className='col s1 m2 row m-p-0 centrar-r' 
                            style={{margin:0, marginTop:'-10px', padding:'0!important', textAlign:'rigth', color:'#ffffff',position:'relative'}}> 
                                <div className='row m-p-0 notificaciones'>
                                    <div className='notificacion-icon'> 
                                        <i className="material-icons">add_alert</i> 
                                    </div>
                                    <div className='notificacion-number'> 
                                        <div className='sombra-azul-chica centrar m-p-0'>
                                            <span>10</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopMenu;