import React,{useState,useEffect} from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import M from 'materialize-css';
import { useNavigate } from 'react-router-dom';

const New = () => {
    const [usuario,setUsuario] = useState({name:"",email:"",password:"",password_confirmation:"",phone:"",sucursal_id:null,rol:""});
    const [validate,setValidate] = useState({name:true, email:true, password:true,password_confirmation:true,sucursal_id:true,rol:true});
    const [sucursales,setSucursales] = useState([]);
    const [roles,setRoles] = useState([]);
    const navigate = useNavigate();
    
    useEffect (() =>{
        //sucursales get
        axios.get('api/sucursals').then(response=>{
            console.log(response.data)
            setSucursales(response.data)
            M.FormSelect.init(document.querySelector('select'));
        }).catch(e=>console.log("error",e))
        //geting all roles
        axios.get('api/roles').then((res)=>{
            console.log("roles",res.data);
            setRoles(res.data)
            M.FormSelect.init(document.querySelector('#select-rol'));
        }).catch(e=>console.log("error ",e))
    },[]);

    const showPassword = ()=>{
        let tipo = document.querySelector('#password');
        if(tipo.type === "password") tipo.type = 'text';
        else tipo.type = 'password';
    }

    const showPasswordConfirm = ()=>{
        let tipo = document.querySelector('#password_confirmation');
        if(tipo.type === "password") tipo.type = 'text';
        else tipo.type = 'password';
    }

    const _saveUser = (e) =>{
        e.preventDefault();
        console.log("guardando...",usuario)
       
        if(usuario.password !== usuario.password_confirmation)return alert("los password no coinciden");

       axios.post('api/newuser',usuario)
        .then(res=>{
            console.log("nuevo user..",res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>usuario creado exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/usuarios")
            }, 3000);
        }).catch(e=>{
            console.log("error",e)
            if(e.message) M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+e.message+'</span>',classes:"error-notify"})
            if(e.response?.data?.errors){
                let errors = e.response.data.errors;
                setValidate({
                    name:(errors.name)? false:true,
                    email:(errors.email)? false:true,
                    password:(errors.password)? false:true,
                    password_confirmation:(errors.password_confirmation)? false:true,
                    sucursal_id:(errors.sucursal_id)? false:true,
                    rol:(errors.rol)? false:true
                });
            }
        })
        
    }
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                    <h5 className='p-5'>Nuevo usuario</h5>
                    <form onSubmit={_saveUser} className="row ancho100" id='general-form'>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">nombre *</label>
                                <input id="name" type="text" className="validate" value={usuario.name} onChange={(e)=>setUsuario({...usuario,name:e.target.value})}/>
                                {!validate.name &&  <span className="helper-text t-rojo">El nombre es obligatorio.</span>}
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="phone">telefono</label>
                                <input id="phone" type="number" className="validate" value={usuario.phone} onChange={(e)=>setUsuario({...usuario,phone:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="email">Email *</label>
                                <input id="email" type="email" className="validate" value={usuario.email} onChange={(e)=>setUsuario({...usuario,email:e.target.value})}/>
                                {!validate.email &&  <span className="helper-text t-rojo">El email es obligatorio.</span>}
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text" style={{position:'relative'}}>
                                <label htmlFor="password">Password *</label>
                                <input id="password" type="password"  className="validate" value={usuario.password} 
                                autoComplete="off"
                                onChange={(e)=>setUsuario({...usuario,password:e.target.value})}/>
                                <i class="tiny material-icons" style={{position:'absolute', top:25,right:20,color:'#525f7f'}} onClick={()=>showPassword()}>remove_red_eye</i>
                                {!validate.password &&  <span className="helper-text t-rojo">El password es obligatorio.</span>}
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text" style={{position:'relative'}}>
                                <label htmlFor="password_confirmation">Password Confimación *</label>
                                <input id="password_confirmation" type="password"  className="validate" value={usuario.password_confirmation} 
                                autoComplete="off"
                                onChange={(e)=>setUsuario({...usuario,password_confirmation:e.target.value})}/>
                                <i class="tiny material-icons" style={{position:'absolute', top:25,right:20,color:'#525f7f'}} onClick={()=>showPasswordConfirm()}>remove_red_eye</i>
                                {usuario.password_confirmation.length > 4 && usuario.password!==usuario.password_confirmation&& <div className='red-text text-lighten-1' style={{marginTop:'-10px'}}>Los password no coinciden</div>}
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="input-text">
                                <label>Sucursal *</label>
                                <select onChange={(e)=>setUsuario({...usuario,sucursal_id:e.target.value})}>
                                    <option defaultValue="seleccionar" disabled selected>Seleccione una sucursal</option>
                                    {sucursales.map((item,i)=>
                                        <option value={item.id}  key={item.id}>{item.nombre_sucursal}</option>
                                    )}
                                </select>
                                {!validate.sucursal_id &&  <span className="helper-text t-rojo">La sucursal es obligatoria.</span>}
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="input-text">
                            <label htmlFor="puesto">Rol *</label>
                                <select id="select-rol" onChange={(e)=>setUsuario({...usuario,rol:e.target.value})}>
                                    <option defaultValue="seleccionar" disabled selected>Seleccione un rol de usuario</option>
                                    {roles.map((item,i)=>
                                        <option value={item.name}  key={item.id}>{item.name}</option>
                                    )}
                                </select>
                                {!validate.rol &&  <span className="helper-text t-rojo">El rol es obligatorio.</span>}
                            </div>
                        </div>
                        <div className='col s12 m12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default New;