import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import M from 'materialize-css';
import EmpleadoBaja from './EmpleadoBaja';
import { UserContext } from '../../UserContext';
import NoPermissions from '../NoPermissions/NoPermissions';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import Loader from '../../Layout/Loaders/Loader';

const Empleados = () => {
    const {user} = useContext(UserContext);;
    const [allEmpleados,setAllEmpleados] = useState([]);
    const [empleados,setEmpleados] = useState([]);
    const [sucursales,setSucursales] = useState([]);
    const [sortBy, setSortBy] = useState({
        field:"",
        sortBy:"ASC"
    });
    const [loader,setLoader] = useState(true);
    const [searchKey,setSearchKey] = useState("");
    const [tiposEmp,setTiposEmp] = useState([]);
    const [filtros , setFiltros] = useState({
        tipoEmpleado:"",
        sucursal:"",
        status:null,
        date_inicio:null, 
        date_fin:null
    });

    const [permisos,setPermisos] = useState({
        employees_create:null,
        employees_list_read:null,
        employees_read:null,
        employees_update:null,
        employee_cancellations_create:null
    });
        
    const [empleadoToDelete,setEmpleadoToDelete] = useState({id:null,nombre:""})

    useEffect(()=>{
       
       
    },[])

    useEffect(()=>{
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                employees_create:checkPermiso("employees_create",user.permissions),
                employees_list_read:checkPermiso("employees_list_read",user.permissions),
                employees_read:checkPermiso("employees_read",user.permissions),
                employees_update:checkPermiso("employees_update",user.permissions),
                employee_cancellations_create:checkPermiso("employee_cancellations_create",user.permissions)
            })); 
            _getAllEmployees();
            _getTiposEmpleados();
            _getSucursales();
            M.Modal.init(document.querySelector('#filtrosempleados'));
            M.Modal.init(document.querySelector('#baja-empleado-modal'));
        }
    },[user])

    useEffect(()=>{
       if(!loader){
            console.log("sortBy",sortBy);
            //_getEmployeess(); 
            _ordenarTabla();
       }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sortBy])

    const _ordenarTabla = () => {
        //se crea una copia de empleados para que la funcion de sort no modifique el array original
        let ordenados = empleados.slice(0);
        if(sortBy.sortBy === "ASC"){
            ordenados.sort((a,b)=>{
                if (a[sortBy.field] > b[sortBy.field]) {
                    return 1;
                  }
                  if (a[sortBy.field] < b[sortBy.field]) {
                    return -1;
                  }
                  return 0;
            })
        }else{
            ordenados.sort((a,b)=>{
                if (a[sortBy.field] < b[sortBy.field]) {
                    return 1;
                  }
                  if (a[sortBy.field] > b[sortBy.field]) {
                    return -1;
                  }
                  return 0;
            })
        }
        setEmpleados(ordenados)
    }

    const _getAllEmployees = () =>{
        axios.get('api/employees').then((resp)=>{
            console.log("empleados",resp.data);
            setEmpleados(resp.data);
            setAllEmpleados(resp.data);
            setLoader(false);
        }).catch(e=>console.error("Error ",e));
    }

    /*const _getEmployeess = ()=>{
        axios.post('api/employees_with_sorting',sortBy).then((resp)=>{
            setEmpleados(resp.data)
        }).catch(e=>console.error("Error ",e));
    }*/

    const _getTiposEmpleados = () =>{
        axios.get('api/employeetipes').then(res=>{
            setTiposEmp(res.data);
            M.FormSelect.init(document.querySelector('#sucursalselect'));
        }).catch(e=>console.log(e))
    }

    const _getSucursales = () =>{
        axios.get('api/sucursals').then((resp)=>{
            setSucursales(resp.data)
        }).catch(e=>console.error("Error ",e));
    }

    const _handleSort = (field) =>{
        if(field !== sortBy.field){
            setSortBy({...sortBy,field:field,sortBy:"DESC"});
        }else{
            setSortBy({...sortBy,sortBy:sortBy.sortBy === "DESC"?"ASC":"DESC"})
        }
    }

    const handleKeyPress = (event) =>{
        if(event.key === 'Enter'){
            setEmpleados(allEmpleados.filter((empleado)=>{
                let nombreCompleto = empleado.nombre.toLowerCase()+" "+empleado.apellidos.toLowerCase();
                if(nombreCompleto.includes(searchKey.toLowerCase())){
                    return empleado;
                }else return null;
            }))
        }
    } 

    const _searchEmpleado = () =>{
        setEmpleados(allEmpleados.filter((empleado)=>{
            let nombreCompleto = empleado.nombre.toLowerCase()+" "+empleado.apellidos.toLowerCase();
            if(nombreCompleto.includes(searchKey.toLowerCase())){
                return empleado;
            }else return null;
        }))
    }

    const _aplicarFiltros = () => {
      //  console.log("filtros... ",filtros);
        axios.post('api/search_employees_by_filters',filtros).then((resp)=>{
            console.log(resp.data)
            setEmpleados(resp.data)
        }).catch(e=>console.log("Error.",e))
    }
    const _limpiarFiltros = () => {
        console.log("cleaning...")
        setFiltros({
            tipoEmpleado:"",
            sucursal:"",
            status:null,
            date_inicio:null, 
            date_fin:null
        })
        setEmpleados(allEmpleados);
    }
    const onChangeValue = (event) => {
        let status = event.target.value;
        setFiltros({...filtros,status:status});
    }

    const _bajaEmpleado = (empleado) => {
        setEmpleadoToDelete({id:empleado.id,nombre:empleado.nombre + " " + empleado.apellidos});
        let elem = document.querySelector('#baja-empleado-modal');
        let instance = M.Modal.getInstance(elem);
        instance.open();
    }

    const _eliminarEmpleado = (empleado) =>{
        
    }

    return (
        <div>
            {/**MODAL DE BAJA DE EMPLEADOS */}
            <EmpleadoBaja empleado={empleadoToDelete} setEmpleadoToDelete={setEmpleadoToDelete} _getAllEmployees={_getAllEmployees}/>
            
            {/**FILTOS MODAL **/}
            <div id="filtrosempleados" className="modal">
                <div className="modal-content m-p-0 p-10">
                    <div className='close-btn modal-close'>
                        <i className="material-icons">close</i>
                    </div>
                    <div className='row modal-content m-p-0' style={{margin:'0!important'}}>
                        <div className="row col s3 m12 p-5">
                            <div className="col s3 m1 m-p-0">
                                <img className='dentometric' src='/images/filter1.png' height={40} alt='filtros empleados'/>
                            </div>
                            <div className="col s3 m10 m-p-0 t-left"><h5 className='t-left'>Filtros</h5></div>
                        </div>
                        <div className='row col m6'>
                            <div className='col s12 m12 m-t-10'>
                                <label>Tipo</label>
                                <select className='browser-default' value={filtros.tipoEmpleado} onChange={(e)=>setFiltros({...filtros,tipoEmpleado:e.target.value})}>
                                    <option value="" disabled>Filtrar por tipo de empleado</option>
                                    {tiposEmp.map((tipo) =>
                                        <option value={tipo.id}  key={tipo.id}>{tipo.descripcion}</option>
                                    )}
                                </select>
                            </div>
                            <div className='col s12 m12  m-t-10'>
                                <label>Sucursal</label>
                                <select  className='browser-default' value={filtros.sucursal} onChange={(e)=>setFiltros({...filtros,sucursal:e.target.value})}>
                                    <option value="" disabled >Filtrar sucursal</option>
                                    {sucursales.map((sucursal) =>
                                        <option value={sucursal.id}  key={sucursal.id}>{sucursal.nombre_sucursal}</option>
                                    )}
                                </select>
                            </div> 
                        </div>
                        
                        <div className='row col m3'>
                            <div className="col s12 m-t-10 input-text">
                                <label htmlFor="inicio">Desde</label>
                                <input id="inicio" type="date" value={filtros.date_inicio} onChange={(e)=>setFiltros({...filtros,date_inicio:e.target.value})}/>
                            </div>

                            <div className="col s12 m-t-10 input-text">
                                <label htmlFor="fin">Hasta</label>
                                <input id="fin" type="date"  value={filtros.date_fin}  onChange={(e)=>setFiltros({...filtros,date_fin:e.target.value})}/>
                            </div>

                        </div>

                        <div className='row col m3'>
                            <div className='col s12 m-t-10'>
                                <label>Filtar por estatus</label>
                                <div className='' onChange={onChangeValue}>
                                    <p>
                                        <label>
                                            <input name="group1" value="Todos" type="radio" />
                                            <span>Todos</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input name="group1" value="Activo"  type="radio" />
                                            <span>Activo</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input name="group1" value="Inactivo"  type="radio" />
                                            <span>Inactivo</span>
                                        </label>
                                    </p>
                                </div>
                               
                            </div>
                        </div>
                       
                    </div>
               
                    <div className="modal-footer row m-p-0">
                        <div className="col s6 m8"></div>
                        <div className="col s3 m2">
                            <button className="btn-blanco waves-effect waves-light row"
                            style={{padding:'4px 8px!important',}}
                            onClick={(e)=>console.log()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/clear-filters.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0  modal-close' style={{fontSize: '14px', cursor:'pointer' }} onClick={(e)=>_limpiarFiltros()}> &nbsp;Cancelar</div>
                            </button>
                        </div>
                        <div className="col s3 m2">
                            <button className="btn-blanco waves-effect waves-light row modal-close"
                            style={{padding:'4px 8px!important',}}
                            onClick={(e)=>_aplicarFiltros()}
                            >
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter1.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Filtrar</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/** END FILTOS MODAL **/}
            <div className='container-admin-menu '> <Breadcrumb hasButton={permisos.employees_create} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                {loader ? 
                <div className='container-admin z-depth-1 row centrar'>
                   <Loader />
                </div>
                :
                <div className='container-admin z-depth-1 row'>
                    <div className='row col s12 info-badge border-r-10 m-p-0 centrar-h' style={{bordeRadius:'10px!important', padding:'0px!important',}}>
                        <div className="col s12 m6 p-0" id='serchDoctor'>
                            <div className="row custom-search btn-blanco" type="submit" name="action">
                                <input className="col s11 m-p-0" type="text" placeholder='Busqueda por nombre o apellidos' 
                                style={{fontSize:15}}
                                onChange={(e)=>setSearchKey(e.target.value)}
                                onKeyPress={handleKeyPress} 
                                />
                                <i className="col s1 material-icons" onClick={(e)=>_searchEmpleado()}>search</i>
                            </div>
                        </div>
                        <div className="col s3 m6 t-right">
                            <button className="btn-blanco row modal-trigger" data-target="filtrosempleados" type="submit" name="action">
                                <div className='col s2 m2 m-p-0 centrar'>
                                    <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/filter.png' alt='doctor dento metric' style={{width:'20px',}} />
                                </div>    
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> + Filtros</div>
                            </button>
                        </div>
                    </div>
                    <div className='col m12 grey lighten-5 border-r-10' style={{padding:5}}>
                        <div className='font16 negritas p-5'>Resultados: {empleados.length}</div>
                    </div>
                   
                    {permisos.employees_list_read ?
                        <div className='m-p-0'>
                            <div className='m-p-10'>
                                <div className='col table table-doc m-p-0' style={{width:'100%'}}>
                                    <div className='table-header row m-t-20 movil-oculto'>
                                        <div className='col s10 m1 centrar'> ID &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("id")}} >unfold_more</i> </div>
                                        <div className='col s10 m1 centrar'> Codigo &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("codigo")}} >unfold_more</i></div>
                                        <div className='col s12 m2 centrar'> Nombre &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("apellidos")}}>unfold_more</i></div>
                                        <div className='col s12 m2 centrar'> Cargo &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("cargo")}}>unfold_more</i></div>
                                        <div className='col s12 m1 centrar'> Tipo cargo &nbsp;</div>
                                        <div className='col s12 m1 centrar'> Sucursal &nbsp;</div>
                                        <div className='col s12 m1 centrar'> Aplicaco &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("fecha_aplicacion")}}>unfold_more</i></div>
                                        <div className='col s12 m1 centrar'> Baja &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("fecha_baja")}}>unfold_more</i></div>
                                        <div className='col s12 m1 centrar'> Status &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("activo")}}>unfold_more</i> </div>
                                        <div className='col s12 m1'> Acciones</div>
                                    </div>
                                    
                                    <div className='table-container'>
                                    {empleados.map((empleado)=>    
                                        <div className='row' key={empleado.id}>
                                            
                                            
                                            <div className='col s6 m1 centrar row'>
                                                <div className='col s6 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'> ID </div>
                                                </div>
                                                <div className='col s9 m12 t-centrado'>{empleado.id} </div>
                                            </div>
                                            <div className='col s6 m1 centrar'>
                                                <div className='col s6 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'> Codibo </div>
                                                </div>
                                                <div className='col s9 m12 t-centrado'>{empleado.codigo} </div>
                                            </div>
                                            
                                            <div className='col s9 m2 centrar row'>
                                                <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'>Nmbre </div>
                                                </div>
                                                <div className='col s9 m12 t-centrado'>{empleado.apellidos} {empleado.nombre} </div>
                                            </div>
                                            <div className='col s9 m2  centrar row'>
                                                <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'>Cargo </div>
                                                </div>
                                                <div className='col s9 m12 t-centrado'>{empleado.cargo} </div>
                                            </div>
                                            <div className='col s12 m1 row'>
                                                <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'> Detalle </div>
                                                </div>
                                                <div className='col s9 m12 t-centrado'> {empleado.employee_type?.descripcion}</div>
                                            </div>
                                            <div className='col s12 m1 row'>
                                                <div className='col s12 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'> Sucursal </div>
                                                </div>
                                                <div className='col s12 m12 t-centrado'>
                                                    {empleado.sucursal?empleado.sucursal.nombre_sucursal:'Sin asignar'}
                                                </div>
                                            </div>
                                            <div className='col s9 m1  centrar row'>
                                                <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'> Inicio </div>
                                                </div>
                                                <div className='col s9 m12 t-centrado'>{empleado.fecha_aplicacion}</div>
                                            </div>
                                            <div className='col s9 m1  centrar row'>
                                                <div className='col s3 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'> Fin </div>
                                                </div>
                                                <div className='col s9 m12 t-centrado'>
                                                    {empleado.fecha_baja !== null ?
                                                        empleado.fecha_baja
                                                        :
                                                        permisos.employee_cancellations_create && <button className='btn-delete' onClick={(e)=>{_bajaEmpleado(empleado)}}>Dar de Baja</button>
                                                    }
                                                </div>
                                            </div>

                                            <div className='col s9 m1 centrar row p-5'>
                                                <div className='col s3 m4 movil-visible'>
                                                    <div className='table-header-movil'> Status </div>
                                                </div>
                                                <div className='col s9 m12'>
                                                    <div className={`col s4 m12 centrar ${ empleado.activo === 1 ? 'badge-success':'badge-error'}`} 
                                                    style={{height:'28px'}}>
                                                        <span className=''
                                                        style={{}} >{empleado.activo === 1 ? 'Activo':'Inactivo'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className='col s12 m1 movil-oculto row'>
                                                <div className='col s12 m4 movil-visible' style={{border:'solid 1px #fff'}}>
                                                    <div className='table-header-movil'> Estado </div>
                                                </div>
                                                <div className='col s12 row centrado cont-textos'>
                                                    {permisos.employees_update&&
                                                    <Link to={`${empleado.id}`} style={{color:'green'}}
                                                    data-position="top"
                                                    data-tooltip="Editar"
                                                    className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                        <i className="material-icons m-p-0" style={{fontSize:'20px',}}>create</i>
                                                    </Link>
                                                    }
                                                    <Link to={`detail/${empleado.id}`} 
                                                    data-position="top"
                                                    data-tooltip="Ver Detalle"
                                                    className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                        <i className="material-icons m-p-0" 
                                                        style={{fontSize:'20px',}}>content_paste</i>
                                                    </Link>
                                                    {permisos.employee_cancellations_create &&
                                                    <div className='col m6 m-p-0 centrar movil-100' onClick={(e)=>{_eliminarEmpleado(empleado)}}>
                                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} >delete_forever</i>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </div>    
                                    
                                </div>
                            </div>
                        </div>
                        :
                        <NoPermissions />
                    }
                </div>

                }
             
            </div>
        </div>
    );
};

export default Empleados;