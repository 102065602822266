import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from '../../../axios';

const options ={
    opacity:0.8,
    endingTop:'10%'
}

const NewModeloModal = (props) => {
    const {diasSeleccionado,sucursalSeleccionada,setSucursalSeleccionada,dias,sucursales,_getCitas} = props;
    const [hora,setHora] = useState('');
    const [observaciones,setObservaciones] = useState('');

    useEffect(()=>{
        console.log("props",props);
        var elems = document.querySelector('#modal-new-modelo');
        M.Modal.init(elems,options);
    },[])

    const _closeModal = () => {
        let instance = M.Modal.getInstance(document.querySelector('#modal-new-modelo'));
        instance.close();
    }

    const _setNewModelo = () => {
        let data = {
            sucursal_id:sucursalSeleccionada,
            day_id:diasSeleccionado,
            hora:hora,
            observaciones:observaciones
        }

        axios.post('/api/appointment_model',data).then((res)=>{
            console.log("Nuevo ..",res.data);
            if(res.status === 201){
                M.toast({
                    html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Modelo creado exitosamente</span>',
                    classes:"success-notify"
                })
                setTimeout(() => {
                    _getCitas();
                    _closeModal();
                }, 3000);
            }else{
                M.toast({
                    html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Error al crear</span>',
                    classes:"error-notify"
                }) 
            }
           
        }).catch(e=>console.log("Error",e));

    }

    return (
        <div id="modal-new-modelo" className="modal">
            <div className="modal-content">
                <div className='close-btn' onClick={_closeModal}>
                    <i className="material-icons">close</i>
                </div>
                <h5>Nuevo Modelo para el </h5>
                <div className='row' id='general-form'>
                    <div className="col s12">
                        <div className='input-text'>
                            <label htmlFor="suc">Sucursal:</label>
                                <select className='browser-default m-0' 
                                    value={sucursalSeleccionada} 
                                    onChange={(e)=>setSucursalSeleccionada(e.target.value)} 
                                    style={{borderRadius:'5px', margin:'0px!important', padding:'20px!important', border:'solid #dee2e6 1px'}}>
                                        <option value="0" className='m-0' disabled selected>Seleccione la sucursal</option>
                                        {sucursales.map( (sucursal)=>
                                        <option value={sucursal.id} key={sucursal.id} className='m-0'>{sucursal.nombre_sucursal}</option>
                                    )}
                                </select>
                        </div>
                    </div>
                    <div className="col s6">
                        <div className='input-text'>
                            <label htmlFor="dia">Dia:</label>
                            <input id="dia" type="text" value={dias[diasSeleccionado-1]} disabled/>
                        </div>
                    </div>
                    {(diasSeleccionado > 0 && sucursalSeleccionada > 0) ?
                        <>
                            <div className="col s6">
                                <div className='input-text'>
                                    <label htmlFor="hora">Hora:</label>
                                    <input id="hora" type="time" onChange={(e)=>setHora(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col s12">
                                <div className="input-text">
                                    <label htmlFor="observaciones">Observaciones</label>
                                    <textarea id="observaciones" className="materialize-textarea validate"  onChange={(e)=>setObservaciones(e.target.value)}></textarea>
                                </div>
                            </div> 
                        </>
                        :
                        <div className='col s12'>
                            <h6>Selecciona una sucursal para continuar</h6>
                        </div>

                      
                    }
                   
                </div>
            </div>
            <div className="modal-footer">
                <button className="waves-effect waves-green btn-flat">Cancelar</button>
                {(diasSeleccionado > 0 && sucursalSeleccionada > 0) &&
                    <button className="waves-effect waves-green btn-flat" onClick={(e)=>_setNewModelo()}>Guardar</button>
                }
            </div>
        </div>
    );
};

export default NewModeloModal;