import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import M from 'materialize-css';

const EmpleadoDataForm = (props) => {
    const {empleadoId,setStep,empleadoData,setEmpleadoData,permisos} = props;
    const [validate,setValidate] = useState({imss:true, rfc:true, sar:true,sueldo_mensual:true,fecha_nacimiento:true,lugar_nacimiento:true});

    useEffect(()=>{
        if(empleadoId){
            setEmpleadoData({...empleadoData,employee_id:empleadoId});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[empleadoId]);

    const _updateDataEmpleado = (e) =>{
        e.preventDefault();
        console.log(empleadoData)
        let url = '';
        let method = '';
        let accion = '';

        if(empleadoData.id > 0){
            accion = 'Editar';
            url = `api/employees_data/${empleadoData.id}`;
            method = 'put';
        }else{
            accion = 'Guardar';
            url = 'api/employees_data';
            method = 'post';
        }

        if(!permisos.employee_datas_create && accion === 'Guardar'){
            M.toast({
                html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>No tienes permisos para ${accion} los datos laborales.</span>`,
                classes:"error-notify"
            })
            setStep(3);
            return null;
        }

        if(!permisos.employee_datas_update && accion === 'Editar'){
            
            M.toast({
                html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>No tienes permisos para ${accion} los datos laborales.</span>`,
                classes:"error-notify"
            })
            return null;
        }

        axios({
            method:method,
            url:url,
            data:empleadoData
        }).then((response)=>{
            console.log("new employee",response.data);
            setEmpleadoData(response.data);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Datos Guardados Exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                if(response.status === 201) setStep(3);
            }, 3000);
        }).catch(e=>{
            console.log(e);
            if(e.response?.data?.errors){
                let errors = e.response.data.errors;
                setValidate({
                    imss:(errors.imss)? false:true, 
                    rfc:(errors.rfc)? false:true, 
                    sar:(errors.sar)? false:true, 
                    sueldo_mensual:(errors.sueldo_mensual)? false:true,
                    fecha_nacimiento:(errors.fecha_nacimiento)? false:true,
                    lugar_nacimiento:(errors.lugar_nacimiento)? false:true
                })
            }
        });
    }

    return (
        <div className='p-20'>
           <form  onSubmit={_updateDataEmpleado} className="row ancho100" id='general-form'>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="imss">Numero de seguro social (IMSS) *</label>
                        <input id="imss" type="text" value={empleadoData.imss} onChange={(e)=>setEmpleadoData({...empleadoData,imss:e.target.value})}/>
                        {!validate.imss &&  <span className="helper-text t-rojo">El Numero de Seguro Social (IMSS) de empleado es obligatorio y de 10 digitos {empleadoData?.imss?.length} /10</span>}
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="sar">SAR </label>
                        <input id="sar" type="text" value={empleadoData.sar}  onChange={(e)=>setEmpleadoData({...empleadoData,sar:e.target.value})}/>
                        {!validate.sar &&  <span className="helper-text t-rojo">El RFC de empleado es obligatorio</span>}
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="rfc">RFC *</label>
                        <input id="rfc" type="text" value={empleadoData.rfc}  onChange={(e)=>setEmpleadoData({...empleadoData,rfc:e.target.value})}/>
                        {!validate.rfc &&  <span className="helper-text t-rojo">El RFC de empleado es obligatorio</span>}
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="altaimss">Fecha de alta en IMSS</label>
                        <input id="altaimss" type="date" value={empleadoData.fecha_alta_imss} onChange={(e)=>setEmpleadoData({...empleadoData,fecha_alta_imss:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="nacimiento">Fecha de nacimiento *</label>
                        <input id="nacimiento" type="date" value={empleadoData.fecha_nacimiento} onChange={(e)=>setEmpleadoData({...empleadoData,fecha_nacimiento:e.target.value})}/>
                        {!validate.fecha_nacimiento &&  <span className="helper-text t-rojo">La fecha de nacimiento del empleado es obligatoria</span>}
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="nacimientol">Lugar de nacimiento *</label>
                        <input id="nacimientol" type="text" value={empleadoData.lugar_nacimiento}  onChange={(e)=>setEmpleadoData({...empleadoData,lugar_nacimiento:e.target.value})}/>
                        {!validate.fecha_nacimiento &&  <span className="helper-text t-rojo">El Lugar de nacimiento de empleado es obligatorio</span>}
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="padre">Nombre del Padre</label>
                        <input id="padre" type="text" value={empleadoData.nombre_padre}  onChange={(e)=>setEmpleadoData({...empleadoData,nombre_padre:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="madre">Nombre de la Madre</label>
                        <input id="madre" type="text" value={empleadoData.nombre_madre}  onChange={(e)=>setEmpleadoData({...empleadoData,nombre_madre:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m3">
                    <div className="input-text">
                        <label htmlFor="sueldo">Sueldo Mensual*</label>
                        <input id="sueldo" type="number" step="0.01" value={empleadoData.sueldo_mensual}  onChange={(e)=>setEmpleadoData({...empleadoData,sueldo_mensual:e.target.value})}/>
                        {!validate.sueldo_mensual &&  <span className="helper-text t-rojo">El sueldo del empleado es obligatorio</span>}
                    </div>
                </div>
                <div className="col s12">
                    <div className="input-text">
                        <label htmlFor="horario">horario</label>
                        <textarea id="horario" className="materialize-textarea validate" value={empleadoData.horario}
                        onChange={(e)=>setEmpleadoData({...empleadoData,horario:e.target.value})}
                        ></textarea>
                    </div>
                </div>
                <div className='col s12 p-t-35'> 
                    <button className="btn waves-effect waves-light right" type="submit" name="action">{empleadoData.id?'Actualizar':'Siguiente'}
                        <i className="material-icons right">arrow_forward</i>
                    </button>
                </div>
           </form>
            
        </div>
    );
};

export default EmpleadoDataForm;