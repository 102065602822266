import React, { useEffect, useState } from 'react';
import ProductRow from '../ProductRow';
import axios from '../../../axios';
import M from 'materialize-css';

const ProductosTab = (props) => {
    const {order,orderDetails,total,setTotal,setUpdated} = props;
    const [productos,setProductos] = useState([]);
    const [productosSelect,setProductosSelect] = useState([]);
    const [selectedProducto,setSelectedProducto] = useState(props.producto);
    const [catSelected,setCatSelected] = useState(null);
   
    useEffect(()=>{
        _getProductos();
        _formatProductos();
    },[]);

    useEffect(()=>{
        //calcular total
        let reduce = productos.reduce((acumulador, producto) => acumulador + (parseFloat((producto.precio_unidad*producto.cantidad)-producto.precio_descuento)), 0);
        //aplicamos descuento
       setTotal(reduce.toFixed(2));
    },[productos])

    const _formatProductos = () =>{
      let tempProduct = orderDetails.map((item)=>{
        let newProd = {
            id:item.product_id,
            nombre_producto:item.product.nombre_producto,
            precio_unidad:item.precio_unidad,
            cantidad:item.cantidad,
            descuento:item.descuento,
            precio_descuento:0,
            dias_entrega:item.product.dias_entrega
        };
        return newProd;
      });
      setProductos(tempProduct);
    }

    const _getProductos = () =>{
        axios.get('api/products').then((resp)=>{
            setProductosSelect(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

    const _addProducto = () =>{
        setProductos(productos => [...productos,{id:null,order_id:order.orderId,nombre_producto:"",precio_unidad:0,cantidad:0,descuento:0,precio_descuento:0,dias_entrega:0}] );
    }

    const updateOrder = () =>{
        console.log("update...",productos);
        if(productos.find(item=>item.id===null)){
            alert("agrega un producto")
        }else{
            buildOrderDetails();
        }
    }

    const buildOrderDetails=()=>{
        if(productos.length > 0){
            let detallesTemporal = productos.map(producto=>{
                let index = orderDetails.findIndex((item)=> item.product_id == producto.id);
                if(index !== -1){
                    return {
                        id:orderDetails[index].id,
                        order_id:order.id,
                        product_id:producto.id,
                        precio_unidad:producto.precio_unidad,
                        cantidad:producto.cantidad,
                        descuento:producto.descuento
                    }
                }else{
                    return {
                        order_id:order.id,
                        product_id:producto.id,
                        precio_unidad:producto.precio_unidad,
                        cantidad:producto.cantidad,
                        descuento:producto.descuento
                    }
                }
            });
            console.log("detalles",detallesTemporal);
            updateOrderDetails(detallesTemporal);
        }  
    }

      const updateOrderDetails = (detallesTemporal) =>{
        if(detallesTemporal.length > 0){
            let message = "Pedido actualizado correctamente";
            axios.post('api/save-order-details',detallesTemporal).then(resp=>{
                console.log("actualizado",resp.data);
                setUpdated(true)
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+message+'</span>',classes:"success-notify"});
            }).catch(e=>console.log("Error",e));
        } 
    } 

    return (
        <>
            <div className='row'>
                <div className='col s12 p-20'>
                    <div className='row'>
                        <div className='col s12 row titles-badge centrado'>
                            <div className='col s10 p-10' style={{fontSize:16}}>Productos</div>
                            <div className='col s2'>
                                <button className="btn-floating pulse cyan right" onClick={()=>_addProducto()}>
                                    <i className="material-icons">add</i>
                                </button>
                            </div>
                        </div>
                        
                        <div className='row col s12 m-t-10'>
                            <div className='col s1 centrar'>ID</div>
                            <div className='col s4 centrar'>Productos</div>
                            <div className='col s2 centrar'>$ Unitario</div>
                            <div className='col s1 centrar'>Cantidad</div>
                            <div className='col s1 centrar'>%</div>
                            <div className='col s1 centrar'>% al $</div>
                            <div className='col s1 centrar'>Dias</div>
                            <div className='col s1 centrar'></div>
                        </div>

                        <div className='row'>
                        {productos.map((item,index)=>
                            <ProductRow
                                productosSelect={productosSelect} 
                                producto={item} 
                                productos={productos} 
                                setProductos={setProductos} 
                                indice={index} 
                                catSelected={catSelected} 
                                orderId={order.id}
                            />
                        )}
                        </div>

                        <div className='row'>
                            <div className='col s12 centrar-r'>
                                <div className='negritas '>Subtotal:</div>
                                <div style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:40,padding:5,width:"15%",marginLeft:15}}>
                                {total}  
                                </div> 
                            </div>
                        </div>
                        
                        <div className='row'>
                            <div className='col s12 t-right'>
                                <button className='btn-info' onClick={(e)=>updateOrder()}>Actualizar Pedido</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    );
};

export default ProductosTab;