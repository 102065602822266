import React from 'react';
import M from 'materialize-css';
import Dropdown from './Dropdown';
import axios from '../../axios';
import { Link } from 'react-router-dom';
import { render } from 'preact/compat';

const DoctorCard = (props) => {
    const {doctores,permisos} = props;
    console.log("doctores", doctores);

    const _delete = (doctorId) =>{
        if(window.confirm("Realmente deseas borrar a este doctor, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/doctors/${doctorId}`)
            .then(res=>{
                
            }).catch(e=>console.log("error",e))
        }
    }
    
    
    return (
        <div className='m-p-0 movil-oculto'>
            
            <div className='cont-contain'>
                <div className="container-fluid m-p-0">
                    <div className="row m-p-0 cont-row">
                    {doctores.map((doctor)=>    
                        <div className="col m5ths s6 cont-box" key={doctor.id}>
                            <div className='cont-contain'  style={{border:''}}>
                                <div className='row m-0 p-0-10'>
                                    <div className='col s3 m1 m-0 p-10-0'> 
                                        <div className='col s3 m-p-0'>
                                            <span className='m-p-0' style={{fontSize:'11px'}}>ID:</span>
                                            <span className='m-p-0' style={{fontSize:'13px',fontWeight:'700'}}>{doctor.id}</span>
                                        </div>
                                    </div>
                                    <div className='col s3 m10 m-p-0 centrar'>
                                        <div className='centrar m-0 p-5' style={{paddingBottom:'0!important'}}>
                                            <div className='contain-icon sombra-azul-chica'>
                                                <div className='m-p-0 centrar'>
                                                    <img className='docFoto' src='/images/doc.png' alt='doctor dento metric' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col s3 m1 m-p-0'> 
                                        <Dropdown  doctorID={doctor.id} permisos={permisos}></Dropdown>
                                    </div>
                                </div>
                                <div className='row m-p-0'>
                                    <div className='details m-p-0' style={{padding:'0px!important',}}>
                                        <div className='contName title m-p-0'>
                                            <span className='sut-title'>Nombre:<br></br></span>
                                            <span style={{color:'#000000', fontWeight:'500', fontSize:'14px'}}>{doctor.nombre} {doctor.apellidos}</span>
                                        </div>
                                        <div className='m-p-0 centrado'>
                                            
                                            <div className='col s4 m4 m-p-0' style={{height:'40px'}}>
                                                <span className='sut-title'>Cel:<br></br></span>
                                                <Link className="m-p-0" to="https://api.whatsapp.com/send?phone='{doctor.celular}'=Hola, Nececito mas informacion!"  target="blank">
                                                    <i className="material-icons m-p-0" style={{color:'#000000', fontSize:'20px', paddingTop:'0!important'}}>tablet_android</i>
                                                </Link>
                                            </div>
                                            <div className='col s4 m4 m-p-0' style={{height:'40px'}}>
                                                <span className='sut-title'>Correo:<br></br></span>
                                                <a href="mailto:`{doctor.email}`?subject={subject}&body={body}"  className='m-p-0'>
                                                    <i className="material-icons m-0 " style={{color:'#000000', fontSize:'20px', paddingTop:'0!important'}}>local_post_office</i>
                                                </a>
                                            </div>
                                            <div className='col s4 m4 m-p-0' style={{height:'40px'}}>
                                                <span className='sut-title'>Tel:<br></br></span>
                                                <Link className="m-p-0" to="tel:+'{doctor.telefono}'">
                                                    <i className="material-icons m-p-0" style={{color:'#000000', fontSize:'20px', paddingTop:'0!important'}}>local_phone</i>
                                                </Link>
                                            </div>
                                            <div className='col s3 m4 m-p-0' style={{height:'40px'}}>
                                                <span className='sut-title'>Inst.:<br></br></span>
                                                <ul id={"institucion"+doctor.id} className="contain-drop dropdown-content">
                                                {doctor.institutions.map((insti)=>    
                                                    <li className='m-0 p-5 li-list'>
                                                    <i className="material-icons m-p-0" style={{color:'blue', fontSize:'12px', paddingTop:'0!important'}}>business</i>
                                                    &nbsp;&nbsp; {insti.institucion}</li>
                                                )}  
                                                </ul>
                                                <button className="dropdown-trigger m-p-0" data-target={"institucion"+doctor.id}
                                                style={{background:'none',border:'solid transparent',textAlign:'center'}}>
                                                    <i className="material-icons m-p-0" style={{color:'#000000', fontSize:'20px', paddingTop:'0!important',width:'50%'}}>businesss</i>
                                                    <span style={{background:'#e8fafd',border:'solid 1px #11cdef', color:'blue', padding:'2px 4px', borderRadius:'100%', fontWeight:600, fontSize:'10px',position:'absolute'}}>{doctor.institutions.length} <br></br></span>
                                                </button>
                                            </div>
                                            <div className='col s3 m4 m-p-0' id={'idul'} style={{height:'40px'}}>
                                                <ul id={"especialidad"+doctor.id} className="m-p-0 contain-drop dropdown-content" style={{width:'46vw!important'}}>
                                                {doctor.specialties.map((esp)=>    
                                                    <li className='m-0 p-5 li-list'>
                                                        <i className="material-icons m-p-0" style={{color:'blue', fontSize:'12px', paddingTop:'0!important'}}>loyalty</i> 
                                                        &nbsp;&nbsp; {esp.especialidad}
                                                    </li>
                                                )}
                                                </ul>
                                                <span className='sut-title'>Esp.:<br></br></span>
                                                <button className="dropdown-trigger m-p-0" data-target={"especialidad"+doctor.id}
                                                style={{background:'none',border:'solid transparent',textAlign:'center'}}>
                                                    <i className="material-icons m-p-0" style={{color:'#000000', fontSize:'16px', paddingTop:'0!important',width:'80%'}}>loyalty</i>
                                                    <span style={{background:'#e8fafd',border:'solid 1px #11cdef', color:'blue', padding:'2px 4px', borderRadius:'100%', fontWeight:600, fontSize:'10px',position:'absolute'}}>
                                                        {doctor.specialties.length}
                                                    </span>
                                                </button>
                                                
                                            </div>
                                            <div className='col s3 m4 m-p-0' style={{height:'40px'}}>
                                                <span className='sut-title'>Consult.:<br></br></span>
                                                <ul id={"consultorio"+doctor.id} className="contain-drop dropdown-content">
                                                {doctor.doctor_offices && doctor.doctor_offices.map((ofice)=>    
                                                    <li className='m-0 p-5 li-list'>
                                                        <i className="material-icons m-p-0" style={{color:'blue', fontSize:'12px', paddingTop:'0!important'}}>home</i>
                                                    &nbsp;&nbsp; {ofice.nombre}</li>
                                                )}  
                                                </ul>
                                                <button className="dropdown-trigger m-p-0" data-target={"consultorio"+doctor.id}
                                                style={{background:'none',border:'solid transparent',textAlign:'center',}}>
                                                    <i className="material-icons m-p-0" style={{color:'#000000', fontSize:'20px', paddingTop:'0!important',width:'80%'}}>home</i>
                                                    <span style={{background:'#e8fafd',border:'solid 1px #11cdef', color:'blue', padding:'2px 4px', borderRadius:'100%', fontWeight:600, fontSize:'10px',position:'absolute'}}>
                                                        {doctor.doctor_offices ? doctor.doctor_offices.length : 0} <br></br></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-10 centrar'>
                                        <Link to={`${doctor.id}`} style={{color:'green'}}
                                            data-position="top"
                                            data-tooltip="Editar" 
                                            className="btn-blanco waves-effect waves-light" 
                                            type="submit" name="action">Visitar</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}   
                    </div>
                </div>
            </div>
        </div>
   
    );
};

export default DoctorCard;