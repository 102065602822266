import axios from '../../axios';
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import M from 'materialize-css';

const causasVisita = ['Normal','Solicito','Queja','Papeleria','Otro'];

const Edit = () => {
    const  {visitaId} = useParams();
    const navigate = useNavigate();
    const [visita,setVisita] = useState({doctor_id:null,fecha_visita:'',hora:'',causa_visita:'',observaciones:'',representante:''});
    const [empleados,setEmpleados] = useState([]);

    useEffect(()=>{
        _getEmpleados();
        if(visitaId > 0){
            axios.get(`api/visits/${visitaId}`).then((resp)=>{
                console.log("visita detalle..",resp.data)
                setVisita(resp.data);
            }).catch(e=>console.error(e))
        }
    },[visitaId])


    const _getEmpleados = () =>{
        axios.get('api/employees').then((resp)=>{
            setEmpleados(resp.data);
        }).catch(e=>console.log("Error:",e))
    }

    const _updateVisita = () =>{
        axios.put(`api/visits/${visitaId}`,visita).then((resp)=>{
            console.log("visita..",resp.data)
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i>&nbsp;&nbsp;<span>Visita actualizada exitosamente</span>',
                classes:"success-notify"
            })
           setTimeout(() => {
                navigate("/admin/visitas");
            }, 3000);
        }).catch(e=>console.error(e));
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                    <div className="row cont-contain "  id='general-form' style={{background:'#e8f0fe', borderRadius:'12px'}}>
                        <div className='col s12'>
                            <h5 className='p-5'>Visitar a doct@r: {visita.doctor&&visita.doctor.nombre+" "+visita.doctor.apellidos}</h5>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">Fecha</label>
                                <input id="name" type="date"
                                value={visita.fecha_visita}
                                onChange={(e)=>setVisita({...visita,fecha_visita:e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="hora">Hora</label>
                                <input id="hora" type="time"  
                                value={visita.hora}
                                onChange={(e)=>setVisita({...visita,hora:e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">Representante</label>
                                <select className='browser-default'  value={visita.representante} onChange={(e)=>setVisita({...visita,representante:e.target.value})}  style={{background:'#FFF!important'}} >
                                    <option value="">Representante</option>
                                    {empleados.map((empleado,i)=>
                                        <option value={empleado.nombre + " " + empleado.apellidos} key={i}>{empleado.nombre} {empleado.apellidos}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label>Causa de visita</label>
                                <select className='browser-default'  value={visita.causa_visita} onChange={(e)=>setVisita({...visita,causa_visita:e.target.value})}  style={{background:'#FFF!important'}} >
                                    <option value="">Causa de la visita</option>
                                    {causasVisita.map((causa,i)=>
                                        <option value={causa} key={i}>{causa}</option>
                                    )}
                                </select>
                            
                            </div>
                        </div>
                    
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="observa4">Observaciones de la visita</label>
                                <textarea id="observa4" 
                                    style={{background:'#ffffff',paddingLeft:10}} 
                                className="materialize-textarea validate"
                                value={visita.observaciones}
                                onChange={(e)=>setVisita({...visita,observaciones:e.target.value})}></textarea>
                            </div>
                        </div>

                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="lugarvisita">Lugar de la visita</label>
                                <textarea id="lugarvisita" 
                                style={{paddingLeft:10,cursor:'not-allowed'}} 
                                className="materialize-textarea disabled"
                                value={visita.direccion}></textarea>
                            </div>
                        </div>

                        <div className='col s12'>
                            <button className="btn-blanco waves-effect waves-light right" style={{padding:'4px 8px!important',margin:10}}  onClick={(e)=>{_updateVisita()}}>
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Actualizar</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;