import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import '../Empleados/empleados.scss';
import EmpleadoAddress from './EmpleadoAddress';
import EmpleadoDataForm from './EmpleadoDataForm';
import EmpleadoForm from './EmpleadoForm';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import Loader from '../../Layout/Loaders/Loader';

const New = () => {
    const {user} = useContext(UserContext);
    const [step,setStep] = useState(1);
    const navigate = useNavigate();
    const [loaded,setLoaded] = useState(false);
    const [empleado,setEmpleado] = useState({
            employee_type_id: null,
            sucursal_id: null,
            jefe_id: null,
            codigo: "",
            nombre: "",
            apellidos: "",
            iniciales: "",
            celular: "",
            telefono: "",
            email: "",
            cargo: "",
            notas: "",
            jefe:"",
            fecha_aplicacion:""
        });  
    const [empleadoData,setEmpleadoData] = useState({employee_id:null,imss:'',sar:'',rfc:'',sueldo_mensual:null,fecha_nacimiento:null,fecha_alta_imss:null,
        lugar_nacimiento:'',nombre_padre:'',nombre_madre:'',horario:''});

    const [empleadoAddress,setEmpleadoAddress] = useState({employee_id:null,country_id:null,state_id:null,municipality_id:null,suburb_id:null,nombre:'',
        direccion:'',entre_calles:'',pais:'',estado:'',municipio:'',colonia:'',cp:'',telefono:''}); 
    
    const [permisos,setPermisos] = useState({
        employees_create:null,
        employee_addresses_create:null,
        employee_datas_create:null
    })

    useEffect(()=>{
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                employees_create:checkPermiso("employees_create",user.permissions),
                employee_addresses_create:checkPermiso("employee_addresses_create",user.permissions),
                employee_datas_create:checkPermiso("employee_datas_create",user.permissions)
            }));
        }
    },[user]);

    useEffect(()=>{
        (permisos.employees_create !== null) && setLoaded(true);
    },[permisos])

    const _redirect = ()=>{
        setTimeout(() => {
            navigate("/admin/empleados")
        }, 3000);
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1 row'>
                 
                    {loaded ?
                    <>
                        <section className='container-steps-empleados'>
                            <div className='row m-p-0'>
                                    <div className="col s12 m1"> </div>
                                    <div id="progressList-1" className="col s12 m1 step-container">
                                        <div>
                                            <div className='step-numero'>
                                                <i className={`material-icons ${'i-activo'}`}> adjust </i>
                                            </div>
                                            Paso 1
                                        </div>
                                    </div>
                                    <div className="col s12 m2 p-0-24"> <hr className={`${(step>=2)?'line-blue':''}`}></hr> </div>
                                    <div id="progressList-2" className="col s12 m1 step-container">
                                        <div>
                                            <div className='step-numero'>
                                                <i className={`material-icons ${(step>=2)?'i-activo':''}`}> av_timer </i>
                                            </div>
                                            Paso 2
                                        </div>
                                    </div>
                                    <div className="col s12 m2 p-0-24"> <hr className={`${(step>=3)?'line-blue':''}`}></hr> </div>
                                    <div id="progressList-3" className="col s12 m1 step-container">
                                        <div>
                                            <div className='step-numero'>
                                                <i className={`material-icons ${(step>=3)?'i-activo':''}`}> blur_circular </i>
                                            </div>
                                            Paso 3
                                        </div>
                                    </div>
                                    <div className="col s12 m2 p-0-24"> <hr className={`${(step>=4)?'line-blue':''}`}></hr> </div>
                                    <div id="progressList-3" className="col s12 m1 step-container">
                                        <div>
                                            <div className='step-numero'>
                                                <i className={`material-icons ${(step===4)?'i-completo':''}`}> check </i>
                                            </div>
                                            Completado
                                        </div>
                                    </div>
                                    <div className="col s12 m1"> </div>
                                
                            </div>
                        </section>
                        <div className="m-t-20">
                            {step===1 && <EmpleadoForm empleado={empleado} setEmpleado={setEmpleado} setStep={setStep} permisos={permisos}/>} 
                            {step===2 && 
                                <EmpleadoDataForm 
                                   empleadoId={empleado.id} 
                                    setStep={setStep} 
                                    empleadoData={empleadoData} 
                                    setEmpleadoData={setEmpleadoData} 
                                    permisos={permisos}
                                    />
                            } 
                            {step===3 && 
                                <EmpleadoAddress 
                                empleadoId={empleado.id} 
                                empleadoAddress={empleadoAddress } 
                                setEmpleadoAddress= {setEmpleadoAddress} 
                                setStep={setStep}
                                permisos={permisos}
                                /> 
                            }
                            {step===4 &&
                                <div className='container p-30'>
                                    {_redirect()}
                                    <div className="card-panel teal succes-gradient ">
                                        <div className='centrado'>
                                            <img src='/images/comprobar.png' style={{height:80}} alt='doctor dento metric' />
                                        </div>
                                        <div className='centrado white-text p-10'>
                                            <span className="font18 ">Nuevo empleado registrado exitosamente</span>
                                            <p>En un momento sera redigido automaticamente...</p>
                                        </div>
                                        <div className='centrado'>
                                            <button className='btn' onClick={()=> navigate("/admin/empleados")}>Salir</button>
                                        </div>
                                    </div>
                                </div>
                            }      
                        </div>
                    </>
                    :
                    <Loader />
                    }
                </div>
            </div>
            
        </div>
    );
};

export default New;