import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './UserContext';

const PrivateRoutes = () => {
    //console.log("la ruta privada")
    const {user,auth} = useContext(UserContext);
    return auth ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;