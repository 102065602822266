import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import axios from '../../../axios';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import Loader from '../../../Layout/Loaders/Loader';
import NoPermissions from '../../NoPermissions/NoPermissions';
import { Link } from 'react-router-dom';

const Clientes = () => {
    const [clientes, setClientes] = useState([]);
    const [clientesRaw,setClientesRaw]= useState([]);
    const [filtroFecha,setFiltroFecha] = useState({fechaInicio:'',fechaFin:''})

    const {user} = useContext(UserContext);
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        patient_create:null,
        patient_read:null,
        patient_update:null,
        patient_delete:null
    });
    useEffect(()=>{
        console.log('user', user)
        if(user.id){
            getClientes();
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                patient_create:checkPermiso("patient_create",user.permissions),
                patient_read:checkPermiso("patient_read",user.permissions),
                patient_update:checkPermiso("patient_update",user.permissions),
                patient_delete:checkPermiso("patient_delete",user.permissions)
            }));
        }
    },[user]);

    useEffect(()=>{
        (permisos.patient_read !==null) && setLoaded(true);
    },[permisos])

    const getClientes = () =>{
        axios.get('api/patients')
        .then((res)=>{
            setClientes(res.data)
        }).catch(e=>console.log(e))
    }
    

    useEffect(() => {      
        var elems = document.querySelector('#modal-fechas');
        M.Modal.init(elems);

        axios.get('api/patients')
        .then( res=>{
            console.log( 'todos los clientes', res.data)
            setClientes(res.data);
            setClientesRaw(res.data)
        }).catch(e =>{console.log(e, 'Error todos los clientes')})
    },[])

    const _delete = (clienteId) =>{
        if(window.confirm("Realmente deseas borrar a esta Paciente, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/patients/${clienteId}`)
            .then(res=>{
                M.toast({html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setClientes(clientes.filter(u=>u.id!==res.data.cliente.id))
            }).catch(e=>console.log("error",e))
        }
    }

    const searchByName = (event) =>{
        if(event.key === 'Enter'){
            let searchString = event.target.value.toLowerCase();
            console.log("searching.....",searchString)
            if(searchString.length > 0){
                let filtrados = clientesRaw.filter((item)=>{
                    let tempNombre = item.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                    if(tempNombre.includes(searchString)) return item;
                    return null;
                });
                setClientes(filtrados);
            }else setClientes(clientesRaw)
        }
    }

    const searchByDate = () =>{
        console.log("fechas.",filtroFecha);
        if(filtroFecha.fechaInicio !== '' && filtroFecha.fechaFin !== ''){
            let fInicio = new Date(filtroFecha.fechaInicio).getTime();
            let fFin = new Date(filtroFecha.fechaFin).getTime();
            let tempArray = clientesRaw.filter((item)=>{
                let fNacimiento = new Date(item.fecha_nacimiento).getTime();
                if(fNacimiento >= fInicio && fNacimiento <= fFin) return item;
                else return null;
            });
            setClientes(tempArray);
            _closeModal();  
        }

    }

    const _cleanModalValues = () =>{
        setFiltroFecha({fechaInicio:'',fechaFin:''})
    }

    const _closeModal = () =>{
        let instance = M.Modal.getInstance(document.querySelector('#modal-fechas'));
        instance.close();
         _cleanModalValues();
    }

    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb hasButton={permisos.patient_create} hasButton={true} hasFilters={false} /> </div>
            <div className='container-admin-container'>

                <div id="modal-fechas" className="modal">
                   
                    <div className="modal-content">
                        <h5>Buscar por fecha</h5>
                       <div className='row p-10 m-5'>
                            <div className='col s6'>
                                <label>Fecha de inicio</label>
                                <input className="" type="date" value={filtroFecha.fechaInicio} onChange={(e)=>setFiltroFecha({...filtroFecha,fechaInicio:e.target.value})}/>
                            </div>
                            <div className='col s6'>
                                <label>Fecha final</label>
                                <input className="" type="date" value={filtroFecha.fechaFin} onChange={(e)=>setFiltroFecha({...filtroFecha,fechaFin:e.target.value})} />
                            </div>

                       </div>
                    </div>
                    <div className="modal-footer">
                        <button className='btn-delete modal-close'>Cancelar</button>
                        <button className='btn-info' onClick={(e)=>searchByDate()}>Aplicar Filtro</button>
                    </div>
                </div>

                <div className='container-admin z-depth-1'>
                {loaded ?
                    permisos.patient_read?
                    <>
                    
                        <div className='row col s12 m-p-0 info-badge border-r-10 m-p-0'>
                            <div className='col s12 m1 m-p-0' 
                            style={{height:'32px', width:'34px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                                <img className='dentometric m-p-0' src='/images/pacientes.png' alt='dento metric' 
                                style={{height:'100%', width:'100%'}} />
                            </div>
                            <div className="col s12 m1 row m-0 p-5">Nombres:</div>
                            <div className="col s12 m4 row m-p-0">
                                <div className="col s12 m12 m-p-0" id='serchDoctor'>
                                    <div className="btn-blanco waves-effect waves-light row m-0" type="submit" name="action"
                                    style={{padding:'4px 12px',width:'100%'}}>
                                        <input className="col s11 m11 m-p-0" type="text" 
                                        placeholder='Buscar por: Nombres | Apellidos'
                                        style={{fontSize:'12px',}}
                                        onKeyDown={(e)=>searchByName(e)}
                                        />
                                        <i className="col s1 m1 material-icons right">search</i>
                                    </div>
                                </div>
                            </div>  
                            <div className='col s12 m1 centrado'>
                                <img className='dentometric m-p-0' src='/images/calendar2.png' alt='dento metric' 
                                style={{height:'32px', width:'34px'}} />
                            </div> 
                            <div className="col s12 m2  m-0 p-5">F. de nacimiento:</div> 
                        
                        <div className='col m3'>
                                <button className='btn-info width-full modal-trigger' data-target="modal-fechas"><span className='negritas'>Buscar..</span></button>
                        </div>
                            
                        
                        </div>

                        <div className='table'>
                            <div className='table-header row m-p-0 movil-oculto'>
                                <div className='col s12 m1 row'>
                                    <span className='p-5'>ID</span>
                                </div>
                                <div className='col s12 m3'> Nombres</div>
                                <div className='col s12 m1'> # Pedidos</div>
                                <div className='col s12 m1'> C.P</div>
                                <div className='col s10 m1'> Ciudad </div>
                                <div className='col s10 m2'> Fecha Nacimiento </div>
                                <div className='col s12 m1'> Teléfono </div>
                                <div className='col s12 m1'> Celular </div>     
                                <div className='col s12 m1 centar'> Acciones </div>                            
                            </div>  
                        
                            {clientes.map( (cliente)=>
                            <div className='table-container row'  key={cliente.id}>
                                <div className='col s12 m1 row m-p-0'>
                                    <div className='col s10 m3 m-p-0 centrar movil-100'>
                                                <div className='m-p-0' 
                                                style={{border:'solid 1px #ffffff',height:'30px', width:'32px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                                                    <img className='dentometric m-p-0' src='/images/paciente.png' alt='dento metric' 
                                                    style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                                </div>
                                            </div>
                                    <div className='col s10 m9'> {cliente.id} </div>
                                </div>
                                <div className='col s12 m3'> {cliente.nombre} </div>
                                <div className='col s12 m1'> {cliente?.orders?.length}</div>
                                <div className='col s12 m1'> {cliente.cp}</div>
                                <div className='col s10 m1'> {cliente.ciudad} </div>
                                <div className='col s10 m2'> {cliente.fecha_nacimiento} </div>
                                <div className='col s12 m1'> {cliente.telefono} </div>  
                                <div className='col s12 m1'> {cliente.celular} </div>
                                <div className='col s12 m1 row m-p-0'>
                                {permisos.patient_update &&
                                    <Link to={`${cliente.id}`} style={{color:'green'}}
                                    data-tooltip="Editar"
                                    className="col s12 m6 m-0 p-0-2 centrar movil-100 tooltipped" >
                                        <i className="material-icons m-p-0 t-verde" 
                                        style={{color:'green!important', cursor:'pointer',fontSize:'20px',}}>create</i>
                                    </Link>
                                }
                                    <div
                                    data-tooltip="Borrar"
                                    className="col s12 m6 m-0 p-0-2 centrar movil-100 tooltipped" 
                                    onClick={(e)=>_delete(cliente.id)}>
                                    {permisos.patient_delete &&
                                        <i className="material-icons m-p-0 t-rojo" 
                                        style={{color:'red!important',cursor:'pointer', fontSize:'20px',}}>delete_forever</i>
                                    }
                                    </div>
                                </div>
                            </div>
                            )}
                                                
                        </div>
                    </>
                    : <NoPermissions />
                :
                <div className='height-200 centrar'>
                    <Loader />
                </div>
                }
                </div>
            </div>
        </>
    );
};

export default Clientes;