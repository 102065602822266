import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from '../../axios';

const options ={
    opacity:0.8,
    endingTop:'10%',
    dismissible:false
}

const EditCitaModal = (props) => {
    const {citaId,citas,setCitas,setCitaSeleccionada,appointments_clean} = props;
    const [cita,setCita] = useState({id:0,paciente:"",telefono:"",observaciones:"",doctor:"",products:[],excedido:0});
    const [productos,setProductos] = useState([]);
    const [selectEstudios,setSelectEstudios] = useState();
    const [excedente,setExcedente] = useState(0);
    const [citaProducts,setCitaProducts] = useState([]);
    
   
    useEffect(()=>{
        var elems = document.querySelector('#modal-edit-cita');
        M.Modal.init(elems,options);
        console.log("citas modal",props);
        axios.get(`api/appointments/${citaId}`).then(resp=>{
            setCita(resp.data);
            if(resp.data.products){
                let tempProds = resp.data.products.map(p=>p.id);
                setCitaProducts(tempProds);
            }
            if(resp.data.excedido>0)setExcedente(resp.data.excedido);
            getProductos();
        }).catch(e=>console.log("Error: ",e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[citaId]);
      
    const getProductos = () =>{
        axios.get('api/products').then((resp)=>{
            setProductos(resp.data);
            setSelectEstudios(M.FormSelect.init(document.querySelector('#estudios-select')));
        })
    }

    const _closeModal = () => {
        setCita({id:null,paciente:"",telefono:"",observaciones:"",doctor:"",products:[]})
        setCitaSeleccionada(null);
        setExcedente(0);
        setCitaProducts([]);
        let instance = M.Modal.getInstance(document.querySelector('#modal-edit-cita'));
        instance.close();
        //instance.destroy();
    }

    const _updateCita = () =>{
       // console.log(cita)
        axios.put(`api/appointments/${cita.id}`,cita).then(resp=>{
            setCita(resp.data);
            if(resp.status === 200){
                M.toast({
                    html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Cita actualizada correctamente</span>`,
                    classes:"success-notify"
                })
                setTimeout(() => {
                    setCitas(citas.map(c=>(c.id===resp.data.id)?resp.data:c));
                    setCitaSeleccionada(null)
                    _closeModal();
                }, 2000);
           }
        }).catch(e=>console.log("Error: ",e))
    }

    const _cleanCita = () =>{
        if(window.confirm("Estas seguro de limpiar esta cita?")){
           
            axios.put(`api/appointments/${cita.id}`,{paciente:"",fecha:cita.fecha,hora:cita.hora,telefono:"",observaciones:"",sucursal_id:cita.sucursal_id,doctor:"",products:[],excedido:0}).then(resp=>{
                setCita(resp.data);
                if(resp.status === 200){
                    M.toast({
                        html:`<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Cita actualizada correctamente</span>`,
                        classes:"success-notify"
                    })
                    setTimeout(() => {
                        setCitas(citas.map(c=>(c.id===resp.data.id)?resp.data:c));
                        setCitaSeleccionada(null)
                        _closeModal();
                    }, 2000);
               }
            }).catch(e=>console.log("Error: ",e))
        }
    }

    const _handleEstudiosIds = () =>{
        let temporalIDs = selectEstudios.getSelectedValues();
       // setCita({...cita,products:temporalIDs});
       _handleEstudio(temporalIDs);
    }

    const _handleEstudio = (estudiosIds) =>{
        console.log(estudiosIds)
        if(estudiosIds.length > 0){
            let estudioTemp = [];
           
            //buscamos los estudios y almancenamos en un array temporal
            estudiosIds.forEach(elem => {
                estudioTemp.push(productos.find(p=>p.id == elem));
            }); 
            // buscamos las citas proximas 
             //console.log(estudioTemp)
             let tempCitas = citas.filter((f)=>f.fecha===cita.fecha);
             //console.log(tempCitas)
             let citaCurrentTime = new Date(cita.fecha +":"+ cita.hora);
             let citaActualIndex = tempCitas.findIndex(t=>t.id===parseInt(cita.id));
             // console.log("INDICE",citaActualIndex);
             if(tempCitas.length > 0 && citaActualIndex+1 < tempCitas.length ){
                 let nextCitaTime = new Date(tempCitas[citaActualIndex+1].fecha +":"+ tempCitas[citaActualIndex+1].hora);
                 let diffMs = (nextCitaTime - citaCurrentTime); // milliseconds
                 let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
                 let duracion_acumulada = 0;
                 estudioTemp.map((item)=>duracion_acumulada += item.duracion_aproximada);
                 //console.log(duracion_acumulada)
                 let excedenteMins = duracion_acumulada - diffMins;
                 console.log("Diferencia en minutos",excedenteMins);
                 if(excedenteMins > 0){
                     let msg = "El estudio seleccionado para esta cita se excede con " + excedenteMins + " minutos, ¿estas seguro de agregarlo?";      
                     if(window.confirm(msg)){
                        setCita({...cita,products:estudiosIds,excedido:excedenteMins});
                        setCitaProducts(estudiosIds);
                        setExcedente(excedenteMins);
                     } 
                     else return;
                 }else{
                     setCita({...cita,products:estudiosIds,excedido:0})
                     setCitaProducts(estudiosIds);
                     setExcedente(0);
                 }
             }
        }else{
            setCita({...cita,products:estudiosIds,excedido:0});
            setCitaProducts(estudiosIds);
            setExcedente(0);
        }
    }

    return (
        <div id="modal-edit-cita" className="modal">
        <div className="modal-content">
            <div className='close-btn' onClick={_closeModal}>
                <i className="material-icons">close</i>
            </div>
            <div className='row' id='general-form'>
                <h5>Editar Cita </h5>
              
                <div className="col s12 m6">
                    <div className='input-text'>
                        <label htmlFor="dia">Nombre del paciente:</label>
                        <input id="dia" type="text" value={cita.paciente} onChange={(e)=>setCita({...cita,paciente:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m6">
                    <div className='input-text'>
                        <label htmlFor="tel">Telefono:</label>
                        <input id="tel" type="text" value={cita.telefono} onChange={(e)=>setCita({...cita,telefono:e.target.value})}/>
                    </div>
                </div>
                <div className="col s12 m6">
                    <div className='input-text'>
                        <label>Estudio</label>
                        <select id="estudios-select" multiple value={citaProducts} onChange={(e)=>{_handleEstudiosIds()}} style={{borderRadius:'5px', margin:'0px!important', padding:'10px!important', border:'solid #dee2e6 1px'}}>
                            <option value="" disabled>Seleccionar estudios</option>
                            {productos.map((producto) =>
                                <option value={producto.id} key={producto.id} className='m-0'>{producto.nombre_producto} - ({producto.duracion_aproximada} min)</option>
                            )}
                        </select>
                    </div>
                    {excedente > 0 &&
                    <blockquote className='p-5 m-5 red-text text-darken-2'>La duracion de la cita se excede por {excedente} minutos</blockquote>}
                </div>
                <div className="col s12 m6">
                    <div className='input-text'>
                        <label htmlFor="doc">Doctor:</label>
                        <input id="doc" type="text" value={cita.doctor} onChange={(e)=>setCita({...cita,doctor:e.target.value})}/>
                    </div>
                </div>
              
                <div className="col s12">
                    <div className='input-text'>
                        <label htmlFor="observa1">Observaciones:</label>
                        <textarea id="observa1" className="materialize-textarea validate" value={cita.observaciones} onChange={(e)=>setCita({...cita,observaciones:e.target.value})}/>
                    </div>
                </div>
                {appointments_clean &&
                <div className='col s12'>
                    <button data-target="modaladdress" className="btn-floating pulse bg-warning modal-trigger right" onClick={_cleanCita}>
                        <i className="material-icons">delete</i>
                    </button>
                </div>
                }

            </div>
        </div>
        <div className="modal-footer">                          
            <button className="btn-blanco m-10" onClick={_closeModal}>Cancelar</button>
            <button className="btn-blanco m-10" onClick={(e)=>_updateCita()}>Guardar</button>
        </div>
    </div>
    );
};

export default EditCitaModal;