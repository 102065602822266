import React from 'react';
import M from 'materialize-css';
import EntregarPedidoForm from './EntregarPedidoForm';

const EntregarPedidoModal = (props) => {
    const {orderIDSelected} = props;

    const _closeModal = () => {   
        let instance = M.Modal.getInstance(document.querySelector('#entregar-pedido-modal'));
        instance.close();
    }

    return (
        <div id="entregar-pedido-modal" className="modal border-r-6" style={{height:'90vh',}}>
                <div className="modal-content m-0 p-10">
                    <div className='close-btn' onClick={_closeModal}>
                        <i className="material-icons">close</i>
                    </div>
                    <EntregarPedidoForm orderID={orderIDSelected}/>
                </div>

        </div>
    );
};

export default EntregarPedidoModal;