import React, { useEffect, useState } from 'react';
import axios from '../../../axios';

const GeneralTab = (props) => {
    const {patient,doctor,direccion,comentarios,envioID,pedidoId} = props;
    const [paciente,setPaciente] = useState();
    const [loaded,setLoaded] = useState(false);
    const [deliveries,setDeliveries] = useState([])
    const [dataEnvios,setDataEnvio] = useState({
        comentarios_envio:comentarios,
        direccion_envio:direccion,
        delivery_id:envioID
    })

    useEffect(()=>{
        if(patient && patient.id){
            setPaciente(patient);
            getEnvio();
            setLoaded(true);
        }
    },[]);

    const getEnvio = ()=>{
        axios.get('api/deliveries').then(resp=>{
            setDeliveries(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

    const updateOrder = () =>{
        console.log("update",dataEnvios);
        axios.put(`api/order-general-update/${pedidoId}`,dataEnvios).then((resp)=>{
            
        }).catch(e=>console.log("Error",e));
    }
   
    return (
        <>
        {loaded&&
        <div className='row'>
            <div className='col s12 m6 p-20'>
                <div className='row'>
                    <div className='col s12 titles-badge centrado'>Datos Generales</div>
                    <div className="col s12 m12 m-t-10">
                        <h6 className="negritas">Datos del Doctor</h6>
                    </div>
                    <div className="col s12 m12 m-t-10">
                        <label className='m-p-0'>Nombre del Doctor</label>
                        <div className="custom-input">{doctor?.nombre} {doctor.apellidos} </div>
                    </div>
                    <div className="col s12 m6 m-t-10">
                        <label className='m-p-0'>Telefono</label>
                        <div className="custom-input">{doctor?.telefono} </div>
                    </div>
                    <div className="col s12 m6 m-t-10">
                        <label className='m-p-0'>Celular</label>
                        <div className="custom-input">{doctor?.celular} </div>
                    </div>
                    <div className="col s12 m-t-10">
                        <label className='m-p-0'>Observaciones Generales</label>
                        <textarea id="comentarios" type="text" 
                        style={{background:'#ffffff', minHeight:'80px',border:'solid 1px #dee2e6',borderRadius:4}} 
                        value={doctor?.observaciones_generales} 
                        disabled></textarea>
                    </div>
                    <div className="col s12 m12 m-t-10">
                        <h6 className="negritas">Datos del Paciente</h6>
                    </div>
                    <div className="col s12 m12 m-t-10">
                        <label className='m-p-0'>Nombre del paciente</label>
                        <div className="custom-input">{paciente.nombre} </div>
                    </div>
                    <div className="col s12 m5 m-t-10">
                        <label htmlFor="name">Direccion</label>
                        <div className="custom-input">{paciente.direccion} </div>
                    </div>
                    <div className="col s12 m5 m-t-10">
                        <label htmlFor="name">Direccion</label>
                        <div className="custom-input">{paciente.ciudad} </div>
                    </div>
                    <div className="col s12 m2 m-t-10">
                        <label htmlFor="cp">C.P.</label>
                        <div className="custom-input">{paciente.cp}</div>
                    </div>
                    <div className="col s12 m4  m-t-10">
                        <label htmlFor="name">F. Nacimiento</label>
                        <div className="custom-input">{paciente.fecha_nacimiento}</div>
                    </div>
                    <div className="col s12 m4  m-t-10">
                        <label htmlFor="tel">Teléfono</label>
                        <div className="custom-input">{paciente.fecha_nacimiento}</div>
                    </div>
                    <div className="col s12 m4  m-t-10">
                        <label htmlFor="cel">Celular</label>
                        <div className="custom-input">{paciente.celular}</div>
                    </div>
                    <div className="col s12 m4  m-t-10">
                        {paciente.con_cita?
                            <div className='badge badge-success centrado'>Con cita</div>
                            :
                            <div className='badge badge-error centrado'>Sin cita</div>
                        }
                       
                    </div>
                </div>
            </div>   

            <div className='col s12 m6 p-20'>
                <div className='row'>
                    <div className='col s12 titles-badge centrado'>Datos de Envío</div>
                    <div className="col s12 m-t-10">
                        <label className='m-p-0'>Forma de envio</label>
                        <select className='browser-default' value={dataEnvios.delivery_id} 
                            onChange={(e)=>setDataEnvio({...dataEnvios,delivery_id:e.target.value})}
                            style={{backgroundColor:"#ffffff",fontSize:"14px",height:30,border:"solid 1px #dee2e6"}}>
                            <option value="">Selecciona una opcion</option>
                            {deliveries.map(envio=>
                                <option key={envio.id} value={String(envio.id)}>{envio.descripcion}</option>
                            )} 
                        </select>
                    </div>
                    <div className="col s12 m-t-10">
                        <label className='m-p-0'>Enviar a</label>
                        <input className='custom-input' id="name" type="text" value={dataEnvios.direccion_envio} onChange={(e)=>setDataEnvio({...dataEnvios,direccion_envio:e.target.value})}/>
                    </div>
                    <div className="col s12 m-t-10">
                        <label className='m-p-0'>Comentarios envio</label>
                        <textarea id="comentarios" type="text" style={{background:'#ffffff', minHeight:'80px',border:'solid 1px #dee2e6',borderRadius:4}} value={dataEnvios.comentarios_envio  } onChange={(e)=>setDataEnvio({...dataEnvios,comentarios_envio:e.target.value})}></textarea>
                    </div>
                    <div className='col s12 t-right'>
                        <button className='btn-info' onClick={(e)=>updateOrder()}>Actualizar datos de envio</button>
                    </div>
                </div>
            </div>
        </div>
        }
        </> 
             
    );
};

export default GeneralTab;