import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import NoData from '../../../Layout/Notificaciones/NoData';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import Loader from '../../../Layout/Loaders/Loader';
import NoPermissions from '../../NoPermissions/NoPermissions';

const Paqueteria = () => {
    const user = useContext(UserContext);
    const [paqueterias,setpaqueterias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        deliveries_create:null,
        deliveries_read:null,
        deliveries_update:null,
        deliveries_delete:null
    });

    useEffect(()=>{
        if(user.user.id){
            getDeliveries();
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                deliveries_create:checkPermiso("deliveries_create",user.user.permissions),
                deliveries_read:checkPermiso("deliveries_read",user.user.permissions),
                deliveries_update:checkPermiso("deliveries_update",user.user.permissions),
                deliveries_delete:checkPermiso("deliveries_delete",user.user.permissions)
            }));
        }
    },[user]);

    useEffect(()=>{
        (permisos.deliveries_create !==null) && setLoaded(true);
    },[permisos])

    const getDeliveries = () =>{
        axios.get('api/deliveries')
        .then((res)=>{
            console.log('paqueterias',res)
            setpaqueterias(res.data)
        }).catch(e=>console.log(e))
    }

    const _delete = (id) =>{
        if(window.confirm("Realmente deseas borrar la Paqueteria, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/deliveries/${id}`)
            .then((res)=>{
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setpaqueterias(paqueterias.filter(u=>u.id!==res.data.paqueteria.id))
            }).catch(e=>console.log(e))
        }
    }
    return (
        <>
        <div className='container-admin-menu'> 
            <Breadcrumb hasButton={permisos.deliveries_create} hasFilters={false} />
        </div> 
        <div className='container-admin-container'>
            <div className='container-admin z-depth-1'>
                {loaded ?
                    permisos.deliveries_read ?
                    <>
                        <div className='table'>
                            <div className="table-item-counter">Total paqueterias: {paqueterias.length}</div>
                            <div className='table-header row movil-oculto'>
                                <div className='col m1'>ID</div>
                                <div className='col m9'>Paqueteria </div>
                                <div className='col m2 t-right'>Acciones</div>
                            </div>
                            {paqueterias.length > 0 ? paqueterias.map(paqueteria=>
                            <div className='table-container row' key={paqueteria.id}>
                                <div className='col s8 m1 movil-oculto'>
                                    <div className='cont-textos'>{paqueteria.id}</div>
                                </div>
                                <div className='col s12 movil-visible'>
                                    <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                        <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>{paqueteria.id}</div> </div>
                                        <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                            <div className='cont-textos row'>
                                                {permisos.deliveries_update &&
                                                <Link to={`${paqueteria.id}`} style={{color:'green'}}
                                                className="col s6 m6 push-m3 m-p-0 centrar">
                                                    <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                                </Link>
                                                }
                                                {permisos.deliveries_delete &&
                                                <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                                    <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(paqueteria.id)}>delete_forever</i>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div className='table-header-movil'>Paqueteria </div>
                                </div>
                                <div className='col s12 m9'>
                                    <div className='cont-textos t-cut'>{paqueteria.descripcion}</div>
                                </div>

                                <div className='col s12 m2 movil-oculto'>
                                    <div className='cont-textos row'>
                                        {permisos.deliveries_update &&
                                            <Link to={`${paqueteria.id}`}
                                            className="col s6 m6 push-m3 m-p-0 centrar">
                                                <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                            </Link>
                                        }
                                        {permisos.deliveries_delete &&
                                        <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                            <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(paqueteria.id)}>delete_forever</i>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <NoData modulo="paqueterias"></NoData>
                            }
                        </div>
                    </>
                    : <NoPermissions />
                :
                <div className='height-200 centrar'>
                    <Loader/>
                </div>
                }
            </div>
        </div> 
    </>
    );
};

export default Paqueteria;