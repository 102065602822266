import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import { useContext } from 'react';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import Spinner from '../../../Layout/Loaders/Spinner';

const Proveedores  = () => {
    const user = useContext(UserContext);
    const [proveedores,setproveedores] = useState([])
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        suppliers_create:null,
        suppliers_read:null,
        suppliers_update:null,
        suppliers_delete:null
    });

    useEffect(()=>{
        if(user.user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                suppliers_create:checkPermiso("suppliers_create",user.user.permissions),
                suppliers_read:checkPermiso("deliveries_read",user.user.permissions),
                suppliers_update:checkPermiso("suppliers_update",user.user.permissions),
                suppliers_delete:checkPermiso("suppliers_delete",user.user.permissions)
            }));
        }
        getSuppliers();
    },[user])

    useEffect(()=>{
        (permisos.suppliers_create !==null) && setLoaded(true);
    },[permisos])

    const getSuppliers = ()=>{
        axios.get('api/suppliers')
        .then((res)=>{
            console.log('proveedores',res)
            setproveedores(res.data)
        }).catch(e=>console.log(e))
    }

    const _delete = (id) =>{
        if(window.confirm("Realmente deseas borrar el Proveedor, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/suppliers/${id}`)
            .then((res)=>{
                console.log('proveedores2',proveedores);
                console.log('proveedoresID',res);
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                setproveedores(proveedores.filter(u=>u.id!==res.data.proveedor.id))
            }).catch(e=>console.log(e))
        }
    }
    return (
        <>
        <div className='container-admin-menu'> 
            <Breadcrumb hasButton={permisos.suppliers_create} hasFilters={false} />
        </div> 
        <div className='container-admin-container'>
            <div className='container-admin z-depth-1'>
            {loaded ?
                permisos.suppliers_read ?
                    <>
                        <div className='table'>
                        <div className='table-header row movil-oculto'>
                            <div className='col m1'>ID</div>
                            <div className='col m3'>Proveedor </div>
                            <div className='col m2'>Contacto </div>
                            <div className='col m2'>Telefono </div>
                            <div className='col m2'>Correo </div>
                            <div className='col m2 centrado'>Acciones</div>
                        </div>
                        {proveedores.map(proveedor=>
                        <div className='table-container row' key={proveedor.id}>
                            <div className='col s8 m1 movil-oculto'>
                                <div className='cont-textos'>{proveedor.id}</div>
                            </div>
                            <div className='col s12 movil-visible'>
                                <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                    <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>{proveedor.id}</div> </div>
                                    <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                        <div className='cont-textos row m-p-0'>
                                            <div className='col s4 m4 m-p-0 centrar'>
                                                <Link to={`${proveedor.id}`}
                                                className="m-p-0 centrar">
                                                    <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                                </Link>
                                            </div>
                                            <div className='col s4 m4 m-p-0 centrar'>
                                                <Link to={`${proveedor.id}`}
                                                className="col s4 m4 m-p-0 centrar">
                                                    <i className="material-icons m-p-0" style={{fontSize:'20px', color:'#5e72e4'}}>library_books</i>
                                                </Link>
                                            </div>
                                            <div className='col s4 m4 m-p-0 centrar'>
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(proveedor.id)}>delete_forever</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Proveedor </div>
                            </div>
                            <div className='col s8 m3'>
                                <div className='cont-textos'>{proveedor.nombre_compania}</div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Conacto </div>
                            </div>
                            <div className='col s8 m2'>
                                <div className='cont-textos'>{proveedor.nombre_contacto}</div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Telefono </div>
                            </div>
                            <div className='col s8 m2'>
                                <div className='cont-textos'>{proveedor.telefono}</div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Correo </div>
                            </div>
                            <div className='col s8 m2'>
                                <div className='cont-textos'>{proveedor.correo_electronico}</div>
                            </div>

                            <div className='col s12 m2 movil-oculto'>
                                <div className='cont-textos row m-p-0'>
                                    <div className='col s4 m4 m-p-0 centrar'>
                                        <Link to={`${proveedor.id}`}
                                        className="m-p-0 centrar">
                                            <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                        </Link>
                                    </div>
                                    <div className='col s4 m4 m-p-0 centrar'>
                                        <Link to={`detail/${proveedor.id}`}
                                        className="col s4 m4 m-p-0 centrar">
                                            <i className="material-icons m-p-0" style={{fontSize:'20px', color:'#5e72e4'}}>library_books</i>
                                        </Link>
                                    </div>
                                    <div className='col s4 m4 m-p-0 centrar'>
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(proveedor.id)}>delete_forever</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                        </div>
                    </>
                    :
                    <NoPermissions />
                :
                <div className='height-200 centrar'>
                    <Spinner size={"big"} />
                </div>
            }
            </div>
        </div> 
    </>
    );
};

export default Proveedores;