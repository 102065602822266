import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import ModalVacation from './ModalVacation';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import Loader from '../../../Layout/Loaders/Loader';
import Spinner from '../../../Layout/Loaders/Spinner';


const NewVacation = () => {
    const {user} = useContext(UserContext);
    const [empleados,setEmpleados] = useState([]);
    const [empleado,setEmpleado] = useState({});
    const [empleadoVacaciones,setEmpleadoVacaciones] = useState([]);
    const [empleadoID,setEmpleadoID]=useState(null);
    const [aniosTrabajados,setAniosTrabajados] = useState(0);
    const [contador,setContador] = useState(0);
    const [diasCorrespondientes,setDiasCorrespondientes] = useState(0);
    const [tabuladorVaca,setTabuladorVaca] = useState([]);
    const [loader,setLoader] = useState(true);
    const [loaded,setLoaded] = useState(false);
    const [loadingVacations,setLoadingVacations] = useState(false);
    const [solicitudId,setSolicitudId] = useState(null);
    const [sortBy, setSortBy] = useState({
        field:"",
        sortBy:"ASC"
    });
    const today =  new Date();
    const [permisos,setPermisos] = useState({
        employee_vacations_create:null,
        employee_vacations_history:null,
        employee_vacations_delete:null
    });

    useEffect(()=>{
        getVacationsTab();
    },[])

    useEffect(()=>{
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                employee_vacations_create:checkPermiso("employee_vacations_create",user.permissions),
                employee_vacations_history:checkPermiso("employee_vacations_history",user.permissions),
                employee_vacations_delete:checkPermiso("employee_vacations_delete",user.permissions)
            }));
        }
    },[user])

    useEffect(()=>{
        if(permisos.doctors_read !== null){
            //  setLoaded(true);
            getEmpleadosAutocomplete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[permisos])

    const getEmpleadosAutocomplete = () => {
        axios.get('/api/employees').then(res=>{
            setEmpleados(res.data);
            var empleadoArray = res.data;
            var dataEmpleado = {};
            for (var i = 0; i < empleadoArray.length; i++) {
                dataEmpleado[empleadoArray[i].id+"-"+empleadoArray[i].nombre+" "+empleadoArray[i].apellidos] = null; 
            }
            console.log(dataEmpleado)
            M.Autocomplete.init(document.querySelectorAll('#autocomplete-input'), {
                data: dataEmpleado,
                minLength:0, 
                limit:10,
                onAutocomplete:_handleChange
            });
            setLoaded(true);
        }).catch(e=>console.log("Error",e));
    }
    
    const getVacationsTab = () =>{
        axios.get('api/vacations_tabs').then(resp=>{
            setTabuladorVaca(resp.data);
        }).catch(e=>console.log("Error en vacations tab: ",e));
    }


    useEffect(()=>{
        if(!loader){
             console.log("sortBy",sortBy);
             //_getEmployeess(); 
             _ordenarTabla();
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[sortBy])

    useEffect(()=>{
        if(empleadoID){
            let tempEmp = empleados.find(({id})=>id==empleadoID);
            let aplicacion = new Date(tempEmp.fecha_aplicacion);
            let anio = calcularDiferenciaAnios(aplicacion,today);
            setAniosTrabajados(anio);
            let diasVacaciones = calcularDiasPorAnio(anio);
            setDiasCorrespondientes(diasVacaciones);
            _getVacacionesRequest(empleadoID,anio);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[empleadoID])

    const  calcularDiferenciaAnios = (fecha1, fecha2) =>{
        let diferencia = (fecha2.getTime() - fecha1.getTime()) / 1000 / (3600 * 24);
        let difAnios = Math.abs(Math.round(diferencia / 365.25));
        let difMonths = fecha1.getMonth() - fecha2.getMonth();
        if(difMonths > 0){
            difAnios = (difAnios > 0) ? difAnios-1 : 0; 
        }
        return difAnios;
    }

    const calcularDiasPorAnio = (anios) =>{
        let diasCorrespondientes = tabuladorVaca.find((ele)=>ele.anios_laborando === anios);
        if(diasCorrespondientes && diasCorrespondientes.dias_vacaciones) return diasCorrespondientes.dias_vacaciones;
        else return 0;
    }
    
    const _handleChange = (value) =>{
        console.log('el valor',value);
        let valueArray = value.split('-');
        let idEmp = String(valueArray[0]).trim();
        setEmpleadoID(idEmp);
    }

    const _getVacacionesRequest = (empleadoID,anio) =>{
        setLoadingVacations(true);
        axios.post(`api/get_employee_vacations/${empleadoID}`,{anio:anio}).then((resp)=>{
            console.log("Vacaciones request",resp);
            if(resp.status === 200){
                setEmpleado(resp.data.data.employee)
                setEmpleadoVacaciones(resp.data.data.vacaciones)
                setTimeout(() => {
                    setLoader(false)
                    setLoadingVacations(false)
                }, 1000);
               
            }else{
                setLoader(true)
            }
        }).catch(e=>console.log(e))
    }

    const changeHistorial = (value) =>{
        setLoadingVacations(true);
        let tempContador = (value==='mas')?contador+1:contador-1;
     
        if(aniosTrabajados+tempContador >= 0){
            setContador(tempContador);
            axios.post(`api/get_employee_vacations/${empleadoID}`,{anio:aniosTrabajados+tempContador}).then((resp)=>{
                if(resp.status === 200){
                    console.log(resp)
                    setEmpleadoVacaciones(resp.data.data.vacaciones)
                    setTimeout(() => {
                        setLoader(false);
                        setLoadingVacations(false);
                    }, 1000);
                }else{
                    setLoader(true)
                }
            }) 
        }
        
    }
    const getTotalDiasTomados = () =>{
        let totalDias = empleadoVacaciones.reduce((accumulator, object) => {
            let numerito =  (object.sin_gose_sueldo != 1) ? object.dias_a_tomar : 0
            return accumulator + numerito;
          }, 0);
          return totalDias;
    }

    const _handleSort = (field) =>{
        if(field !== sortBy.field){
            setSortBy({...sortBy,field:field,sortBy:"DESC"});
        }else{
            setSortBy({...sortBy,sortBy:sortBy.sortBy === "DESC"?"ASC":"DESC"})
        }
    }

    const _ordenarTabla = () => {
        //se crea una copia de empleados para que la funcion de sort no modifique el array original
        let ordenados = empleadoVacaciones.slice(0);
        if(sortBy.sortBy === "ASC"){
            ordenados.sort((a,b)=>{
                if (a[sortBy.field] > b[sortBy.field]) {
                    return 1;
                  }
                  if (a[sortBy.field] < b[sortBy.field]) {
                    return -1;
                  }
                  return 0;
            })
        }else{
            ordenados.sort((a,b)=>{
                if (a[sortBy.field] < b[sortBy.field]) {
                    return 1;
                  }
                  if (a[sortBy.field] > b[sortBy.field]) {
                    return -1;
                  }
                  return 0;
            })
        }
        setEmpleadoVacaciones(ordenados)
    }

    const _renderDias = (fechas) =>{
        let arrayFechas = fechas.split(",");
        return (
           arrayFechas.map((fecha,i)=>
            <span key={i}>
                {new Date(fecha).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}<br></br>
            </span>
            )
        );
    }

    const _deleteVacationRequest = (vacacionId) =>{
        console.log("vacacion request id",vacacionId)
        if(window.confirm("Estas seguro de borrar esta solicitud")){
            axios.delete(`api/employees_vacations/${vacacionId}`).then((resp)=>{
                console.log("Borrado",resp)
                _getVacacionesRequest(empleadoID,aniosTrabajados);
                M.toast({
                    html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>solicitud eliminada exitosamente</span>',
                    classes:"success-notify"
                })
            }).catch(e=>console.log("Error",e));
        }
    }

    const _calcularDate  = (fecha,anios) =>{
        let date =  new Date(fecha)
        date.setFullYear(date.getFullYear() + anios);
        date.setDate(date.getDate()+1);
        return new Date(date).toLocaleDateString("es-MX", { year: 'numeric', month: 'short', day: 'numeric' });
    }

    const cleanSearch = ()=>{
        console.log("clean.....")
        setEmpleadoID(null);
        setEmpleado({});
        let inputsito =document.querySelector("#autocomplete-input");
        inputsito.value = "";
        
    } 

    return (
        <div>
            <div className='container-admin-menu'> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                    <h5 className='p-5'>Nueva Solicitud de Vacaciones</h5>
                    {loaded ?
                        <div className='row grey lighten-5'>
                            <div className='col s8'>
                                <div className='input-field m-p-0'>
                                    <label htmlFor="autocomplete-input">Buscar Empleado</label>
                                    <input type="text" id="autocomplete-input" class="autocomplete" autoComplete="off"/>
                                </div>
                            </div>
                            <div className='col s4'>
                                <button className='btn-blanco centrar puntero' style={{margin:8}} onClick={(e)=>cleanSearch()}>Limpiar <i className="material-icons">clear</i></button> 
                            </div>
                        </div>
                        :
                        <Loader></Loader>
                    }
                    <ModalVacation empleadoID={empleadoID} solicitudId={solicitudId}  _getVacacionesRequest={_getVacacionesRequest} aniosTrabajados={aniosTrabajados}></ModalVacation>
                    {empleado.id && <>
                        {loader?
                            <div className='container-admin row centrar' style={{minHeight:200}}>
                                <div className="preloader-wrapper big active">
                                    <div className="spinner-layer spinner-blue">
                                        <div className="circle-clipper left">
                                        <div className="circle"></div>
                                        </div><div className="gap-patch">
                                        <div className="circle"></div>
                                        </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div  className="row ancho100" id='general-form'>
                                <div className="col s12"> <hr style={{borderTop: 'solid 4px blue'}}></hr> </div>
                                <div className='col s12 row m-p-0' style={{position:'relative'}}>
                                    <div className='col m12 valign-wrapper grey lighten-5 border-r-10' style={{padding:5}}>
                                        <div className='col s4 valign-wrapper' style={{height:150,position:'relative', paddingLeft:'6px'}}>
                                            <img src='/images/vacations.png' alt={empleado.nombre} className="responsive-img" style={{height:140,borderRadius:10}}/>
                                        </div>
                                        <div className='col s12'>
                                            <p className='m-5'><label>ID:</label> {empleado.id}</p>
                                            <p className='m-5'><label>Nombre:</label> {empleado.nombre} {empleado.apellidos}</p>
                                            <p className='m-5'><label>Antiguedad:</label> {calcularDiferenciaAnios(new Date(empleado.fecha_aplicacion),today)} años laborando</p>
                                            <p className='m-5'><label>Periodo vacacional actual:</label> Del {_calcularDate(empleado.fecha_aplicacion,aniosTrabajados)} al {_calcularDate(empleado.fecha_aplicacion,aniosTrabajados+1)} </p>
                                        </div>
                                        <div className='col s12'>
                                            <p className='m-5'><label>Día de Hoy:</label> {new Date(today).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                            <p className='m-5'><label>Fecha de Ingreso:</label> {empleado.fecha_aplicacion} </p>
                                            <p className='m-5'><label>Correspondientes</label> {diasCorrespondientes}</p>
                                        </div>
                                    </div>
                                    {permisos.employee_vacations_create &&
                                        <button data-target="modalvacaciones" className="btn-floating pulse cyan modal-trigger" style={{position:'absolute',top:5,right:20}}>
                                            <i className="material-icons">add</i>
                                        </button> 
                                    }
                                   
                                </div>
                                <div className="col s12"> <hr style={{borderTop: 'solid 4px blue'}}></hr> </div>
                                {permisos.employee_vacations_history ?
                                    <>
                                        <div className='col s12 row m-p-0' >
                                            <div className='col s12 m12 valign-wrapper grey lighten-5 border-r-10' style={{padding:5}}>
                                                <div className='col s12 m2' style={{paddingLeft:'6px',}}>
                                                    <label style={{color:'blue', fontSize:'16px', fontWeight:900}}>HISTORIAL ANUAL</label>
                                                </div>
                                                <div className='col s12 m3 m-p-0'  style={{textAlign:'center'}}>
                                                    <div className='row m-p-0'  style={{textAlign:'center'}}>
                                                        <div className='col s2 m4 m-p-0 centrar'  style={{textAlign:'center'}}>
                                                            <label>Anterior</label>
                                                        </div>
                                                        <div className='col s3 m4 m-p-0 centrar' style={{textAlign:'center'}}>
                                                            <label>Año</label>
                                                        </div>
                                                        <div className='col s3 m4 m-p-0 centrar' style={{textAlign:'center'}}>
                                                            <label>Siguiente</label>
                                                        </div>
                                                    </div>
                                                    <div className='row m-p-0'  style={{textAlign:'center'}}>
                                                        <div className='col s2 m4 m-p-0 centrar'  style={{textAlign:'center'}} onClick={(e)=>changeHistorial('menos')}>
                                                            <div><i class="material-icons">fast_rewind</i></div>
                                                        </div>
                                                        <div className='col s3 m4 m-p-0 centrar' style={{border:'solid 1px', textAlign:'center'}}>
                                                            <div>{aniosTrabajados+contador}</div>
                                                        </div>
                                                        <div className='col s3 m4 m-p-0 centrar' style={{textAlign:'center'}} onClick={(e)=>changeHistorial('mas')}>
                                                            <div><i class="material-icons">fast_forward</i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col s12 m3'>
                                                    <p className='' style={{textAlign:'right'}}><label>Antiguedad:</label> {aniosTrabajados+contador} años laborando</p>
                                                </div>
                                                <div className='col s12 m2'>
                                                    <p className='' style={{textAlign:'center'}}><label>Dias Aplicados:</label> {getTotalDiasTomados()} </p>
                                                </div>
                                                <div className='col s12 m2'>
                                                    <p className=''><label>&nbsp;Dias Restantes:</label> {diasCorrespondientes-getTotalDiasTomados()} </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='m-p-0'>
                                            <div className='m-p-10'>
                                                {!loadingVacations ? 
                                                <div className='col table table-doc m-p-0' style={{width:'100%'}}>
                                                    <div className='table-header row m-t-20 '>
                                                        <div className='col s12 m2 centrar'> Día(s) Aplicados &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("fecha_inicio")}}>unfold_more</i></div>
                                                        <div className='col s12 m2 centrar'> Goce de Sueldo &nbsp;<i className="material-icons" onClick={(e)=>{_handleSort("sin_gose_sueldo")}}>unfold_more</i></div>
                                                        <div className='col s12 m2 centrar'> Tipo de permiso &nbsp;<i className="material-icons" onClick={(e)=>{_handleSort("tipo_permiso")}}>unfold_more</i></div>
                                                        <div className='col s12 m1 centrar'> Num Días &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("dias_a_tomar")}}>unfold_more</i></div>
                                                        <div className='col s12 m2 centrar'> Notas &nbsp; </div>
                                                        <div className='col s12 m2 centrar'> Estado &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("status_aprobacion")}}>unfold_more</i> </div>
                                                        <div className='col s12 m1'> Acciones</div>
                                                    </div>
                                                    
                                                    <div className='table-container'>
                                                    {empleadoVacaciones.map((vacacion,index)=>
                                                        <div className='row' key={index}>
                                                            <div className='col s2 m2 centrar '>
                                                                <div className='centrado'>
                                                                    {_renderDias(vacacion.fechas)} 
                                                                </div>
                                                            </div>
                                                            <div className='col s3 m2 centrar'>
                                                                <div className='centrado'>{vacacion.sin_gose_sueldo?"Sin goce de sueldo":"Pagadas"}</div>
                                                            </div>
                                                            <div className='col s3 m2 centrar'>
                                                                <div className='centrado'>{vacacion.tipo_permiso}</div>
                                                            </div>
                                                            <div className='col s3 m1 centrar'>
                                                                <div className='centrado'>{vacacion.dias_a_tomar >= 1 ? vacacion.dias_a_tomar+" Dia(s)":'Medio Dia'}</div>
                                                            </div>
                                                            <div className='col s3 m2 centrar'>
                                                                <div className="badge" style={{fontSize:11}}>
                                                                    <span className='p-h-10'>{vacacion.notas}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col s3 m2 centrar'>
                                                                <div className="centrado badge">
                                                                    <span className='p-h-10' style={{color:'green'}}>{vacacion.status_aprobacion}</span>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className='col s12 m1 movil-oculto'>
                                                                <div className='row centrado cont-textos'>
                                                                    {permisos.employee_vacations_delete &&
                                                                    <div className='col m6 m-p-0 centrar movil-100' onClick={()=>_deleteVacationRequest(vacacion.id)}>
                                                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} >delete_forever</i>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    </div>    
                                                    
                                                </div>
                                                :
                                                <div className='col s12 centrado centrar height-200'>
                                                    <Spinner />
                                                </div>
                                                
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <NoPermissions />
                                }
                                

                            </div>
                        }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default NewVacation;