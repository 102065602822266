import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import axios from '../../../axios';

const today = new Date();
today.toLocaleString('en-US', { timeZone: 'America/Mexico_City' });

const ReporteGlobal = () => {
    const [dia,setDia] = useState(new Date());
    const [rango,setRango] = useState({field:'fecha_pedido',start:today,end:''});

    const handleDate = (e) => {
        let date =  new Date(e.target.value);
        date.setDate(date.getDate());
        let tomorrow = new Date(e.target.value);
        tomorrow.setDate(date.getDate()+1);
        //date.toLocaleString('en-US', { timeZone: 'America/Mexico_City' });
       
        setDia(date);
        setRango({...rango,start:date,end:tomorrow});
    }

    useEffect(()=>{
        getPedidos();
    },[rango]);

    const getPedidos = () =>{
        axios.post("/api/search-orders-by-date",rango).then((resp)=>{
            console.log("response",resp)
        }).catch(e=>console.log("Error",e))
    }


    return (
        <div>
             <div className='container-admin-menu '> <Breadcrumb hasButton={true} hasFilters={false} /> </div>
             <div className='container-admin-container'>
                <div className='container-admin z-depth-1 row'>
                    <div className='row col s12 info-badge border-r-10 m-p-0 centrar-h' style={{bordeRadius:'10px!important', padding:'0px!important',}}>
                        <div className="col s12 m6 p-0">
                           <h5>Reporte Global de Ingresos</h5>
                           <div>Fecha Analisis: {dia.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })} </div>
                        </div>
                        <div className="row col s12 m6 input-text">
                            <div className='col s12 m6 right'>
                                <label htmlFor="dia">Seleccionar día</label>
                                <input id="dia" type="date" onChange={handleDate}/>
                            </div>
                        </div>
                    </div>

                    <div className='col s12 row m-t-10 z-depth-1 p-10 border-r-6 centrar '>
                        <div className="container p-10 table table-doc">
                            <div className='row m-p-0 table-header'>
                                <div className='col s12 m2' style={{border:'solid 1px'}}>
                                    2
                                </div>
                                <div className='col s12 m10' style={{border:'solid 1px'}}>
                                    Patricia Maria Castorena
                                </div>
                            </div>
                            <div className='row m-p-0' style={{display:'flex'}}>
                                <div className='col s12 m2' style={{border:'solid 1px'}}></div>
                                <div className='col s12 m4 negritas' style={{border:'solid 1px'}}>
                                    PEDIDOS:
                                </div>
                                <div className='col s12 m6 negritas' style={{border:'solid 1px'}}>
                                    SUBTOTAL:
                                </div>
                            </div>
                            <div className='row m-p-0'>
                                <div className='col s12 m2' style={{border:'solid 1px'}}>
                                    EFECTIVO
                                </div>
                                <div className='col s12 m4' style={{border:'solid 1px'}}>
                                    3
                                </div>
                                <div className='col s12 m6' style={{border:'solid 1px'}}>
                                    $1,187.99
                                </div>
                            </div>
                            <div className='row m-p-0'>
                                <div className='col s12 m2' style={{border:'solid 1px'}}>
                                    TARJETAS
                                </div>
                                <div className='col s12 m4' style={{border:'solid 1px'}}>
                                    0
                                </div>
                                <div className='col s12 m6' style={{border:'solid 1px'}}>
                                    $245.00
                                </div>
                            </div>
                            <div className='row m-p-0'>
                                <div className='col s12 m2' style={{border:'solid 1px'}}>
                                    DEBITO
                                </div>
                                <div className='col s12 m4' style={{border:'solid 1px'}}>
                                    0
                                </div>
                                <div className='col s12 m6' style={{border:'solid 1px'}}>
                                   $0.00
                                </div>
                            </div>
                            <div className='row m-p-0'>
                                <div className='col s12 m2' style={{border:'solid 1px'}}>
                                    TRANSF:
                                </div>
                                <div className='col s12 m4' style={{border:'solid 1px'}}>
                                    0
                                </div>
                                <div className='col s12 m6' style={{border:'solid 1px'}}>
                                    $0.00
                                </div>
                            </div>
                            <div className='row m-p-0 m-t-10'>
                                <div className='col s12 m2' style={{border:'solid 1px'}}>
                                    GASTOS:
                                </div>
                                <div className='col s12 m4' style={{border:'solid 1px'}}>
                                    0
                                </div>
                                <div className='col s12 m6' style={{border:'solid 1px'}}>
                                    $0.00
                                </div>
                            </div>
                            <div className='row m-p-0 m-t-10'>
                                <div className='col s12 m6' style={{border:'solid 1px'}}>
                                    TOTAL DE EFECTIVO:
                                </div>
                                <div className='col s12 m6' style={{border:'solid 1px'}}>
                                    $1,187.99
                                </div>
                            </div>

                            <div className='m-p-0 m-t-25 table-container'>
                                <div className='row'>
                                    <div className='col s12 m2' style={{border:'solid 1px'}}>
                                        TOTAL:
                                    </div>
                                    <div className='col s12 m4' style={{border:'solid 1px'}}>
                                        4
                                    </div>
                                    <div className='col s12 m6' style={{border:'solid 1px'}}>
                                        $1,432.00|
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className='col s12 row m-t-10 z-depth-1 p-10 border-r-6 centrar '>
                        <div className='container p-10'>
                            <div className='row m-p-0' style={{display:'flex'}}>
                                <div className='col s12 m2' style={{border:'solid 1px'}}></div>
                                <div className='col s12 m4 negritas' style={{border:'solid 1px'}}>
                                    PEDIDOS:
                                </div>
                                <div className='col s12 m6 negritas' style={{border:'solid 1px'}}>
                                    TOTAL:
                                </div>
                            </div>

                            <div className='row m-p-0 negritas' style={{display:'flex'}}>
                                <div className='col s12 m3' style={{border:'solid 1px'}}> Global:</div>
                                <div className='col s12 m3 ' style={{border:'solid 1px'}}>
                                   45
                                </div>
                                <div className='col s12 m6 negritas' style={{border:'solid 1px'}}>
                                    $19,345.99
                                </div>
                            </div>

                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    );
};

export default ReporteGlobal;