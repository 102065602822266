import React, {useEffect,useState} from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';

const Detail = () => {
    const {userId} = useParams();
    const [usuario,setUsuario] = useState({name:"",email:"",password:"",password_confirmation:"",phone:"",sucursals_id:0});
    let defaultImg = "/images/default-user.png";
    
    useEffect(()=>{
        console.log("detalle")
        axios.get(`api/users/${userId}`)
        .then((res)=>{
            console.log(res.data)
            setUsuario(res.data)
        })
        .catch(e=>console.log("error",e))
    },[])

    const _updateImg = (file) =>{
        console.log(file)
        const fData = new FormData();
        fData.append('id', usuario.id)
		fData.append("file", file);

        axios.post('api/update-profile-image',fData).then((resp)=>{
            console.log("Response ",resp)
        }).catch(e=>console.log("error",e))
    }
    
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb actual={usuario.name}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1' >
                    <h5>Detalle de usuario</h5>
                    <div className='row'>
                        <div className='col m12 valign-wrapper grey lighten-5' style={{padding:5}}>
                            <div className='valign-wrapper' style={{height:150,position:'relative'}}>
                                <img src={usuario.image ? usuario.image:defaultImg} alt="user profile image" className="responsive-img" style={{height:140,borderRadius:10}}/>
                                <label style={{position:'absolute',right:5,top:5,cursor:'pointer'}}>
                                    <i className="material-icons" style={{color:'#5E72E4'}}>create</i>
                                    <input type="file"  style={{display:'none'}} 
                                    onChange={(e)=>_updateImg(e.target.files[0])} 
                                    accept="image/png, image/gif, image/jpeg" />
                                </label>
                            </div>
                            <div className='m-5'>
                                <p className='m-5'>ID: {usuario.id}</p>
                                <p className='m-5'><label>Fecha de creación: {new Date(usuario.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</label></p>
                                <p className='m-5'><label>Rol: {usuario.rol&&usuario.rol.name}</label></p>
                            </div>
                        </div>
                       
                        <div className='col s12 m6 m-t-20'>
                            <label>Nombre</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>person</i>
                                <span>{usuario.name}</span>  
                            </div>
                        </div>
                        <div className='col s12 m6 m-t-20'>
                            <label>Email</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>mail</i>
                                <span>{usuario.email}</span>  
                            </div>
                        </div>
                        <div className='col s12 m6 m-t-20'>
                        <label>Telefono</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>call</i>
                                <span>{usuario.phone}</span>  
                            </div>
                        </div>
                        <div className='col s12 m6 m-t-20'>
                            <label>Sucursal</label>
                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>location_city</i>
                                <span>{(usuario.sucursal)?usuario.sucursal.nombre_sucursal:'Sin asignar'}</span>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detail;