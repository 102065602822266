import React from 'react';

const NoData = (props) => {
    const {modulo} = props;
    return (
        <div className='height-200 m-t-20 p-20 centrar'>
            <div className='notification-info p-30'>
                <span>Lo sentimos no hay {modulo} disponibles.</span>
            </div>  
        </div>
    );
};

export default NoData;