import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import axios from '../../axios';
import M from 'materialize-css';
import FiltrosFecha from './FiltrosFecha';
import EntregarPedidoModal from './EntregarPedidoModal';

const Pedidos = () => {
    const [pedidos, setPedidos] = useState([]);
    const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
    const [order, setOrder] = useState([]);
    const [pacientes,setPacientes] = useState([]);
    const [doctor,setDoctores] = useState([]);
    const [sucursales,setSucursales] = useState([]);
    const [sinResultados,setSinResultados] = useState(false);
    const [loader,setLoader] = useState(false);
    const [openDateFilters,setOpenDateFilters] = useState(false);
    const [orderIDSelected,setOrderIDSelected] = useState(null);
    const [searchData,setSearchData] = useState({
        searchword:null,
        searchField:null
    });

    const stateRef = useRef();
    
    useEffect(() => {      
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});  
        axios.get('api/orders')
        .then( res=>{
            var el = document.querySelector('#detail');
            M.Tabs.init(el);
            console.log( 'todos los orders', res.data)
            setPedidos(res.data);
            setPedidosFiltrados(res.data);
            stateRef.current = res.data;
        }).catch(e =>{console.log(e, 'Error todos los pedidos')})
    },[])

    useEffect(()=>{
        var elems = document.querySelector('#modal-detalle-info');
        M.Modal.init(elems,{});
        _getSucursales();
        _getPacientes();
        _getDoctores();
        _getProductos();
    },[])

    const _getSucursales = () =>{
        axios.get('api/sucursals')
        .then((resp)=>{
            console.log("sucursales",resp.data)
            setSucursales(resp.data)
        })
        .catch(e=>console.log("Error: ",e));
    }

    const _closeModal = () => {
        let instance = M.Modal.getInstance(document.querySelector('#modal-detalle-info'));
        instance.close();
    }

    const _details = (obj) =>{
        console.log('obje', obj);
        setOrder(obj)
    }

    const searchById = (event) =>{
        if(event.keyCode === 13 ){
            let searchById = event.target.value;
            if(searchById > 0) setSearchData({...searchData,searchword:searchById,searchField:'id'});
            else setPedidosFiltrados(pedidos);
        }
    }

    useEffect(()=>{
        if(searchData.searchField !== null){
            axios.post("/api/search-orders",searchData).then((resp)=>{
                console.log("response",resp)
                if(resp.data.length > 0) setPedidosFiltrados(resp.data);
                else setSinResultados(true);
            }).catch(e=>console.log("Error",e))
        }else{
            setPedidosFiltrados(pedidos)
        }
    },[searchData]);

    const handleAutocompleteName = (value)=>{
        if(value.length === 0){ 
            console.log("value",value)
            setPedidosFiltrados(pedidos)
        }
    }

     const _getPacientes = ()=>{
         axios.get('api/patients').then((resp)=>{
            console.log("pacientes",resp)
            setPacientes(resp.data);
            var pacientesArray = resp.data;
            var dataPacientes = {};
            for (var i = 0; i < pacientesArray.length; i++) {
                dataPacientes[pacientesArray[i].nombre] = null; 
            }
            initAutocomplete(dataPacientes);
         }).catch(e=>console.error(e));
     }
 
     const initAutocomplete = (dataPacientes)=>{
         const elem = document.querySelectorAll('#paciente-autocomplete');
         const options = {
             data:dataPacientes,
             minLength:1, 
             limit:20,
             onAutocomplete:(value)=>{
              
                console.log("value",value)
                _setPacienteSelected(value);
             }
         };
         M.Autocomplete.init(elem,options,{isOpen:true});
     }
    
    const _setPacienteSelected = (value) =>{
        console.log(value)
        let pedidosTemporal = stateRef.current;
        setPedidosFiltrados(pedidosTemporal.filter((item)=>item?.patient?.nombre === value));
    }

    const _getDoctores = ()=>{
        axios.get('api/doctors').then((resp)=>{
            console.log("doctores",resp.data);
           setDoctores(resp.data);
           var doctoresArray = resp.data;
           var dataDoctores = {};
           for (var i = 0; i < doctoresArray.length; i++) {
               dataDoctores[`${doctoresArray[i].nombre} ${doctoresArray[i].apellidos} `] = null; 
           }
           initDoctorsAutocomplete(dataDoctores);
        }).catch(e=>console.error(e));
    }

    const initDoctorsAutocomplete = (dataDoctores)=>{
        const elem = document.querySelectorAll('#doctores-autocomplete');
        const options = {
            data:dataDoctores,
            minLength:1, 
            limit:20,
            onAutocomplete:(value)=>{
                _setDoctoresSelected(value);
            }
        };
        M.Autocomplete.init(elem,options,{isOpen:true});
    }

    const _setDoctoresSelected = (value) =>{
        console.log("doctor value",value)
        let pedidosTemporal = stateRef.current;
        setPedidosFiltrados(pedidosTemporal.filter((item)=>`${item?.doctor?.nombre} ${item?.doctor?.apellidos}` === value));
    }

    const _getProductos = ()=>{
        axios.get('api/products').then((resp)=>{
            console.log("servicios",resp.data);
           setDoctores(resp.data);
           var productsArray = resp.data;
           var dataProducts = {};
           for (var i = 0; i < productsArray.length; i++) {
               dataProducts[`${productsArray[i].nombre_producto}`] = null; 
           }
           initAutocompleteProds(dataProducts);
        }).catch(e=>console.error(e));
    }

    const initAutocompleteProds = (dataProducts)=>{
        const elem = document.querySelectorAll('#productos-autocomplete');
        const options = {
            data:dataProducts,
            minLength:1, 
            limit:20,
            onAutocomplete:(value)=>{
                _setProductsSelected(value);
            }
        };
        M.Autocomplete.init(elem,options,{isOpen:true});
    }

    const _setProductsSelected = async (value) =>{
        console.log("",value)
        let pedidosTemporal = stateRef.current;
        let temp = await pedidosTemporal.filter((item)=>{
            let finded = false;
            for(let i = 0;i<item.order_details.length;i++){
                if(item.order_details[i].product.nombre_producto === value){
                    finded = true;
                }
            }
          if(finded) return item;
          else return null;
        })
        setPedidosFiltrados(temp);
    }

    const searchByDate = (data) =>{
        console.log("buscando por fecha....",data);

        axios.post("/api/search-orders-by-date",data).then((resp)=>{
            console.log("response",resp)
            if(resp.data.length > 0) setPedidosFiltrados(resp.data);
            else setSinResultados(true);
        }).catch(e=>console.log("Error",e))

    } 
    
    const filterByStatus = (value) =>{
        if(value > 0){
            let tempArray = pedidos.filter((item)=>item.estatus === parseInt(value));
            setPedidosFiltrados(tempArray);
        }else{
            setPedidosFiltrados(pedidos);
        } 
    }

    const _limpiarFiltros = () => {
        setPedidosFiltrados(pedidos);
        document.getElementById("filterId").reset();
    }

    const openEntregaModal = (pedidoID) =>{
        setOrderIDSelected(pedidoID);
        let instanceEntrega = M.Modal.getInstance(document.querySelector('#entregar-pedido-modal'));
        instanceEntrega.open();
    }

    return (
        <>
            {/* <!-- Modal Structure -->*/}
            <div id="modal-detalle-info" className="modal border-r-10">
                <div className="modal-content border-r-10 m-p-0">
                    <div className='' style={{padding:'24px'}}>
                        
                        <div className='close-btn' onClick={_closeModal}>
                            <div className='cont-close-btn'>
                                <i className="material-icons">close</i>
                            </div>
                        </div>
                        <div className='row col s12 info-badge border-r-10 m-p-0'>
                            <div className='col s12 m6 m-p-0'>
                                Detalle de el Pedido
                            </div>
                            <div className='col s12 m3' style={{textAlign:'right'}}>
                                <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Pedido: </label>
                                {order?.id}
                            </div>
                            <div className='col s12 m3' style={{textAlign:'left'}}>
                                <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Status: </label>
                                <span className='border-r-4 p-0-10' style={{color:'green', background:'#fff', fontWeight:900}}>
                                {order?.recibido != null? 
                                    <b className='font12' style={{color:'green'}}> Entregado </b> 
                                :
                                    <b className='font12' style={{color:'orange'}}> Pendiente </b> 
                                }</span>
                            </div>
                        </div>
                    </div>
                    <div className='row  s12 m12 m-p-0'>

                        <div className="row s12 m12 m-0 p-10">
                            <div className="col s12">
                                <ul className="tabs" id="detail">
                                    <li className="tab col s12 m2"><a className="active" href="#test1">Datos generales</a></li>
                                    <li className="tab col s12 m2"><a href="#test2">Detalles Productos</a></li>
                                </ul>
                            </div>
                            <div id="test1" className="col s12 m12 m-p-0">
                                <div className='row m-p-0' style={{minHeight:'50vh'}}>
                                    <div className='col s12 m3 m-p-0'>
                                        <div className='m-p-0 centrar'><div className='bg-pedidos'></div></div>
                                    </div>
                                    <div className='col s12 m9 row m-p-0 p-0'>
                                        <div className='col s12 m8'>
                                            <h6 style={{color:'#000', fontWeight:900, fontSize:16,textTransform:'uppercase'}}> Datos Pedido </h6>
                                        </div>
                                        <div className='col s12 m4'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Fecha del Pedido:</label> <br></br>
                                            {order?.fecha_pedido}
                                        </div>
                                        <div className='col s12 m4'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Sucursal:</label> <br></br>
                                            {order?.sucursal?.domicilio}
                                        </div>
                                        <div className='col s12 m4'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Atendio:</label> <br></br>
                                            {order?.employee_atendido_por}
                                        </div>
                                        <div className='col s12 m4'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Caja:</label> <br></br>
                                            {order?.employee_cash_register}
                                        </div>
                                        <div className='col s12 m12'> <hr style={{border:'solid 1px #5e72e4'}}></hr> </div>
                                        <div className='col s12 m6'>
                                            <h6 style={{color:'#000', fontWeight:900, fontSize:16,textTransform:'uppercase'}}> Datos Entrega </h6>
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Fecha envio de Pedido:</label> <br></br>
                                            {new Date(order?.fecha_envio).toLocaleDateString("es-MX", { year: 'numeric', month: 'numeric', day: 'numeric' })}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Entregado por:</label> <br></br>
                                            {order?.employee_entregado_rx_por}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Forma de envio:</label> <br></br>
                                            {order?.delivery?.descripcion}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Doctor:</label> <br></br>
                                            {order?.doctor?.apellidos} {order?.doctor?.nombre}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Compañia:</label> <br></br>
                                            {order?.doctor?.compania}
                                        </div>
                                        <div className='col s12 m8'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Direccion:</label> <br></br>
                                            <b>{order?.direccion_envio}</b>
                                        </div>
                                        <div className='col s12 m4'>
                                            <div className='m-p-0 '><div className='bg-ubicacion'></div></div>
                                        </div>
                                        <div className='col s12 m12'> <hr style={{border:'solid 1px #5e72e4'}}></hr> </div>
                                        <div className='col s12 m12'>
                                            <h6 style={{color:'#000', fontWeight:900, fontSize:16,textTransform:'uppercase'}}> Datos Paciente</h6>
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Nombre del Paciente:</label> <br></br>
                                            {order?.patient?.nombre}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Direccion del Paciente:</label> <br></br>
                                            {order?.patient?.direccion}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Telefono del Paciente:</label> <br></br>
                                            {order?.patient?.telefono}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Celular del Paciente:</label> <br></br>
                                            {order?.patient?.celular}
                                        </div>
                                        <div className='col s12 m6'>
                                            <label style={{color:'#11cdef', fontWeight:900}}> <i style={{fontSize:'10px'}} className="material-icons">forward</i> Comentarios de Envío:</label> <br></br>
                                            {order?.comentarios_envio}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div id="test2" className="col s12 m12 m-p-0">
                                <div className='col s12 m3 m-p-0'>
                                    <div className='m-p-0 centrar'><div className='bg-pedidos2' style={{marginLeft:'-20px!important'}}></div></div>
                                </div>
                                <div className='col s12 m9 m-0 p-10'>  
                                    <div className='col s12 m12'>
                                        <h6 style={{color:'#11cdef', fontWeight:900, fontSize:24,textTransform:'uppercase'}}> Detalles de el/los Producto(s)</h6>
                                    </div>  
                                    <div>
                                    </div>                    
                                    <div className='col s12 m12 table table-doc m-0 p-5' 
                                    style={{borderRadius:'0 0 20px 20px', background:'#fff', marginBottom:'15px !important'}}>
                                        <div className='table-header row'>
                                            <div className='row col s12'>
                                                <div className='col s1 centrar'>ID</div>
                                                <div className='col s5 centrar'>Productos</div>
                                                <div className='col s2 centrar'>$ Unidad</div>
                                                <div className='col s1 centrar'>Cantidad</div>
                                                <div className='col s1 centrar'>%</div>
                                                <div className='col s2 centrar'>$ con %</div>
                                            </div>
                                        </div>
                                        
                                        <div className='table-container'>
                                        {order?.order_details?.map( (productos)=>
                                            <div className='row col s12'>
                                                <div className='col s1 centrar'>{productos.id}</div>
                                                <div className='col s5 centrar'>{productos.product.nombre_producto}</div>
                                                <div className='col s2 centrar'>{productos.product.precio_unidad}</div>
                                                <div className='col s1 centrar'>{productos.cantidad}</div>
                                                <div className='col s1 centrar'>{productos.descuento}</div>
                                                <div className='col s2 centrar'>{productos.precio_unidad}</div>
                                            </div>
                                        )}  
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
            {/* <!-- Modal Filtro de Fechas -->*/}
            <FiltrosFecha openDateFilters={openDateFilters} setOpenDateFilters={setOpenDateFilters} searchByDate={searchByDate}/>
            {/* <!-- Modal Entregar Pedido -->*/}
            <EntregarPedidoModal orderIDSelected={orderIDSelected}/>                                     
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <div className='row col s12 info-badge border-r-10 m-p-0'>
                    <ul className="collapsible col s12 border-r-6">
                        <li className='m-p-0 border-r-6'>
                            <div className="collapsible-header border-r-6 m-p-0">
                                <div className='col s12 m1 m-p-0' style={{height:'36px', width:'36px'}}>
                                    <img className='m-p-0 dentometric' src='/images/FILTER1.png' alt='dento metric' 
                                    style={{height:'100%', width:'100%'}} />
                                </div>
                                <div className='row col s12 m10 m-p-0'> <b style={{color:'gray', paddingTop:'20', paddingLeft:20}}>CREAR FILTROS</b> </div>
                                <div className='col s12 m1 m-p-0 centrar-r' style={{height:'32px', width:'32px'}}>
                                    <img className='m-p-0 dentometric sombra-azul-chica' src='/images/clic.png' alt='dento metric' 
                                    style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                </div>
                            </div>
                            <div className="collapsible-body m-p-0"  style={{borderBottom:'none', padding:'0px!important'}}>

                            <div className='row col s12 m-p-0 '>    
                            <div className="col s12 m2 m-p-0">
                                <div className="input-text col s12 m-0 p-0-3">
                                    <label>ID:</label>
                                    <input className="custom-input" type="number"
                                    id='filterId' 
                                    placeholder='Buscar por ID'
                                    style={{fontSize:'12px',}}
                                    onKeyDown={(e)=>searchById(e)}
                                    />
                                </div>
                            </div>
    
                            <div className="col s12 m2 m-p-0">
                                <div className="input-text col s12 m-0">
                                    <label>Status:</label>
                                    <select className='browser-default  m-0 p-0-3 custom-select' 
                                        defaultValue={-1}
                                        onChange={(e)=>filterByStatus(e.target.value)}
                                        >
                                        <option value={-1}>Todos</option>
                                        <option value={1}>Entregado</option>
                                        <option value={0}>Pendiente</option>
                                    </select>
                                </div>
                            </div>  
                            
                            <div className="col s12 m4 m-p-0">
                                <div className="input-text col s12 m-0">
                                    <label>Sucursal:</label>
                                    <select className='browser-default  m-0 p-0-3 custom-select' 
                                        defaultValue={-1}
                                        onChange={(e)=>filterByStatus(e.target.value)}
                                    >
                                        <option value={-1}>Todas</option>
                                        {sucursales.map((sucursal)=>
                                            <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre_sucursal}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='input-text col s12 m2'><br></br><label>Boton de borrar Filtros &rarr;</label> </div>
                            <div className='input-text col s12 m2'> 
                                <div className="input-text col s12 m-p-0">
                                    <button 
                                    onClick={_limpiarFiltros}
                                    className='col s12 m12 m-p-0 button-hover'> 
                                        <div className='col s12 m8 centrar' > <b style={{color:'gray', paddingTop:8}}>LIMPIAR</b> </div>
                                            <div className='col s12 m4 m-p-0' style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'32px', width:'32px', padding:0, margin:'0!important', marginTop:'-6px'}}>
                                            <img className='dentometric sombra-azul-chica  m-p-0' src='/images/clean.png' alt='dento metric' 
                                            style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                        </div>
                                    </button>
                                </div>
                            </div>                     
                        </div>
                        <div className='row col s12 m-p-0 '>  
                            <div className='input-text col s12 m4'>
                                <label>F Pedido:</label> 
                                <input type="date" className="custom-input" style={{height:"24px!important"}} onChange={(e)=>searchByDate(e.target.value,"fecha_pedido")}/>
                            </div>
                            <div className='input-text col s12 m4'>
                                <label>F Envio:</label>
                                <input type="date" className="custom-input" style={{height:"24px!important"}}/>
                            </div>
                            <div className='input-text col s12 m4'>
                                <label className='col s12'>Buscar por Fecha:</label>
                                <button className="col s12 btn-blanco row m-p-0 " onClick={(e)=>setOpenDateFilters(true)} style={{width:'60%'}}>
                                    <div className='col s2 m2 m-p-0 centrar'>
                                        <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/calendar.png' alt='doctor dento metric' style={{height:'28px', width:'30px',}} />
                                    </div>    
                                    <div className='col s10 m10 m-0' style={{fontSize: '12px', cursor:'pointer', padding:'0', paddingTop:'6%!important'}}>Busqueda por fecha</div>
                                </button>
                            </div>  
                        </div>
                        <div className='row col s12 m-p-0 '>
                            <div className="col s12 m4">
                                <label>Paciente:</label>
                                <div className="input-field m-p-0">
                                    <input type="text" 
                                        id="paciente-autocomplete"
                                        autoComplete="off" 
                                        className="autocomplete" 
                                        onChange={(e)=>handleAutocompleteName(e.target.value)}
                                        style={{backgroundColor:"#ffffff",fontSize:"14px",height:24,border:"solid 1px #dee2e6",borderRadius:4}}   
                                    />
                                </div>
                            </div> 
                            <div className="col s12 m4">
                                <label>Doctores:</label>
                                <div className="input-field m-p-0">
                                    <input type="text" 
                                        id="doctores-autocomplete"
                                        autoComplete="off" 
                                        className="autocomplete" 
                                        onChange={(e)=>handleAutocompleteName(e.target.value)}
                                        style={{backgroundColor:"#ffffff",fontSize:"14px",height:24,border:"solid 1px #dee2e6",borderRadius:4}}   
                                    />
                                </div>
                            </div>
                            <div className="col s12 m4">
                                <label>Estudios:</label>
                                <div className="input-field m-p-0">
                                    <input type="text" 
                                        id="productos-autocomplete"
                                        autoComplete="off" 
                                        className="autocomplete" 
                                        onChange={(e)=>handleAutocompleteName(e.target.value)}
                                        style={{backgroundColor:"#ffffff",fontSize:"14px",height:24,border:"solid 1px #dee2e6",borderRadius:4}}   
                                    />
                                </div>
                            </div>
                        </div>

                            </div>
                        </li>
                    </ul>

                        
                    </div>
                    {!sinResultados?
                    <div className='table'>
                        <div className='table-header row m-p-0 movil-oculto'>
                            <div className='col s12 m1 row m-p-0'>
                                <div className='col s10 m4'> ID </div>
                                <div className='col s10 m8'>Status</div>
                            </div>
                            <div className='col s12 m2'> Paciente</div>
                            <div className='col s10 m2'> Doctor </div>
                            <div className='col s12 m3'>Estudios</div>
                            <div className='col s10 m2'> Entrega</div>
                            <div className='col s12 m1'> Fecha</div>
                            <div className='col s12 m1'> Acciones </div>                            
                        </div>  
                       

                        {pedidosFiltrados.map((pedido)=>
                        <div className='table-container row' key={pedido.id}>
                            <div className='col s12 m1 row m-p-0'>
                                <div className='col s10 m4 m-0 p-2'> {pedido.id} </div>
                                <div className='col s10 m8 m-0 m-p-0'>  
                                    {pedido.estatus === 1? 
                                        <p className='m-p-0'>
                                            <div className='m-p-0 centrar'>
                                                <img className='col s2 m2 m-0 dentometric centrar' src='/images/entregadosi.png' alt='dentometric' style={{padding:'8px 0 0 0', width:'42px',}} />
                                            </div> 
                                            <div className='m-p-0 centrar' style={{color:'green', fontSize:9}}>
                                                <b>Entregado</b>
                                            </div> 
                                        </p>
                                    :
                                        <p className='m-p-0 centrar'>
                                            <div className='m-p-0 centrar'>
                                                <img className='col s2 m2 m-0 dentometric centrar' src='/images/entregadono.png' alt='dentometric' style={{padding:'10px 0 0 0', width:'32px',}} />
                                            </div>  
                                        </p>
                                    }
                                    
                                </div>
                            </div>
                            <div className='col s12 m2 m-0 p-2' style={{fontSize:12}}> 
                                {pedido?.patient?.nombre}
                            </div>
                            <div className='col s10 m2 m-0 p-2'>{pedido?.doctor?.apellidos} {pedido?.doctor?.nombre}  </div>
                            <div className='col s12 m3 m-0 p-2' 
                            style={{display:'block!important', alignItems:'normal!important', verticalAlign:'middle!important'}}> 
                                <ul className=' m-p-0' style={{display:'block!important'}}>
                                {pedido.order_details &&
                                pedido.order_details.map( (detalle,i)=>
                                    <li key={i} className=' m-p-0' style={{display:'block!important'}}>
                                        <span> <i className="material-icons" style={{fontSize:'12px'}}>play_arrow</i> </span> {detalle?.product.nombre_producto}
                                    </li>
                                )}
                                </ul>
                            </div>
                            <div className='col s10 m2 m-0 p-2'>{pedido?.employee} </div>
                            <div className='col s12 m1 m-0 p-2' style={{fontSize:12, display:'block'}}>
                                <p className='col s12 m12 m-p-0'>
                                    <b className='col s12 m12 m-p-0' style={{fontSize:'10px'}}>F. Pedido</b>
                                    {new Date(pedido.fecha_pedido).toLocaleDateString("es-MX", { year: 'numeric', month: 'numeric', day: 'numeric' })}
                                </p>
                                <p className='col s12 m12 m-p-0'>
                                    <b className='col s12 m12 m-p-0' style={{fontSize:'10px'}}>F. Envio</b>
                                    {new Date(pedido.fecha_envio).toLocaleDateString("es-MX", { year: 'numeric', month: 'numeric', day: 'numeric' })}
                                </p>
                            </div>
                            <div className='col s12 m1 m-0 p-2'>
                                <div className='row m-p-0 '>
                                    <button className="col m6 m-0 p-0-2 centrar movil-100 tooltipped modal-trigger" 
                                    to="#modal-detalle-info" style={{background:'none', border:'transparent'}}
                                    onClick={(e)=> _details(pedido)}
                                    data-target="modal-detalle-info" 
                                    data-tooltip="Ver mas" >
                                        <i className="material-icons m-p-0 t-azul" 
                                        style={{color:'orange!important',cursor:'pointer', fontSize:'20px',}}>find_in_page</i>
                                    </button>
                                    <div 
                                    data-tooltip="Editar"
                                    className="col m6 m-0 p-0-2 centrar movil-100 tooltipped">
                                        <Link to={`${pedido.id}`} >
                                            <i className="material-icons m-p-0 t-verde" 
                                            style={{color:'green!important', cursor:'pointer',fontSize:'20px'}} >create</i>
                                        </Link>
                                    </div>
                                    {pedido.estatus === 0? 
                                        <button className="col m6 m-0 p-0-2 centrar movil-100" style={{background:'none', border:'transparent'}} onClick={(e)=>openEntregaModal(pedido.id)}>
                                            <i className="material-icons indigo-text" style={{cursor:'pointer', fontSize:'22px',}}> local_shipping</i>
                                        </button>
                                    :
                                    <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                        )}
                    </div>:
                    <div className='col s12 height-200 centrado centrar'>
                        <h5 className='bg-warning border-r-6 p-20'>No se encontraron coincidencias</h5>
                    </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Pedidos;