import React from 'react';

const NoPermissions = () => {
    return (
        <>
            <h5 className='centrar p-10 negritas'> 
                <span className='t-rojo'> No &nbsp;</span> tienes permisos para ver esta información</h5>
            <div className='centrar' style={{height:'40vh',}}>
                <div className='no-access'>  
                    <div className='height-200 centrar p-t-35'>
                        &nbsp;
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoPermissions;