import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../axios';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';

const Editar = () => {
    let {proveedorId} = useParams();
    const [proveedor,setProveedores] = useState({nombre_proveedor:"",  id:0});
    const navigate = useNavigate();
    useEffect (() =>{
        axios.get(`api/suppliers/${proveedorId}`)
        .then(res=>{
            console.log(res.data, "editar proveedor");
            setProveedores(res.data);
        }).catch(e =>{console.log(e, 'Error ')})
    },[])

    const _update = (e) => {
        e.preventDefault();
        console.log("guardando...",proveedor)
        axios.put(`api/suppliers/${proveedorId}`,proveedor)
        .then(res=>{
            console.log("nuevo proveedor..",res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Proveedor actualizada exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/proveedores")
            }, 5000);
        }).catch(e=>{
            console.log("error",e)
            if(e.message) M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+e.message+'</span>',classes:"error-notify"})
        })
    }
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb actual={proveedor.nombre_proveedor}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5>Editar proveedor</h5>
                    <form className="row ancho100" id='general-form'  onSubmit={_update}>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">Nombre</label>
                                <input id="name" type="text" className="validate" value={proveedor.nombre_contacto} 
                                onChange={(e)=>setProveedores({...proveedor,nombre_contacto:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">Nombre</label>
                                <input id="name" type="text" className="validate" value={proveedor.nombre_compania} 
                                onChange={(e)=>setProveedores({...proveedor,nombre_compania:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="observaciones">Observaciones</label>
                                <input id="observaciones" type="text" className="validate" value={proveedor.observaciones} 
                                onChange={(e)=>setProveedores({...proveedor,observaciones:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="web">Página WEB</label>
                                <input id="web" type="text" className="validate" value={proveedor.pagia_web} 
                                onChange={(e)=>setProveedores({...proveedor,pagia_web:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="rfc">RFC</label>
                                <input id="rfc" type="text" className="validate" value={proveedor.rfc} 
                                onChange={(e)=>setProveedores({...proveedor,rfc:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="input-text">
                                <label htmlFor="tel1">Telefono</label>
                                <input id="tel1" type="text" className="validate" value={proveedor.telefono} 
                                onChange={(e)=>setProveedores({...proveedor,telefono:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="input-text">
                                <label htmlFor="tel2">Celular</label>
                                <input id="tel2" type="text" className="validate" value={proveedor.telefono_celular} 
                                onChange={(e)=>setProveedores({...proveedor,telefono_celular:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="input-text">
                                <label htmlFor="tel3">Tel. Particular</label>
                                <input id="tel3" type="text" className="validate" value={proveedor.telefono_particular} 
                                onChange={(e)=>setProveedores({...proveedor,telefono_particular:e.target.value})}/>
                            </div>
                        </div>
                   
                        
                        <div className='col s12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Editar;