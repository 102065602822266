import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import axios from '../../axios';
import { useNavigate } from 'react-router-dom';

const New = () => {
    const [doctor,setDoctor] = useState({
        nombre:'',
        apellidos:'',
        telefono:'',
        celular:'',
        email:'',
        pagina:'',
        fax:'',
        rfc:'',
        compania:'',
        descuento:0,
        observaciones_generales:'',
        observaciones_importantes:'',
        observaciones_dental:'',
        boletin:false,
        visitar:null,
        especialidades:[],
        instituciones:[]
    });
    const [especialidades,setEspecialidades] = useState([]);
    const [instituciones,setInstituciones] = useState([]);
    const navigate = useNavigate();
    var selectEspecialidad = {};
    var selectInstitucion = {}; 

    
    
    useEffect(()=>{
        axios.get('api/specialties').then((res)=>{
            console.log(res.data)
            setEspecialidades(res.data);  
        }).catch(e=>console.log(e))

        axios.get('api/institutions').then((res)=>{
            console.log(res.data)
            setInstituciones(res.data);  
        }).catch(e=>console.log(e))
        
    },[])
    
    selectEspecialidad = M.FormSelect.init(document.querySelector('#especialidades'));
    selectInstitucion = M.FormSelect.init(document.querySelector('#instituciones'));

    const setEspacialidadesHandler = () =>{
        setDoctor({...doctor,especialidades:selectEspecialidad.getSelectedValues()});
    }
    const setInstitucionesHandler = () =>{
        setDoctor({...doctor,instituciones:selectInstitucion.getSelectedValues()});
    }

    const _saveDoctor = (e) => {
        e.preventDefault();
        console.log("doctor..",doctor)
        axios.post('api/doctors',doctor).then(resp=>{
            console.log(resp.data)
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Doctor creado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/doctores")
            }, 3000);
        }).catch(e=>console.log(e))
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin  z-depth-1'>
                    <h5 className='p-5'>Alta de doctor</h5>
                    <form  onSubmit={_saveDoctor} className="row ancho100" id='general-form'>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">nombres</label>
                                <input id="name" type="text"  onChange={(e)=>setDoctor({...doctor,nombre:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="apellidos">Apellidos</label>
                                <input id="apellidos" type="text" onChange={(e)=>setDoctor({...doctor,apellidos:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className='input-text'>
                                <label>Especialidades</label>
                                <select id="especialidades" multiple onChange={(e)=>setEspacialidadesHandler()}>
                                    <option value="" disabled>Seleccione las especialidades</option>
                                    {especialidades.map((item) =>
                                        <option value={item.id}  key={item.id}>{item.especialidad}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className='input-text'>
                                <label>Instituciones</label>
                                <select id="instituciones" multiple onChange={(e)=>setInstitucionesHandler()}>
                                    <option value="" disabled >Seleccione las instituciones</option>
                                    {instituciones.map((item) =>
                                        <option value={item.id}  key={item.id}>{item.institucion}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="rfc">RFC</label>
                                <input id="rfc" type="text" onChange={(e)=>setDoctor({...doctor,rfc:e.target.value})} data-length="15" maxLength='15'/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="company">Compañia</label>
                                <input id="company" type="text" onChange={(e)=>setDoctor({...doctor,compania:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="tel">Telefono Particular</label>
                                <input id="tel" placeholder="Ej. 3330893423" type="text" onChange={(e)=>setDoctor({...doctor,telefono:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="cel">Celular</label>
                                <input id="cel" placeholder="Ej. 3330893423" type="text" onChange={(e)=>setDoctor({...doctor,celular:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="email">E-mail</label>
                                <input id="email" type="email" onChange={(e)=>setDoctor({...doctor,email:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="pagina">Pagina Web</label>
                                <input id="pagina" type="text" onChange={(e)=>setDoctor({...doctor,pagina:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="fax">Fax</label>
                                <input id="fax" type="text" onChange={(e)=>setDoctor({...doctor,fax:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m2">
                            <div className="input-text">
                                <label htmlFor="descuento">Descuento %</label>
                                <input id="descuento" type="number" onChange={(e)=>setDoctor({...doctor,descuento:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s6 m3 centrar" style={{minHeight:90}}>       
                            <div className="switch input-text">
                                <label>
                                VISITAR
                                <input id="visitar" type="checkbox" onChange={(e)=>setDoctor({...doctor,visitar:e.target.checked})}/>
                                <span className="lever"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col s6 m3 centrar" style={{minHeight:90}}>       
                            <div className="switch input-text">
                                <label>
                                BOLETIN
                                <input id="boletin" type="checkbox"  onChange={(e)=>setDoctor({...doctor,boletin:e.target.checked})}/>
                                <span className="lever"></span>
                                </label>
                            </div>
                        </div>

                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="observa1">Observaciones Generales</label>
                                <textarea id="observa1" className="materialize-textarea validate"
                                onChange={(e)=>setDoctor({...doctor,observaciones_generales:e.target.value})}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="observa2">Observaciones Importantes</label>
                                <textarea id="observa2" className="materialize-textarea validate"
                                onChange={(e)=>setDoctor({...doctor,observaciones_importantes:e.target.value})}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="observa3">Observaciones Dental</label>
                                <textarea id="observa3" className="materialize-textarea validate"
                                onChange={(e)=>setDoctor({...doctor,observaciones_dental:e.target.value})}
                                ></textarea>
                            </div>
                        </div>

                        <div className='col s12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                                <i className="material-icons right">save</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default New;