import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import M from 'materialize-css';
import Breadcrumb from '../Breadcrumb/Breadcrumb';


const New = () => {
    const [especialidad, setEspecialidad] = useState({especialidad:'',notas:''})
    const [validate,setValidate] = useState({especialidad:true});
    const navigate = useNavigate();

    const _save = (e)=>{
        e.preventDefault();
        console.log(especialidad)
        axios.post('api/specialties',especialidad)
        .then((res)=>{
            console.log(res.data)
            setEspecialidad(res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Nueva especialidad</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/especialidades")
            }, 5000);
        }).catch(e=>{
            console.log("error",e)
            if(e.response.data.errors){
                let errors = e.response.data.errors;
                setValidate({
                    especialidad:(errors.especialidad)? false:true, 
                })
                if(errors.especialidad) {
                    M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span> Falta especialidad </span>',classes:"error-notify"})
                }
            }
        })
    }

    return (
        <>
            <div className='container-admin-menu'><Breadcrumb/></div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5>Nueva especialidad</h5>
                    <form className="row ancho100" id='general-form'  onSubmit={_save}>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">Especialidad *</label>
                                <input id="name" type="text" className="validate" 
                                onChange={(e)=>setEspecialidad({...especialidad, especialidad:e.target.value})}/>
                                {!especialidad.especialidad &&  <span class="helper-text t-rojo">El nombre de la Especialidad es obligatorio</span>}
                            </div>
                        </div>
                        <div className='col s12'>
                            <div className='input-text'>
                                <label htmlFor='notas'>Notas</label>
                                <textarea id='notas' value={especialidad.notas? especialidad.notas:''}
                                onChange={(e)=>setEspecialidad({...especialidad,notas:e.target.value})}></textarea>
                                {especialidad.notas.length > 255 &&  <span class="helper-text t-rojo"> La descripcion es muy larga</span>}
                            </div>
                        </div>
                        <div className='col s12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default New;