import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import M from 'materialize-css';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

const New = () => {
    const [institucion, setInstitucion] = useState({institucion:''})
    const navigate = useNavigate();

    const _save = (e)=>{
        e.preventDefault();
        axios.post('api/institutions',institucion)
        .then((res)=>{
            console.log(res.data)
            setInstitucion(res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Nueva Institucion</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/instituciones")
            }, 3000);
        }).catch(e=>console.log(e))
    }
    return (
        <>
        <div className='container-admin-menu'><Breadcrumb/></div>
        <div className='container-admin-container'>
            <div className='container-admin'>
                <h5>Nueva Institucion</h5>
                <form className="row ancho100" id='general-form'  onSubmit={_save}>
                    <div className="col s12">
                        <div className="input-text">
                            <label htmlFor="name">Institucion</label>
                            <input id="name" type="text" className="validate" value={institucion.institucion} 
                            onChange={(e)=>setInstitucion({...institucion,institucion:e.target.value})}/>
                        </div>
                    </div>
                    <div className='col s12 p-t-35'> 
                        <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                            <i className="material-icons right">send</i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>
    );
};

export default New;