import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './breadcrumb.scss'

const Breadcrumb = (props) => {
    const {hasButton,hasModalButton,HasFilters,actual} = props;
    const [breadcrumbs,setBreadcrums] = useState([])
    const navigate = useNavigate();
    let location = useLocation();
    let ruta = '/admin';

    useEffect(()=>{
        //console.log("actual",location)
        let arrayRutes = location.pathname.split("/");
        setBreadcrums(arrayRutes);
    },[location])

    const _changeView = ()=>{
        navigate(location.pathname+"/new");
    }

    return (
        <div>
            <div className='row m-p-0' >
                <div className='col s9 m9 row m-0'>
                    <div className='col s12 m3 actual movil-text-derecho centrar-l'>
                        <div className='centrar'>  
                           <i className="icon material-icons">my_location</i>&nbsp;&nbsp;{breadcrumbs[2]}
                        </div>
                    </div>
                    <div className='col s12 m9 migajas'> 
                        {breadcrumbs.map((item,index)=>
                            (index>1&&!Number(item))&&
                               <span className='col row m-0' style={{padding:'0 2%',}} key={index}>
                                <i className="icon material-icons" style={{fontSize:6}}>fiber_manual_record</i> &nbsp;&nbsp;
                                <Link to={item==='detail'?location.pathname:ruta+='/'+item}>{item}</Link>  
                              </span>
                            
                        )}
                        {actual&&
                        
                            <span className='col s12 m6 row m-0' style={{padding:'0 2%',}}>
                                <i className="icon material-icons" style={{fontSize:6}}>fiber_manual_record</i> &nbsp;&nbsp;
                                <Link to={location.pathname}>{actual}</Link>  
                            </span>
                        }
                    </div>
                </div>
                <div className='col s3 m3 row m-p-0 centrar-r'>
                    {hasModalButton&&
                        <div className='col s3 m6 m-p-0 cont-btn-bread movil-100' style={{float:'right'}}>
                            <button className='btn-blanco modal-trigger' href={"#"+hasModalButton}> Nuevo </button>
                        </div>
                    }
                    {hasButton&&
                        <div className='col s3 m6 m-p-0 cont-btn-bread movil-100' style={{float:'right'}}>
                            <button className='btn-blanco' onClick={()=>_changeView()}> Nuevo </button>
                        </div>
                    }
                    {HasFilters&&
                        <div className='col s3 m6 m-p-0 cont-btn-bread movil-100'>
                            <button className='btn-blanco'>Filtros</button></div>
                    }  
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;