import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import { useParams } from 'react-router-dom';
import M from 'materialize-css';
import GeneralTab from './Tabs/GeneralTab';
import ProductosTab from './Tabs/ProductosTab';
import PagosTab from './Tabs/PagosTab';

const Edit = () => {
    const  {pedidoId} = useParams();
    const [pedido,setPedido] = useState();
    const [updated,setUpdated] = useState(true);
    const [total,setTotal] = useState(0);
    const [loading,setLoading] = useState(true);
    
    useEffect(()=>{
        console.log("updated",updated)
        if(updated){
            var elem = document.querySelector('#ordertabs');
            M.Tabs.init(elem);
            axios.get(`api/orders/${pedidoId}`).then((resp)=>{
                console.log("la orden",resp.data)
                setPedido(resp.data);
                setLoading(false);
                setUpdated(false);
            }).catch(e=>console.log("Error: ",e));
        }
    },[pedidoId,updated]);

    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <div className='container-admin-container'>
                    
                        <div className='row info-badge border-r-10' style={{bordeRadius:'10px!important', padding:'0px!important'}}>
                        {!loading&&
                            <>
                                <div className='col s12 m1'>
                                    ID Pedido: {pedido.id}
                                </div>     
                                <div className='col s12 m4'>
                                    Fecha de pedido: {new Date(pedido.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}
                                </div>     
                                <div className='col s12 m3'>
                                    Sucursal: {pedido?.sucursal?.nombre_sucursal}
                                </div>
                                <div className='col s12 m4'>
                                    Atendio: {pedido.employee_atendido_por}
                                </div>
                            </>
                        }
                        </div> 
                        {/*** TABS ***/}
                        <div className='row'>
                            <div className="col s12">
                                <ul className="tabs" id="ordertabs">
                                    <li className="tab col s12 m2"><a className="active" href="#generales">Datos generales</a></li>
                                    <li className="tab col s12 m2"><a href="#productos">Detalles Productos</a></li>
                                    <li className="tab col s12 m2"><a href="#caja">Caja</a></li>
                                </ul>
                            </div>
                            <div id="generales" className="col s12">
                              {!loading &&
                               <GeneralTab 
                                    patient={pedido?.patient}
                                    doctor={pedido?.doctor}
                                    direccion={pedido?.direccion_envio}
                                    comentarios={pedido?.comentarios_envio}
                                    envioID={pedido?.delivery_id}
                                    pedidoId={pedidoId}
                                    />
                                }
                            </div>
                            <div id="productos" className="col s12">
                            {!loading && <ProductosTab order={pedido} orderDetails={pedido.order_details} total={total} setTotal={setTotal} setUpdated={setUpdated}/>}
                            </div>
                            <div id="caja" className="col s12">
                                {!loading && <PagosTab order={pedido}  payment={pedido.order_payment} orderDetails={pedido.order_details} total={total}/>}
                            </div>
                        </div>
                                
                       
                    </div>
                </div>
            </div> 
        </>  
    );
};

export default Edit;