import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import axios from '../../../axios';
import M from 'materialize-css';
import { useNavigate, useParams } from 'react-router-dom';

const Edit = () => {
    const [pais,setPais] = useState({description:'',code:'',status:1});
    const {paisId} = useParams();
    const navigate = useNavigate()

    useEffect(()=>{
        axios.get(`api/countries/${paisId}`)
        .then(res=>{
            console.log(res.data)
            setPais(res.data)
        }).catch(e=>console.log("error",e))
    },[paisId])

    const _update = (e)=>{
        e.preventDefault();
        axios.put(`api/countries/${paisId}`,pais)
        .then((res)=>{
            console.log(res.data)
            setPais(res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Pais actualizado exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/paises")
            }, 3000);
        }).catch(e=>console.log(e))
    }
    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb actual={pais.description}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5>Editar país</h5>
                    <form className="row ancho100" id='general-form'  onSubmit={_update}>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">País</label>
                                <input id="name" type="text" className="validate" value={pais.description} 
                                onChange={(e)=>setPais({...pais,description:e.target.value})}/>
                            </div>
                        </div>
                     
                        <div className="col s12">
                            <div className="switch">
                                <label>
                                Activo
                                <input type="checkbox" checked={pais.status} onChange={(e)=>setPais({...pais,status:e.target.checked})}/>
                                <span className="lever"></span>
                                Inactivo
                                </label>
                            </div>
                        </div>

                        <div className='col s12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Edit;