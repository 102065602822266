import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import axios from '../../../axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Nuevo = () => {
    const [cupon,setCupones] = useState({
        nombre:'',
        tipo:'',
        descuento:'',
        vigencia:'',
        activo:1,
    });
    const navigate = useNavigate();

    const _saveCupone = (e) => {
        e.preventDefault();
        console.log("cupones..",cupon)
        axios.post('api/coupons',cupon).then(resp=>{
            console.log('guardo paciente', resp.data)
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>cupon creado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/cupones")
            }, 3000);
        }).catch(e=>console.log(e))
    }
    return (
        <>
                    <div className='container-admin-menu '> <Breadcrumb hasButton={true} hasFilters={false} /> </div>
                    <div className='container-admin-container'>
                        <div className='container-admin z-depth-1'>
                            <div className='container-admin-container m-p-0'>
                                <div className='row col s12 info-badge border-r-10 m-p-0' style={{bordeRadius:'10px!important', padding:'0px!important'}}>
                                    <div className="col s12 m12 row m-0 ">
                                        <div className='col s12 m3 m-p-0' 
                                        style={{height:'45px', width:'44px', padding:0, margin:'0!important',}}>
                                            <img className='dentometric m-p-0' src='/images/cupones.png' alt='dento metric' 
                                            style={{height:'100%', width:'100%'}} />
                                        </div>
                                        <div className="col s12 m6 m-6 centar"> <br></br> Crear nuevo cupone </div> 
                                    </div>
                                </div>
                            </div>
                            
                            <div> 
                            <form  onSubmit={_saveCupone} className="row ancho100" id='general-form'>
                                <div className="col s12 m12 m-t-25">
                                    <div className="input-text">
                                        <label htmlFor="name"> Nombre </label>
                                        <input id="name" type="text"  
                                        onChange={(e)=>setCupones({...cupon,nombre:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="tipo">Tipo de Cupon</label>
                                        <select className='browser-default custom-select' value={cupon.tipo} onChange={(e)=> setCupones({...cupon,tipo:e.target.value})}>
                                        <option value="">Seleccione un tipo</option>
                                            <option value={'Descuento %'}>Descuento %</option>
                                            <option value={'Cantidad'}>Cantidad</option>
                                        </select>
                                        
                                    </div>
                                </div>
                                <div className="col s12 m3 m-t-25">
                                    <div className="input-text">
                                        <label htmlFor="descuento"> Descuento </label>
                                        <input id="descuento" type="number"  
                                        onChange={(e)=>setCupones({...cupon,descuento:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="vigencia">Vigencia (Expira el día)</label>
                                        <input id="vigencia" type="date" 
                                        onChange={(e)=>setCupones({...cupon,vigencia:e.target.value})}/>
                                    </div>
                                </div>
                                

                                <div className="col s12 m12 centrar-r"> 
                                    <button className="btn waves-effect waves-light" type="submit" name="action"> Guardar cupon
                                        <i className="material-icons right">save</i>
                                    </button>
                                </div>
                            </form>


                            </div>
                        </div>
                    </div>
        </>
    );
};

export default Nuevo;