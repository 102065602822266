import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axios';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Spinner from '../../Layout/Loaders/Spinner';

const Editar = () => {
    let {sucursalId} = useParams();
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({sucursals_update:null});
    const [sucursal,setSucursal] = useState({nombre_sucursal:"", domicilio:"", horario:"", horario_entregas:"", telefono1:"", telefono2:"", telefono3:"", id:0});
    const [validate,setValidate] = useState({nombre_sucursal:true, domicilio:true, horario:true, horario_entregas:true, telefono1:true});
    const [loaded,setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect (() =>{
        setLoaded(false);
        if(user.user.id){
            setPermisos((prevPremisos) => ({...prevPremisos,sucursals_update:checkPermiso("sucursals_update",user.user.permissions)}));
            axios.get(`api/sucursals/${sucursalId}`).then(res=>{
                console.log(res.data, "editar sucursal");
                setSucursal(res.data)
            }).catch(e =>{console.log(e, 'Error ')}) 
            setLoaded(true);
        }
    },[sucursalId, user])

    useEffect(()=>{
        (permisos.sucursals_update !==null) && setLoaded(true)
    },[permisos]);
    
    const _update = (e) => {
        e.preventDefault();
        console.log("guardando...",sucursal)
        axios.put(`api/sucursals/${sucursalId}`,sucursal)
        .then(res=>{
            console.log("nueva sucursal..",res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>sucursal actualizada exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/sucursales")
            }, 5000);
        }).catch(e=>{
            console.log("error",e)
            if(e.message) M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+e.message+'</span>',classes:"error-notify"})
            if(e.response.data.errors){
                let errors = e.response.data.errors;
                setValidate({
                    nombre_sucursal:(errors.nombre_sucursal)? false:true, 
                    domicilio:(errors.domicilio)? false:true,
                    horario:(errors.horario)? false:true,
                    horario_entregas:(errors.horario_entregas)? false:true,
                    telefono1:(errors.telefono1)? false:true,
                    telefono2:(errors.telefono2)? false:true,
                    telefono3:(errors.telefono3)? false:true,
                })
            }
        })
    }
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb actual={sucursal.nombre_sucursal}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                {loaded ?
                    <>
                        <h5>Editar sucursal</h5>
                        {permisos.sucursals_update ?
                            <form className="row ancho100" id='general-form'  onSubmit={_update}>
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="name">Nombre *</label>
                                        <input id="name" type="text" className="validate" value={sucursal.nombre_sucursal} 
                                        onChange={(e)=>setSucursal({...sucursal,nombre_sucursal:e.target.value})}/>
                                        {!validate.nombre_sucursal &&  <span class="helper-text t-rojo">El nombre es obligatorio</span>}
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="domicilio">Domicilio *</label>
                                        <input id="domicilio" type="text" className="validate" value={sucursal.domicilio} 
                                        onChange={(e)=>setSucursal({...sucursal,domicilio:e.target.value})}/>
                                        {!validate.domicilio &&  <span class="helper-text t-rojo">El domicilio es obligatorio</span>}
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="horario">Horario de Atención *</label>
                                        <input id="horario" type="text" className="validate" value={sucursal.horario} 
                                        onChange={(e)=>setSucursal({...sucursal,horario:e.target.value})}/>
                                        {!validate.horario &&  <span class="helper-text t-rojo">El horario de atencion es obligatorio</span>}
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <div className="input-text">
                                        <label htmlFor="horario_entregas">Horario de entrega *</label>
                                        <input id="horario_entregas" type="text" className="validate" value={sucursal.horario_entregas} 
                                        onChange={(e)=>setSucursal({...sucursal,horario_entregas:e.target.value})}/>
                                        {!validate.horario_entregas &&  <span class="helper-text t-rojo">El horario de entrega es obligatorio</span>}
                                    </div>
                                </div>
                                <div className="col s4">
                                    <div className="input-text">
                                        <label htmlFor="telefono1">Telefono *</label>
                                        <input id="telefono1" type="number" className="validate" value={sucursal.telefono1} 
                                        pattern="[0-9]{10}" required length="10"
                                        onChange={(e)=>setSucursal({...sucursal,telefono1:e.target.value})}/>
                                        {
                                            (() => {
                                                if(!validate.telefono1) {
                                                    return (
                                                        <span class="helper-text t-rojo">El telefeno es obligatorio</span>
                                                    )
                                                } 
                                                if(sucursal.telefono1.length !== 10) {
                                                    return (
                                                        <span class="helper-text t-rojo">10 dijitos</span>
                                                    )
                                                } 
                                            })()  
                                        } 
                                        
                                    </div>
                                </div>
                                <div className="col s4">
                                    <div className="input-text">
                                        <label htmlFor="telefono2">Telefono</label>
                                        <input id="telefono2" type="number" className="validate" value={sucursal.telefono2} 
                                        onChange={(e)=>setSucursal({...sucursal,telefono2:e.target.value})}/>
                                        {
                                            (() => {
                                                if(sucursal.telefono2.length !== 10) {
                                                    return (
                                                        <span class="helper-text t-rojo">10 dijitos</span>
                                                    )
                                                } 
                                            })()  
                                        } 
                                    </div>
                                </div>
                                <div className="col s4">
                                    <div className="input-text">
                                        <label htmlFor="telefono3">Telefono</label>
                                        <input id="telefono3" type="number" className="validate" value={sucursal.telefono3} 
                                        onChange={(e)=>setSucursal({...sucursal,telefono3:e.target.value})}/>
                                        {
                                            (() => {
                                                if(sucursal.telefono3.length !== 10) {
                                                    return (
                                                        <span class="helper-text t-rojo">10 dijitos</span>
                                                    )
                                                } 
                                            })()  
                                        } 
                                    </div>
                                </div>
                        
                                
                                <div className='col s12 p-t-35'> 
                                    <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                        <i className="material-icons right">send</i>
                                    </button>
                                </div>
                            </form>
                            :
                            <NoPermissions />
                        }
                    </>
                    :
                    <div className='height-200 centrar'>
                        <Spinner size={"medium"} />
                    </div>
                }
                </div>
                
            </div>
        </div>
    );
};

export default Editar;