import React,{ useContext, useEffect, useState}  from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import M from 'materialize-css';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import Spinner from '../../../Layout/Loaders/Spinner';

const Edit = () => {
    let {institucionId} = useParams();
    const user = useContext(UserContext);
    const [institucion,setInstitucion] = useState({institucion:''})
    const [permisos,setPermisos] = useState({doctor_institutions_update:null});
    const [loaded,setLoaded] = useState(false);
    const navigate = useNavigate()
    
    useEffect(()=>{
        setLoaded(false);
        if(user.user.id){
            setPermisos((prevPremisos) => ({...prevPremisos,doctor_institutions_update:checkPermiso("doctor_institutions_update",user.user.permissions)}));
            axios.get(`api/institutions/${institucionId}`)
            .then((res)=>{
                setInstitucion(res.data)
            }).catch(e=>console.log(e))
        }
    },[user,institucionId])

    useEffect(()=>{
        (permisos.doctor_institutions_update !==null) && setLoaded(true)
    },[permisos]);

    const _update = (e) =>{
        e.preventDefault()
        axios.put(`api/institutions/${institucionId}`,institucion)
        .then((res)=>{
            console.log(res.data)
            setInstitucion(res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Institucion actualizada exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/instituciones")
            }, 4000);
        }).catch(e=>console.log(e))
    }

    return (
        <>
            <div className='container-admin-menu'><Breadcrumb actual={institucion.institucion}/></div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                {loaded ?
                    <>
                        <h5>Editar Institución</h5>
                        {permisos.doctor_institutions_update ?
                        <form className="row ancho100" id='general-form'  onSubmit={_update}>
                            <div className="col s12">
                                <div className="input-text">
                                    <label htmlFor="name">Institución</label>
                                    <input id="name" type="text" className="validate" value={institucion.institucion} 
                                    onChange={(e)=>setInstitucion({...institucion,institucion:e.target.value})}/>
                                </div>
                            </div>
                            <div className='col s12 p-t-35'> 
                                <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                    <i className="material-icons right">send</i>
                                </button>
                            </div>
                        </form>
                        :
                        <NoPermissions />
                        }
                    </>  
                    :
                    <div className='height-200 centrar'>
                        <Spinner size={"medium"} />
                    </div>
                }
                </div>
            </div>
        </>
    );
};

export default Edit;