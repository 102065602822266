import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';

const Ingresos = () => {
    
    return (
        <>
            <div className='container-admin-menu'> 
                <Breadcrumb  hasFilters={false} />
            </div> 
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <div className="col m12 s12 cont-box m-0 p-10">
                        <h1>Filtros Reportes</h1>
                    </div>
                    <div className="row m-p-0 cont-row">
                     

                        <div className="col m4 s12 m-0 p-10 bordo-marco" style={{color:'green'}}>
                            <div className='m-0 p-10 border-container'>
                                <Link to={'reporte-global-sucursal'} className="border-a m-p-0 centrar"
                                style={{boxShadow:'green!important'}}>
                                    <div  className="border-a" style={{boxShadow: '3px 3px 10px green'}}>
                                        <div className='col m12 row m-p-0 centrar' >
                                            <i className="icon material-icons font38 m-0 p-10">assignment</i>
                                            <h6>Resumen Global de Ingresos por Sucursal</h6>
                                        </div>
                                        <span className="border-cont border-top"></span>
                                        <span className="border-cont border-right"></span>
                                        <span className="border-cont border-bottom"></span>
                                        <span className="border-cont border-left"></span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col m4 s12 m-0 p-10 bordo-marco" style={{color:'red'}}>
                            <div className='m-0 p-10 border-container'>
                                <Link to={'reporte-global'} className="border-a m-p-0"
                                style={{boxShadow:'red!important'}}>
                                    <div  className="border-a" style={{boxShadow: '3px 3px 10px red'}}>
                                        <div className='col m12 row m-p-0 centrar' >
                                            <i className="icon material-icons font38 m-0 p-10">assignment</i>
                                            <h6>Resumen Global de Ingresos</h6>
                                        </div>
                                        <span className="border-cont border-top"></span>
                                        <span className="border-cont border-right"></span>
                                        <span className="border-cont border-bottom"></span>
                                        <span className="border-cont border-left"></span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col m4 s12 m-0 p-10 bordo-marco" style={{color:'orange'}}>
                            <div className='m-0 p-10 border-container'>
                                <Link to={'reporte-global-sucursal'} className="border-a m-p-0"
                                style={{boxShadow:'orange!important'}}>
                                    <div  className="border-a" style={{boxShadow: '3px 3px 10px orange'}}>
                                        <div className='col m12 row m-p-0 centrar' >
                                            <i className="icon material-icons font38 m-0 p-10">assignment</i>
                                            <h6>Reporte Global de Ingresos por Día en Sucursal</h6>
                                        </div>
                                        <span className="border-cont border-top"></span>
                                        <span className="border-cont border-right"></span>
                                        <span className="border-cont border-bottom"></span>
                                        <span className="border-cont border-left"></span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col m4 s12 m-0 p-10 bordo-marco" style={{color:'blue'}}>
                            <div className='m-0 p-10 border-container'>
                                <Link to={'reporte-global-sucursal'} className="border-a m-p-0"
                                style={{boxShadow:'blue!important'}}>
                                    <div  className="border-a" style={{boxShadow: '3px 3px 10px blue'}}>
                                        <div className='col m12 row m-p-0 centrar' >
                                            <i className="icon material-icons font38 m-0 p-10">assignment</i>
                                            <h6>Reporte Global de Ingresos por Día Persona</h6>
                                        </div>
                                        <span className="border-cont border-top"></span>
                                        <span className="border-cont border-right"></span>
                                        <span className="border-cont border-bottom"></span>
                                        <span className="border-cont border-left"></span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col m4 s12 m-0 p-10 bordo-marco" style={{color:'purple'}}>
                            <div className='m-0 p-10 border-container'>
                                <Link to={'reporte-global-sucursal'} className="border-a m-p-0">
                                    <div  className="border-a" style={{boxShadow: '3px 3px 10px purple'}}>
                                        <div className='col m12 row m-p-0 centrar' >
                                            <i className="icon material-icons font38 m-0 p-10">assignment</i>
                                            <h6>Reporte Facturación Sucursal</h6>
                                        </div>
                                        <span className="border-cont border-top"></span>
                                        <span className="border-cont border-right"></span>
                                        <span className="border-cont border-bottom"></span>
                                        <span className="border-cont border-left"></span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ingresos;