import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { useParams } from 'react-router-dom';
import PermisosCollapsible from './PermisosCollapsible';
import M from 'materialize-css';
import axios from '../../axios';
import { useNavigate } from 'react-router-dom';

const EditarRol = () => {
    const  {rolId} = useParams();
    const navigate = useNavigate();
    const [roles,setRoles] = useState();
    const [rol,setRol] = useState({ 
        name:null,
        description:null,
        permissions_updated:[]
    });
    const [permisosSelected,setPermisosSelected] = useState([])

    const getRol = () =>{
        axios.get(`api/get_rol/${rolId}`).then((resp)=>{
            console.log(resp.data, "roles");
            setRol(resp.data);
            let permisosNames = resp.data.permissions.map(item=>item.name);
            console.log("permisosNames",permisosNames);
            setPermisosSelected(permisosNames)
        }).catch(e=>console.log(e));
    }

    const saveRole = () => {
        console.log("nuevo Rol", rol);
        axios.put(`api/update_rol/${rolId}`, rol).then((res)=>{
            console.log("Guarda", res.data);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Rol editado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/roles")
            }, 3000);
        }).catch(e=>console.log(e))
    }

    useEffect(()=>{
        setRol((prevRol) => ({
            ...prevRol,
            permissions_updated: permisosSelected,
        }));
    },[permisosSelected])

    useEffect(()=>{
        getRol();
    },[])


    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <h5 className='p-5'>Nuevo Rol</h5>
                    <div className="row m-10" id='general-form' style={{}}>
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="nombre">Nombre del Rol</label>
                                <input id="nombre" value={rol.name} type="text" onChange={(e)=>setRol({...roles,name:e.target.value})} />
                            </div>
                        </div>
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="descripcion">Descripcion</label>
                                <textarea id="descripcion" className="materialize-textarea validate"
                                value={rol.description}  onChange={(e)=>setRol({...rol,description:e.target.value})}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='container-admin-container m-p-0'>
                        <div className='row s12 info-badge m-p-0' style={{bordeRadius:'10px!important', padding:'0px!important', margin:'0px!important'}}>
                            <div className="col s12 m12 row m-p-0 " style={{padding:'0px!important', margin:'0px!important',}}>
                                <div className='col s12 m2 m-p-0' 
                                style={{height:'26px', width:26}}>
                                    <img className='dentometric m-p-0 ' src='/images/rol4.png' alt='dento metric' 
                                    style={{height:'100%', width:'100%'}} />
                                </div>
                                <div className="col s12 m10 m-6 centar"> Listado de permisos a asignar a usuarios</div> 
                            </div>
                        </div>
                    </div>
                    <PermisosCollapsible permisosSelected={permisosSelected} setPermisosSelected={setPermisosSelected}/>

                    <div className='col s12 p-t-35'> 
                        <button className="btn waves-effect waves-light" type="submit" name="action" onClick={(e)=>saveRole()}>Guardar
                            <i className="material-icons right">save</i>
                        </button>
                    </div>
                
                </div>
            </div>
        </>
    );
};

export default EditarRol;