import React,{useState} from 'react';
import  DatePicker,{ registerLocale }  from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('es', es);


// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Ranges = (props) => {
    const {startDate, setStartDate,endDate, setEndDate,searchDates,setSearchDates} = props;

   // const [startDate, setStartDate] = useState(new Date());
    //const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
  
        let inicio = new Date(start);
        inicio.setMinutes(inicio.getMinutes() - inicio.getTimezoneOffset());
        let fin = new Date(end);
        fin.setMinutes(fin.getMinutes() - fin.getTimezoneOffset());

        setStartDate(start);
        setEndDate(end);  
        setSearchDates({...searchDates,start:inicio.toISOString().split('T')[0],end:fin.toISOString().split('T')[0]});
    };

    return (
        <DatePicker 
            selected={startDate}
            value={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            locale="es"
            inline 
        />
    );
};

export default Ranges;